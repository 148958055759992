import React from 'react';
import { Logo } from '../../Common';
import { Row, Col, Button, Layout, message, Menu, notification, Modal } from 'antd';
import './SideNav.css';
import { AuthContext } from '../../Context/AuthContext';
import { DataContext } from '../../Context/ProfileData';
import { withRouter, Link } from 'react-router-dom';
import { v4 } from 'uuid';


const EMBED = {
    LIVECLASSES: "LIVECLASSES",
    CLIENT_PROGRAMS: "CLIENT_PROGRAMS",
    ON_DEMAND_VIDEOS: "ON_DEMAND_VIDEOS",
    CLIENT_PORTAL: "CLIENT_PORTAL",
};

class SideNav extends React.Component {
    static contextType = AuthContext;
    state = {
        collapsed: false,
        embed: EMBED.LIVECLASSES,
        embedModalOpen: false,
    }

    onCollapse = collapsed => {
        this.setState({
            collapsed: collapsed,
        })
    }
    handleSignOut = async () => {
        try {
            const { logout } = this.context;
            await logout();
            this.props.history.push('/login');
        } catch(e) {
            console.log(e);
            message.error('Failed to log out.');
        }
    }

    cancelSubscription = () => {
        notification.open({
            message: 'Please contact us.',
            description: 'Need to cancel your subscription? Please email us at support@phros.ca',
            duration: null,
        })
    }

    getLiveClassEmbed = () => {
        const { currentUser } = this.context;
        return (
            `
            <iframe
            src="https://phros.ca/embed/${currentUser.uid}/classes"
            title="Live Classes"
            frameborder="0"
            style="height: 1000px !important; width: 100% !important;"
            id="live-class-embed">
            </iframe>
            `
        )
    }

    getProgramEmbed = () => {
        const { currentUser } = this.context;
        return (
            `
            <iframe
            src="https://phros.ca/embed/${currentUser.uid}/programs"
            title="Programs"
            frameborder="0"
            style="height: 1000px !important; width: 100% !important;">
            </iframe>
            `
        )
    }

    getCustomerPortal = () => {
        const { currentUser } = this.context;
        return (
            `
            <iframe
            src="https://phros.ca/embed/${currentUser.uid}/purchases"
            title="Customer Login Area"
            frameborder="0"
            style="height: 1000px !important; width: 100% !important;">
            </iframe>
            `
        )
    }

    render() {
        return (
            <DataContext.Consumer>
                {
                    data => (
                <Layout.Sider
                breakpoint='lg'
                collapsedWidth='0'
                className='SideNav-Sider'
                theme='light'
                width={300}
                collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                    <Modal
                        onCancel={() => this.setState({ embedModalOpen: false })}
                        closable
                        visible={this.state.embedModalOpen}
                        footer={null}
                    >
                        <div style={{ marginBottom: "30px" }}></div>
                        {
                            this.state.embed === EMBED.LIVECLASSES ?
                            <div>
                                <h4>Copy the HTML below and add it to your website so customers can purchase Live Classes directly from your site</h4>
                                <div style={{ padding: "30px" }}>
                                    <code className="language-html">
                                        {this.getLiveClassEmbed()}
                                    </code>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <Button onClick={() => {
                                        try {
                                            navigator.clipboard.writeText(this.getLiveClassEmbed())
                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }}>Copy HTML</Button>
                                </div>
                            </div>
                            :
                            this.state.embed === EMBED.CLIENT_PROGRAMS ?
                            <div>
                                <h4>Copy the HTML below and add it to your website so customers can purchase Programs directly from your site</h4>
                                <div style={{ padding: "30px" }}>
                                    <code className="language-html">
                                        {this.getProgramEmbed()}
                                    </code>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <Button onClick={() => {
                                        try {
                                            navigator.clipboard.writeText(this.getProgramEmbed())
                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }}>Copy HTML</Button>
                                </div>
                            </div>
                            :
                            this.state.embed === EMBED.CLIENT_PORTAL ?
                            <div>
                                <h4>Copy the HTML below and add it to your website so customers can login to your site to view their purchases.</h4>
                                <div style={{ padding: "30px" }}>
                                    <code className="language-html">
                                        {this.getCustomerPortal()}
                                    </code>
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <Button onClick={() => {
                                        try {
                                            navigator.clipboard.writeText(this.getCustomerPortal())
                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }}>Copy HTML</Button>
                                </div>
                            </div>
                            :
                            null
                        }
                    </Modal>
                    <Row justify='center' className='SideNav-Row' gutter={[0,10]}>
                        <Col xs={24} className='SideNav-Main-Col'>
                            <Logo LogoOnly />
                            <h1>PHROS</h1>
                        </Col>
                        <Col xs={24}>
                            <Menu mode='inline'>
                                <Menu.Item>
                                    <Link to='/'>
                                        Dashboard
                                    </Link>
                                </Menu.Item>
                                <Menu.Item>
                                    <Link to='/profile'>
                                        Profile
                                    </Link>
                                </Menu.Item>
                                <Menu.SubMenu title='Client Programs'>
                                    <Menu.Item>
                                        <Link to={`/all-programs`}>
                                            View All Programs
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/programs/${v4()}`}>
                                            Add Client Program
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>

                                <Menu.SubMenu title='Live Classes'>
                                    <Menu.Item>
                                        <Link to={`/add-live-class/${v4()}`}>
                                            Add Live Class
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/view-live-classes`}>
                                            View Live Classes
                                        </Link>
                                    </Menu.Item>
                                    {/*
                                    <Menu.SubMenu title="Live Bootcamps">
                                        <Menu.Item>
                                            <Link to={`/bootcamp/${v4()}`}>
                                                Add Bootcamp
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link to="/all-bootcamps">
                                                View Bootcamps
                                            </Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                    */}
                                </Menu.SubMenu>

                                <Menu.SubMenu title='On-Demand Classes'>
                                    <Menu.Item>
                                        <Link to={`/on-demand-class/${v4()}`}>
                                            Add On-Demand Class
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/view-on-demand-classes`}>
                                            View On Demand Classes
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>

                                <Menu.SubMenu title="Add to your Website">
                                    <Menu.Item>
                                        <Button
                                        onClick={() => {
                                            this.setState({
                                                embed: EMBED.CLIENT_PROGRAMS,
                                                embedModalOpen: true,
                                            })
                                        }}
                                        type="text" style={{ padding: "0px" }}>
                                            Add Client Programs
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button
                                        onClick={() => {
                                            this.setState({
                                                embed: EMBED.LIVECLASSES,
                                                embedModalOpen: true,
                                            })
                                        }}
                                        type="text" style={{ padding: "0px" }}>
                                            Add Live Classes
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button
                                        onClick={() => {
                                            this.setState({
                                                embed: EMBED.CLIENT_PORTAL,
                                                embedModalOpen: true,
                                            })
                                        }}
                                        type="text" style={{ padding: "0px" }}>
                                            Add Customer Portal
                                        </Button>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                <Menu.Item>
                                    <Link to='/website'>
                                        Edit Website
                                    </Link>
                                </Menu.Item>
                                {/*
                                {
                                    data.profile.PLAN?.FEATURES.TRAININGPROGRAM ?
                                    <Menu.SubMenu title='Build a Training Program'>
                                        <Menu.SubMenu title="Exercises">
                                            <Menu.Item>
                                                <Link to={`/exercise/${v4()}`}>
                                                    Add Exercise
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to="/all-exercises">
                                                    View Exercises
                                                </Link>
                                            </Menu.Item>
                                        </Menu.SubMenu>
                                        <Menu.SubMenu title="Programs">
                                            <Menu.Item>
                                                <Link to={`/program/${v4()}`}>
                                                    Create Program
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <Link to="/view-programs">
                                                    View Programs
                                                </Link>
                                            </Menu.Item>
                                        </Menu.SubMenu>
                                    </Menu.SubMenu>
                                        :
                                        null
                                }
                                {
                                    data.profile?.PLAN?.FEATURES?.MEMBERSHIPS && (data.profile?.PLAN?.NAME === "ADVANCED" || data.profile?.PLAN?.NAME === "INTERMEDIATE") ?
                                    <Menu.SubMenu title='Memberships'>
                                        <Menu.Item>
                                            <Link to={`/membership/create`}>
                                                Create Membership
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Link to={`/membership/view-memberships`}>
                                                View Memberships
                                            </Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                    :
                                    null
                                }
                                <Menu.SubMenu title='Customer Coupons'>
                                    <Menu.Item>
                                        <Link to={`/coupon/create-coupon`}>
                                            Create Coupon
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link to={`/view-coupons`}>
                                            View Coupons
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                */}
                                {
                                    data.profile.uid === "wDcTtoCPb9NcXDvexhhzptk50dx2" ?
                                    <Menu.Item>
                                        <Link to="/custom-website-av">
                                            Custom Website
                                        </Link>
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {
                                    data.profile?.PLAN?.FREETRIAL ?
                                    <Menu.Item>
                                        <Link to="/pricing">
                                            <Button block>
                                                Purchase Plan
                                            </Button>
                                        </Link>
                                    </Menu.Item>
                                    :
                                    <Menu.Item>
                                        <Button block danger onClick={this.cancelSubscription}>
                                            Cancel Subscription
                                        </Button>
                                    </Menu.Item>
                                }

                                <Menu.Item>
                                <Button size='large' type='primary' className='Button' block onClick={this.handleSignOut}>Sign Out</Button>
                                </Menu.Item>
                            </Menu>
                        </Col>
                    </Row>
                </Layout.Sider>
                )
            }
        </DataContext.Consumer>
        )
    }
}

export default withRouter(SideNav);