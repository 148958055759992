import React from 'react';
import './Navbar1.css';
import { Row, Col, Button, Menu, Drawer, Dropdown, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { checkArray } from '../../../../../Verification';
import { SocialIcon } from 'react-social-icons';
import { DownOutlined, ShoppingOutlined, MenuOutlined } from "@ant-design/icons";

/*
Props:
LogoURL
BusinessName
ExistingWebsite
ExistingWebsiteURL
SocialMedia
Profile
*/

class Navbar1 extends React.Component {
    state = {
        visible: false,
    }

    render() {
        const dropdownMenu = (
            <Menu>
                {
                    this.props.Profile?.PLAN?.FEATURES?.LIVECLASSES ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/live-classes">Live Classes</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.ONDEMAND ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/on-demand-classes">On Demand Videos</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.TRAININGPROGRAM ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/programs">Workout Programs</Link>
                    </Menu.Item>
                    :
                    null
                }
                {
                    this.props.Profile?.PLAN?.FEATURES?.MEMBERSHIPS ?
                    <Menu.Item>
                        <Link className="Navbar1-Large-Link-Text" to="/memberships">Memberships</Link>
                    </Menu.Item>
                    :
                    null
                }
            </Menu>
        )
        return (
            <Row className="Navbar1-Main-Row">
            <Col xs={0} md={24}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Row align="middle">
                            <Col>
                            <img className="Navbar1-Large-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                            </Col>
                            <Col>
                            <h3 className="Navbar1-Large-BusinessName">{this.props.BusinessName}</h3>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={[20,10]} align="middle">
                            <Col>
                                {
                                    this.props.ExistingWebsite ?
                                    <a className="Navbar1-Large-Link-Text" href={this.props.ExistingWebsiteURL} target="_blank" rel="noreferrer">Home</a>
                                    :
                                    <Link to="/" className="Navbar1-Large-Link-Text">Home</Link>
                                }
                            </Col>
                            <Col>
                                <a className="Navbar1-Large-Link-Text" href="/#contact-me">Contact Me</a>
                            </Col>
                            <Col>
                               <Dropdown overlay={dropdownMenu}>
                                <Button className="Navbar1-Large-Link-Text" type="link" onClick={e => e.preventDefault()}>
                                Shop <DownOutlined />
                                </Button>
                               </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={[10,10]} align="middle">
                            {
                                checkArray(this.props.SocialMedia).map(sm => (
                                    <Col>
                                    <SocialIcon bgColor="black" fgColor="white" className="Navbar1-React-Social-Icon"  url={sm} />
                                    </Col>
                                ))
                            }
                            <Col>
                            <Button type="link" icon={<ShoppingOutlined className="Navbar1-Shopping-Cart" />} />
                            </Col>
                            <Col>
                            <button className="Navbar1-Large-Login-Button">Login</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={0}>
                <Row justify="space-between" align="middle">
                    <Col>
                    <h3 className="Navbar1-Large-BusinessName">{this.props.BusinessName}</h3>
                    </Col>
                    <Col>
                        <Button onClick={() => {
                            this.setState({
                                visible: true,
                            })
                        }} icon={<MenuOutlined />} />
                    </Col>
                </Row>
                <Row justify="center" className="Navbar-Drawer">
                    <Col xs={24}>
                    <Drawer
                    placement="right"
                    closable
                    onClose={() => {
                        this.setState({
                            visible: false,
                        })
                    }}
                    style={{ position: "absolute" }}
                    visible={this.state.visible}>
                        <Row justify="center">
                            <Col xs={24} className="Navbar-Small-Logo-Name">
                            <img className="Navbar1-Small-Logo" src={this.props.LogoURL} alt={this.props.BusinessName} />
                            <h3 className="Navbar1-Small-BusinessName">{this.props.BusinessName}</h3>
                            <Divider />
                            </Col>
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                                {
                                    this.props.ExistingWebsite ?
                                    <a className="Navbar1-Large-Link-Text" href={this.props.ExistingWebsiteURL} target="_blank" rel="noreferrer">Home</a>
                                    :
                                    <Link to="/" className="Navbar1-Large-Link-Text">Home</Link>
                                }
                            </Col>
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                                <a className="Navbar1-Large-Link-Text" href="/#contact-me">Contact Me</a>
                            </Col>
                            {
                                this.props.Profile?.PLAN?.FEATURES?.LIVECLASSES ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/live-classes">Live Classes</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                this.props.Profile?.PLAN?.FEATURES?.ONDEMAND ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/on-demand-classes">On Demand Videos</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                this.props.Profile?.PLAN?.FEATURES?.TRAININGPROGRAM ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/programs">Workout Programs</Link>
                                </Col>
                                :
                                null
                            }
                            {
                                this.props.Profile?.PLAN?.FEATURES?.MEMBERSHIPS ?
                                <Col xs={24} className="Navbar-Small-Drawer-Link">
                                    <Link className="Navbar1-Large-Link-Text" to="/memberships">Memberships</Link>
                                </Col>
                                :
                                null
                            }
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                            <Button type="link" icon={<ShoppingOutlined className="Navbar1-Shopping-Cart" />} />
                            </Col>
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                            <button className="Navbar1-Large-Login-Button">Login</button>
                            </Col>
                            <Col xs={24} className="Navbar-Small-Drawer-Link">
                                <Row justify="center" gutter={[10,10]}>
                                {
                                    checkArray(this.props.SocialMedia).map(sm => (
                                        <Col>
                                        <SocialIcon bgColor="black" fgColor="white" className="Navbar1-React-Social-Icon"  url={sm} />
                                        </Col>
                                    ))
                                }
                                </Row>
                            </Col>
                        </Row>
                    </Drawer>
                    </Col>
                </Row>
            </Col>
            </Row>
        )
    }
}

export default Navbar1;