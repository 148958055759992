import React from "react";
import IDS from "../../IDS";
import { ImageTemplate, ColourTemplate } from "../../TemplateData";
import { Component } from "../../Component";
import "./Banner2.css";

class Banner2 extends Component {
    ID = IDS.BANNER2;
    constructor(title, subtitle, CTA, image, backgroundColour, email) {
        super();
        this.Title = title;
        this.SubTitle = subtitle;
        this.CTA = CTA;
        this.Image = image;
        this.BackgroundColour = backgroundColour;
        this.Email = email;
    }
    static Default() {
        return new Banner2("Your Title", "You can change the text, background colour, and image.", "contact me", ImageTemplate, ColourTemplate, "your@email.com");
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Banner2(obj.Title, obj.SubTitle, obj.CTA, obj.Image, obj.BackgroundColour, obj.Email);
    }
}

const Banner2View = ({ data = Banner2.Default() }) => {
    return (
        <div className="Banner2--Container" style={{ backgroundColor: data.BackgroundColour }}>
            <div className="Banner2-ImageDiv">
                <img src={data.Image} alt={data.Title} className="Banner2--Image" />
            </div>
            <div className="Banner2--TextContainer">
                <h1 className="Banner2--Title">{data.Title}</h1>
                <h3 className="Banner2--SubTitle">{data.SubTitle}</h3>
                <button className="Banner2--Contact">{data.CTA}</button>
            </div>
        </div>
    )
}


export {
    Banner2,
    Banner2View,
}