import React from "react";
import "./Navbar1.css";
import { LogoTemplate } from "../../TemplateData";
import { NAVBARS } from "../../IDS";
import { Component } from "../../Component";
import { SocialIcon } from "react-social-icons";
import "../../common.css";

class Navbar1Link {
    constructor(name, url, external) {
        this.name = name;
        this.url = url;
        this.external = external;
    }
    static Default() {
        return new Navbar1Link("Home", "/", false);
    }
}
class Navbar1 extends Component {
    ID = NAVBARS.NAVBAR1;
    links = [Navbar1Link.Default()];
    constructor(logo, links, title, socialMediaLinks, CTA, ctaText, ctaLink) {
        super();
        this.logo = logo;
        this.links = links;
        this.Title = title;
        this.SocialMedia = socialMediaLinks;
        this.CTA = CTA;
        this.CTAText = ctaText;
        this.CTALink = ctaLink;
    }
    setLinks(links) {
        this.links = links;
    }
    static Default() {
        const defaultLink = Navbar1Link.Default();
        const anotherLink = new Navbar1Link("Classes", "/classes", false);
        return new Navbar1(LogoTemplate, [defaultLink, anotherLink], "Phros", ["https://www.instagram.com/phros.ca/"], true, "Contact Me", "/contact-me");
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        const title = obj.Title || "";
        const sm = obj.SocialMedia || [];
        return new Navbar1(obj.logo, obj.links, title, sm);
    }
}

const Navbar1View = ({ data = Navbar1.Default() }) => {
    return (
        <div className="Navbar1--Container">
            <div className="Navbar1--Upper">
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <img
                            style={{ width: "40px", height: "40px", objectFit: "contain" }}
                            src={data.logo}
                            alt="Logo Alt Text"
                        />
                    </div>
                    <div>
                        <h2 className="Navbar1--Upper--Title">{data.Title}</h2>
                    </div>
                </div>
                <div className="d-flex justify-space-around flex-wrap">
                    {
                        data?.SocialMedia?.map((link) => {
                            return (
                                <div className="Navbar1--SocialMediaDiv">
                                    <SocialIcon url={link} style={{ width: "30px", height: "30px" }} bgColor="black" fgColor="white" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="Navbar1--Lower d-flex justify-content-center w-100 flex-wrap">
                {
                    data.links.map((link) => {
                        return (
                            <div>
                                {
                                    link.external ?
                                    <a href={link.url} style={{ color: "black", paddingLeft: "10px", paddingRight: "10px" }} target="_blank" rel="noreferrer">{link.name}</a>
                                    :
                                    <a href={link.url} style={{ color: "black", paddingLeft: "10px", paddingRight: "10px" }}>{link.name}</a>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export {
    Navbar1,
    Navbar1Link,
    Navbar1View,
}