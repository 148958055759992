import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from './Context/AuthContext';
import { DataContext } from './Context/ProfileData';

class PrivateRoute extends React.Component {
    static contextType = AuthContext;
    render() {
        const { currentUser } = this.context;
        const Component = this.props.component;
        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <div>
                            {
                            currentUser ?
                            <Route {...this.props} render={(props) => <Component {...props}/>} />
                            :
                            <Redirect to='/login' />
                            }
                            </div>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

export default PrivateRoute;