import { useState, useEffect } from "react";
import { Navbar1, Navbar1Link } from "../../phros-web-components/Navbars/Navbar1/Navbar1";
import { PhrosUpload } from "../../Common/Uploads/Upload";
import { ImageTemplate } from "../../phros-web-components/TemplateData";
import { Input, Button, Select } from "antd";
import { DeleteFilled } from "@ant-design/icons";

/*
    this.logo = logo;
    this.links = links;
    this.Title = title;
    this.SocialMedia = socialMediaLinks;
    this.CTA = CTA;
    this.CTAText = ctaText;
    this.CTALink = ctaLink;
*/

const Navbar1Edit = ({ data = Navbar1.Default(), editComponent = () => {} }) => {
    const [imageMap, setImageMap] = useState({
        "image_id": {
            url: data.logo,
            description: data.Title,
            fileId: "image_id",
        }
    });
    const [links, setLinks] = useState([Navbar1Link.Default()]);
    const [socialMedia, setSocialMedia] = useState(data.SocialMedia);
    const [title, setTitle] = useState(data.Title);
    const [cta, setCta] = useState(data.CTA);
    const [ctaText, setCtaText] = useState(data.CTAText);
    const [ctaLink, setCtaLink] = useState(data.CTALink);

    const deleteWebComponentImage = () => {
        return;
    }

    useEffect(() => {
        const keys = Object.keys(imageMap);
        let imageUrl = ImageTemplate;
        if (keys.length > 0) {
            const image = imageMap[keys[0]];
            imageUrl = image.url;
        }
        const updateBanner = new Navbar1(imageUrl, links, title, socialMedia, cta, ctaText, ctaLink);
        editComponent(updateBanner);
    }, [imageMap, links, socialMedia, cta, ctaText, title, ctaLink]);

    return (
        <div>
            <h3>Logo:</h3>
            <PhrosUpload
                accept = ".jpg,.jpeg,.png"
                text="Choose an image or drag it here."
                type="static"
                fileMap={imageMap}
                setFileMap={setImageMap}
                deleteFile={deleteWebComponentImage}
            />
            <br />
            <br />
            <h3>Title:</h3>
            <Input style={{ marginBottom: "20px" }} value={title} onChange={(e) => setTitle(e.target.value)} />

            <h3>Site Links: </h3>
            {
                links?.map((link, index) => {
                    return (
                        <div style={{ marginBottom: "10px" }}>
                            <div className="d-flex justify-space-between align-items-center">
                                <p style={{ fontWeight: 600, marginBottom: "0px", marginRight: "5px", width: "80px" }}>Name:</p>
                                <Input
                                    onChange={(e) => {
                                        setLinks((prevState) => {
                                            const newLink = new Navbar1Link(e.target.value, link.url, link.external);
                                            return [
                                                ...prevState.slice(0, index),
                                                newLink,
                                                ...prevState.slice(index+1)
                                            ]
                                        })
                                    }}
                                    value={link.name}
                                />
                            </div>
                            <div className="d-flex justify-space-between align-items-center">
                                <p style={{ fontWeight: 600, marginBottom: "0px", marginRight: "5px", width: "80px" }}>URL:</p>
                                <Input
                                    onChange={(e) => {
                                        setLinks((prevState) => {
                                            const newLink = new Navbar1Link(link.name, e.target.value, link.external);
                                            return [
                                                ...prevState.slice(0, index),
                                                newLink,
                                                ...prevState.slice(index+1)
                                            ]
                                        })
                                    }}
                                    value={link.url}
                                />
                            </div>
                            <div className="d-flex justify-space-between align-items-center">
                                <p style={{ fontWeight: 600, marginBottom: "0px", marginRight: "5px", width: "80px" }}>External:</p>
                                <Select
                                    onChange={(value) => {
                                        setLinks((prevState) => {
                                            const newLink = new Navbar1Link(link.name, link.url, value);
                                            return [
                                                ...prevState.slice(0, index),
                                                newLink,
                                                ...prevState.slice(index+1)
                                            ]
                                        })
                                    }}
                                    value={link.external}
                                    style={{ width: "100%" }}
                                >
                                    <Select.Option value={true}>Yes</Select.Option>
                                    <Select.Option value={false}>No</Select.Option>
                                </Select>
                            </div>
                        </div>
                    )
                })
            }
            <Button
            style={{ marginTop: "5px" }}
            type="primary"
            ghost
            onClick={() => setLinks(prevState => {
                const link = new Navbar1Link("Nav Link", "/", false);
                return [...prevState, link];
            })}>Add Site Link</Button>

            <h3 style={{ marginTop: "20px" }}>Social Media Links:</h3>
            <p>Enter in the your social media links below (i.e. https://www.instagram.com/phros.ca/).</p>
            {
                socialMedia?.map((link, index) => {
                    return (
                        <div key={index} style={{ marginTop: "20px" }} className="d-flex justify-space-between align-items-center">
                            <Input value={link} onChange={(e) => setSocialMedia((prevState) => {
                                return [
                                    ...prevState.slice(0, index),
                                    e.target.value,
                                    ...prevState.slice(index+1),
                                ];
                            })} />
                            <Button onClick={() => {
                                setSocialMedia((prevState) => {
                                    const temp = [...prevState];
                                    temp.splice(index, 1);
                                    return temp;
                                })
                            }} style={{ marginLeft: "5px" }} type="primary" danger><DeleteFilled /></Button>
                        </div>
                    )
                })
            }
            <Button style={{ marginTop: "20px" }} type="primary" ghost onClick={() => setSocialMedia(prevState => {
                return [...prevState, ""];
            })}>Add Social Media</Button>
        </div>
    )
};

export {
    Navbar1Edit
}