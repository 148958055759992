import React from "react";
import IDS from "../../IDS";
import { ImageTemplate, ColourTemplate } from "../../TemplateData";
import { Component } from "../../Component";
import "./Banner3.css";

class Banner3 extends Component {
    ID = IDS.BANNER3;
    constructor(title, description, image, backgroundColour) {
        super();
        this.Title = title;
        this.Description = description;
        this.Image = image;
        this.BackgroundColour = backgroundColour;
    }
    static Default() {
        return new Banner3("Your Title", "You can change the text, background colour, and image.", ImageTemplate, ColourTemplate);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Banner3(obj.Title, obj.Description, obj.Image, obj.BackgroundColour);
    }
}


const Banner3View = ({ data = Banner3.Default() }) => {
    return (
        <div className="Banner3--Container" style={{ backgroundColor: data.BackgroundColour }}>
            <div className="Banner3--Left">
                <div className="Banner3--TextContainer">
                    <h1 className="Banner3--Title">{data.Title}</h1>
                    <h5 className="Banner3--Description">{data.Description}</h5>
                </div>
            </div>
            <div className="Banner3--Right">
                <img src={data.Image} alt={`${data.Title}`} className="Banner3--Image" />
            </div>
        </div>
    )
}

export {
    Banner3,
    Banner3View,
}
