import React from "react";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";

class Text1 extends Component {
    ID = IDS.TEXT1;
    constructor(variant, align, bold, italic, colour, text) {
        super();
        this.Variant = variant;
        this.Align = align;
        this.Bold = bold;
        this.Italic = italic;
        this.Colour = colour;
        this.Text = text;
    }
    static Default() {
        return new Text1("h1", "start", false, false, "black", "Edit this text!");
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Text1(obj.Variant, obj.Align, obj.Bold, obj.Italic, obj.Colour, obj.Text);
    }
}

const Text1View = ({ data = Text1.Default() }) => {
    const styles = {
        fontWeight: data.Bold ? "bold" : "normal",
        fontStyle: data.Italic ? "italic" : "normal",
        color: data.Colour,
        textAlign: data.Align,
        width: "100%"
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
        <div className="container">
            {
                data.Variant === "h2" ?
                <h2 className="Text1--H2" style={styles}>{data.Text}</h2>
                :
                data.Variant === "h3" ?
                <h3 className="Text1--H3" style={styles}>{data.Text}</h3>
                :
                data.Variant === "h4" ?
                <h4 className="Text1--H4" style={styles}>{data.Text}</h4>
                :
                data.Variant === "h5" ?
                <h5 className="Text1--H5" style={styles}>{data.Text}</h5>
                :
                data.Variant === "p" ?
                <p className="Text1--P" style={styles}>{data.Text}</p>
                :
                <h1 className="Text1--H1" style={styles}>{data.Text}</h1>
            }
        </div>
        </div>
    )
}


export {
    Text1,
    Text1View,
}