const checkString = (str) => {
    if (typeof str === "string") {
      return str;
    } else {
      return "";
    }
  };

const checkStringReturnDef = (str, def) => {
    if (typeof str === "string") {
        return str;
    } else {
        return def;
    }
}

  const checkBool = (bool) => {
    if (typeof bool === "boolean") {
      return bool;
    } else {
      return false;
    }
  };

  const checkArray = (array) => {
    if (Array.isArray(array)) return array;
    return [];
  };

  const checkInt = (intNum) => {
    if (Number.isInteger(intNum)) return intNum;
    return 0;
  };

  const checkNum = (n) => {
    if (Number.isInteger(n) || (Number(n) === n && n % 1 !== 0)) return n;
    return 0;
  };

  const checkObj = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      return obj;
    } else {
      return {};
    }
  };

  module.exports = {
    checkString,
    checkBool,
    checkArray,
    checkInt,
    checkNum,
    checkStringReturnDef,
    checkObj,
  };
