import { Input, Button } from "antd";
import { RootSider } from "./Sider";
import { useState, useContext, useEffect } from "react";
import { WebsiteContext } from "../../Context/WebsiteContext";

const Dashboard = () => {
    const [url, setUrl] = useState("");
    const [displayError, setDisplayError] = useState(false);
    const { siteInfo, setSiteInfo, upsertDomain } = useContext(WebsiteContext);

    useEffect(() => {
        if (!siteInfo) return;
        const { URL, PhrosDomain } = siteInfo;
        if (PhrosDomain) {
            const temp = String(URL).replace(".phros.ca", "");
            setUrl(temp);
        }
    }, [siteInfo])

    const handleUrl = (urlInput) => {
        if (String(urlInput).match(/[^a-zA-Z0-9]/)) {
            setDisplayError(true);
        }
        let temp = String(urlInput).replace(/[^a-zA-Z0-9]/gi,'');
        setSiteInfo((prevState) => {
            return {
                ...prevState,
                URL: temp,
            }
        })
    };

    return (
        <RootSider>
            <div style={{ padding: "20px" }}>
                <h2 style={{ fontWeight: 600 }}>Website Details:</h2>
                <div style={{ maxWidth: "400px" }}>
                    <h5>Website URL:</h5>
                    <Input value={siteInfo.URL} onChange={(e) => handleUrl(e.target.value)} addonBefore="https://" addonAfter=".phros.ca" />
                    <small style={{ fontWeight: 600 }}>Your website URL: {`https://${url}.phros.ca`}</small>
                    <br />
                    {
                        displayError ?
                        <small style={{ color: "red" }}>URL can only contain letters and numbers.</small>
                        :
                        null
                    }
                    <br />
                    <Button type="primary" danger onClick={upsertDomain}>Update Website URL</Button>
                </div>
                <div style={{ marginTop: "50px" }}>
                    <h2 style={{ fontWeight: 600 }}>View Your Website:</h2>
                    <Button type="primary" ghost>
                    <a target="_blank" rel="noreferrer" href={`https://${siteInfo.URL}.phros.ca`}>View Website: https://{siteInfo.URL}.phros.ca</a>
                    </Button>
                </div>
                <div style={{ marginTop: "50px", marginBottom: "20px" }}>
                    <h5>Have a request, need a new design, or need help? Send us an email.</h5>
                    <a href="mailto:support@phros.ca">support@phros.ca</a>
                </div>
            </div>
        </RootSider>
    )
}

export {
    Dashboard,
}