import IDS, { NAVBARS, FOOTERS } from "./IDS";

// Banners
import { Banner1, Banner1View } from "./Banners/Banner1/Banner1";
import { Banner2, Banner2View } from "./Banners/Banner2/Banner2";
import { Banner3, Banner3View } from "./Banners/Banner3/Banner3";

// FAQs
import { FAQ1, FAQ1View } from "./FAQ/FAQ1/FAQ1";
import { FAQ2View, FAQ2 } from "./FAQ/FAQ2/FAQ2";


// Profiles
import { Profile1, Profile1View } from "./Profile/Profile1/Profile1";


// Spacers
import { Spacer1, Spacer1View } from "./Spacer/Spacer1/Spacer1";

// Text
import { Text1, Text1View } from "./Text/Text1/Text1";

// HTML
import { HTML1, HTML1View } from "./HTML/HTML1/HTML1";

// Navbars
import { Navbar1, Navbar1View } from "./Navbars/Navbar1/Navbar1";

// Live Classes
import { LiveClass, LiveClassView } from "./PhrosEmbed/LiveClasses/LiveClass";

// Programs
import { Program, ProgramView } from "./PhrosEmbed/Programs/Program";

// Cards
import { Card1View, Card1 } from "./Cards/Card1/Card1";

// Purchases
import { Purchase, PurchaseView } from "./PhrosEmbed/Purchases/Purchase";

// Footers
import { Footer1View, Footer1 } from "./Footers/Footer1/Footer1";

// Default
import { Default1View, Default1 } from "./Default/Default";

const Banners = {
    [IDS.BANNER1]: {
        Class: Banner1,
        View: Banner1View,
    },
    [IDS.BANNER2]: {
        Class: Banner2,
        View: Banner2View,
    },
    [IDS.BANNER3]: {
        Class: Banner3,
        View: Banner3View,
    }
};

const Faqs = {
    [IDS.FAQ1]: {
        Class: FAQ1,
        View: FAQ1View,
    },
    [IDS.FAQ2]: {
        Class: FAQ2,
        View: FAQ2View,
    }
};

const Profiles = {
    [IDS.PROFILE1]: {
        Class: Profile1,
        View: Profile1View,
    }
}

const Spacers = {
    [IDS.SPACER1]: {
        Class: Spacer1,
        View: Spacer1View,
    }
}

const Text = {
    [IDS.TEXT1]: {
        Class: Text1,
        View: Text1View,
    }
}

const Navbar = {
    [NAVBARS.NAVBAR1]: {
        Class: Navbar1,
        View: Navbar1View,
    }
};

const Footer = {
    [FOOTERS.FOOTER1]: {
        Class: Footer1,
        View: Footer1View,
    }
};

const HTML = {
    [IDS.HTML1]: {
        Class: HTML1,
        View: HTML1View,
    }
};

const LiveClassEmbed = {
    [IDS.LIVECLASS1]: {
        Class: LiveClass,
        View: LiveClassView,
    }
}

const ProgramEmbed = {
    [IDS.PROGRAM1]: {
        Class: Program,
        View: ProgramView,
    }
}

const PurchaseEmbed = {
    [IDS.PURCHASES1]: {
        Class: Purchase,
        View: PurchaseView,
    }
}

const Cards = {
    [IDS.CARD1]: {
        Class: Card1,
        View: Card1View,
    }
};

const DUMMY = {
    [IDS.DEFAULT]: {
        Class: Default1,
        View: Default1View,
    }
}

// Needs to have a Components and Description for each
const ADD_COMPONENTS = {
    Banners: {
        Components: Banners,
        Description: "Banners are sections that could contain an image, text, and a paragraph. Banners typically span the full-width of your website, and are useful for displaying eye-catching information."
    },
    FAQs: {
        Components: Faqs,
        Description: "FAQ's (frequently asked questions) are a list of questions and answers that your customers typically ask, or for information you want to provide upfront."
    },
    Profiles: {
        Components: Profiles,
        Description: "Profile Sections are sections that allow you to leave long text information with various titles and an image. You can use these sections to describe yourself or anything you else you think is appropriate."
    },
    Spacers: {
        Components: Spacers,
        Description: "Spacers are areas of space to add between components. If you need more spacing, simply add more spacers and drag them in place."
    },
    Text: {
        Components: Text,
        Description: "Add some basic text to your website and change the size, color, boldness, and if italic or not."
    },
    HTML: {
        Components: HTML,
        Description: "Add your custom HTML with this component."
    },
    Cards: {
        Components: Cards,
        Description: "Cards can be a combination of images, text, and call-to-actions (CTA's)."
    },
    "Phros Classes": {
        Components: LiveClassEmbed,
        Description: "Add your live/in-person classes to your website with this component."
    },
    "Phros Programs": {
        Components: ProgramEmbed,
        Description: "Add your programs to your website with this component."
    },
    "Phros Login/Purchases": {
        Components: PurchaseEmbed,
        Description: "Add your customer login portal so they can view their purchases."
    },
}

const COMPONENTS = {
    ...Banners,
    ...Faqs,
    ...Profiles,
    ...Spacers,
    ...Text,
    ...HTML,
    ...LiveClassEmbed,
    ...ProgramEmbed,
    ...PurchaseEmbed,
    ...Cards,
    ...DUMMY,
}

export {
    COMPONENTS,
    ADD_COMPONENTS,
    Navbar,
    Footer,
}