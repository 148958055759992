import './Service2.css';
import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { checkArray } from '../../../../../Verification';
import DOMPurify from 'dompurify';

class Service2 extends React.Component {
    render() {
        return (
            <Row justify='center' style={{ backgroundColor: "white", padding: "20px" }}>
            <Col xs={24}>
                <Row gutter={[20,20]}>
                    {
                        checkArray(this.props.Services).map((service) => {
                            return (
                                <Col xs={24} md={12}>
                                    <Row className="Service2-Card">
                                        <Col xs={24}>
                                            <img src={service.ImageURL} alt={service.Title} className="Service2-Image" />
                                        </Col>
                                        <Col xs={24}>
                                            <div className="Service2-Div">
                                            <h2 className="Service2-Title">{service.Title}</h2>
                                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(service.Description)}} className="Service2-Description" />
                                            {
                                                service.ExternalLink ?
                                                <a href={service.Link} className="Service-Button">{service.ButtonText}</a>
                                                :
                                                <Link to={service.Link} className="Service-Button">{service.ButtonText}</Link>
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            </Row>
        )
    }
}

export default Service2;