import React from 'react';
import { Row, Col, Upload, Card, Button, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { checkArray } from '../../../Verification';


class Testimonials extends React.Component {
    render() {
        return (
            <Row gutter={[10,10]}>
                <Col xs={24}>
                    <h3>Testiomnial Section</h3>
                </Col>
                {
                    checkArray(this.props.Testimonials).map((tst, index) => (
                        <Col xs={24} sm={12}>
                            <Row gutter={[10,10]}>
                                <Col xs={24}>
                                    <p>Testimonial {index+1}</p>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <p>Name of Individual/Organization</p>
                                    <Input
                                    value={tst.Name}
                                    onChange={(e) => {
                                        let testimonials = [...this.props.Testimonials];
                                        testimonials[index].Name = e.target.value;
                                        this.props.handleChange("Testimonials", testimonials);
                                    }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <p>Testimonial/Review: </p>
                                    <Input.TextArea
                                    value={tst.Description}
                                    onChange={(e) => {
                                        let testimonials = [...this.props.Testimonials];
                                        testimonials[index].Description = e.target.value;
                                        this.props.handleChange("Testimonials", testimonials);
                                    }}
                                    autoSize={{ minRows: 3 }}
                                    />
                                </Col>
                                <Col xs={24}>
                                {
                                    tst.ImageURL !== undefined && tst.ImageURL !== null  && String(tst.ImageURL).length > 0?
                                    <Card
                                    cover={<img src={tst.ImageURL} alt="" className="Gallery-Card-Image-Style" />}
                                    >
                                    <Upload
                                    fileList={[]}
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={async (info) => {
                                        const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                        let testimonials = [...this.props.Testimonials];
                                        testimonials[index].ImageURL = url;
                                        this.props.handleChange("Testimonials", testimonials);
                                    }}>
                                        <Button icon={<UploadOutlined />}>Replace Image</Button>
                                    </Upload>
                                    </Card>
                                    :
                                    <Upload
                                        fileList={[]}
                                        accept=".png,.jpeg,.jpg"
                                        customRequest={async (info) => {
                                            const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                            let testimonials = [...this.props.Testimonials];
                                            testimonials[index].ImageURL = url;
                                            this.props.handleChange("Testimonials", testimonials);
                                        }}>
                                        <Button icon={<UploadOutlined />}>Upload Image</Button>
                                    </Upload>
                                }
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        let testimonials = [...this.props.Testimonials];
                                        testimonials.splice(index, 1);
                                        this.props.handleChange("Testimonials", testimonials);
                                    }}
                                    >
                                        Delete Testimonial {index+1}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
                <Col xs={24} sm={8}>
                    <Button
                    type="primary"
                    block
                    onClick={() => {
                        let testimonials = [...this.props.Testimonials];
                        let temp = {
                            ImageURL: "",
                            Name: "",
                            Description: "",
                        }
                        testimonials.push(temp);
                        this.props.handleChange("Testimonials", testimonials);
                    }}
                    >
                        Add Testimonial
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default Testimonials;