import React from "react";

const Scheduler = () => {

    return (
        <div>
            <h1>1-1 Scheduling</h1>
            <p>Link your calendar and get started</p>
        </div>
    )
}

export {
    Scheduler,
}