class Component {
}

export {
    Component,
}

// TEMPLATE:

/*

import React from "react";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";

class CompName extends Component {
    ID = IDS.Profile1;
    constructor() {
        super();
    }
    static Default() {
        return new CompName();
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new CompName();
    }
}

const ComponentView = ({ data = CompName.Default() }) => {
    return (
        <div>
        </div>
    )
}


export {
    CompName,
    ComponentView
}
*/