import { useState, useEffect } from "react";
import { Banner2 } from "../../phros-web-components/Banners/Banner2/Banner2";
import { Input } from "antd";
import { SketchPicker } from "react-color";
import { PhrosUpload } from "../../Common/Uploads/Upload";
import { ImageTemplate } from "../../phros-web-components/TemplateData";

const Banner2Edit = ({ data = Banner2.Default(), editComponent = () => {} }) => {
    const [title, setTitle] = useState(data.Title);
    const [subTitle, setSubTitle] = useState(data.SubTitle);
    const [cta, setCTA] = useState(data.CTA);
    const [colour, setColour] = useState(data.BackgroundColour);
    const [imageMap, setImageMap] = useState({
        "image_id": {
            url: data.Image,
            fileId: "image_id",
            title: "placeholder-image",
            name: "placeholder-image",
        }
    });
    const [email, setEmail] = useState(data.Email);

    useEffect(() => {
        const keys = Object.keys(imageMap);
        let imageUrl = ImageTemplate;
        if (keys.length > 0) {
            const image = imageMap[keys[0]];
            imageUrl = image.url;
        }
        const banner = new Banner2(title, subTitle, cta, imageUrl, colour, email);
        editComponent(banner);
    }, [title, subTitle, imageMap, cta, colour, email]);

    const deleteWebComponentImage = () => {
        return;
    }

    return (
        <div>
            <h3>Title:</h3>
            <Input.TextArea
                value = {title}
                onChange = {(e) => setTitle(e.target.value)}
            />
            <br />
            <br />
            <h3>Subtitle:</h3>
            <Input.TextArea
                value = {subTitle}
                onChange = {(e) => setSubTitle(e.target.value)}
            />
            <br />
            <br />
            <h3>CTA:</h3>
            <Input.TextArea
                value = {cta}
                onChange = {(e) => setCTA(e.target.value)}
            />
            <br />
            <br />
            <h3>Email:</h3>
            <small>What email do you want customers to email you at?</small>
            <Input.TextArea
                value = {cta}
                onChange = {(e) => setEmail(e.target.value)}
            />
            <br />
            <br />
            <h3>Image</h3>
            <PhrosUpload
                accept = ".jpg,.jpeg,.png"
                text="Choose an image or drag it here."
                type="static"
                fileMap={imageMap}
                setFileMap={setImageMap}
                deleteFile={deleteWebComponentImage}
            />
            <br />
            <br />
            <h3>Background Colour:</h3>
            <SketchPicker
                color={colour}
                onChangeComplete={(color) => setColour(color.hex)}
            />
        </div>
    )
};

export {
    Banner2Edit,
}