import React from 'react';
import 'antd/dist/antd.css';
import { AuthProvider } from './Context/AuthContext';
import { DataProvider } from './Context/ProfileData';
import { WebContextProvider } from "./Context/WebsiteContext";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import {
  StripeReauth,
  SignUp,
  Dashboard,
  Login, Profile,
  AddLiveClass,
  ViewLiveClasses,
  ReviewWebsite,
  OnDemandClass,
  ViewOnDemandClasses,
  Pricing,
  PaymentSuccess,
  AddExercise,
  ViewExercise,
  AddWorkout,
  ViewWorkouts,
  AddBootcamp,
  ViewBootcamps,
  EditWebsite,
  ViewPrograms,
  Program,
  Aumthenticus,
  Coupon,
  ViewCoupons,
  Membership,
  ViewMemberships,
  BuildWebsite,
  Scheduler,

  CourseProgram,
  ViewAllPrograms,
  WebsiteDashboard,
  PageEditor,
} from './Pages';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <DataProvider>
        <WebContextProvider>
        <Switch>
          <PrivateRoute exact path='/profile' component={Profile} />
          <PrivateRoute exact path='/add-live-class/:ID' component={AddLiveClass} />
          <PrivateRoute exact path='/view-live-classes' component={ViewLiveClasses} />
          <PrivateRoute exact path='/on-demand-class/:ID' component={OnDemandClass} />
          <PrivateRoute exact path='/view-on-demand-classes' component={ViewOnDemandClasses} />
          <PrivateRoute exact path='/review-website' component={ReviewWebsite} />
          <PrivateRoute exact path='/profile/stripe/reauth' component={StripeReauth} />
          <PrivateRoute exact path='/' component={Dashboard} />
          <PrivateRoute exact path="/exercise/:ID" component={AddExercise} />
          <PrivateRoute exact path="/all-exercises" component={ViewExercise} />

          <PrivateRoute exact path="/workouts/:ID" component={AddWorkout} />
          <PrivateRoute exact path="/program/:ID" component={Program} />
          <PrivateRoute exact path="/view-workouts" component={ViewWorkouts} />
          <PrivateRoute exact path="/view-programs" component={ViewPrograms} />
          <PrivateRoute exact path="/bootcamp/:ID" component={AddBootcamp} />
          <PrivateRoute exact path="/all-bootcamps" component={ViewBootcamps} />
          <PrivateRoute exact path="/edit-website" component={EditWebsite} />
          <PrivateRoute exact path="/build-website" component={BuildWebsite} />
          <PrivateRoute exact path="/coupon/create-coupon" component={Coupon} />
          <PrivateRoute exact path="/membership/create" component={Membership} />
          <PrivateRoute exact path="/membership/view-memberships" component={ViewMemberships} />
          <PrivateRoute exact path="/view-coupons" component={ViewCoupons} />
          <PrivateRoute exact path="/custom-website-av" component = {Aumthenticus} />

          {/* Workout route uses /program, when we deprecate, update */}
          <PrivateRoute exact path="/programs/:ID" component={CourseProgram} />
          <PrivateRoute exact path="/all-programs" component={ViewAllPrograms} />

          {/* NEW PHROS ROUTES */}
          <PrivateRoute exact path="/website" component={WebsiteDashboard} />
          <PrivateRoute exact path="/website/page/:ID" component={PageEditor} />

          <AuthRoute exact path="/plan-payment-success/:ID" component={PaymentSuccess} />
          <Route path="/scheduler" component={Scheduler} />
          <Route path='/login' component={Login} />
          <Route path='/sign-up' component={SignUp} />
          <AuthRoute path='/pricing' component={Pricing} />
        </Switch>
        </WebContextProvider>
        </DataProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
