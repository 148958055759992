import React from 'react';
import './Logo.css';
import PhrosLogo from '../../Assets/phros-logo.png';

class Logo extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.LogoOnly ?
                    <img src={PhrosLogo} alt='PHROS' className='Logo-Image' />
                    :
                    <div className='Logo-Text'>
                    <img src={PhrosLogo} alt='PHROS' className='Logo-Image' /> PHROS
                    </div>
                }
            </div>
        )
    }
}
export default Logo;