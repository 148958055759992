import { useState, useEffect } from "react";
import {Text1} from "../../phros-web-components/Text/Text1/Text1";
import { Input, Select } from "antd";


/*
        this.Variant = variant;
        this.Align = align;
        this.Bold = bold;
        this.Italic = italic;
        this.Colour = colour;
        this.Text = text;
*/

const Text1Edit = ({ data = Text1.Default(), editComponent = () => {} }) => {
    const [text, setText] = useState(data.Text);
    const [variant, setVariant] = useState(data.Variant);
    const [align, setAlign] = useState(data.Align);
    const [bold, setBold] = useState(data.Bold);
    const [italic, setItalic] = useState(data.Italic);
    const [colour, setColour] = useState(data.Colour);

    useEffect(() => {
        const updateFaq = new Text1(variant, align, bold, italic, colour, text)
        editComponent(updateFaq);
    }, [text, variant, align, bold, italic, colour])

    return (
        <div>
            <h3>Text:</h3>
            <Input.TextArea
                value = {text}
                onChange = {(e) => setText(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Align</h3>
            <Select
                value={align}
                onChange={(value) => setAlign(value)}
            >
                <Select.Option value="start">Left</Select.Option>
                <Select.Option value="center">Center</Select.Option>
                <Select.Option value="end">Right</Select.Option>
            </Select>

            <h3 style={{ marginTop: "20px" }}>Bold Text?</h3>
            <Select
                value={bold}
                onChange={(value) => setBold(value)}
            >
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
            </Select>

            <h3 style={{ marginTop: "20px" }}>Italic Text?</h3>
            <Select
                value={italic}
                onChange={(value) => setItalic(value)}
            >
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
            </Select>

            <h3 style={{ marginTop: "20px" }}>Variant</h3>
            <p>Variant controls the size of your text. H1 is the largest and p is the smallest.</p>
            <Select
                value={variant}
                onChange={(value) => setVariant(value)}
            >
                <Select.Option value="h1">H1</Select.Option>
                <Select.Option value="h2">H2</Select.Option>
                <Select.Option value="h3">H3</Select.Option>
                <Select.Option value="h4">H4</Select.Option>
                <Select.Option value="h5">H5</Select.Option>
                <Select.Option value="p">P</Select.Option>
            </Select>
        </div>
    )
};

export {
    Text1Edit,
}