import React from 'react';
import { Row, Col, Upload, Card, Button } from 'antd';
import ReactQuill from 'react-quill';
import { UploadOutlined } from '@ant-design/icons';

class AboutMe extends React.Component {
    render() {
        return (
            <Row gutter={[30,30]}>
                <Col xs={24}>
                    <h3>About Me Section Information</h3>
                </Col>
                <Col xs={24}>
                <p>About Me Text</p>
                <ReactQuill
                    style={{ backgroundColor: 'white' }}
                    value={this.props.AboutMe}
                    onChange={(content) => this.props.handleChange("AboutMe", content)}
                />
                </Col>
                <Col xs={24}>
                    <p>About Me Image</p>
                    <p>Upload an image for your About Me section.</p>
                    {
                        this.props.AboutMePictureURL !== undefined && this.props.AboutMePictureURL !== null  && String(this.props.AboutMePictureURL).length > 0?
                        <Card
                        style={{ width: "400px" }}
                        cover={<img src={this.props.AboutMePictureURL} alt="" />}
                        >
                        <Upload
                        fileList={[]}
                        accept=".png,.jpeg,.jpg"
                        customRequest={async (info) => {
                            const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                            this.props.handleChange("AboutMePictureURL", url);
                        }}>
                            <Button icon={<UploadOutlined />}>Replace Image</Button>
                        </Upload>
                        </Card>
                        :
                        <Upload
                            fileList={[]}
                            accept=".png,.jpeg,.jpg"
                            customRequest={async (info) => {
                                const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                this.props.handleChange("AboutMePictureURL", url);
                            }}>
                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                        </Upload>
                    }
                </Col>
            </Row>
        )
    }
}

export default AboutMe;