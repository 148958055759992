import React from 'react';
import { Row, Col, Layout, Button, Popconfirm, Modal, Table, Tag, notification, Divider } from 'antd';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

class ViewPrograms extends React.Component {
    static contextType = AuthContext;
    state = {
        Programs: [],
        loading: true,
        Clients: [],
        Visibility: false,
        SelectedWorkout: {},
        AssignedWorkouts: {},
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/programs/get-all-workout-programs`, data, config);
        this.setState({
            Programs: response.data,
            loading: false,
        })
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-profile/get-user-profiles-for-trainer`, {}, config);
        this.setState({
            Clients: res.data
        })
        const assignedWorkouts = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-programs/get-assigned-programs`, {}, config);
        this.setState({
            AssignedWorkouts: assignedWorkouts.data,
            loading: false,
        })
    }

    deleteProgram = async (index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const workout = this.state.Programs[index];
        const id = workout.ID;

        if (currentUser.uid !== workout.uid) {
            return notification.error({
                message: 'Not Authorized',
                description: "You're not authorized to delete this training program.",
                duration: null,
            })
        }
        const data = {
            id: id,
        };
        const deleteEvent = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/programs/delete-workout-program`, data, config);
        if (deleteEvent.data.error) {
            return notification.error({
                message: "Failed to Delete",
                description: "Failed to delete this program. Please try again, or contact our team."
            })
        }

        const classes = [...this.state.Programs];
        classes.splice(index, 1);
        this.setState({
            Programs: classes,
        })

        return (
            notification.success({
                message: 'Success',
                description: 'Successfully deleted the training program.'
            })
        )
    }

    handleAssignWorkout = (index) => {
        this.setState({
            SelectedWorkout: this.state.Programs[index]
        })
        this.handleModalVisible();
    }

    handleModalVisible = () => {
        this.setState({
            Visibility: !this.state.Visibility,
        })
    }

    assignWorkout = async (ClientUID, Email) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ClientEmail: Email,
            ClientUID: ClientUID,
            ProgramID: this.state.SelectedWorkout.ID,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/programs/assign-workout-program`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error assigning workout.",
                description: "Something went wrong when assigning your workout. Please try again, or contact our team at support@phros.ca for more support."
            })
        } else {
            notification.success({
                message: "Success",
                description: "Successfully assigned the workout to your client. They can access it by logging in on your website."
            })
        }
    }

    removeAssignedWorkout = async (ClientUID, ProgramID) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ClientUID: ClientUID,
            WorkoutID: ProgramID,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-programs/delete-assigned-program`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error removing workout from user.",
                description: "Something went wrong when assigning your workout. Please try again, or contact our team at support@phros.ca for more support."
            })
        } else {
            notification.success({
                message: "Success",
                description: "Successfully removed the assigned workout from your client. Please refresh the page to view the changes."
            })
        }
    }

    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Title}</h4>
                )
            },
            {
                title: "Price",
                dataIndex: "Price",
                key: "Price",
                render: (text, obj) => (
                    <h4>{obj.Price} {obj.Currency ? obj.Currency : null}</h4>
                )
            },
            {
                title: "Intensity",
                dataIndex: "Intensity",
                key: "Intensity",
                render: (text, obj) => (
                    <h4>{obj.IntensityRating}/5</h4>
                )
            },
            {
                title: "Published Status",
                dataIndex: "PublishedStatus",
                key: "PublishedStatus",
                render: (text, obj) => {
                    return (
                        obj.Published ?
                        <Tag color="green">PUBLISHED</Tag>
                        :
                        <Tag color="red">NOT PUBLISHED</Tag>
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <div>
                        <Row justify="center" gutter={[10,10]}>
                            <Col>
                                <Button onClick={() => this.handleAssignWorkout(index)}>Assign Program to Client</Button>
                            </Col>
                            <Col>
                                <Link to={`/program/${obj.ID}`}>
                                <Button type='primary'>Edit</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Popconfirm
                                title='Are you sure you want to delete this program? This action cannot be reversed, and any customers who purchased this program will not be able to access this program.'
                                onConfirm={() => this.deleteProgram(index)}
                                okText="Delete Workout Program"
                                >
                                <Button type='primary' danger icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Modal width={800} visible={this.state.Visibility} onCancel={this.handleModalVisible} footer={null}>
                            <Row>
                                <Col xs={24}><h2><strong>{this.state.SelectedWorkout.Title}</strong></h2></Col>
                            </Row>
                            {
                                this.state.Clients.map((client, index) => {
                                    return (
                                        <Row>
                                            <Col xs={24} sm={8}>
                                                {client.Name}
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                {client.Email}
                                            </Col>
                                            {
                                                this.state.AssignedWorkouts[client.UID] && this.state.AssignedWorkouts[client.UID]?.indexOf(this.state.SelectedWorkout.ID) !== -1 ?
                                                <Col xs={24} sm={8}>
                                                    <Row gutter={[10,10]}>
                                                        <Col xs={24}>
                                                            <Button block danger onClick={() => this.removeAssignedWorkout(client.UID, this.state.SelectedWorkout.ID)}>
                                                                Remove Workout Program
                                                            </Button>
                                                        </Col>
                                                        <Col xs={24}>
                                                            <Button block onClick={() => {
                                                                this.removeAssignedWorkout(client.UID, this.state.SelectedWorkout.ID);
                                                                this.assignWorkout(client.UID, client.Email);
                                                            }}>
                                                                Update Workout Program
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                :
                                                <Col xs={24} sm={8}>
                                                    <Button block onClick={() => this.assignWorkout(client.UID, client.Email)}>
                                                        Assign Workout
                                                    </Button>
                                                </Col>
                                            }

                                            <Col xs={24}>
                                                <Divider />
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Modal>
                        </div>
                    )
                }
            },
        ]
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View Training Programs'/>
                            <Table
                            scroll={{ x: true }}
                            loading={this.state.loading}
                            columns={columns} dataSource={this.state.Programs} />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewPrograms;