import { v4 } from "uuid";

const getSessionAnonId = () => {
    let id = window.sessionStorage.getItem("SessionAnonId");
    if (id === null) {
        id = v4();
        window.sessionStorage.setItem("SessionAnonId", id);
    }
    return id;
}

const getAuthHeader = async (currentUser) => {
    const token = await currentUser.getIdToken(true);
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}

export {
    getSessionAnonId,
    getAuthHeader,
}