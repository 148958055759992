import React from 'react';
import './OnDemandClass.css';
import { Row, Col, Layout, Input, Button, Upload, Card, Select, Affix, message, InputNumber } from 'antd';
import { SideNav } from '../../Components';
import { H2 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { UploadOutlined, FileOutlined } from '@ant-design/icons';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import currencies from '../../Assets/currencies';
import { TRAINING_EXPERIENCE_LEVEL, TRAINING_INTENSITY_LEVEL, FREE_EVENTS, FITNESS_CATEGORIES } from '../../constants';
import axios from 'axios';

class OnDemandClass extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Title: '',
            Description: '',
            Price: 0,
            Currency: '',
            Categories: [],
            FreeClass: FREE_EVENTS.NO.Key,
            IntensityRating: 1,
            Level: undefined,
            Published: false,
            Duration: 0,

            VideoFileList: [],
            VideoURL: '',
            VideoDisabled: false,

            WaiverFileList: [],
            WaiverURL: '',
            WaiverDisabled: false,

            ThumbnailFileList: [],
            ThumbnailURL: '',
            ThumbnailDisabled: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.ID !== this.props.match.params.ID) {
            this.setState({
                Title: '',
                Description: '',
                FreeClass: FREE_EVENTS.NO.Key,
                Price: 0,
                Currency: '',
                Categories: [],
                IntensityRating: 1,
                Level: undefined,
                Published: false,
                Duration: 0,

                VideoFileList: [],
                VideoURL: '',
                VideoDisabled: false,

                WaiverFileList: [],
                WaiverURL: '',
                WaiverDisabled: false,

                ThumbnailFileList: [],
                ThumbnailURL: '',
                ThumbnailDisabled: false,
            });
            this.getData();
        }
    }

    getData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/on-demand-class/get-on-demand-class`, payload, config);
        if(response.data.error) return;
        let free = FREE_EVENTS.NO.Key;
        if (response.data.Free === true) {
            free = FREE_EVENTS.YES.Key;
        }
        if (response.data) {
            this.setState({
                Title: response.data.Title,
                Description: response.data.Description,
                Price: response.data.Price,
                Currency: response.data.Currency,
                Categories: response.data.Categories,
                FreeClass: free,
                IntensityRating: response.data.IntensityRating,
                Level: response.data.Level,
                Published: response.data.Published,
                VideoURL: response.data.VideoURL,
                WaiverURL: response.data.WaiverURL,
                ThumbnailURL: response.data.ThumbnailURL,
                Duration: response.data.Duration,
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleArrayAdd = (name, value) => {
        let tempArray = [...this.state[name], value];
        this.setState({
            [name]: tempArray
        })
    }

    handleArrayDelete = (name, value) => {
        let tempArray = [...this.state[name]];
        let index = tempArray.indexOf(value);
        tempArray.splice(index, 1);
        this.setState({
            [name]: tempArray
        })
    }

    handleFileUpload = async (fileListName, urlName, buttonName, fileType, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/${fileType}/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                message.error('Something went wrong, please contact our team.');
                console.log(err);
            }, () => {
                storage.ref(`${fileType}/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            // Send URL to backend to delete
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        console.log(e);
                        message.error('Failed to delete the file.');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        if (fileType === 'videos') {
            try {
                const vid = document.createElement('video');
                vid.src = url;
                vid.addEventListener('loadedmetadata', () => {
                    const duration = vid.duration;
                    this.setState({
                        Duration: duration,
                    })
                })
            } catch(e) {
                console.log(e);
            }

        }
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    setDuration = (duration) => {
        this.setState({
            Duration: duration,
        })
    }

    saveEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
            ID: this.props.match.params.ID,
            Title: this.state.Title,
            Description: this.state.Description,
            FreeClass: this.state.FreeClass,
            Price: this.state.Price,
            Currency: this.state.Currency,
            Categories: this.state.Categories,
            IntensityRating: this.state.IntensityRating,
            Level: this.state.Level,
            VideoURL: this.state.VideoURL,
            WaiverURL: this.state.WaiverURL,
            ThumbnailURL: this.state.ThumbnailURL,
            Duration: this.state.Duration,
        }
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/on-demand-class/save-on-demand-class`, data, config);
        if (save.data.error) {
            message.error('Failed to save on-demand class information, please contact a member of our team.')
        } else {
            message.success('Successfully saved event information.')
        }
    }

    publishEvent = async () => {
        await this.saveEvent();
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            uid: currentUser.uid,
            Published: !this.state.Published,
        }
        const publish = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/on-demand-class/publish-on-demand-class`, data, config);
        if (publish) {
            this.setState({
                Published: !this.state.Published,
            })
            this.saveEvent();
            return message.success('Published the event.');
        }
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                    <Col xs={23} sm={22}>
                        <Row>
                            <Col xs={24}>
                            <H2 text='On-Demand Class' />
                            </Col>
                        </Row>
                        <Row gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                            <h3>Title</h3>
                            <Input value={this.state.Title} onChange={(e) => this.handleChange('Title', e.target.value)} placeholder="I.e. HIIT Training"/>
                        </Col>
                        <Col xs={24} sm={4}>
                            <h3>Free Class?</h3>
                            <Select onChange={(value) => this.handleChange('FreeClass', value)} value={this.state.FreeClass} style={{ width: '100%' }}>
                                <Select.Option key={FREE_EVENTS.YES.Key}>{FREE_EVENTS.YES.Value}</Select.Option>
                                <Select.Option key={FREE_EVENTS.NO.Key}>{FREE_EVENTS.NO.Value}</Select.Option>
                            </Select>
                        </Col>
                        {
                            this.state.FreeClass === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Price</h3>
                            <InputNumber className='AddLiveClass-Input' min={1} value={this.state.Price} onChange={(value) => this.handleChange('Price', value)} />
                            <p style={{ marginBottom: '0px' }}>Minimum class price is $1, unless free </p>
                            </Col>
                            :
                            null
                        }
                        {
                            this.state.FreeClass === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Currency</h3>
                            <Select
                                placeholder="Search for a currency"
                                filterOption={(input, option) =>
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value) => this.handleChange('Currency', value)}
                                showSearch={true}
                                value={this.state.Currency}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(currencies).map((curr) => {
                                            return (
                                                <Select.Option key={curr} name={currencies[curr]}>
                                                <strong>{curr}: </strong>{currencies[curr]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            :
                            null
                        }
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <h3>Description</h3>
                                <Input.TextArea value={this.state.Description} onChange={(e) => this.handleChange("Description", e.target.value)} autoSize={{ minRows: 3}} />
                            </Col>
                        </Row>
                        <Row className='AddLiveClass-Row' gutter={[10,10]}>
                            <Col xs={24} sm={8}>
                                <h3>Intensity Rating</h3>
                                <Select value={this.state.IntensityRating} className='AddLiveClass-Input' onChange={(value) => this.handleChange('IntensityRating', parseInt(value))}>
                                    {
                                        TRAINING_INTENSITY_LEVEL.map(val => {
                                            return <Select.Option key={val}>{val}</Select.Option>
                                        })
                                    }
                                </Select>
                                <p>1: Low Intensity, 5: High Intensity</p>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Level of Experience</h3>
                                <Select value={this.state.Level} className='AddLiveClass-Input' onSelect={(value) => this.handleChange('Level', value)}>
                                    {
                                        Object.keys(TRAINING_EXPERIENCE_LEVEL).map(lvl => {
                                            return <Select.Option key={lvl}>{TRAINING_EXPERIENCE_LEVEL[lvl]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Categories</h3>
                                <Select
                                mode='multiple'
                                style={{ width: '100%' }}
                                value={this.state.Categories}
                                onSelect={(value) => this.handleArrayAdd('Categories', value)}
                                onDeselect={(value) => this.handleArrayDelete('Categories', value)}
                                >
                                    {
                                        FITNESS_CATEGORIES.map((cat) => {
                                            return (
                                                <Select.Option key={cat}>{cat}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                <p>Select Multiple</p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col xs={24}>
                            <h3>Upload On-Demand Video</h3>
                            {
                                this.state.VideoURL !== undefined && this.state.VideoURL !== null && String(this.state.VideoURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                >
                                <Col xs={24} style={{ paddingBottom: '10px' }}>
                                <video width="100%" height="400" controls>
                                    <source src={this.state.VideoURL} />
                                </video>
                                </Col>
                                <Upload
                                    listType='picture'
                                    accept=".mp4"
                                    customRequest={(info) => this.handleFileUpload('VideoFileList','VideoURL','VideoDisabled','videos',info)}
                                    fileList={this.state.VideoFileList}>
                                    <Button icon={<UploadOutlined />}>Replace Video</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".mp4"
                                    customRequest={(info) => this.handleFileUpload('VideoFileList','VideoURL','VideoDisabled','videos',info)}
                                    fileList={this.state.VideoFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Video</Button>
                                </Upload>
                            }
                            <p>When uploading, please wait for your video to finish before saving or exiting the page.</p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }} gutter={[10,10]}>
                            <Col xs={24} sm={12}>
                            <h3>Upload Video Thumbnail</h3>
                            {
                                this.state.ThumbnailURL !== undefined && this.state.ThumbnailURL !== null && String(this.state.ThumbnailURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                cover={<img src={this.state.ThumbnailURL} alt='Profile' className='Profile-ProfilePicture' />}
                                >
                                <Upload
                                    listType='picture'
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={(info) => this.handleFileUpload('ThumbnailFileList','ThumbnailURL','ThumbnailDisabled','images',info)}
                                    fileList={this.state.ThumbnailFileList}>
                                    <Button icon={<UploadOutlined />}>Replace Video Thumbnail</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={(info) => this.handleFileUpload('ThumbnailFileList','ThumbnailURL','ThumbnailDisabled','images',info)}
                                    fileList={this.state.ThumbnailFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Video Thumbnail</Button>
                                </Upload>
                            }
                            </Col>
                            <Col xs={24} sm={12}>
                            <h3>Upload Waiver/Liability Form</h3>
                            {
                                this.state.WaiverURL !== undefined && this.state.WaiverURL !== null && String(this.state.WaiverURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                >
                                <Col xs={24} style={{ paddingBottom: '10px' }}>
                                <a target="_blank" href={this.state.WaiverURL} rel="noreferrer"><FileOutlined style={{ fontSize: '25px' }}/>View Uploaded PDF</a>
                                </Col>
                                <Upload
                                    listType='picture'
                                    accept=".pdf"
                                    customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled','forms',info)}
                                    fileList={this.state.WaiverFileList}>
                                    <Button icon={<UploadOutlined />} >Upload Waiver</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".pdf"
                                    customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled','forms',info)}
                                    fileList={this.state.WaiverFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Waiver</Button>
                                </Upload>
                            }
                            </Col>
                        </Row>
                        <Row justify='end' className='AddLiveClass-Save-Buttons' gutter={[10,10]}>
                        <Col xs={6}>
                            {
                                this.state.Published ?
                                <Button block type='primary' danger onClick={this.publishEvent}>Un-Publish Event</Button>
                                :
                                <Button block className='Button-Yellow' onClick={this.publishEvent}>Publish Event</Button>
                            }
                        </Col>
                        <Col xs={6}>
                            <Affix offsetBottom={20}>
                            <Button block type='primary' className='Button' onClick={this.saveEvent}>Save Event</Button>
                            </Affix>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default OnDemandClass;