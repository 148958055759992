import React from "react";
import "./Profile1.css";
import { ImageTemplate, LongText, TitleTemplate, SubTitleTemplate } from "../../TemplateData";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";

class Profile1 extends Component {
    ID = IDS.Profile1;
    constructor(image, imageAlt, title, subtitle, text, buttonText, buttonLink) {
        super();
        this.Image = image;
        this.ImageAlt = imageAlt;
        this.Title = title;
        this.SubTitle = subtitle;
        this.Text = text;
        this.ButtonText = buttonText;
        this.ButtonLink = buttonLink;
    }
    static Default() {
        return new Profile1(ImageTemplate, "Placeholder", TitleTemplate, SubTitleTemplate, LongText, "Contact Me", "/");
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Profile1(obj.Image, obj.ImageAlt, obj.Title, obj.SubTitle, obj.Text, obj.ButtonText, obj.ButtonLink);
    }
}

const Profile1View = ({ data = Profile1.Default() }) => {
    if (data.Image === "") {
        data.Image = ImageTemplate;
    }
    return (
        <div className="d-flex justify-content-center align-items-center">
        <div className="container justify-content-center">
            <div className="Profile1--Container">
                <div className="Profile1--TextContainer">
                    <h1 className="Profile1--Title">{data.Title}</h1>
                    <h3 className="Profile1--Subtitle">{data.SubTitle}</h3>
                    <p className="Profile1--Paragraph">{data.Text}</p>
                    <a href={data.ButtonLink} className="Profile1--Button">
                        {data.ButtonText}
                    </a>
                </div>
                <div className="Profile1--ImageContainer">
                    <img src={data.Image} className="Profile1--Image" alt={data.ImageAlt} />
                </div>
            </div>
        </div>
        </div>
    )
}


export {
    Profile1,
    Profile1View
}