import React from "react";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";

class Purchase extends Component {
    ID = IDS.PURCHASES1;
    constructor(uid) {
        super();
        this.UID = uid;
    }
    static Default() {
        let uid = localStorage.getItem("phros-app-uid");
        if (!uid) {
            uid = "blank";
        }
        return new Purchase(uid);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Purchase(obj.UID);
    }
}

const PurchaseView = ({ data = Purchase.Default() }) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="container">
            <iframe
            src={`https://phros.ca/embed/${data.UID}/purchases`}
            title="Live Classes"
            frameborder="0"
            style={{
                height: "1000px",
                width: "100%"
            }}
            id="live-class-embed">
            </iframe>
            </div>
        </div>
    )
}

export {
    Purchase,
    PurchaseView,
}