import React from 'react';
import { Row, Col, Spin, notification } from 'antd';
import axios from 'axios';
import { ENVIRONMENT } from '../../constants';
import { Redirect } from 'react-router-dom';

class PaymentSuccess extends React.Component {
    state = {
        dashboard: false,
    }
    componentDidMount() {
        this.paymentSuccess();
    }

    paymentSuccess = async () => {
        const payload = {
            BookingID: this.props.match.params.ID,
        }
        const response = await axios.post(`${ENVIRONMENT.URL}/stripe/update-plan-session-success`, payload);
        if (response.data.error) {
            notification.open({
                message: "Something went wrong :(",
                description: "Please contact our team so we can get your account running ASAP! support@cloudsafari.io"
            })
        } else {
            this.setState({
                dashboard: true,
            })
        }
    }
    render() {
        return (
            <Row justify="center" align='middle' style={{ height: '100vh', padding: '20px' }}>
                <Col>
                    <div>
                    Setting up your account, taking you one step closer to success!
                    </div>
                    <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                    </div>
                </Col>
                <Col>
                {
                    this.state.dashboard ?
                    <Redirect to="/" />
                    :
                    null
                }
                </Col>
            </Row>
        )
    }
}

export default PaymentSuccess;