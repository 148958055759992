import { useState, useEffect } from "react";
import { Banner1 } from "../../phros-web-components/Banners/Banner1/Banner1";
import { Input } from "antd";
import { PhrosUpload } from "../../Common/Uploads/Upload";
import { ImageTemplate } from "../../phros-web-components/TemplateData";

const Banner1Edit = ({ data = Banner1.Default(), editComponent = () => {} }) => {
    const [imageMap, setImageMap] = useState({
        "image_id": {
            url: data.Image,
            description: data.ImageAlt,
            fileId: "image_id",
        }
    });
    const [title, setTitle] = useState(data.Title);
    const [subTitle, setSubTitle] = useState(data.SubTitle);

    const deleteWebComponentImage = () => {
        return;
    }

    useEffect(() => {
        const keys = Object.keys(imageMap);
        let imageUrl = ImageTemplate;
        let imageAlt = "This is some image alt text.";
        if (keys.length > 0) {
            const image = imageMap[keys[0]];
            imageUrl = image.url;
            imageAlt = `${image.title} ${image.description}`;
        }
        const updateBanner = new Banner1(imageUrl, imageAlt, title, subTitle);
        editComponent(updateBanner);
    }, [imageMap, title, subTitle]);

    return (
        <div>
            <h3>Image</h3>
            <PhrosUpload
                accept = ".jpg,.jpeg,.png"
                text="Choose an image or drag it here."
                type="static"
                fileMap={imageMap}
                setFileMap={setImageMap}
                deleteFile={deleteWebComponentImage}
            />

            <h3>Title:</h3>
            <Input.TextArea
                value = {title}
                onChange = {(e) => setTitle(e.target.value)}
            />

            <h3>Subtitle:</h3>
            <Input.TextArea
                value = {subTitle}
                onChange = {(e) => setSubTitle(e.target.value)}
            />
        </div>
    )
};

export {
    Banner1Edit,
}