import React, { useState, useContext } from 'react';
import './SignUp.css';
import { Row, Col, Input, Button, message, notification } from 'antd';
import { Logo } from '../../Common';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import { DataContext } from '../../Context/ProfileData';
import { ENVIRONMENT } from '../../constants';
import axios from 'axios';
import { getSessionAnonId } from "../../util";
import { ArrowRightOutlined } from "@ant-design/icons";
import PhrosLogoWrapped from "../../Assets/phros-logo-wrapped.png";

const SignUp = (props) => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { currentUser, signInWithGoogle, signup } = useContext(AuthContext);
    const { updateValues } = useContext(DataContext);
    const [signUpDisabled, setSignUpDisabled] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const checkFields = () => {
        if (currentUser) return true;
        // Passwords have to match
        if (password !== confirmPassword) {
            message.error('Passwords do not match.')
            return false;
        }

        // Passwords have to be longer than 6 characters
        if (String(password).length < 6) {
            message.error('Password must be least 6 characters');
            return false;
        }

        // Email must be valid - SIMPLE CHECK
        if (!String(email).includes('@') || !String(email).includes('.')) {
            message.error('Please enter a valid email');
            return false;
        }
        return true;
    }

    const handleSignUp = async (user) => {
        const dataValidated = checkFields();
        if (!dataValidated) return;
        try {
            setSignUpDisabled(true);

            // Get the Config Details
            const token = await user.getIdToken(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const split = fullName.indexOf(" ");
            let firstName = fullName;
            let lastName = "";
            if (split >= 1) {
                firstName = fullName.slice(0, split+1);
                lastName = fullName.slice(split+1);
            }

            const payload = {
                FirstName: firstName,
                LastName: lastName,
            };

            if (user) {
                // Get the new data and update state
                setSignUpDisabled(false);
            }

            try {
                axios.post(`${ENVIRONMENT.URL}/profile/store-default-data`, payload, config);
            } catch (e) {
                console.log(e);
            }

            await updateValues(user);

            return user;
        } catch (e) {
            console.log(e);
            message.error('Failed to create account');
            return e;
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };


    if (currentUser) {
        return (
            <Redirect
                to="/"
            />
        )
    }

    return (
        <div className="SignUp--Wrapper">
            <div className="SignUp--Container">
                <div className="SignUp--Left">
                    <img src={PhrosLogoWrapped} className="SignUp--Image" alt="Phros Logo Wrapped in Yellow" />
                    <h3 className="SignUp--Phros">PHROS</h3>
                    <h5 className="SignUp--Catch"><span className="SignUp--Catch--Span">The First Step</span> to Growing your Online Fitness Business.</h5>
                </div>
                <div className="SignUp--Right">
                    <h1 style={{ fontWeight: 600, textDecoration: "underline" }}>Sign Up</h1>
                    <h5>Already a member? <Link to="/login">Login</Link></h5>

                    <Input placeholder="Full Name" className="SignUp--Input" bordered={false} value={fullName} onChange={(e) => setFullName(e.target.value)} />

                    <Input placeholder="Email" className="SignUp--Input" bordered={false} value={email} onChange={(e) => setEmail(e.target.value)} />

                    <Input placeholder="Password" className="SignUp--Input" type="password" value={password} bordered={false} onChange={(e) => setPassword(e.target.value)} />

                    <Input placeholder="Confirm Password" className="SignUp--Input" type="password" value={confirmPassword} bordered={false} onChange={(e) => setConfirmPassword(e.target.value)} />

                    <button
                    disabled={signUpDisabled}
                    onClick={async () => {
                        if (password != confirmPassword) {
                            return message.error("Passwords do not match.");
                        }
                        if (!validateEmail(email)) {
                            return message.error("Please enter a valid email.");
                        }
                        try {
                            const { user } = await signup(email, password);
                            await handleSignUp(user);
                        } catch (e) {
                            message.error(e.message);
                        }

                    }}
                    className="SignUp--Action">Sign Up</button>


                    <div style={{ textAlign: "center" }}>
                        <h3 style={{ color: "black", fontWeight: 600, marginTop: "20px" }}>OR SIGN UP WITH</h3>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <Button size="large"
                        onClick={async () => {
                            try {
                                const { user } = await signInWithGoogle();
                                setFullName(user.displayName);
                                await handleSignUp(user);
                            } catch(e) {
                                console.log(e);
                                message.error(e.message);
                            }

                        }}
                        style={{ borderRadius: "50%", height: "50px", width: "50px", marginBottom: "20px" }}>
                            <img className='Auth-Google-Image' style={{ height: "100%", width: "100%", objectFit: "contain" }} src="https://img.icons8.com/color/48/000000/google-logo.png" alt="google icon"/>
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SignUp;