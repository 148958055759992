import React from 'react';
import './Bootcamps.css';
import { Row, Col, Layout, Input, Button, Upload, Card, Select, Affix, message, InputNumber, DatePicker, notification } from 'antd';
import { SideNav } from '../../Components';
import { H2, } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import moment from 'moment';
import currencies from '../../Assets/currencies';
import { TRAINING_EXPERIENCE_LEVEL, TRAINING_INTENSITY_LEVEL, FREE_EVENTS } from '../../constants';
import axios from 'axios';
import { checkArray } from '../../Verification';


class AddBootcamp extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Title: undefined,
            Price: undefined,
            Currency: undefined,
            Description: undefined,
            Level: undefined,
            IntensityRating: undefined,
            Capacity: 20,

            PictureFileList: [],
            PictureURL: '',
            PictureDisabled: false,

            WaiverFileList: [],
            WaiverURL: '',
            WaiverDisabled: false,

            DocumentFileList: [],
            DocumentURL: '',
            DocumentDisabled: false,

            Published: false,

            FreeEvent: "No",

            LiveClasses: [],
            UserLiveClasses: [],
            UserLiveClassesOBJ: {},
            SellingStartDate: moment(new Date()),
            SellingEndDate: moment(new Date()),
        }
    }

    componentDidMount() {
        this.getEventData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.ID !== this.props.match.params.ID) {
            this.setState({
                Title: undefined,
                Price: undefined,
                Currency: undefined,
                Description: undefined,
                Level: undefined,
                IntensityRating: undefined,
                Capacity: 0,
                Availability: [],
                PictureFileList: [],
                PictureURL: '',
                PictureDisabled: false,
                WaiverFileList: [],
                WaiverURL: '',
                WaiverDisabled: false,
                Published: false,
                FreeEvent: 'No',
                LiveClasses: [],
                DocumentFileList: [],
                DocumentURL: '',
                DocumentDisabled: false,
                SellingStartDate: moment(new Date()),
                SellingEndDate: moment(new Date()),
                UserLiveClasses: [],
                UserLiveClassesOBJ: {},
            });
            this.getEventData();
        }
    }
    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            uid: currentUser.uid,
        };
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bootcamps/get-bootcamp-with-id`, data, config);
        if(response.data.error) return;
        let free = FREE_EVENTS.NO.Key;
        if (response.data.Free === true) {
            free = FREE_EVENTS.YES.Key;
        }
        const bootcamps = response.data.bootcampData;
        const liveClassData = response.data.liveClassData;
        let startDate = new Date();
        let endDate = new Date();
        if (String(bootcamps.SellingStartDate).length > 0) {
            startDate = bootcamps.SellingStartDate;
        }
        if (String(bootcamps.SellingEndDate).length > 0) {
            endDate = bootcamps.SellingEndDate;
        }

        // Key data fro Live Class Data
        let liveClassDataObj = {};
        if (checkArray(liveClassData).length > 0) {
            liveClassDataObj = liveClassData.reduce((obj, curr) => {
                return {
                    ...obj,
                    [curr.ID]: curr,
                }
            }, {});
        }
        this.setState({
            Title: bootcamps.Title,
            Price: bootcamps.Price,
            Currency: bootcamps.Currency,
            Description: bootcamps.Description,
            Level: bootcamps.Level,
            IntensityRating: bootcamps.IntensityRating,
            Capacity: bootcamps.Capacity || 0,
            PictureURL: bootcamps.PictureURL,
            WaiverURL: bootcamps.WaiverURL,
            DocumentURL: bootcamps.DocumentURL,
            Published: bootcamps.Published,
            SellingStartDate: moment(startDate),
            SellingEndDate: moment(endDate),
            FreeEvent: free,
            LiveClasses: checkArray(bootcamps.LiveClasses),
            UserLiveClasses: checkArray(liveClassData),
            UserLiveClassesOBJ: liveClassDataObj,
        })
    }

    publishEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            uid: currentUser.uid,
            Published: !this.state.Published,
        }
        const publish = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bootcamps/publish-bootcamp`, data, config);
        const save = await this.saveEvent();
        if (publish && save) {
            notification.success({
                message: 'Success',
                description: 'Successfully changed published status.'
            })
            this.setState({
                Published: !this.state.Published,
            })
        } else {
            notification.error({
                message: 'Error',
                description: 'Failed to publish the bootcamp, please contact us at support@phros.ca if the problem persists.'
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }
    addLiveClass = () => {
        const liveClass = {
            Title: '',
            LiveClasID: '',
            Availability: '',
            AvailabilityID: '',
        }
        this.setState({
            LiveClasses: [...this.state.LiveClasses, liveClass]
        })
    }
    deleteLiveClass = (index) => {
        const temp = this.state.LiveClasses;
        temp.splice(index, 1);
        this.setState({
            LiveClasses: temp,
        })
    }
    changeLiveClass = (name, index, value) => {
        const temp = this.state.LiveClasses[index];
        temp[name] = value;
        this.setState({
            LiveClasses: [...this.state.LiveClasses.slice(0, index), temp, ...this.state.LiveClasses.slice(index+1, this.state.LiveClasses.length)]
        })
    }

    handleFileUpload = async (fileListName, urlName, buttonName, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/static/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`static/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            // Send URL to backend to delete
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        message.error('Failed to delete the file.');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    saveEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
            ID: this.props.match.params.ID,
            Title: this.state.Title,
            Price: this.state.Price,
            Currency: this.state.Currency,
            Description: this.state.Description,
            Level: this.state.Level,
            IntensityRating: this.state.IntensityRating,
            PictureURL: this.state.PictureURL,
            WaiverURL: this.state.WaiverURL,
            DocumentURL: this.state.DocumentURL,
            Published: this.state.Published,
            SellingStartDate: this.state.SellingStartDate,
            SellingEndDate: this.state.SellingEndDate,
            FreeEvent: this.state.FreeEvent,
            LiveClasses: this.state.LiveClasses,
            Capacity: this.state.Capacity,
        }
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bootcamps/save-bootcamp`, data, config);
        if (!save.data.error) {
            notification.success({
                message: 'Success',
                description: 'Successfully saved Bootcamp information.'
            })
        } else {
            notification.error({
                message: 'Error',
                description: 'Failed to save the Bootcamp, please try again or contact us at support@phros.ca if the problem persists.'
            })
        }
        return {};
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                <Col xs={23} sm={22}>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col xs={24}>
                        <H2 text='Add a Live Bootcamp' />
                        </Col>
                        <Col xs={24}>
                            Live bootcamps are a combination of live classes that your customers can book all in one "Live Bootcamp".
                        </Col>
                    </Row>
                    <Row gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                        <h3>Bootcamp Name</h3>
                        <Input value={this.state.Title} onChange={(e) => this.handleChange('Title', e.target.value)} placeholder="I.e. HIIT Training"/>
                        </Col>
                        <Col xs={24} sm={4}>
                        <h3>Free Bootcamp?</h3>
                        <Select onChange={(value) => this.handleChange('FreeEvent', value)} value={this.state.FreeEvent} style={{ width: '100%' }}>
                            <Select.Option key={FREE_EVENTS.YES.Key}>{FREE_EVENTS.YES.Value}</Select.Option>
                            <Select.Option key={FREE_EVENTS.NO.Key}>{FREE_EVENTS.NO.Value}</Select.Option>
                        </Select>
                        </Col>
                        {
                            this.state.FreeEvent === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Price</h3>
                            <InputNumber className='AddLiveClass-Input' min={1} value={this.state.Price} onChange={(value) => this.handleChange('Price', value)} />
                            <p style={{ marginBottom: '0px' }}>Minimum class price is $1, unless free </p>
                            </Col>
                            :
                            null
                        }
                        {
                            this.state.FreeEvent === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Currency</h3>
                            <Select
                                placeholder="Search for a currency"
                                filterOption={(input, option) =>
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value) => this.handleChange('Currency', value)}
                                showSearch={true}
                                value={this.state.Currency}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(currencies).map((curr) => {
                                            return (
                                                <Select.Option key={curr} name={currencies[curr]}>
                                                <strong>{curr}: </strong>{currencies[curr]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    <Row className="AddLiveClass-Row" gutter={[10,10]}>
                        <Col xs={24} sm={12}>
                            <h3>First Day to Sell Bootcamp:</h3>
                            <p>This is the first day that your Bootcamp is available for purchase.</p>
                            <DatePicker style={{ width: '100%' }} value={this.state.SellingStartDate} onChange={(value) => this.handleChange('SellingStartDate', value)} />
                        </Col>
                        <Col xs={24} sm={12}>
                            <h3>Last Day to Sell Bootcamp:</h3>
                            <p>This is the last day that your Bootcamp will be available for purchase.</p>
                            <DatePicker style={{ width: '100%' }} value={this.state.SellingEndDate} onChange={(value) => this.handleChange('SellingEndDate', value)}/>
                        </Col>
                    </Row>
                    <Row className='AddLiveClass-Row'>
                        <Col xs={24}>
                            <h3>Bootcamp Description</h3>
                            <Input.TextArea value={this.state.Description} onChange={(e) => this.handleChange('Description', e.target.value)}/>
                        </Col>
                    </Row>
                    <Row className='AddLiveClass-Row' gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                            <h3>Intensity Rating</h3>
                            <Select value={this.state.IntensityRating} className='AddLiveClass-Input' onChange={(value) => this.handleChange('IntensityRating', parseInt(value))}>
                                {
                                    TRAINING_INTENSITY_LEVEL.map(val => {
                                        return <Select.Option key={val}>{val}</Select.Option>
                                    })
                                }
                            </Select>
                            <p>1: Low Intensity, 5: High Intensity</p>
                        </Col>
                        <Col xs={24} sm={8}>
                            <h3>Level of Experience</h3>
                            <Select value={this.state.Level} className='AddLiveClass-Input' onSelect={(value) => this.handleChange('Level', value)}>
                                {
                                    Object.keys(TRAINING_EXPERIENCE_LEVEL).map(lvl => {
                                        return <Select.Option key={lvl}>{TRAINING_EXPERIENCE_LEVEL[lvl]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col xs={24} sm={8}>
                            <h3>Capacity</h3>
                            <InputNumber min={0} value={this.state.Capacity} onChange={(value) => this.handleChange('Capacity', value)} />
                        </Col>
                    </Row>
                    <Row className="AddLiveClass-Row" gutter={[10,10]}>
                        <Col xs={24}>
                            <h3>Bootcamp Live Classes</h3>
                        </Col>
                        <Col xs={24}>
                            {
                                this.state.LiveClasses.length > 0 ?
                                <Row gutter={[10,10]}>
                                    <Col xs={0} sm={10}>
                                        <h4>Select Live Class</h4>
                                    </Col>
                                    <Col xs={0} sm={10}>
                                        <h4>Select Class Time</h4>
                                    </Col>
                                    <Col xs={0} sm={4}>
                                        <h4>Remove</h4>
                                    </Col>
                                </Row>
                                :
                                null
                            }
                            {
                                this.state.LiveClasses.map((liveClass, index) => {
                                    return (
                                        <Row gutter={[10,10]} className="AddLiveClass-Row">
                                            <Col xs={24} sm={10}>
                                                <Select value={liveClass.Title}
                                                style={{ width: '100%' }}
                                                onSelect={(value, data)=> {
                                                    this.changeLiveClass('Title', index, data.value);
                                                    this.changeLiveClass('ID', index, data.key)
                                                }} >
                                                    {
                                                        this.state.UserLiveClasses.map((ulc) => {
                                                            return (
                                                                <Select.Option value={ulc.Name} key={ulc.ID}>
                                                                    {ulc.Name}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                            <Col xs={24} sm={10}>
                                                <Select
                                                value={liveClass.Availability}
                                                onSelect={(value, data) => {
                                                    this.changeLiveClass('Availability', index, data.value);
                                                    this.changeLiveClass('AvailabilityID', index, data.key)
                                                }}
                                                style={{ width: '100%' }}>
                                                    {
                                                        checkArray(this.state.UserLiveClassesOBJ[liveClass.ID]?.Availability).map((avail) => {
                                                            const dateString = `${new Date(avail.Date).toDateString()},
                                                            ${new Date(avail.StartTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} to ${new Date(avail.EndTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`
                                                            return (
                                                                <Select.Option value={dateString} key={avail.ID}>
                                                                    {dateString}
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                            <Col xs={24} sm={4}>
                                                <Button block icon={<DeleteOutlined />} type='primary' danger onClick={() => this.deleteLiveClass(index)}>Remove</Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Col>
                        <Col xs={24}>
                            <Button type="primary" onClick={this.addLiveClass}>Add Live Class to Bootcamp</Button>
                        </Col>
                    </Row>
                    <Row className='AddLiveClass-Row' gutter={[10,10]}>
                        <Col xs={24} sm={12}>
                        <h3>Upload a Bootcamp Picture</h3>
                        <p>Upload a picture for your bootcamp (makes it more enticing to buy!)</p>
                        {
                            this.state.PictureURL !== undefined && this.state.PictureURL !== null && String(this.state.PictureURL).length > 0?
                            <Card
                            hoverable
                            style={{ width: '100%' }}
                            cover={<img src={this.state.PictureURL} alt='Profile' className='Profile-ProfilePicture' />}
                            >
                            <Upload
                                disabled={this.state.PictureDisabled}
                                listType='picture'
                                accept=".png,.jpeg,.jpg"
                                customRequest={(info) => this.handleFileUpload('PictureFileList','PictureURL','PictureDisabled',info)}
                                fileList={this.state.PictureFileList}>
                                <Button icon={<UploadOutlined />}>Replace Bootcamp Picture</Button>
                            </Upload>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.PictureDisabled}
                                listType='picture'
                                accept=".png,.jpeg,.jpg"
                                customRequest={(info) => this.handleFileUpload('PictureFileList','PictureURL','PictureDisabled',info)}
                                fileList={this.state.PictureFileList}>
                                <Button icon={<UploadOutlined />}>Upload Bootcamp Picture</Button>
                            </Upload>
                        }
                        </Col>
                        <Col xs={24} sm={12}>
                        <h3>Upload a Bootcamp Waiver</h3>
                        <p>Have a waiver your clients need to confirm on? Upload it here.</p>
                        {
                            this.state.WaiverURL !== undefined && this.state.WaiverURL !== null && String(this.state.WaiverURL).length > 0 ?
                            <Card
                            hoverable
                            style={{ minWidth: '100%' }}
                            >
                            <Row style={{ textAlign: 'center' }} gutter={[10,10]}>
                                <Col xs={24}>
                                <a target="_blank" href={this.state.WaiverURL} rel="noreferrer">View Uploaded PDF</a>
                                </Col>
                                <Col xs={24}>
                                <Upload
                                disabled={this.state.WaiverDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled',info)}
                                fileList={this.state.WaiverFileList}>
                                <Button icon={<UploadOutlined />}>Replace Bootcamp Waiver</Button>
                                </Upload>
                                </Col>
                            </Row>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.WaiverDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled',info)}
                                fileList={this.state.WaiverFileList}>
                                <Button icon={<UploadOutlined />}>Upload Bootcamp Document</Button>
                            </Upload>
                        }
                        </Col>
                        <Col xs={24} sm={12}>
                        <h3>Upload a Bootcamp Document</h3>
                        <p>Have a document that your customer gets when signing up (I.e., Nutrition Plan, Motivational Document, etc.)? Upload it here!</p>
                        {
                            this.state.DocumentURL !== undefined && this.state.DocumentURL !== null && String(this.state.DocumentURL).length > 0 ?
                            <Card
                            hoverable
                            style={{ minWidth: '100%' }}
                            >
                            <Row style={{ textAlign: 'center' }} gutter={[10,10]}>
                                <Col xs={24}>
                                <a target="_blank" href={this.state.DocumentURL} rel="noreferrer">View Uploaded Document</a>
                                </Col>
                                <Col xs={24}>
                                <Upload
                                disabled={this.state.DocumentDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('DocumentFileList','DocumentURL','DocumentDisabled',info)}
                                fileList={this.state.DocumentFileList}>
                                <Button icon={<UploadOutlined />}>Replace Bootcamp Document</Button>
                                </Upload>
                                </Col>
                            </Row>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.DocumentDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('DocumentFileList','DocumentURL','DocumentDisabled',info)}
                                fileList={this.state.DocumentFileList}>
                                <Button icon={<UploadOutlined />}>Upload Bootcamp Waiver</Button>
                            </Upload>
                        }
                        </Col>
                    </Row>
                    <Row justify='end' className='AddLiveClass-Save-Buttons' gutter={[10,10]}>
                        <Col xs={6}>
                            {
                                this.state.Published ?
                                <Button block type='primary' danger onClick={this.publishEvent}>Un-Publish Bootcamp</Button>
                                :
                                <Button block className='Button-Yellow' onClick={this.publishEvent}>Publish Bootcamp</Button>
                            }
                        </Col>
                        <Col xs={6}>
                            <Affix offsetBottom={20}>
                            <Button block type='primary' className='Button' onClick={this.saveEvent}>Save Bootcamp</Button>
                            </Affix>
                        </Col>
                    </Row>
                </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default AddBootcamp;