import './Pricing.css';
import React from 'react';
import { Row, Col, Select, Modal, notification, Button, Switch, message, Spin } from 'antd';
import PhrosLogo from '../../Assets/phros-logo.png';
import { H2 } from '../../Common';
import { PLANS, FEATURES, ENVIRONMENT } from '../../constants';
import { AuthContext } from '../../Context/AuthContext';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../../Context/ProfileData';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class PricingCard extends React.Component {
    render() {
        return (
            <div className="PricingCard-Div">
                <Row justify='center'>
                    <Col xs={24}>
                        <h2 className="PricingCard-PlanName">{this.props.PlanName}</h2>
                        <p className="PricingCard-PlanDescription">{this.props.PlanDescription}</p>
                    </Col>
                    <Col xs={24}>
                        {
                            this.props.YEAR ?
                            <h2 className="PricingCard-Pricing">${Math.floor(Number(this.props.Pricing)*12*0.85)} USD<p className="PricingCard-PriceDuration">/year</p></h2>
                            :
                            <h2 className="PricingCard-Pricing">${this.props.Pricing} USD<p className="PricingCard-PriceDuration">{this.props.PriceDuration}</p></h2>
                        }
                        <h2 className="PricingCard-PlanDescription">{this.props.Percentage}</h2>
                    </Col>
                    <Col xs={23}>
                        <div className="PriceCard-YellowLine" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <button type='primary'
                        onClick={() => this.props.onClick(this.props.PLAN)}
                        className="PricingCard-Button"
                        >
                            get started
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <h3 className="PricingCard-Features">{this.props.NumFeatures}</h3>
                    </Col>
                    {
                        this.props.FeatureSelection.map((feature) => {
                            return (
                                <Col xs={24}>
                                    <h3 className="PricingCard-FeatureList">{feature}</h3>
                                </Col>
                            )
                        })
                    }
                    {
                        this.props.OtherFeatures.map((feature) => {
                            return (
                                <Col xs={24}>
                                    <h3 className="PricingCard-Features">{feature}</h3>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        )
    }
}

class Pricing extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            selection: PLANS.ADVANCED,
            open: false,
            featuresSelected: [],
            logout: false,
            yearPrice: true,
        }
        this.handleSelect = this.handleSelect.bind(this);
    }

    handlePlanConfirmation = async () => {
        notification.open({
            message: 'Creating subscription checkout!',
            description: "Sit tight, you're one step closer to success!"
        });
        const payload = {
            PLAN: this.state.selection,
            SELECTED_FEATURES: this.state.featuresSelected,
            PAY_BY_YEAR: this.state.yearPrice
        }
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.post(`${ENVIRONMENT.URL}/stripe/create-plan-checkout-session`, payload, config);
        if (response.data.error) {
            notification.open({
                message: 'Failed to Checkout',
                description: "We're sorry, something went wrong! Please try again or email us at support@phros.ca."
            });
        }
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
            sessionId: response.data.id,
        });
        if (result.error) {
            return message.error(result.error);
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleSelect = (value) => {
        this.setState({
            selection: value,
            open: true,
        })
    }

    handleAdvancedSelect = (value) => {
        if (value === PLANS.ADVANCED) {
            this.setState({
                selection: PLANS.ADVANCED
            })
            this.handlePlanConfirmation();
        }
    }

    handleBeginnerSelect = (value) => {
        let temp = [value];
        this.setState({
            featuresSelected: temp,
        })
    }

    handleVisibility = () => {
        this.setState({
            open: !this.state.open,
        })
    }

    handleArrayAdd = (name, value) => {
        if (this.state.featuresSelected.length >= 2) {
            notification.open({
                message: 'Feature Selection',
                description:
                  'Please remove a feature before selecting another one (intermediate plan has a max of 2 features).',
              });
            return;
        }
        let tempArray = [...this.state[name], value];
        this.setState({
            [name]: tempArray
        })
    }

    handleArrayDelete = (name, value) => {
        let tempArray = [...this.state[name]];
        let index = tempArray.indexOf(value);
        tempArray.splice(index, 1);
        this.setState({
            [name]: tempArray
        })
    }

    handleSignOut = async () => {
        const { logout } = this.context;
        await logout();
        this.setState({
            logout: true,
        })
    }

    render() {
        const beginner = (
            <Row>
                <Col xs={24}>
                    <h3 className="PricingCard-Features">Beginner Plan</h3>
                    <p className="Pricing-PlanDescription">Select your 1 feature with the dropdown below:</p>
                </Col>
                <Col xs={24}>
                    <Select style={{ width: '100%' }} value={FEATURES[this.state.featuresSelected[0]]} onSelect={(value) => this.handleBeginnerSelect(value)}>
                        {
                            Object.keys(FEATURES).map((key) => <Select.Option key={key}>{FEATURES[key]}</Select.Option>)
                        }
                    </Select>
                </Col>
            </Row>
        )
        const intermediate = (
            <Row>
                <Col xs={24}>
                    <h3 className="PricingCard-Features">Intermediate Plan</h3>
                    <p className="Pricing-PlanDescription">Select your 2 features with the dropdown below:</p>
                </Col>
                <Col xs={24}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        value={this.state.featuresSelected}
                        onSelect={(value) => this.handleArrayAdd("featuresSelected", value)}
                        onDeselect={(value) => this.handleArrayDelete("featuresSelected", value)}
                    >
                        {
                            Object.keys(FEATURES).map((key) => <Select.Option key={key}>{FEATURES[key]}</Select.Option>)
                        }
                    </Select>
                </Col>
            </Row>
        )
        return (
            <DataContext.Consumer>
            {
                data => {
                    return (
                        <div>
                        {
                        data.loading ? // TODO CHANGE to data.loading not !data.loading
                        <Spin />
                        :
                        <div className="Pricing-Main-Div">
                        {
                            data.profile?.PLAN && !data.profile?.PLAN?.FREETRIAL ?
                            <Redirect to="/" />
                            :
                            null
                        }
                        {
                            this.state.logout ?
                            <Redirect to="/" />
                            :
                            null
                        }
                        <Modal
                            visible={this.state.open}
                            onCancel={this.handleVisibility}
                            okText="Confirm"
                            onOk={this.handlePlanConfirmation}
                        >
                            {
                                this.state.selection === PLANS.BEGINNER ?
                                beginner
                                : this.state.selection === PLANS.INTERMEDIATE ?
                                intermediate
                                : null
                            }
                            <p>Please note, payments are processed through CLOUDSAFARI INC.</p>
                        </Modal>
                        <Row justify='center'>
                        <Col xs={23} sm={22} md={21}>
                            <Row>
                                <Col xs={24}>
                                    <h3 className="Pricing-Phros-Name">
                                    <img src={PhrosLogo} className="Pricing-Logo" alt="PHROS"/>
                                    PHROS
                                    </h3>
                                </Col>
                                <Col xs={24}>
                                    <H2 text="Select a Plan" highlight />
                                </Col>
                                <Col xs={24}>
                                    <h3>Check out our plans below! <strong>Save almost 40%</strong> on plans by paying for a year,
                                        toggle the switch to view different
                                        pricing plans: <Switch
                                        checkedChildren="Yearly Price"
                                        unCheckedChildren="Monthly Price"
                                        checked={this.state.yearPrice}
                                        onClick={(checked) => this.handleChange("yearPrice", checked)}
                                         />
                                    </h3>
                                </Col>
                            </Row>
                            <Row justify='space-around' align='stretch' gutter={[20,20]}>
                                <Col xs={24} sm={8}>
                                    <PricingCard
                                        PLAN={PLANS.BEGINNER}
                                        PlanName="Beginner"
                                        PlanDescription="best for trainers getting started or who need a website"
                                        Pricing={15}
                                        PriceDuration="/month"
                                        Percentage="+7.75% booking fee"
                                        NumFeatures="1 feature of your choice"
                                        FeatureSelection={['choose 1 feature between:', 'live classes & bootcamps', 'on-demand classes', 'build training programs']}
                                        OtherFeatures={['custom branded website', 'phros domain', 'customer portal']}
                                        onClick={this.handleSelect}
                                        YEAR = {this.state.yearPrice}
                                    />
                                </Col>
                                <Col xs={24} sm={8}>
                                    <PricingCard
                                        PLAN={PLANS.INTERMEDIATE}
                                        PlanName="Intermediate"
                                        PlanDescription="best for trainers starting to feel the pain of managing customers"
                                        Pricing={25}
                                        Percentage="+6.5% booking fee"
                                        PriceDuration="/month"
                                        NumFeatures="2 features of your choice"
                                        FeatureSelection={['choose 2 features between:', 'live classes & bootcamps', 'on-demand classes', 'build training programs']}
                                        OtherFeatures={['custom branded website', 'custom domain', 'customer memberships', 'customer portal']}
                                        onClick={this.handleSelect}
                                        YEAR = {this.state.yearPrice}
                                    />
                                </Col>
                                <Col xs={24} sm={8}>
                                    <PricingCard
                                        PLAN={PLANS.ADVANCED}
                                        PlanName="Advanced"
                                        PlanDescription="perfect for trainers dealing with large volume"
                                        Pricing={30}
                                        Percentage="+4.9% booking fee"
                                        PriceDuration="/month"
                                        NumFeatures="all features"
                                        FeatureSelection={['all the following are included:', 'live classes & bootcamps', 'on-demand classes', 'build training programs']}
                                        OtherFeatures={['custom branded website', 'custom domain', 'customer memberships', 'customer portal']}
                                        onClick={this.handleAdvancedSelect}
                                        YEAR = {this.state.yearPrice}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col xs={24}>
                                    <h3>Have any questions or want a demo? Send us a message at <a href="mailto:support@phros.ca">support@phros.ca</a></h3>
                                </Col>
                            </Row>
                            <Row justify='end'>
                                <Col>
                                <Button type='primary' danger onClick={this.handleSignOut}>Sign Out</Button>
                                </Col>
                            </Row>
                        </Col>
                        </Row>
                        </div>
                        }
                        </div>
                    )
                }
            }
        </DataContext.Consumer>
        )
    }
}

export default Pricing;