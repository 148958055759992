import { useState, useEffect } from "react";
import { Profile1 } from "../../phros-web-components/Profile/Profile1/Profile1";
import { Input } from "antd";
import { PhrosUpload } from "../../Common/Uploads/Upload";
import { ImageTemplate } from "../../phros-web-components/TemplateData";

/*
this.Image = image;
this.ImageAlt = imageAlt;
this.Title = title;
this.SubTitle = subtitle;
this.Text = text;
this.ButtonText = buttonText;
this.ButtonLink = buttonLink;
*/

const Profile1Edit = ({ data = Profile1.Default(), editComponent = () => {} }) => {
    const [imageMap, setImageMap] = useState({
        "image_id": {
            url: data.Image,
            description: data.ImageAlt,
            fileId: "image_id",
        }
    });
    const [title, setTitle] = useState(data.Title);
    const [subTitle, setSubTitle] = useState(data.SubTitle);
    const [text, setText] = useState(data.Text);
    const [buttonText, setButtonText] = useState(data.ButtonText);
    const [buttonLink, setButtonLink] = useState(data.ButtonLink);

    const deleteWebComponentImage = () => {
        return;
    }

    useEffect(() => {
        const keys = Object.keys(imageMap);
        let imageUrl = ImageTemplate;
        let imageAlt = "This is some image alt text.";
        if (keys.length > 0) {
            const image = imageMap[keys[0]];
            imageUrl = image.url;
            imageAlt = `${image.title} ${image.description}`;
        }
        const updateProfile = new Profile1(imageUrl, imageAlt, title, subTitle, text, buttonText, buttonLink);
        editComponent(updateProfile);
    }, [imageMap, title, subTitle, text, buttonText, buttonLink]);

    return (
        <div>
            <h3>Image</h3>
            <PhrosUpload
                accept = ".jpg,.jpeg,.png"
                text="Choose an image or drag it here."
                type="static"
                fileMap={imageMap}
                setFileMap={setImageMap}
                deleteFile={deleteWebComponentImage}
            />

            <h3 style={{ marginTop: "20px" }}>Title:</h3>
            <Input.TextArea
                value = {title}
                onChange = {(e) => setTitle(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Subtitle:</h3>
            <Input.TextArea
                value = {subTitle}
                onChange = {(e) => setSubTitle(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Text:</h3>
            <Input.TextArea
                value = {text}
                onChange = {(e) => setText(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Button Text:</h3>
            <Input.TextArea
                value = {buttonText}
                onChange = {(e) => setButtonText(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Button Link:</h3>
            <p>Where does this button link to? Please enter a URL.</p>
            <Input.TextArea
                value = {buttonLink}
                onChange = {(e) => setButtonLink(e.target.value)}
            />
        </div>
    )
};

export {
    Profile1Edit,
}