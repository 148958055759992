import React from 'react';
import './AddLiveClass.css';
import { Radio, Row, Col, Layout, Input, Button, Upload, Card, Select, Affix, message, InputNumber, DatePicker, Modal, Popconfirm } from 'antd';
import { SideNav, LocationSearch } from '../../Components';
import { H2, TimeRange, } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import moment from 'moment';
import currencies from '../../Assets/currencies';
import { TRAINING_EXPERIENCE_LEVEL, TRAINING_INTENSITY_LEVEL, FREE_EVENTS, ONLINE_IN_PERSON } from '../../constants';
import momentTZ from 'moment-timezone';
import axios from 'axios';

const timezoneList = momentTZ.tz.names();

const INTERVALS = {
    DAYS: { NAME: "Day(s)", ID: "DAY" },
    WEEKS: { NAME: "Week(s)", ID: "WEEK" },
    MONTHS: { NAME: "Month(s)", ID: "MONTH" },
    YEARS: { NAME: "Year(s)", ID: "YEAR" },
};

class AddLiveClass extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Name: undefined,
            Price: undefined,
            Currency: undefined,
            Description: undefined,
            Level: undefined,
            IntensityRating: undefined,
            Capacity: 0,
            Availability: [],

            PictureFileList: [],
            PictureURL: '',
            PictureDisabled: false,

            WaiverFileList: [],
            WaiverURL: '',
            WaiverDisabled: false,

            Published: false,

            FreeEvent: "No",

            ModalOpen: false,
            SelectedAvailability: {},
            RepeatingInterval: {
                Number: 1,
                Frequency: INTERVALS.DAYS.ID,
                EndDate: moment(new Date().setDate(new Date().getDate()+1)),
                TotalToCreate: 0,
            },
            RepeatedEventDates: [],
        }
        this.changeClassAvailability = this.changeClassAvailability.bind(this);
        this.handleLocationSelection = this.handleLocationSelection.bind(this);
        this.handleMapQueryChange = this.handleMapQueryChange.bind(this);
        this.handleMapChange = this.handleMapChange.bind(this);
        this.getDatesBetween = this.getDatesBetween.bind(this);
        this.repeatClassAvailability = this.repeatClassAvailability.bind(this);
    }

    componentDidMount() {
        this.getEventData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.ID !== this.props.match.params.ID) {
            this.setState({
                Name: undefined,
                Price: undefined,
                Currency: undefined,
                Description: undefined,
                Level: undefined,
                IntensityRating: undefined,
                Capacity: 0,
                Availability: [],
                PictureFileList: [],
                PictureURL: '',
                PictureDisabled: false,
                WaiverFileList: [],
                WaiverURL: '',
                WaiverDisabled: false,
                Published: false,
                FreeEvent: 'No',
            });
            this.getEventData();
        }
    }
    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            uid: currentUser.uid,
        };
        const event = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/events/get-event-with-id`, data, config);
        if(event.data.error) return;
        let free = FREE_EVENTS.NO.Key;
        if (event.data.Free === true) {
            free = FREE_EVENTS.YES.Key;
        }
        this.setState({
            Name: event.data.Name,
            Price: event.data.Price,
            Currency: event.data.Currency,
            Description: event.data.Description,
            Level: event.data.Level,
            IntensityRating: event.data.IntensityRating,
            Capacity: event.data.Capacity,
            Availability: event.data.Availability,
            PictureURL: event.data.PictureURL,
            WaiverURL: event.data.WaiverURL,
            Published: event.data.Published,
            FreeEvent: free,
        })
    }

    publishEvent = async () => {
        await this.saveEvent();
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            uid: currentUser.uid,
            Published: !this.state.Published,
        }
        const publish = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/events/publish-event`, data, config);
        const save = await this.saveEvent();
        if (publish && save) return message.success('Published the event.')
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleMapQueryChange = (value, index) => {
        this.changeClassAvailability("Address", index, value)
    }

    handleMapChange = (address, latitude, longitude, index) => {
        this.changeClassAvailability("Address", index, address);
        this.changeClassAvailability("Latitude", index, latitude);
        this.changeClassAvailability("Longitude", index, longitude);
    }

    repeatClassAvailability = () => {
        const newDates = this.state.RepeatedEventDates;
        let newAvailabilities = [];
        for (let i = 0; i<newDates.length; i++) {
            const newDate = new Date(newDates[i]);
            const avail = {
                ...this.state.SelectedAvailability,
                Date: moment(newDate),
                ID: v4(),
            }
            newAvailabilities.push(avail);
        }
        this.setState({
            Availability: [...this.state.Availability, ...newAvailabilities]
        })
    }

    getRepeatedEventDates = () => {
        // Set the event date
        let eventDate = this.state.SelectedAvailability?.Date;
        if (!eventDate) return;
        eventDate = new Date(eventDate);

        // Loop from the event date based on the case
        const number = this.state.RepeatingInterval.Number;
        const totalEventsToAdd = this.getDatesBetween();
        const currentDate = new Date();
        let tempDates = [];

        for (let i = 1; i<(totalEventsToAdd+2); i++) {
            const toAdd = i*number;
            const tempDate = new Date(eventDate);
            let newDate = new Date().setUTCHours(0, 0, 0, 0);
            switch (this.state.RepeatingInterval.Frequency) {
                case INTERVALS.DAYS.ID:
                    newDate = tempDate.setDate(tempDate.getDate() + toAdd);
                    break;
                case INTERVALS.WEEKS.ID:
                    newDate = tempDate.setDate(tempDate.getDate() + (toAdd*7));
                    break;
                case INTERVALS.MONTHS.ID:
                    newDate = tempDate.setMonth(tempDate.getMonth() + toAdd);
                    break;
                case INTERVALS.YEARS.ID:
                    newDate = tempDate.setFullYear(tempDate.getFullYear() + toAdd);
                    break;
                default:
                    break;
            }
            if (newDate > currentDate && newDate <= new Date(this.state.RepeatingInterval.EndDate).setUTCHours(23, 59, 59, 999)) {
                tempDates.push(newDate);
            }
        }

        this.setState({ RepeatedEventDates: tempDates });
    }

    getDatesBetween = () => {
        let eventDate = this.state.SelectedAvailability?.Date;
        if (!eventDate) return 0;
        eventDate = new Date(eventDate);

        // Get the end date from the interval
        const endDate = new Date(this.state.RepeatingInterval?.EndDate);

        if (endDate < eventDate) {
            return 0;
        }

        // Day calculation
        const daysBetween = Math.floor((endDate-eventDate)/(24 * 60 * 60 * 1000));
        const weeksBetween = Math.floor((endDate-eventDate)/(24*60*60*1000*7));

        let monthsBetween;
        monthsBetween = (endDate.getFullYear() - eventDate.getFullYear()) * 12;
        monthsBetween -= endDate.getMonth();
        monthsBetween += eventDate.getMonth();

        const yearsBetween = endDate.getFullYear() - eventDate.getFullYear();

        switch (this.state.RepeatingInterval.Frequency) {
            case INTERVALS.DAYS.ID:
                const days = Math.floor(daysBetween/this.state.RepeatingInterval.Number);
                if (days < 0) return 0;
                return days;
            case INTERVALS.WEEKS.ID:
                const weeks = Math.floor(weeksBetween/this.state.RepeatingInterval.Number);
                if (weeks < 0) return 0;
                return weeks;
            case INTERVALS.MONTHS.ID:
                const months = Math.floor(monthsBetween/this.state.RepeatingInterval.Number);
                if (months < 0) return 0;
                return months;
            case INTERVALS.YEARS.ID:
                const years = Math.floor(yearsBetween/this.state.RepeatingInterval.Number);
                if (years < 0) return 0;
                return years;
            default:
                return 0;
        }
    }

    addClassAvailability = () => {
        const avail = {
            Date: moment(new Date()),
            StartTime: undefined,
            EndTime: undefined,
            TimeZone: momentTZ.tz.guess(),
            MeetingLink: undefined,
            OnlineOrInPerson: ONLINE_IN_PERSON.ONLINE.value,
            Address: "",
            Latitude: 43.641750,
            Longitude: -79.386659,
            ID: v4(),
        }
        this.setState({
            Availability: [...this.state.Availability, avail]
        })
    }
    deleteClassAvailability = (index) => {
        const temp = this.state.Availability;
        temp.splice(index, 1);
        this.setState({
            Availability: temp,
        })
    }
    changeClassAvailability = (name, index, value) => {
        const temp = this.state.Availability[index];
        temp[name] = value;
        this.setState({
            Availability: [...this.state.Availability.slice(0, index), temp, ...this.state.Availability.slice(index+1, this.state.Availability.length)]
        })
    }

    handleFileUpload = async (fileListName, urlName, buttonName, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/images/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`images/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined) {
                            // Send URL to backend to delete
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        message.error('Failed to delete the file.');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    handleTimeZoneChange = (name, value, index) => {
        let tempData = this.state[name];
        let tempValue  = tempData[index];
        tempValue.StartTime = moment(tempValue.StartTime).tz(value);
        tempValue.EndTime = moment(tempValue.EndTime).tz(value);
        tempValue.TimeZone = value;
        this.setState({
            [name]: [...this.state[name].slice(0, index), tempValue, ...this.state[name].slice(index+1, this.state[name].length)]
        })
    }

    saveEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
            ID: this.props.match.params.ID,
            Name: this.state.Name,
            Price: this.state.Price,
            Currency: this.state.Currency,
            Description: this.state.Description,
            Level: this.state.Level,
            IntensityRating: this.state.IntensityRating,
            Capacity: this.state.Capacity,
            Availability: this.state.Availability,
            PictureURL: this.state.PictureURL,
            WaiverURL: this.state.WaiverURL,
            FreeEvent: this.state.FreeEvent,
        }
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/events/save-event`, data, config);
        if (save.data.error) {
            message.error('Failed to save event information.')
        } else {
            message.success('Successfully saved event information.')
        }
    }

    handleLocationSelection = (latitude, longitude, address, index) => {
        this.changeClassAvailability("Address", index, address);
        this.changeClassAvailability("Latitude", index, latitude);
        this.changeClassAvailability("Longitude", index, longitude);
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                <Col xs={23} sm={22}>
                    <Row>
                        <Col xs={24}>
                        <H2 text='Add a Live Class' />
                        </Col>
                    </Row>
                    <Row gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                        <h3>Class Name</h3>
                        <Input value={this.state.Name} onChange={(e) => this.handleChange('Name', e.target.value)} placeholder="I.e. HIIT Training"/>
                        </Col>
                        <Col xs={24} sm={4}>
                        <h3>Free Class?</h3>
                        <Select onChange={(value) => this.handleChange('FreeEvent', value)} value={this.state.FreeEvent} style={{ width: '100%' }}>
                            <Select.Option key={FREE_EVENTS.YES.Key}>{FREE_EVENTS.YES.Value}</Select.Option>
                            <Select.Option key={FREE_EVENTS.NO.Key}>{FREE_EVENTS.NO.Value}</Select.Option>
                        </Select>
                        </Col>
                        {
                            this.state.FreeEvent === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Price</h3>
                            <InputNumber className='AddLiveClass-Input' min={1} value={this.state.Price} onChange={(value) => this.handleChange('Price', value)} />
                            <p style={{ marginBottom: '0px' }}>Minimum class price is $1, unless free </p>
                            </Col>
                            :
                            null
                        }
                        {
                            this.state.FreeEvent === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Currency</h3>
                            <Select
                                placeholder="Search for a currency"
                                filterOption={(input, option) =>
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value) => this.handleChange('Currency', value)}
                                showSearch={true}
                                value={this.state.Currency}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(currencies).map((curr) => {
                                            return (
                                                <Select.Option key={curr} name={currencies[curr]}>
                                                <strong>{curr}: </strong>{currencies[curr]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    <Row className='AddLiveClass-Row'>
                        <Col xs={24}>
                            <h3>Class Description</h3>
                            <Input.TextArea value={this.state.Description} onChange={(e) => this.handleChange('Description', e.target.value)}/>
                        </Col>
                    </Row>
                    <Row className='AddLiveClass-Row' gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                            <h3>Intensity Rating</h3>
                            <Select value={this.state.IntensityRating} className='AddLiveClass-Input' onChange={(value) => this.handleChange('IntensityRating', parseInt(value))}>
                                {
                                    TRAINING_INTENSITY_LEVEL.map(val => {
                                        if (val === 6) {
                                            return <Select.Option key={val}>Open Intensity</Select.Option>
                                        }
                                        return <Select.Option key={val}>{val}</Select.Option>
                                    })
                                }
                            </Select>
                            <p>1: Low Intensity, 5: High Intensity</p>
                        </Col>
                        <Col xs={24} sm={8}>
                            <h3>Level of Experience</h3>
                            <Select value={this.state.Level} className='AddLiveClass-Input' onSelect={(value) => this.handleChange('Level', value)}>
                                {
                                    Object.keys(TRAINING_EXPERIENCE_LEVEL).map(lvl => {
                                        return <Select.Option key={lvl}>{TRAINING_EXPERIENCE_LEVEL[lvl]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col xs={24} sm={8}>
                            <h3>Class Capacity</h3>
                            <InputNumber className='AddLiveClass-Input' value={this.state.Capacity} min={0} onChange={(value) => this.handleChange('Capacity', value)} />
                        </Col>
                    </Row>
                    <Row className='AddLiveClass-Row'>
                        <Col xs={24}>
                            <h3>Class Availability</h3>
                        </Col>
                        <Col xs={24}>
                            {
                                this.state.Availability.map((val, index) => {
                                    let latitude = val.Latitude || 43.641750;
                                    let longitude = val.Longitude || -79.386659;
                                    let query = val.Address || "";
                                    let onlineOrInPerson = val.OnlineOrInPerson || ONLINE_IN_PERSON.ONLINE.value;

                                    return (
                                        <Row gutter={[0,10]} justify="center" align="stretch" className='AddLiveClass-Availability-Row'>
                                            <Col xs={24}>
                                                <h3>Session #{index+1}</h3>
                                            </Col>
                                            <Col xs={24} sm={22}>
                                                <Row gutter={[10,10]}>
                                                <Col xs={24} sm={4}>
                                                    <h4>Date</h4>
                                                    <DatePicker value={moment(val.Date)} onChange={(value) => this.changeClassAvailability('Date', index, value)} />
                                                </Col>
                                                <Col xs={24} sm={6}>
                                                <h4>TimeZone</h4>
                                                <Select defaultValue={momentTZ.tz.guess()}
                                                        style={{ width: '100%' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                        value={val.TimeZone}
                                                        onChange={(value) => this.handleTimeZoneChange('Availability', value, index)}
                                                    >
                                                        {
                                                            timezoneList.map((value) => {
                                                                return (
                                                                    <Select.Option value={value}>{value}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Col>
                                                <Col xs={24} sm={6}>
                                                <h4>Start Time & End Time</h4>
                                                    <TimeRange
                                                    onChange={this.changeClassAvailability}
                                                    values={[moment(val.StartTime).tz(val.TimeZone), moment(val.EndTime).tz(val.TimeZone)]}
                                                    StartTime='StartTime'
                                                    EndTime='EndTime'
                                                    index={index}
                                                    TimeZone={val.TimeZone}
                                                    />
                                                </Col>
                                                <Col xs={24} sm={8}>
                                                <h4>Online or In-Person Class</h4>
                                                    <Radio.Group value={onlineOrInPerson} onChange={(e) => this.changeClassAvailability("OnlineOrInPerson", index, e.target.value)}>
                                                        <Radio value={ONLINE_IN_PERSON.ONLINE.value}>{ONLINE_IN_PERSON.ONLINE.name}</Radio>
                                                        <Radio value={ONLINE_IN_PERSON.IN_PERSON.value}>{ONLINE_IN_PERSON.IN_PERSON.name}</Radio>
                                                    </Radio.Group>
                                                </Col>
                                                <Col xs={24}>
                                                    {
                                                        onlineOrInPerson === ONLINE_IN_PERSON.ONLINE.value ?
                                                        <div>
                                                            <h4>Meeting Link</h4>
                                                            <Input value={val.MeetingLink} onChange={(e) => this.changeClassAvailability('MeetingLink', index, e.target.value)} placeholder="meeting link" />
                                                        </div>
                                                        :
                                                        <div style={{ width: "100%", height: "250px" }}>
                                                            <h4>Location</h4>
                                                            {/*
                                                            <MapSearch
                                                            handleChange={this.handleMapChange}
                                                            handleQueryChange={this.handleMapQueryChange}
                                                            query = {query}
                                                            latitude = {latitude}
                                                            longitude = {longitude}
                                                            id={id}
                                                             />
                                                            */}
                                                            <LocationSearch
                                                            latitude = {latitude}
                                                            longitude = {longitude}
                                                            address = {query}
                                                            handleLocationSelection = {this.handleLocationSelection}
                                                            name = {`LiveClass-Map-${index}`}
                                                            indexedLocation = {true}
                                                            index = {index}
                                                            />
                                                        </div>
                                                    }
                                                </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={2}>
                                                <h4>Delete</h4>
                                                <Button className="AddLiveClass-Availability-Delete" block type='primary' danger onClick={() => this.deleteClassAvailability(index)} icon={<DeleteOutlined />}/>
                                            </Col>
                                            <Col xs={24}>
                                                <Modal
                                                    visible={this.state.ModalOpen}
                                                    onCancel={() => this.setState({ ModalOpen: false })}
                                                    footer={null}
                                                >
                                                    <h4 style={{ fontWeight: 600 }}>Schedule a Recurring Class</h4>
                                                    <p>
                                                        Select the repeating interval below. Once complete, this will create multiple event availabilities given the event criteria (you will be able to change each newly created event availability if needed).
                                                    </p>
                                                    <div style={{ marginTop: "20px" }}>
                                                        <small style={{ fontWeight: 600 }}>Repeat Interval:</small>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                                                            <div style={{ marginRight: "20px" }}>
                                                                <small style={{ fontWeight: 600 }}>Every </small>
                                                            </div>
                                                            <div style={{ display: "block", marginRight: "20px" }}>
                                                                <InputNumber value={this.state.RepeatingInterval.Number} min={1}
                                                                onChange={(value) => {
                                                                    this.setState({ RepeatingInterval: { ...this.state.RepeatingInterval, Number: +value } });
                                                                }} />
                                                            </div>
                                                            <div style={{ display: "block" }}>
                                                                <Select style={{ minWidth: "100px" }} value={this.state.RepeatingInterval.Frequency}
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            RepeatingInterval: {
                                                                                ...this.state.RepeatingInterval,
                                                                                Frequency: value
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    {
                                                                        Object.keys(INTERVALS).map((key) => {
                                                                            return (
                                                                                <Select.Option key={INTERVALS[key].ID} value={INTERVALS[key].ID}>
                                                                                    {INTERVALS[key].NAME}
                                                                                </Select.Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: "10px" }}>
                                                        <small style={{ fontWeight: 600, marginRight: "20px" }}>Until</small>
                                                        <DatePicker value={this.state.RepeatingInterval.EndDate}
                                                        onChange={(value) => {
                                                            this.setState({ RepeatingInterval: { ...this.state.RepeatingInterval, EndDate: value }})
                                                        }} />
                                                    </div>
                                                    <div style={{ marginTop: "20px" }}>
                                                        <Button style={{ marginBottom: "20px" }} onClick={() => this.getRepeatedEventDates()}>
                                                            Click Here to Verify the New Event Dates
                                                        </Button>
                                                        {
                                                            this.state.RepeatedEventDates?.length > 0 ?
                                                            <div>
                                                                <small style={{ fontWeight: 600 }}>Availability to be repeated on the following days:</small>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {
                                                            this.state.RepeatedEventDates?.map((red) => (
                                                                <div>
                                                                    <small>{new Date(red).toDateString()}</small>
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            this.state.RepeatedEventDates?.length > 0 ?
                                                            <Popconfirm
                                                            title={`Are you sure you want to add these availabilities for this class? You can only undo this by deleting all events individually.`}
                                                            onConfirm={() => {
                                                                this.repeatClassAvailability();
                                                                this.setState({
                                                                    ModalOpen: false,
                                                                })
                                                            }}
                                                            >
                                                                <Button
                                                                style={{ marginTop: "20px" }}
                                                                type="primary">Create Recurring Event Times</Button>
                                                            </Popconfirm>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </Modal>
                                                <Button onClick={() => {
                                                    this.setState({ ModalOpen: true, SelectedAvailability: val })
                                                }} type="primary">Schedule as Recurring Class</Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </Col>
                        <Col xs={24}>
                        <p>When a client books a class, this information will be sent to them (including the meeting link).</p>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Button block type='primary' className='Button' onClick={this.addClassAvailability}>add class availability</Button>
                        </Col>
                    </Row>
                    <Row className='AddLiveClass-Row' gutter={[10,10]}>
                        <Col xs={24} sm={12}>
                        <h3>Upload an Event Picture</h3>
                        <p>Upload a picture for your event (makes it more enticing to buy!)</p>
                        {
                            this.state.PictureURL !== undefined && this.state.PictureURL !== null && String(this.state.PictureURL).length > 0?
                            <Card
                            hoverable
                            style={{ width: '100%' }}
                            cover={<img src={this.state.PictureURL} alt='Profile' className='Profile-ProfilePicture' />}
                            >
                            <Upload
                                disabled={this.state.PictureDisabled}
                                listType='picture'
                                accept=".png,.jpeg,.jpg"
                                customRequest={(info) => this.handleFileUpload('PictureFileList','PictureURL','PictureDisabled',info)}
                                fileList={this.state.PictureFileList}>
                                <Button icon={<UploadOutlined />}>Replace Event Picture</Button>
                            </Upload>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.PictureDisabled}
                                listType='picture'
                                accept=".png,.jpeg,.jpg"
                                customRequest={(info) => this.handleFileUpload('PictureFileList','PictureURL','PictureDisabled',info)}
                                fileList={this.state.PictureFileList}>
                                <Button icon={<UploadOutlined />}>Upload Event Picture</Button>
                            </Upload>
                        }
                        </Col>
                        <Col xs={24} sm={12}>
                        <h3>Upload an Event Waiver</h3>
                        <p>Have a waiver your clients need to sign? Upload it here.</p>
                        {
                            this.state.WaiverURL !== undefined && this.state.WaiverURL !== null && String(this.state.WaiverURL).length > 0 ?
                            <Card
                            hoverable
                            style={{ minWidth: '100%' }}
                            >
                            <Row style={{ textAlign: 'center' }} gutter={[10,10]}>
                                <Col xs={24}>
                                <a target="_blank" href={this.state.WaiverURL} rel="noreferrer">View Uploaded PDF</a>
                                </Col>
                                <Col xs={24}>
                                <Upload
                                disabled={this.state.WaiverDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled',info)}
                                fileList={this.state.WaiverFileList}>
                                <Button icon={<UploadOutlined />}>Replace Event Waiver</Button>
                                </Upload>
                                </Col>
                            </Row>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.WaiverDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled',info)}
                                fileList={this.state.WaiverFileList}>
                                <Button icon={<UploadOutlined />}>Upload Event Waiver</Button>
                            </Upload>
                        }
                        </Col>
                    </Row>
                    <Row justify='end' className='AddLiveClass-Save-Buttons' gutter={[10,10]}>
                        <Col xs={6}>
                            {
                                this.state.Published ?
                                <Button block type='primary' danger onClick={this.publishEvent}>Un-Publish Event</Button>
                                :
                                <Button block className='Button-Yellow' onClick={this.publishEvent}>Publish Event</Button>
                            }
                        </Col>
                        <Col xs={6}>
                            <Affix offsetBottom={20}>
                            <Button block type='primary' className='Button' onClick={this.saveEvent}>Save Event</Button>
                            </Affix>
                        </Col>
                    </Row>
                </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default AddLiveClass;