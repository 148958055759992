import './PreviewWebsite.css';
import React from 'react';
import { Row, Col } from 'antd';
import { Banner1, Banner2, Banner3, Banner4,
    Navbar1, Navbar2, Navbar3,
    AboutMe1, AboutMe2, AboutMe3,
    Gallery1, Gallery2,
    Service1, Service2, Service3,
    Testimonial1, Testimonial2, Testimonial3,
    FAQ1, FAQ2,
    Contact1, Contact2, } from '../WebsiteComponents';
import { WEBSITE_SECTIONS, WEBSITE_COMPONENTS } from '../../../constants';
import { checkArray } from '../../../Verification';

/* Props
SelectedDesigns
LogoURL
BusinessName
ExistingWebsite
ExistingWebsiteURL
SocialMedia
Profile
BannerImageURL
ProfessionalTitle
FirstName
LastName
BusinessStatement
ColourPalette
Tesimonials
Gallery
Services
FAQ
AboutMe
AboutMePictureURL
Order
*/
class PreviewWebsite extends React.Component {
    render() {
        const navbar = (
            this.props.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR1 ?
            <Navbar1
                LogoURL = {this.props.LogoURL}
                BusinessName = {this.props.BusinessName}
                ExistingWebsite = {this.props.ExistingWebsite}
                ExistingWebsiteURL = {this.props.ExistingWebsiteURL}
                SocialMedia = {this.props.SocialMedia}
                Profile = {this.props.Profile}
            />
            :
            this.props.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR2 ?
            <Navbar2
                LogoURL = {this.props.LogoURL}
                BusinessName = {this.props.BusinessName}
                ExistingWebsite = {this.props.ExistingWebsite}
                ExistingWebsiteURL = {this.props.ExistingWebsiteURL}
                SocialMedia = {this.props.SocialMedia}
                Profile = {this.props.Profile}
            />
            :
            this.props.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR3 ?
            <Navbar3
                LogoURL = {this.props.LogoURL}
                BusinessName = {this.props.BusinessName}
                ExistingWebsite = {this.props.ExistingWebsite}
                ExistingWebsiteURL = {this.props.ExistingWebsiteURL}
                SocialMedia = {this.props.SocialMedia}
                Profile = {this.props.Profile}
            />
            :
            null
        )

        const banner = (
            this.props.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER1 ?
            <Banner1
                BannerImageURL = {this.props.BannerImageURL}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                BusinessStatement = {this.props.BusinessStatement}
                ColourPalette = {this.props.ColourPalette}
            />
            :
            this.props.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER2 ?
            <Banner2
                BannerImageURL = {this.props.BannerImageURL}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                BusinessStatement = {this.props.BusinessStatement}
                ColourPalette = {this.props.ColourPalette}
            />
            :
            this.props.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER3 ?
            <Banner3
                BannerImageURL = {this.props.BannerImageURL}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                BusinessStatement = {this.props.BusinessStatement}
                ColourPalette = {this.props.ColourPalette}
            />
            :
            this.props.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER4 ?
            <Banner4
                BannerImageURL = {this.props.BannerImageURL}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                BusinessStatement = {this.props.BusinessStatement}
                ColourPalette = {this.props.ColourPalette}
            />
            :
            null
        )

        const aboutme = (
            this.props.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME1 ?
            <AboutMe1
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                AboutMe = {this.props.AboutMe}
                AboutMePictureURL = {this.props.AboutMePictureURL}
            />
            :
            this.props.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME2 ?
            <AboutMe2
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                AboutMe = {this.props.AboutMe}
                AboutMePictureURL = {this.props.AboutMePictureURL}
            />
            :
            this.props.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME3 ?
            <AboutMe3
                FirstName = {this.props.FirstName}
                LastName = {this.props.LastName}
                ProfessionalTitle = {this.props.ProfessionalTitle}
                AboutMe = {this.props.AboutMe}
                AboutMePictureURL = {this.props.AboutMePictureURL}
            />
            :
            null
        )

        const faq = (
            this.props.SelectedDesigns?.FAQ === WEBSITE_COMPONENTS.FAQ.FAQ1 ?
            <FAQ1
                FAQ = {this.props.FAQ}
            />
            :
            this.props.SelectedDesigns?.FAQ === WEBSITE_COMPONENTS.FAQ.FAQ2 ?
            <FAQ2
                FAQ = {this.props.FAQ}
            />
            :
            null
        )

        const contact = (
            this.props.SelectedDesigns?.CONTACT === WEBSITE_COMPONENTS.CONTACTME.CONTACT1 ?
            <Contact1 />
            :
            this.props.SelectedDesigns?.CONTACT === WEBSITE_COMPONENTS.CONTACTME.CONTACT2 ?
            <Contact2 />
            :
            null
        )

        const services = (
            this.props.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE1 ?
            <Service1
                Services = {this.props.Services}
            />
            :
            this.props.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE2 ?
            <Service2
                Services = {this.props.Services}
            />
            :
            this.props.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE3 ?
            <Service3
                Services = {this.props.Services}
            />
            :
            null
        )

        const gallery = (
            this.props.SelectedDesigns?.GALLERY === WEBSITE_COMPONENTS.GALLERY.GALLERY1 ?
            <Gallery1
                Gallery = {this.props.Gallery}
            />
            :
            this.props.SelectedDesigns?.GALLERY === WEBSITE_COMPONENTS.GALLERY.GALLERY2 ?
            <Gallery2
                Gallery = {this.props.Gallery}
            />
            :
            null
        )

        const testimonials = (
            this.props.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL1 ?
            <Testimonial1
                Testimonials = {this.props.Testimonials}
            />
            :
            this.props.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL2 ?
            <Testimonial2
                Testimonials = {this.props.Testimonials}
            />
            :
            this.props.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL3 ?
            <Testimonial3
                Testimonials = {this.props.Testimonials}
            />
            :
            null
        )

        return (
        <Row gutter={[20,20]}>
        <Col xs={24}>
            <h2>Website Preview</h2>
            <p>Check out a preview of your website below. This is what your customers will see when they get to the landing page of your website.</p>
        </Col>
        <Col xs={24} style={{ border: "1px solid black" }}>
            <Row justify="center">
                <Col xs={24}>
                {navbar}
                {banner}
                </Col>
                <Col xs={24} className="PreviewWebsite-Max-Col">
                {
                    checkArray(this.props.Order).map((key, index) => {
                        const section = WEBSITE_SECTIONS[key].VALUE;
                        if (section === WEBSITE_SECTIONS.FAQ.VALUE) {
                            return (
                            <div
                            className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(this.props.Order).length-1 ? "-Last" : "" }`}
                            >
                                {faq}
                            </div>)
                        }
                        if (section === WEBSITE_SECTIONS.ABOUTME.VALUE) {
                            return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(this.props.Order).length-1 ? "-Last" : "" }`}>{aboutme}</div>)
                        }
                        if (section === WEBSITE_SECTIONS.CONTACT.VALUE) {
                            return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(this.props.Order).length-1 ? "-Last" : "" }`}>{contact}</div>)
                        }
                        if (section === WEBSITE_SECTIONS.SERVICES.VALUE) {
                            return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(this.props.Order).length-1 ? "-Last" : "" }`}>{services}</div>)
                        }
                        if (section === WEBSITE_SECTIONS.GALLERY.VALUE) {
                            return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(this.props.Order).length-1 ? "-Last" : "" }`}>{gallery}</div>)
                        }
                        if (section === WEBSITE_SECTIONS.TESTIMONIALS.VALUE) {
                            return (<div className={`PreviewWebsite-Div${index===0 ? "-First" : index === checkArray(this.props.Order).length-1 ? "-Last" : "" }`}>{testimonials}</div>)
                        }
                        return null;
                    })
                }
                </Col>
            </Row>
        </Col>
        </Row>
        )
    }
}

export default PreviewWebsite;