import React from 'react';
import './Coupons.css';
import { Row, Col, Layout, Input, Radio, notification, Button, Select, InputNumber, DatePicker } from 'antd';
import { SideNav } from '../../Components';
import { H2 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import currencies from '../../Assets/currencies';
import moment from 'moment';
import axios from 'axios';
import { COUPON_TYPES, COUPON_DURATION }  from '../../constants'
import Checkbox from 'antd/lib/checkbox/Checkbox';

class Coupon extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Name: "",
            Code: "",
            CouponType: COUPON_TYPES.PERCENTAGE.value,
            Currency: "CAD",
            Duration: COUPON_DURATION.ONCE.value,
            DurationMonths: 0,
            PercentageDiscount: 1,
            DiscountAmount: 1,
            LimitDate: false,
            RedeemBy: moment(new Date()),
            LimitMaxRedeem: false,
            MaxRedemptions: 10,
            FirstTimeOrder: false,
            LimitMinOrder: false,
            MinOrder: 10,
            Products: [],
            SelectedProducts: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/data/get-product-data`, {}, config);

        if (response.data.error) return notification.error({
            message: "Error",
            description: "Failed to get products, the coupon created here will apply to all items at checkout."
        });

        this.setState({
            Products: response.data
        })
    }

    createCoupon = async () => {
        if (String(this.state.Name).length === 0) {
            return notification.error({
                message: "Error",
                description: "Please enter in a name for this coupon."
            })
        }
        if (String(this.state.Code).length === 0) {
            return notification.error({
                message: "Error",
                description: "Please enter in a code for this coupon."
            })
        }

        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            Name: this.state.Name,
            Code: this.state.Code,
            CouponType: this.state.CouponType,
            Currency: this.state.Currency,
            Duration: this.state.Duration,
            DurationMonths: this.state.DurationMonths,
            PercentageDiscount: this.state.PercentageDiscount,
            DiscountAmount: this.state.DiscountAmount,
            LimitDate: this.state.LimitDate,
            RedeemBy: this.state.RedeemBy,
            LimitMaxRedeem: this.state.LimitMaxRedeem,
            MaxRedemptions: this.state.MaxRedemptions,
            FirstTimeOrder: this.state.FirstTimeOrder,
            LimitMinOrder: this.state.LimitMinOrder,
            MinOrder: this.state.MinOrder,
            Products: this.state.Products,
            SelectedProducts: this.state.SelectedProducts,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupons/create-coupon`, data, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: "Failed to create the coupon. Please make sure you enter in the correct information. If this problem still persists, contact us at support@phros.ca.",
                duration: 0,
            })
        }
        return notification.success({
            message: "Success",
            description: "Successfully created the coupon."
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                    <Col xs={23} sm={22}>
                        <Row>
                            <Col xs={24}>
                            <H2 text='Create a Coupon' />
                            <h3>Coupons can be used to discount products or memberships on checkout.</h3>
                            </Col>
                        </Row>
                        <Row gutter={[20,20]}>
                            <Col xs={24} sm={8}>
                                <h3>Coupon Name</h3>
                                <Input value={this.state.Name} onChange={(e) => this.handleChange("Name", e.target.value)} />
                                <p>A name to reference your coupon.</p>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Coupon Code</h3>
                                <Input value={this.state.Code} onChange={(e) => this.handleChange("Code", e.target.value)} />
                                <p>I.e., 25OFF. Please note this code should be unique. If it's not unique, one of the coupons with the same code is selected at random.</p>
                            </Col>
                            <Col xs={24}>
                                <h3>Type</h3>
                                <Radio.Group value={this.state.CouponType} onChange={(e) => this.handleChange("CouponType", e.target.value)}>
                                    <Radio value={COUPON_TYPES.FIXED.value}>{COUPON_TYPES.FIXED.name}</Radio>
                                    <Radio value={COUPON_TYPES.PERCENTAGE.value}>{COUPON_TYPES.PERCENTAGE.name}</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                this.state.CouponType === COUPON_TYPES.FIXED.value ?
                                <Col xs={24}>
                                    <Row gutter={[10,10]}>
                                        <Col xs={12} sm={8}>
                                            <h3>Currency</h3>
                                            <Select
                                            placeholder="Select Currency"
                                            onSelect={(value) => this.handleChange('Currency', value)}
                                            value={this.state.Currency}
                                            style={{ width: '100%' }}
                                            >
                                                {
                                                    Object.keys(currencies).map((curr) => {
                                                        return (
                                                            <Select.Option key={curr} name={currencies[curr]}>
                                                            <strong>{curr}: </strong>{currencies[curr]}
                                                            </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                            <p>Please ensure that the currency is the same as your Stripe account, otherwise this coupon will not work.</p>
                                        </Col>
                                        <Col xs={12} sm={8}>
                                            <h3>Discount Amount</h3>
                                            <InputNumber value={this.state.DiscountAmount} onChange={(value) => this.handleChange("DiscountAmount", value)} style={{ width: "100%" }} min={1} />
                                        </Col>
                                    </Row>
                                </Col>
                                : this.state.CouponType === COUPON_TYPES.PERCENTAGE.value ?
                                <Col xs={24} sm={8}>
                                    <h3>Percentage off</h3>
                                    <InputNumber value={this.state.PercentageDiscount} onChange={(value) => this.handleChange("PercentageDiscount", value)} style={{ width: '100%' }} min={1} max={100} />
                                </Col>
                                :
                                null
                            }
                        </Row>
                        <Row className="Coupon-Row" gutter={[10,10]}>
                            <Col xs={24} sm={8}>
                                <h3>Duration</h3>
                                <Select
                                placeholder="Select duration"
                                onSelect={(value) => this.handleChange('Duration', value)}
                                value={this.state.Duration}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(COUPON_DURATION).map((key) => {
                                            return (
                                                <Select.Option key={COUPON_DURATION[key].value} name={COUPON_DURATION[key].value}>
                                                {COUPON_DURATION[key].name}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            {
                                this.state.Duration === COUPON_DURATION.MULTIPLE_MONTHS.value ?
                                <Col xs={24} sm={8}>
                                    <h3>Number of months</h3>
                                    <InputNumber value={this.state.DurationMonths} onChange={(value) => this.handleChange("DurationMonths", value)} min={1} style={{ width: '100%' }} />
                                </Col>
                                :
                                null
                            }
                            <Col xs={24}>
                                <p>For subscriptions (memberships), this determines how long this coupon will apply once redeemed. One-off invoices accept both "once" and "forever" coupons. If you're unsure, please select "Once".</p>
                            </Col>
                        </Row>
                        <Row className="Coupon-Row" gutter={[20,20]}>
                            <Col xs={24}>
                                <h3>Coupon Product Restriction</h3>
                                <p>Restrict the products that this coupon can be applied to. If left empty, this coupon will apply to all your products.</p>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Please select"
                                    value={this.state.SelectedProducts}
                                    onSelect={(value) => {
                                        const temp = [...this.state.SelectedProducts, value];
                                        this.handleChange("SelectedProducts", temp);
                                    }}
                                    onDeselect={(value) => {
                                        const index = this.state.SelectedProducts.findIndex(id => id === value);
                                        const temp = [...this.state.SelectedProducts];
                                        temp.splice(index, 1);
                                        this.handleChange("SelectedProducts", temp);
                                    }}
                                >
                                    {
                                        this.state.Products.map((prod) => (
                                            <Select.Option key={prod.ID} value={prod.ID}>
                                                {prod.Title}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row className="Coupon-Row" gutter={[20,20]}>
                            <Col xs={24}>
                                <h3>Coupon Redemption Limits</h3>
                            </Col>
                            <Col xs={24}>
                                <Checkbox checked={this.state.LimitDate} onChange={(e) => this.handleChange("LimitDate", e.target.checked)}>Limit the date range when customers can redeem this coupon.</Checkbox>
                            </Col>
                            {
                                this.state.LimitDate ?
                                <Col xs={24}>
                                    <Row gutter={[10,10]} align="middle">
                                        <Col>
                                        <h4>Redeem by </h4>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                        <DatePicker
                                        disabledDate={(current) => {
                                        return moment().add(-1, 'days')  >= current ||
                                                moment().add(60, 'month')  <= current;
                                        }}
                                        showTime onSelect={(value) => this.handleChange("RedeemBy", value)} value={this.state.RedeemBy} onChange={(value) => this.handleChange("RedeemBy", value)} style={{ width: "100%" }} />
                                        </Col>
                                        <Col>
                                        {moment.tz.guess()} Timezone
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                null
                            }
                            <Col xs={24}>
                                <Checkbox checked={this.state.LimitMaxRedeem} onChange={(e) => this.handleChange("LimitMaxRedeem", e.target.checked)}>Limit the total number of times this coupon can be redeemed.</Checkbox>
                                <p>Note, this will not restrict a customer from redeeming a coupon multiple times.</p>
                            </Col>
                            {
                                this.state.LimitMaxRedeem ?
                                <Col xs={24}>
                                    <Row gutter={[10,10]} align="middle">
                                        <Col xs={24} sm={8}>
                                            <InputNumber style={{ width: '100%' }} value={this.state.MaxRedemptions} min={1} onChange={(value) => this.handleChange("MaxRedemptions", value)} />
                                        </Col>
                                        <Col>
                                        <h4>times</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                null
                            }
                            <Col xs={24}>
                                <Checkbox checked={this.state.LimitMinOrder} onChange={(e) => this.handleChange("LimitMinOrder", e.target.checked)}>Require minimum order value.</Checkbox>
                            </Col>
                            {
                                this.state.LimitMinOrder ?
                                <Col xs={24}>
                                    <Row gutter={[10,10]}>
                                        <Col xs={12} sm={8}>
                                            <h3>Currency</h3>
                                            <Select
                                            placeholder="Select Currency"
                                            onSelect={(value) => this.handleChange('Currency', value)}
                                            value={this.state.Currency}
                                            style={{ width: '100%' }}
                                            disabled={this.state.CouponType === COUPON_TYPES.FIXED.value}
                                            >
                                                {
                                                    Object.keys(currencies).map((curr) => {
                                                        return (
                                                            <Select.Option key={curr} name={currencies[curr]}>
                                                            <strong>{curr}: </strong>{currencies[curr]}
                                                            </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                            <p>Please ensure that the currency is the same as your Stripe account, otherwise this coupon will not work.</p>
                                            <p>If selecting a fixed discount, the currency will be the same.</p>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <h3>Minimum Value</h3>
                                            <InputNumber style={{ width: "100%" }} value={this.state.MinOrder} onChange={(value) => this.handleChange("MinOrder", value)} />
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                null
                            }
                        </Row>
                        <Row justify="end" className="Coupon-Row">
                            <Col>
                            <Button className="Button" onClick={() => this.createCoupon()}>Create Coupon</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default Coupon;