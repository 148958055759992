import { useState, useEffect } from "react";
import { Footer1, Footer1Link } from "../../phros-web-components/Footers/Footer1/Footer1";
import { PhrosUpload } from "../../Common/Uploads/Upload";
import { ImageTemplate } from "../../phros-web-components/TemplateData";

const Footer1Edit = ({ data = Footer1.Default(), editComponent = () => {} }) => {
    const [imageMap, setImageMap] = useState({
        "image_id": {
            url: data.Image,
            description: data.ImageAlt,
            fileId: "image_id",
        }
    });
    const [links, setLinks] = useState([Footer1Link.Default()]);

    const deleteWebComponentImage = () => {
        return;
    }

    useEffect(() => {
        const keys = Object.keys(imageMap);
        let imageUrl = ImageTemplate;
        if (keys.length > 0) {
            const image = imageMap[keys[0]];
            imageUrl = image.url;
        }
        const updateBanner = new Footer1(imageUrl, links);
        editComponent(updateBanner);
    }, [imageMap, links]);

    return (
        <div>
            <h3>Logo:</h3>
            <PhrosUpload
                accept = ".jpg,.jpeg,.png"
                text="Choose an image or drag it here."
                type="static"
                fileMap={imageMap}
                setFileMap={setImageMap}
                deleteFile={deleteWebComponentImage}
            />
            <br />
            <br />
            <h3>Site Links: </h3>
        </div>
    )
};

export {
    Footer1Edit,
}