import React from 'react';
import { Row, Col, Upload, Card, Button, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { checkArray } from '../../../Verification';
import './BuildWebsiteComponents.css';

class Gallery extends React.Component {
    render() {
        return (
            <Row gutter={[10,10]} align="bottom">
                <Col xs={24}>
                    <h3>Gallery Section</h3>
                </Col>
                {
                    checkArray(this.props.Gallery).map((img, index) => (
                        <Col xs={24} sm={12} md={8}>
                            <Row gutter={[10,10]}>
                            <Col xs={24}>
                            <p>Gallery Image {index+1}</p>
                            <p>Upload an image for your gallery.</p>
                            {
                                img.ImageURL !== undefined && img.ImageURL !== null  && String(img.ImageURL).length > 0?
                                <Card
                                cover={<img src={img.ImageURL} alt="" className="Gallery-Card-Image-Style" />}
                                >
                                <Upload
                                fileList={[]}
                                accept=".png,.jpeg,.jpg"
                                customRequest={async (info) => {
                                    const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                    let gallery = [...this.props.Gallery];
                                    gallery[index].ImageURL = url;
                                    this.props.handleChange("Gallery", gallery);
                                }}>
                                    <Button icon={<UploadOutlined />}>Replace Image</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    fileList={[]}
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={async (info) => {
                                        const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                        let gallery = [...this.props.Gallery];
                                        gallery[index].ImageURL = url;
                                        this.props.handleChange("Gallery", gallery);
                                    }}>
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                            }
                            </Col>
                            <Col xs={24}>
                                <p>Image ALT Text</p>
                                <p>This alt text is an accessibility feature, so individuals using screen readers can interpret the picture.</p>
                                <Input
                                value={img.ALT}
                                onChange={(e) => {
                                    let gallery =[...this.props.Gallery];
                                    gallery[index].ALT = e.target.value;
                                    this.props.handleChange("Gallery", gallery);
                                }}
                                />
                            </Col>
                            <Col xs={24}>
                                <Button
                                block
                                type="primary"
                                danger
                                onClick={() => {
                                    let gallery = [...this.props.Gallery];
                                    gallery.splice(index, 1);
                                    this.props.handleChange("Gallery", gallery);
                                }}
                                >
                                    Delete Image {index+1}
                                </Button>
                            </Col>
                            </Row>
                        </Col>
                    ))
                }
                <Col xs={24} sm={8}>
                    <Button
                    type="primary"
                    block
                    onClick={() => {
                        let gallery = [...this.props.Gallery];
                        let temp = {
                            ImageURL: "",
                            ALT: "",
                        }
                        gallery.push(temp);
                        this.props.handleChange("Gallery", gallery);
                    }}
                    >
                        Add Image to Gallery
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default Gallery;