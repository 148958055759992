import React from 'react';
import { Row, Col, Layout, Button, Spin, Popconfirm, Modal, notification, Divider } from 'antd';
import { H2, H3 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { storage } from '../../Firebase';

class ViewWorkouts extends React.Component {
    static contextType = AuthContext;
    state = {
        Workouts: [],
        loading: true,
        Clients: [],
        Visibility: false,
        SelectedWorkout: {},
        AssignedWorkouts: {},
    }

    componentDidMount() {
        this.getData();
    }

    handleModalVisible = () => {
        this.setState({
            Visibility: !this.state.Visibility,
        })
    }

    handleAssignWorkout = (index) => {
        this.setState({
            SelectedWorkout: this.state.Workouts[index],
        })
        this.handleModalVisible();
    }

    getData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/workouts/get-all-workouts-with-uid`, data, config);
        this.setState({
            Workouts: response.data,
            loading: false,
        })
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-profile/get-user-profiles-for-trainer`, {}, config);
        this.setState({
            Clients: res.data
        })
        console.log(res);
        const assignedWorkouts = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-workouts/get-assigned-workouts`, {}, config);
        this.setState({
            AssignedWorkouts: assignedWorkouts.data
        })
    }

    assignWorkout = async (ClientUID) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ClientUID: ClientUID,
            Workout: this.state.SelectedWorkout,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-workouts/assign-user-workout`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error assigning workout.",
                description: "Something went wrong when assigning your workout. Please try again, or contact our team at support@phros.ca for more support."
            })
        } else {
            notification.success({
                message: "Success",
                description: "Successfully assigned the workout to your client. They can access it by logging in on your website. Please refresh the page to view the changes."
            })
        }
    }

    removeAssignedWorkout = async (ClientUID, WorkoutID) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ClientUID: ClientUID,
            WorkoutID: WorkoutID,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user-workouts/delete-assigned-workout`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error removing workout from user.",
                description: "Something went wrong when assigning your workout. Please try again, or contact our team at support@phros.ca for more support."
            })
        } else {
            notification.success({
                message: "Success",
                description: "Successfully removed the assigned workout from your client. Please refresh the page to view the changes."
            })
        }
    }

    deleteWorkout = async (index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const workout = this.state.Workouts[index];
        const id = workout.ID;

        if (currentUser.uid !== workout.uid) {
            return notification.error({
                message: 'Not Authorized',
                description: "You're not authorized to delete this workout.",
                duration: null,
            })
        }
        const data = {
            id: id,
        };
        const deleteEvent = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/workouts/delete-workout`, data, config);
        if (deleteEvent.data.error) {
            return notification.error({
                message: "Failed to Delete",
                description: "Failed to delete this exercise. Please try again, or contact our team."
            })
        }

        const classes = this.state.Workouts;
        classes.splice(index, 1);
        this.setState({
            Workouts: classes,
        })
        try {
            const file = storage.refFromURL(workout.WaiverURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        try {
            const file = storage.refFromURL(workout.ThumbnailURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        return (
            notification.success({
                message: 'Success',
                description: 'Successfully deleted the workout.'
            })
        )
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Modal width={800} visible={this.state.Visibility} onCancel={this.handleModalVisible} footer={null}>
                        <Row>
                            <Col xs={24}><h2><strong>{this.state.SelectedWorkout.Title}</strong></h2></Col>
                        </Row>
                        {
                            this.state.Clients.map((client, index) => {
                                return (
                                    <Row>
                                        <Col xs={24} sm={8}>
                                            {client.Name}
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            {client.Email}
                                        </Col>
                                        {
                                            this.state.AssignedWorkouts[client.UID] && this.state.AssignedWorkouts[client.UID]?.indexOf(this.state.SelectedWorkout.ID) !== -1 ?
                                            <Col xs={24} sm={8}>
                                                <Button block danger onClick={() => this.removeAssignedWorkout(client.UID, this.state.SelectedWorkout.ID)}>
                                                    Remove Workout from Client
                                                </Button>
                                            </Col>
                                            :
                                            <Col xs={24} sm={8}>
                                                <Button block onClick={() => this.assignWorkout(client.UID)}>
                                                    Assign Workout
                                                </Button>
                                            </Col>
                                        }

                                        <Col xs={24}>
                                            <Divider />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Modal>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View Workouts'/>
                            <Row className='ViewLiveClasses-Name-Row'>
                                <Col xs={0} sm={6}>
                                    <H3 text="Name" className='H2-Text' />
                                </Col>
                                <Col xs={0} sm={6}>
                                    <H3 text="Assign to Client" />
                                </Col>
                                <Col xs={0} sm={6}>
                                    <H3 text="View/Edit" />
                                </Col>
                                <Col xs={0} sm={6}>
                                    <H3 text="Delete" />
                                </Col>
                            </Row>
                            {
                                this.state.loading ?
                                <Row className='ViewLiveClasses-Name-Row' justify='space-around'>
                                    <Col>
                                        <Spin size='large'/>
                                    </Col>
                                </Row>
                                :
                                null
                            }
                            {
                                this.state.Workouts.map((val, index) => {
                                    return (
                                        <Row className='ViewLiveClasses-Sub-Row' gutter={[10,10]}>
                                            <Col xs={24} sm={6}>
                                                <h3>{val.Title}</h3>
                                            </Col>
                                            <Col xs={24} sm={6}>
                                                <Button block type="primary" onClick={() => this.handleAssignWorkout(index)}>
                                                    Assign Workout to Client
                                                </Button>
                                            </Col>
                                            <Col xs={24} sm={6}>
                                                <Link to={`/workouts/${val.ID}`}>
                                                <Button className='Button' type='primary' block>View/Edit</Button>
                                                </Link>
                                            </Col>
                                            <Col xs={24} sm={6}>
                                                <Popconfirm
                                                title='Are you sure you want to delete this workout? This action cannot be reversed.'
                                                onConfirm={() => this.deleteWorkout(index)}
                                                okText="Delete Workout"
                                                >
                                                <Button block type='primary' danger style={{ borderRadius: '10px' }}>
                                                    Delete
                                                </Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewWorkouts;