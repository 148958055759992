import React from "react";
import "./Banner1.css";
import { ImageTemplate, TitleTemplate, SubTitleTemplate } from "../../TemplateData";
import IDS from "../../IDS";
import { Component } from "../../Component";


class Banner1 extends Component {
    ID = IDS.BANNER1;
    constructor(image, imageAlt, title, subtitle) {
        super();
        this.Image = image;
        this.ImageAlt = imageAlt;
        this.Title = title;
        this.SubTitle = subtitle;
    }
    static Default() {
        return new Banner1(ImageTemplate, "Placeholder", TitleTemplate, SubTitleTemplate);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Banner1(obj.Image, obj.ImageAlt, obj.Title, obj.SubTitle);
    }
}

const Banner1View = ({ data = Banner1.Default() }) => {
    if (data.Image === "") {
        data.Image = ImageTemplate;
    }
    return (
        <div className="Container">
            <img src={data.Image} className="Image" alt={data.ImageAlt} />
            <div className="TextContainer">
                <h1 className="Title">{data.Title}</h1>
                <h3 className="SubTitle">{data.SubTitle}</h3>
            </div>
        </div>
    )
}


export {
    Banner1,
    Banner1View,
}