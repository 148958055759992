import { Banner1Edit } from "./Banners/Banner1";
import { Banner2Edit } from "./Banners/Banner2";

// Navbars
import { Navbar1Edit } from "./Navbars/Navbar1";

// Footers
import { Footer1Edit } from "./Footers/Footer1";

// FAQ
import { Faq1Edit } from "./FAQ/FAQ1";
import { Faq2Edit } from "./FAQ/FAQ2";

// Text
import { Text1Edit } from "./Text/Text1";

// Profile
import { Profile1Edit } from "./Profile/Profile1";

// Default
import { DefaultEdit } from "./Default/Default";

// HTML
import { HTML1Edit } from "./HTML/HTML";

// Card
import { Card1Edit } from "./Cards/Card1";

import IDS, { NAVBARS, FOOTERS } from "../phros-web-components/IDS";


const EDITABLE_COMPONENTS = {
    [IDS.BANNER1]: Banner1Edit,
    [IDS.BANNER2]: Banner2Edit,

    [IDS.FAQ1]: Faq1Edit,
    [IDS.FAQ2]: Faq2Edit,

    [IDS.TEXT1]: Text1Edit,

    [IDS.PROFILE1]: Profile1Edit,

    [IDS.CARD1]: Card1Edit,

    [IDS.DEFAULT]: DefaultEdit,

    [IDS.HTML1]: HTML1Edit,
};

const EDITABLE_NAVBARS = {
    [NAVBARS.NAVBAR1]: Navbar1Edit,
};

const EDITABLE_FOOTERS = {
    [FOOTERS.FOOTER1]: Footer1Edit,
}

export {
    EDITABLE_COMPONENTS,
    EDITABLE_NAVBARS,
    EDITABLE_FOOTERS,
};