import React from 'react';

import './LocationSearch.css';

// Import React Script Library to Load Google Object
import Script from 'react-load-script';

// Input Antd
import { Input } from 'antd';

require('dotenv').config();


// eslint-disable-next-line no-unused-vars
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;

// Build Location Search
class LocationSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            city: '',
            query: this.props.address,
            lat: this.props.latitude,
            long: this.props.longitude,
            name: '',
        }

        this.handlePinDrag = this.handlePinDrag.bind(this);

    }

    componentDidMount() {
        this.setState({
            name: this.props.name
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.latitude !== this.state.lat ||
            nextProps.longitude !== this.state.long ||
            nextProps.address !== this.state.query) {
                this.setState({
                    lat: nextProps.latitude,
                    long: nextProps.longitude,
                    query: nextProps.address
                })

                const myLatLng = { lat: nextProps.latitude, lng: nextProps.longitude };

                const map = new google.maps.Map(document.getElementById(`map-${this.state.name}`), {
                zoom: 14,
                center: myLatLng
                });

                let marker = new google.maps.Marker({
                position: myLatLng,
                draggable: true,
                map,
                title: "Meeting Location"
                });

                const handleDrag = this.handlePinDrag;
                google.maps.event.addListener(marker, 'dragend', function() {
                    handleDrag(marker.getPosition().lat(),
                    marker.getPosition().lng());
                })
        }
    }

    handleScriptLoad = () => {
        // Declare Options For Autocomplete
        // Putting Establishment and Geocode together is like putting no
        const options = {
          types: [],
        };

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById(`autocomplete-${this.state.name}`),
          options,
        );

        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);

        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

        const myLatLng = { lat: this.state.lat, lng: this.state.long };
        const map = new google.maps.Map(document.getElementById(`map-${this.state.name}`), {
          zoom: 14,
          center: myLatLng
        });
        let marker = new google.maps.Marker({
          position: myLatLng,
          map,
          title: "Meeting Location"
        });
        const handleDrag = this.handlePinDrag;
        google.maps.event.addListener(marker, 'dragend', function() {
            handleDrag(marker.getPosition().lat(),
            marker.getPosition().lng());
        })
      }

      handlePlaceSelect = () => {

        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;

        // Check if address is valid
        if (address) {

        let latitude = addressObject.geometry.location.lat();
        let longitude = addressObject.geometry.location.lng();
        let address = addressObject.formatted_address;

        this.setState({
            lat: latitude,
            long: longitude,
            query: address
        })

        // Set the Meeting Location on Search
        if (this.props.indexedLocation) {
            this.props.handleLocationSelection(latitude, longitude, address, this.props.index);
        } else {
            this.props.handleLocationSelection(latitude, longitude, address);
        }

        // Replace the Map and Marker
        const myLatLng = { lat: latitude, lng: longitude };

        const map = new google.maps.Map(document.getElementById(`map-${this.state.name}`), {
        zoom: 14,
        center: myLatLng
        });

        let marker = new google.maps.Marker({
        position: myLatLng,
        draggable: false,
        map,
        title: "Meeting Location"
        });

        const handleDrag = this.handlePinDrag;
        google.maps.event.addListener(marker, 'dragend', function() {
            handleDrag(marker.getPosition().lat(),
            marker.getPosition().lng());
        })
        }
      }

    async handlePinDrag(lat, long) {
        // GeoCoder
        const handleLocationChange = this.props.handleLocationSelection;
        const index = this.props.index;
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            latLng: new google.maps.LatLng(lat, long)
        }, function(response) {
            if (response && response.length > 0) {
            // Set the Meeting Location on Drag and Drop Pin
            handleLocationChange(lat, long, response[0].formatted_address, index);
            } else {
            handleLocationChange(lat, long, '');
            }
        });
    }

    handleSearchChange = (event) => {
        this.setState({
            query: event.target.value
        })
    }

    render() {
        return (
            <div class="Location-SearchBar-Primary">
                <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP}&libraries=places`}
                onLoad={this.handleScriptLoad}
                />
                <Input
                id={`autocomplete-${this.state.name}`}
                placeholder="Search for a location."
                value = {this.state.query}
                onChange={this.handleSearchChange}
                style={{ height: "50px", width: "100%" }}/>
                <div style={{ position: 'relative', height: '200px', width: '100%' }}>
                    <div id={`map-${this.state.name}`} style={{ height: '200px', width: '100%' }}></div>
                </div>
            </div>
        )
    }
}

export default LocationSearch;