import React from 'react';
import './Profile.css';
import { Row, Col, Layout, Input, Button, Select, Affix, message, Checkbox } from 'antd';
import { SideNav } from '../../Components';
import { H2, H3 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import { COUNTRIES } from '../../constants';
import axios from 'axios';
import { checkArray, checkString, checkStringReturnDef, checkBool } from '../../Verification';
import './Stripe-Connect.css';

class Profile extends React.Component {
    static contextType = AuthContext;

    state = {
        FirstName: undefined,
        LastName: undefined,
        email: undefined,
        PhoneNumber: undefined,
        ProfilePictureURL: undefined,
        LogoPictureURL: undefined,
        BusinessName: undefined,
        BusinessStatement: undefined,
        BusinessLogo: undefined,
        SocialMedia: [],
        ColourPalette: 'PALETTE_1',
        BankAccount: null,
        Country: 'CA',
        PARQForm: false,

        ProfilePicture: null,
        ProfilePicDisabled: false,
        ProfilePictureFileList: [],

        LogoPicture: null,
        LogoPicDisabled: false,
        LogoPicFileList: [],

        accountExistsLoading: true,
        accountExists: false,
    }

    componentDidMount() {
        const { currentUser } = this.context;
        if (currentUser) {
            this.setState({
                email: currentUser.email,
            })
        }
        this.checkUserAccount();
        this.getProfileData(currentUser.uid);
    }

    checkUserAccount = async () => {
        // Get Token
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        // Send Token and Check if User has an Account Already
        const accountCheck = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stripe/check-account`, {}, config);

        if (accountCheck.data.error) {
            this.setState({
                accountExistsLoading: false,
                accountExists: false,
            })
        } else {
            this.setState({
                accountExistsLoading: false,
                accountExists: accountCheck.data,
            })
        }
    }

    getProfileData = async (uid) => {
        const data = {
            uid: uid,
        }
        const profile = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/profile/get-profile-with-uid`, data);
        if (profile.data.error) return;
        this.setState({
            FirstName: checkString(profile.data.FirstName),
            LastName: checkString(profile.data.LastName),
            PhoneNumber: checkString(profile.data.PhoneNumber),
            ProfilePictureURL: checkString(profile.data.PictureURL),
            LogoPictureURL: checkString(profile.data.LogoURL),
            BusinessName: checkString(profile.data.BusinessName),
            BusinessStatement: checkString(profile.data.BusinessStatement),
            SocialMedia: checkArray(profile.data.SocialMedia),
            PARQForm: checkBool(profile.data.PARQForm),
            ColourPalette: checkStringReturnDef(profile.data.ColourScheme, 'PALETTE_1'),
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    addSocialMedia = () => {
        const sm = {
            name: '',
            url: '',
        }
        this.setState({
            SocialMedia: [...this.state.SocialMedia, sm]
        });
    }
    deleteSocialMedia = (index) => {
        const sm = this.state.SocialMedia;
        sm.splice(index, 1);
        this.setState({
            SocialMedia: sm,
        })
    }
    changeSocialMedia = (name, index, value) => {
        const sm = this.state.SocialMedia[index];
        sm[name] = value;
        this.setState({
            SocialMedia: [...this.state.SocialMedia.slice(0, index), sm, ...this.state.SocialMedia.slice(index+1, this.state.SocialMedia.length)],
        });
    }

    handleProfileImageUpload = async (fileListName, urlName, buttonName, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/images/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`images/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        message.error('Failed to delete previous image');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    saveProfileInformation = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            Email: currentUser.email,
            FirstName: checkString(this.state.FirstName),
            LastName: checkString(this.state.LastName),
            PhoneNumber: checkString(this.state.PhoneNumber),
            BusinessName: checkString(this.state.BusinessName),
            BusinessStatement: checkString(this.state.BusinessStatement),
            PictureURL: checkString(this.state.ProfilePictureURL),
            LogoURL: checkString(this.state.LogoPictureURL),
            SocialMedia: checkArray(this.state.SocialMedia),
            ColourScheme: checkString(this.state.ColourPalette),
            PARQForm: checkBool(this.state.PARQForm),
        };
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/profile/save-profile`, data, config);
        if (save.data.error) {
            message.error('Failed to save profile information.')
        } else {
            message.success('Successfully saved profile information.')
        }
    }

    getStripeOnboardingURL = async () => {
        // Save Profile Information
        await this.saveProfileInformation();

        // Axios Request - Returns Redirect URL
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const payload = {
            Country: this.state.Country
        }
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stripe/connect-account`, payload, config);
        if (res.data.error) return message.error('Failed to get Stripe Onboarding URL, please contact us!');
        window.location.href=res.data.url;
    }

    render() {

        return (
            <Layout>
            <SideNav />
            <Layout.Content>
            <Row justify='space-around' className='Profile-Row'>
                <Col xs={23} sm={22}>
                    <Row gutter={[10,10]}>
                        <Col xs={24}>
                            <H2 text='Profile' />
                        </Col>
                        <Col xs={24} sm={12}>
                            <h3>Registered First Name</h3>
                            <Input disabled value={this.state.FirstName} onChange={(e) => this.handleChange('FirstName', e.target.value)} placeholder='First Name'/>
                        </Col>
                        <Col xs={24} sm={12}>
                            <h3>Registered Last Name</h3>
                            <Input disabled value={this.state.LastName} onChange={(e) => this.handleChange('LastName', e.target.value)} placeholder='Last Name'/>
                        </Col>
                    </Row>
                    <Row className='Profile-Row' gutter={[10,10]}>
                        <Col xs={24} sm={12}>
                            <h3>Registered Account Email</h3>
                            <Input value={this.state.email} disabled />
                        </Col>
                    </Row>
                    <Row className='Profile-Row'>
                        <Col xs={24}>
                            <h3>PAR-Q Form</h3>
                            <p>Do you require your clients to ackowledge and agree to the PAR-Q form before making a purchase or registering for a free event?</p>
                        </Col>
                        <Col xs={24}>
                            <Checkbox
                                checked={this.state.PARQForm}
                                onChange={(e) => this.handleChange('PARQForm', e.target.checked)}
                            >
                                Yes, I require my customers to acknowledge and agree to the PAR-Q forms.
                            </Checkbox>
                        </Col>
                    </Row>

                    <Row className='Profile-Row'>
                        <Col xs={24}>
                            <H3 text='Start Recieving Payments' />
                            <p>
                                Connect your account below with Stripe. Stripe is an
                                online payment processor for internet businesses.
                                <a href='stripe.com' target="_blank"> Check out Stripe.</a>
                            </p>
                            {
                                !this.state.accountExistsLoading && this.state.accountExists ?
                                <p>Looks like your account is connected! Log into <a href="https://dashboard.stripe.com/login" target="_blank" rel="noreferrer">Stripe</a> to check your account if needed.</p>
                                :
                                !this.state.accountExistsLoading && !this.state.accountExists ?
                                <>
                                <Row>
                                    <Col xs={24} sm={16}>
                                        Country of Incorporation
                                        <Select onSelect={(value) => this.handleChange('Country', value)} value={this.state.Country} style={{ width: '100%' }}>
                                            {
                                                COUNTRIES.map((country) => {
                                                    return (
                                                        <Select.Option key={country.Code}>
                                                            {country.Name}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                                <div style={{ marginBottom: '5px' }}>
                                Please select the country where your account is located so you can receive payments through Stripe.
                                </div>
                                <Button className='stripe-connect slate' onClick={this.getStripeOnboardingURL}>
                                    <span>Connect with</span>
                                </Button>
                                </>
                                :
                                null
                            }

                        </Col>
                    </Row>
                    <Row className='Profile-Row' justify='end'>
                        <Col xs={24} sm={8}>
                            <Affix offsetBottom={20}>
                            <Button type='primary' size='large' className='Button' onClick={this.saveProfileInformation} block >Save Profile Information</Button>
                            </Affix>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Layout.Content>
            </Layout>
        )
    }
}

export default Profile;