import React from "react";
import "./Footer1.css";
import { LogoTemplate } from "../../TemplateData";
import { FOOTERS } from "../../IDS";
import { Component } from "../../Component";


class Footer1Link {
    constructor(name, url, external) {
        this.name = name;
        this.url = url;
        this.external = external;
    }
    static Default() {
        return new Footer1Link("Home", "/", false);
    }
}

class Footer1 extends Component {
    ID = FOOTERS.FOOTER1;
    links = [Footer1Link.Default()];
    logo = "";
    constructor(logo, links) {
        super();
        this.logo = logo;
        this.links = links;
    }
    setLinks(links) {
        this.links = links;
    }
    static Default() {
        const defaultLink = Footer1Link.Default();
        return new Footer1(LogoTemplate, [defaultLink]);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Footer1(obj.logo, obj.links);
    }
}

const Footer1View = ({ data = Footer1.Default() }) => {
    return (
        <div className="Container">
            <h1>Footer</h1>
            <img
                style={{ width: "30px", height: "30px", objectFit: "contain" }}
                src={data.logo}
                alt="Logo Alt Text"
            />
            {
                data.links.map((link) => {
                    return (
                        <div>
                            <a href={link.url}>{link.name}</a>
                        </div>
                    )
                })
            }
        </div>
    )
}


export {
    Footer1,
    Footer1Link,
    Footer1View,
}