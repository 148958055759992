import { Button, Tooltip, Layout, Menu, Modal, Input, message, Popconfirm } from "antd";
import { useState, createElement, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./Website.css";
import { COMPONENTS, ADD_COMPONENTS, Navbar, Footer } from "../../phros-web-components/index";
import { v4 } from "uuid";
import { WebsiteContext, Page, WebComponent } from "../../Context/WebsiteContext";
import { useParams, Redirect } from "react-router-dom";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const extraComponents = {
    NAVBAR: "NAVBAR",
    FOOTER: "FOOTER",
};

const SiderViews = {
    ROOT: "ROOT",
    PAGE: "PAGE",
    NAVBAR: "NAVBAR",
    FOOTER: "FOOTER",
};

const PageDetails = ({ page = Page.Default(), setPageEditOpen = () => {} }) => {
    const [name, setName] = useState(page.name);
    const [link, setLink] = useState(page.link);
    const [title, setTitle] = useState(page.title);
    const [description, setDescription] = useState(page.description);
    const { pageData, setPageData } = useContext(WebsiteContext);
    const { ID } = useParams();

    useEffect(() => {
        if (String(link).charAt(0) === "/") {
            setLink(String(link).replace("/", ""));
        }
    }, [link]);

    const handlePageDataChange = () => {
        setPageData(() => {
            page.name = name;
            page.title = title;
            page.link = link;
            page.description = description;
            return {
                ...pageData,
                [ID]: page,
            }
        })
        message.success('Changed page details, click "Save Website" to make your changes live.');
        setPageEditOpen(false);
    }

    const handlePageLink = (link) => {
        const temp = String(link).replace(/[^a-zA-Z0-9_-]/gi,'');
        setLink(temp);
    }

    return (
        <>
        <h1 style={{ fontWeight: "600" }}>Page Details:</h1>
        <p style={{ marginBottom: "10px" }}>Edit the basic details for this page, including the link, name, and meta data.</p>
        <h5>Page Name</h5>
        <small>What do you want to name this page (I.e. Pricing, Home, etc.)?</small>
        <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
        />
        <br />
        <br />
        <h5>Page Link</h5>
        <small>What do you want the url for this page to be (I.e. "/new-page", "/pricing", etc.)?</small>
        <Input
            addonBefore="/"
            value={link}
            onChange={(e) => handlePageLink(e.target.value)}
        />
        <small>Link only allows text, numbers, dashes, and underscores.</small>
        <br />
        <br />
        <h5>Page Title</h5>
        <small>This will appear on the top of your page, in Google search results, and when you share the link for this page (I.e. "My Health & Fitness Business", "Pricing", "Live Classes").</small>
        <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <h5>Page Description</h5>
        <small>This will appear in Google when someone searches for your page and when you share the link.</small>
        <Input.TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
        />
        <br/>
        <br />
        <Button type="primary" onClick={() => handlePageDataChange()}>Apply Changes</Button>
        </>
    )
}

const RootSider = (props) => {
    const { children, view = SiderViews.ROOT } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = useState(false);
    const [pageEditOpen, setPageEditOpen] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [selectedPage, setSelectedPage] = useState(Page.Default());
    const { setWebsiteComponents, pageData, setPageData, saveWebsite, setNavbar, setFooter, deletePage } = useContext(WebsiteContext);
    const { ID } = useParams();
    const [redirectToRoot, setRedirectToRoot] = useState(false);


    const addComponent = (key) => {
        const id = v4();
        const data = JSON.stringify(COMPONENTS[key].Class.Default());
        const webComp = {
            ID: id,
            ComponentID: key,
            Data: data,
        };
        setWebsiteComponents((prev) => {
            let newComponents = [];
            if (ID in prev && prev[ID]) {
                newComponents = prev[ID];
            }
            newComponents.push(webComp);
            return {
                ...prev,
                [ID]: newComponents,
            }
        })
        message.success("Added the component to the page.")
    }

    const handleDeletePage = () => {
        setWebsiteComponents(prev => {
            const temp = { ...prev };
            delete temp[ID];
            return temp;
        })
        setPageData(prev => {
            const temp = { ...prev };
            delete temp[ID];
            return temp;
        })
        deletePage(ID);
        setRedirectToRoot(true);
    }

    const root = (
        <Menu theme="dark" mode="inline" defaultOpenKeys={["pages"]}>
            <div style={{ margin: "20px"}}>
                <Link to="/" className="SiderText"><b><ArrowLeftOutlined /> Back to Dashboard</b></Link>
            </div>
            <Menu.Item>
                <Button type="primary" block onClick={() => saveWebsite()}>Save Website</Button>
            </Menu.Item>
            <SubMenu key="pages" title={<b>Pages</b>}>
                {
                    Object.keys(pageData).map((key) => {
                        const page = pageData[key];
                        return (
                            <Menu.Item key={page.id}>
                                <Link to={`/website/page/${page.id}`}>
                                    {page.name}
                                </Link>
                            </Menu.Item>
                        )
                    })
                }
                <Menu.Item>
                    <Button type="primary" block ghost
                    onClick={() => setPageData((prev) => {
                        const page = Page.Default();
                        return {
                            ...prev,
                            [page.id]: page,
                        }
                    })}>
                        Add Page
                    </Button>
                </Menu.Item>
            </SubMenu>
        </Menu>
    )

    const page = (
        <Menu theme="dark" mode="inline" activeKey={null}>
            {
                redirectToRoot ?
                <Redirect to="/website" />
                :
                null
            }
            <div style={{ margin: "20px"}}>
                <Link to="/website" className="SiderText"><b><ArrowLeftOutlined /> Back</b></Link>
            </div>
            <Menu.Item>
                <Button type="primary" block onClick={() => saveWebsite()}>Save Website</Button>
            </Menu.Item>
            <Menu.Item>
                <b>Page</b>
            </Menu.Item>
            <div style={{ marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
                <Button block type="primary" ghost
                    onClick={() => {
                        setPageEditOpen(true);
                        setSelectedPage(pageData[ID]);
                    }}
                >
                    Edit Page Details
                </Button>
                <Popconfirm
                    title="Are you sure you want to delete this page?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={handleDeletePage}
                >
                    <Button block type="primary" danger ghost style={{ marginTop: "20px" }}>
                        Delete Page
                    </Button>
                </Popconfirm>
            </div>
            <Menu.Item key="site-defaults">
                <b>Site Defaults</b>
            </Menu.Item>
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                <Tooltip placement="right" title="Navbars are navigation located at the top part of your site for every page.">
                    <Button
                    onClick={() => {
                        setSelectedComponent(extraComponents.NAVBAR);
                        setOpen(true);
                    }}
                    block type="primary" ghost style={{ marginBottom: "10px" }}>
                        Navbar
                    </Button>
                </Tooltip>
            </div>
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                <Tooltip placement="right" title="Navbars are navigation located at the top part of your site for every page.">
                    <Button
                    onClick={() => {
                        setSelectedComponent(extraComponents.FOOTER);
                        setOpen(true);
                    }}
                    block type="primary" ghost style={{ marginBottom: "10px" }}>
                        Footer
                    </Button>
                </Tooltip>
            </div>
            <Menu.Item key="2">
                <b>Add Section</b>
            </Menu.Item>
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                {
                    Object.keys(ADD_COMPONENTS).map(key => {
                        const comp = ADD_COMPONENTS[key];
                        return (
                            <Tooltip placement="right" title={comp.Description}>
                                <Button
                                onClick={() => {
                                    setSelectedComponent(key);
                                    setOpen(true);
                                }}
                                block type="primary" ghost style={{ marginBottom: "10px" }}>
                                    {key}
                                </Button>
                            </Tooltip>
                        )
                    })
                }
            </div>
        </Menu>
    )

    // Fetch to get the data
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider theme="dark" collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
                {
                    collapsed ?
                    null
                    :
                    view === SiderViews.PAGE ?
                    page
                    :
                    root
                }
            </Sider>
            <Layout>
                <Content>
                    <div style={{ backgroundColor: "white", width: "100%", minHeight: "100vh" }}>
                    <Modal width={1000} visible={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)}>
                        {
                            selectedComponent === extraComponents.NAVBAR ?
                            <>
                            <h1>Navbar</h1>
                            <p>Select a Navbar for your site.</p>
                            {
                                Object.keys(Navbar).map((key) => (
                                    <div onClick={() => {
                                        const nav = Navbar[key].Class.Default();
                                        const temp = new WebComponent(key, nav);
                                        setNavbar(temp.getObject());
                                        message.success("Added Navbar to your site.")
                                    }} className="ModalElement">
                                        {createElement(Navbar[key].View)}
                                    </div>
                                ))
                            }
                            </>
                            :
                            selectedComponent === extraComponents.FOOTER ?
                            <>
                            <h1>Footer</h1>
                            <p>Select a Footer for your site.</p>
                            {
                                Object.keys(Footer).map((key) => (
                                    <div onClick={() => {
                                        const foot = Footer[key].Class.Default();
                                        const temp = new WebComponent(key, foot);
                                        setFooter(temp.getObject());
                                        message.success("Added Footer to your site.")
                                    }} className="ModalElement">
                                        {createElement(Footer[key].View)}
                                    </div>
                                ))
                            }
                            </>
                            :
                            <>
                            <h1 style={{ fontWeight: "600" }}>{selectedComponent}</h1>
                            <p>{ADD_COMPONENTS[selectedComponent]?.Description}</p>
                            <small>Click on a component to add it to the page.</small>
                            {
                                Object.keys(ADD_COMPONENTS[selectedComponent]?.Components || {})?.map((key) => (
                                    <div onClick={() => addComponent(key)} className="ModalElement">
                                        <div className="ModelElement-Cover"></div>
                                        {createElement(ADD_COMPONENTS[selectedComponent]?.Components[key].View)}
                                    </div>
                                ))
                            }
                            </>
                        }
                    </Modal>
                    <Modal footer={null} width={500} visible={pageEditOpen} onOk={() => setPageEditOpen(false)} onCancel={() => setPageEditOpen(false)}>
                        <PageDetails page={selectedPage} setPageEditOpen={setPageEditOpen} />
                    </Modal>
                    {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export {
    RootSider,
    SiderViews,
};