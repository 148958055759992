import { useState, useEffect, useContext } from "react";
import { SideNav } from "../../Components";
import { Layout, message, Table, Button, Tag, Modal } from "antd";
import "./Programs.scss";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import { ENVIRONMENT } from "../../constants";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { v4 } from "uuid";
import { getAuthHeader } from "../../util";

const ConfirmDelete = ({ open, setOpen = () => {}, programs = [], programIndex = 0, handleDelete = () => {}}) => {
    return (
        <Modal
        title="Delete Program"
        visible={open}
        okText="Delete"
        okButtonProps={{ backgroundColor: "red" }}
        onOk={handleDelete}
        onCancel={() => setOpen(false)}>
            <strong>Program: {programs[programIndex]?.name}</strong>
            <br />
            <br />
            Are you sure you want to delete this program? This action cannot be undone.
        </Modal>
    )
}

const ViewAllPrograms = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [programs, setPrograms] = useState([]);
    const [loadPrograms, setLoadPrograms] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [programIndex, setProgramIndex] = useState(0);

    useEffect(() => {
        getProgramData();
    }, []);


    const handleDelete = async () => {
        // Delete from DB
        const config = await getAuthHeader(currentUser);
        const payload = {
            ProgramID: programs[programIndex].program_id,
        }
        try {
            const response = await axios.post(`${ENVIRONMENT.URL}/quick-program/delete-program`, payload, config);
            if (response.status !== 200) {
                return message.error("Couldn't delete your program, please contact us at support@phros.ca");
            }
        } catch (e) {
            return message.error("Couldn't delete your program, please contact us at support@phros.ca");
        } finally {
            setLoadPrograms(false);
        }

        // Remove from List
        setPrograms((prevState) => {
            const temp = [...prevState];
            temp.splice(programIndex, 1);
            return temp;
        })

        // Update Index
        const newIndex = programIndex - 1 >= 0 ? programIndex - 1 : 0;
        setProgramIndex(newIndex);

        // Close the modal
        setDeleteModal(false);
    }

    const getProgramData = async () => {
        const config = await getAuthHeader(currentUser);
        try {
            const response = await axios.post(`${ENVIRONMENT.URL}/quick-program/get-all-programs`, {}, config);
            if (response.status !== 200) {
                return message.error("Couldn't get your programs, please contact us at support@phros.ca");
            }
            if (response.data?.programs?.length > 0) {
                setPrograms(response.data.programs);
            }
        } catch (e) {
            return message.error("Couldn't get your programs, please contact us at support@phros.ca");
        } finally {
            setLoadPrograms(false);
        }
    }


        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.name}</h4>
                )
            },
            {
                title: "Price",
                dataIndex: "Price",
                key: "Code",
                render: (text, obj) => (
                    <h4>{obj.price/100} {obj.currency ? obj.currency : null}</h4>
                )
            },
            {
                title: "Published Status",
                dataIndex: "PublishedStatus",
                key: "PublishedStatus",
                render: (text, obj) => {
                    return (
                        obj.published ?
                        <Tag color="green">PUBLISHED</Tag>
                        :
                        <Tag color="red">NOT PUBLISHED</Tag>
                    )
                }
            },
            {
                title: "Created At",
                dataIndex: "CreatedAt",
                key: "CreatedAt",
                render: (text, obj) => {
                    return (
                        new Date(obj.created_at).toLocaleDateString()
                    )
                }
            },
            {
                title: "Updated At",
                dataIndex: "UpdatedAt",
                key: "UpdatedAt",
                render: (text, obj) => {
                    return (
                        new Date(obj.updated_at).toLocaleDateString()
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <div className="flex-row justify-start">
                            <div style={{ marginRight: "5px" }}>
                                <Link to={`/programs/${obj.program_id}`}>
                                    <Button type='primary' icon={<EditOutlined />}>
                                    </Button>
                                </Link>
                            </div>
                            <div>
                                <Button type='primary' danger icon={<DeleteOutlined />}
                                onClick={() => {
                                    setProgramIndex(index);
                                    setDeleteModal(true);
                                }}>
                                </Button>
                            </div>
                        </div>
                    )
                }
            },
        ]

    return (
    <Layout>
        <SideNav />
        <Layout.Content>
            <ConfirmDelete
                open={deleteModal}
                setOpen={setDeleteModal}
                programs={programs}
                programIndex={programIndex}
                handleDelete = {handleDelete}
            />
            <div className="ViewPrograms">
                <h1 className="AddProgram--Title">Your Health & Fitness Programs</h1>
                <Table
                    scroll={{ x: true }}
                    loading={loadPrograms}
                    pagination={{ defaultPageSize: 10 }}
                    columns={columns} dataSource={programs} />
                <div style={{ marginTop: "20px", }}>
                    <Link to={`/programs/${v4()}`}>
                        <Button type="primary">
                            Create a New Program
                        </Button>
                    </Link>
                </div>
            </div>
        </Layout.Content>
    </Layout>
    )
}

export {
    ViewAllPrograms,
}