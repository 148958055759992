const image = "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/phros%2Fpexels-taryn-elliott-3889927-min.jpg?alt=media&token=f1a33c35-c82f-4fe8-a954-0b912aa8fbcc";
const title = "A Title";
const subtitle = "This is a subtitle you can replace";
const logo = "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fphros-temp-logo.png?alt=media&token=1817c770-9b3d-4308-baea-77f7d5ac2bc1";
const colour = "#EEEEEE";
const questionAnswer = {
    Question: "What is Phros?",
    Answer: "Phros is a platform for online health & fitness instructors. We give you the tools you need to scale and grow your online business.",
}
const longText = "This is some long description text as an example. \n\n Replace this text with some text (however long). \n\n Thanks!";

export {
    image as ImageTemplate,
    title as TitleTemplate,
    subtitle as SubTitleTemplate,
    logo as LogoTemplate,
    colour as ColourTemplate,
    questionAnswer as QuestionAnswer,
    longText as LongText,
}