import React from 'react';
import { Row, Col, Layout, Button, Popconfirm, Table, Tag, notification } from 'antd';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { COUPON_DURATION, COUPON_TYPES } from '../../constants';
import { DeleteOutlined } from '@ant-design/icons';
import { checkArray } from '../../Verification';

class ViewCoupons extends React.Component {
    static contextType = AuthContext;
    state = {
        Coupons: [],
        ProductObj: {},
        loading: true,
    }

    componentDidMount() {
        this.getEventData();
    }

    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupons/get-all-coupons-with-uid`, {}, config);
        this.setState({
            Coupons: response.data.coupons,
            ProductObj: response.data.products,
            loading: false,
        })
    }

    deleteCoupon = async (CouponID, index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            CouponID: CouponID,
        };
        const deleteCoupon = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupons/delete-coupon`, data, config);
        if (deleteCoupon.data.error) {
            return notification.error({
                message: "Error",
                description: "Failed to delete this coupon, please refresh the page and try again. If this persists, please contact us at support@cloudsafari.io.",
                duration: 0,
            })
        } else {
            let coupons = [...this.state.Coupons];
            coupons.splice(index, 1);
            this.setState({
                Coupons: coupons,
            })
            return notification.success({
                message: "Success",
                description: "Successfully deleted the coupon."
            })
        }
    }


    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Name}</h4>
                )
            },
            {
                title: "Code",
                dataIndex: "Code",
                key: "Code",
                render: (text, obj) => (
                    <h4>{obj.Code}</h4>
                )
            },
            {
                title: "Type",
                dataIndex: "CouponType",
                key: "CouponType",
                render: (text, obj) => {
                    const coupontype = obj.CouponType;
                    let coupon = "";
                    if (coupontype === COUPON_TYPES.PERCENTAGE.value) {
                        coupon = COUPON_TYPES.PERCENTAGE.name;
                    } else {
                        coupon = COUPON_TYPES.FIXED.name;
                    }
                    return (
                        <h4>{coupon}</h4>
                    )
                }
            },
            {
                title: "Discount",
                dataIndex: "Discount",
                key: "Discount",
                render: (text, obj) => {
                    const coupontype = obj.CouponType;
                    let coupon = "";
                    if (coupontype === COUPON_TYPES.PERCENTAGE.value) {
                        coupon = `${obj.PercentageDiscount}% off`
                    } else {
                        coupon = `$${obj.DiscountAmount} (${obj.Currency}) off`
                    }
                    return (
                        <h4>{coupon}</h4>
                    )
                }
            },
            {
                title: "Duration",
                dataIndex: "Duration",
                key: "Duration",
                render: (text, obj) => {
                    let duration = obj.Duration;
                    let months = null;
                    if (duration === COUPON_DURATION.MULTIPLE_MONTHS.value) {
                        duration = COUPON_DURATION.MULTIPLE_MONTHS.name;
                        months = obj.DurationMonths;
                    } else if (duration === COUPON_DURATION.ONCE.value) {
                        duration = COUPON_DURATION.ONCE.name;
                    } else {
                        duration = COUPON_DURATION.FOREVER.name;
                    }
                    return (
                        <h4>{duration} { months ? `- repeats for ${months} month(s).` : null}</h4>
                    )
                }
            },
            {
                title: "Restrictions",
                dataIndex: "Restrictions",
                key: "Restrictions",
                render: (text, obj) => {
                    let redeemBy = null;
                    let maxRedeem = null;
                    let minOrder = null;
                    if (obj.LimitDate) {
                        redeemBy = new Date(obj.RedeemBy).toLocaleString();
                    }
                    if (obj.LimitMaxRedeem) {
                        maxRedeem = obj.MaxRedemptions;
                    }
                    if (obj.LimitMinOrder) {
                        minOrder = obj.MinOrder;
                    }
                    return (
                        <div>
                            { redeemBy ? <h4>Expires {redeemBy}</h4> : null}
                            { maxRedeem ? <h4>Coupon can be used {maxRedeem} times only.</h4> : null}
                            { minOrder ? <h4>Minimum Order Value, ${minOrder} ({obj.Currency}).</h4> : null}
                            { !redeemBy && !maxRedeem && !minOrder ? <h4>No coupon restrictions.</h4> : null}
                        </div>
                    )
                }
            },
            {
                title: "Product Restrictions",
                dataIndex: "ProductRestrictions",
                key: "ProductRestrictions",
                render: (text, obj) => {
                    const appliedTo = checkArray(obj.AppliedProducts);
                    return (
                        <div>
                            {
                                appliedTo.map((id) => (
                                    <Tag color="blue">{this.state.ProductObj[id]}</Tag>
                                ))
                            }
                            { appliedTo.length === 0 ? <h4>Coupon applies to all products.</h4> : null }
                        </div>
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <Popconfirm
                            title="Are you sure you want to delete this coupon?"
                            onConfirm={() => this.deleteCoupon(obj.CouponID, index)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" danger icon={<DeleteOutlined />}>Delete</Button>
                        </Popconfirm>
                    )
                }
            },
        ]
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View Coupons'/>
                            <Table
                            scroll={{ x: true }}
                            loading={this.state.loading}
                            columns={columns} dataSource={this.state.Coupons} />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewCoupons;