import React from 'react';
import { Row, Col, Layout, Button, Popconfirm, Table, notification } from 'antd';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { storage } from '../../Firebase';
import { DeleteOutlined } from '@ant-design/icons';

class ViewExercise extends React.Component {
    static contextType = AuthContext;
    state = {
        Exercises: [],
        loading: true,
    }

    componentDidMount() {
        this.getEventData();
    }

    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/exercise/get-all-exercises-with-uid`, data, config);
        this.setState({
            Exercises: response.data,
            loading: false,
        })
    }

    deleteEvent = async (index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const event = this.state.Exercises[index];
        const id = event.ID;

        if (currentUser.uid !== event.uid) {
            return notification.error({
                message: 'Not Authorized',
                description: "You're not authorized to delete this exercise.",
                duration: null,
            })
        }
        const data = {
            id: id,
        };
        const deleteEvent = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/exercise/delete-exercise`, data, config);
        if (deleteEvent.data.error) {
            return notification.error({
                message: "Failed to Delete",
                description: "Failed to delete this exercise. Please try again, or contact our team."
            })
        }

        const classes = [...this.state.Exercises];
        classes.splice(index, 1);
        this.setState({
            Exercises: classes,
        })
        try {
            const file = storage.refFromURL(event.VideoURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        try {
            const file = storage.refFromURL(event.ThumbnailURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        return (
            notification.success({
                message: 'Success',
                description: 'Successfully deleted the exercise.'
            })
        )
    }

    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Title}</h4>
                )
            },
            {
                title: "Reference Name",
                dataIndex: "ReferenceName",
                key: "ReferenceName",
                render: (text, obj) => (
                    <h4>{obj.ReferenceName}</h4>
                )
            },
            {
                title: "Description",
                dataIndex: "Description",
                key: "Description",
                render: (text, obj) => {
                    return (
                        <p>{obj.Description}</p>
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <Row justify="center" gutter={[10,10]}>
                            <Col>
                                <Link to={`/exercise/${obj.ID}`}>
                                <Button type='primary'>Edit</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Popconfirm
                                title={`Are you sure you want to delete this exercise (${obj.Title})? This action cannot be reversed.`}
                                onConfirm={() => this.deleteEvent(index)}
                                okText="Delete Exercise"
                                >
                                <Button type='primary' danger icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    )
                }
            },
        ]
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View Exercises'/>
                            <Table
                            scroll={{ x: true }}
                            loading={this.state.loading}
                            columns={columns} dataSource={this.state.Exercises} />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewExercise;