import './Contact2.css';
import React from 'react';
import { Row, Col, Input } from 'antd';

class Contact2 extends React.Component {
    render() {
        return (
        <Row justify="center" style={{ backgroundColor: "white" }}>
        <Col xs={23}>
            <Row gutter={[20,20]}>
                <Col xs={24}>
                    <h2 className="Contact2-Title">Contact Me</h2>
                </Col>
                <Col xs={24} sm={12}>
                    <Input size="large" bordered={false} className="Contact2-Input" placeholder="First Name" />
                    <hr className="Contact2-HR" />
                </Col>
                <Col xs={24} sm={12}>
                    <Input size="large" bordered={false} className="Contact2-Input" placeholder="Last Name" />
                    <hr className="Contact2-HR" />
                </Col>
                <Col xs={24} sm={12}>
                    <Input size="large" bordered={false} className="Contact2-Input" placeholder="Email" />
                    <hr className="Contact2-HR" />
                </Col>
                <Col xs={24} sm={12}>
                    <Input size="large" bordered={false} className="Contact2-Input" placeholder="Phone Number" />
                    <hr className="Contact2-HR" />
                </Col>
                <Col xs={24}>
                    <Input.TextArea size="large" bordered={false} className="Contact2-Input" autoSize={{ minRows: 3 }} placeholder="Description" />
                    <hr className="Contact2-HR" />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <button className="Contact2-Contact-Me">Contact Me</button>
                </Col>
            </Row>
        </Col>
        </Row>
        )
    }
}

export default Contact2;