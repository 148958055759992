import React from 'react';
import './Memberships.css';
import { Row, Col, Layout, Input, Radio, notification, Button, Progress, Upload, Card, Select, message, InputNumber } from 'antd';
import { SideNav } from '../../Components';
import { H2 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { DataContext } from '../../Context/ProfileData';
import currencies from '../../Assets/currencies';
import axios from 'axios';
import { SUBSCRIPTION_INTERVAL, RESOURCE_CAPACITY, TRIAL_PERIOD }  from '../../constants'
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import { UploadOutlined, FileOutlined } from '@ant-design/icons';

class Membership extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Name: "",
            Description: "",
            Price: 0,
            Currency: "CAD",
            RecurringInterval: "month",
            SelectedResources: {
                LiveClasses: {
                    Capacity: RESOURCE_CAPACITY.UNLIMITED.value,
                    Limit: 0,
                },
                OnDemandClasses: {
                    Capacity: RESOURCE_CAPACITY.UNLIMITED.value,
                    Limit: 0,
                },
                TrainingPrograms: {
                    Capacity: RESOURCE_CAPACITY.UNLIMITED.value,
                    Limit: 0,
                }
            },
            TrialPeriod: TRIAL_PERIOD.NOTRIAL.value,
            TrialPeriodDays: 0,
            ImageURL: null,
            WaiverURL: null,
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleImageUpload = async (folderName, buttonName, info) => {
        notification.open({
            key: "Progress-Upload",
            message: "Uploading File",
            description: (
                <Row>
                    <Col xs={24}>
                        Status:
                    </Col>
                    <Col xs={24}>
                        <Progress percent={0} status="active" />
                    </Col>
                </Row>
            )
        })
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/static/${uid}/${folderName}/${filename}`).put(file);
            uploadTask.on('state_changed',
            (snapshot) => {
                notification.open({
                    key: "Progress-Upload",
                    message: "Uploading File",
                    description: (
                        <Row>
                            <Col xs={24}>
                                Status:
                            </Col>
                            <Col xs={24}>
                                <Progress percent={Math.ceil(snapshot.bytesTransferred/snapshot.totalBytes*100)} />
                            </Col>
                        </Row>
                    )
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`/static/${uid}/${folderName}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    resolve(firebaseUrl);
                })
            })
        });
        return url;
    }

    deleteFile = async (url) => {
        try {
            if (url === null || url === undefined || String(url).length === 0) return;
            const previousLogo = storage.refFromURL(url);
            const value = await previousLogo.delete();
            if (value) return true;
            return false;
        } catch (e) {
            message.error('Failed to delete file.');
            return false;
        }
    }

    createMembership = async () => {
        if (String(this.state.Name).length === 0) {
            return notification.error({
                message: "Error",
                description: "Please enter in a name for this membership."
            });
        }
        if (this.state.Price <= 0) {
            return notification.error({
                message: "Error",
                description: "Price must be greater than $0."
            })
        }
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            Name: this.state.Name,
            Description: this.state.Description,
            Price: this.state.Price,
            Currency: this.state.Currency,
            RecurringInterval: this.state.RecurringInterval,
            SelectedResources: this.state.SelectedResources,
            TrialPeriod: this.state.TrialPeriod,
            TrialPeriodDays: this.state.TrialPeriodDays,
            ImageURL: this.state.ImageURL,
            WaiverURL: this.state.WaiverURL,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/memberships/create-membership`, payload, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: "Failed to create membership, please try again or contact us at support@phros.ca if this persists.",
                duration: null,
            })
        } else {
            return notification.success({
                message: "Success",
                description: "Successfully created the membership."
            })
        }
    }

    render() {
        return (
        <DataContext.Consumer>
            {
            data => (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                    <Col xs={23} sm={22}>
                        <Row>
                            <Col xs={24}>
                            <H2 text='Create a Membership' />
                            <h3>Memberships are synonymous to subscriptions. Create a membership so that your customers can pay you on a recurring basis.</h3>
                            </Col>
                        </Row>
                        <Row gutter={[20,20]}>
                            <Col xs={24} sm={8}>
                                <h3>Membership Name</h3>
                                <Input value={this.state.Name} onChange={(e) => this.handleChange("Name", e.target.value)} />
                                <p>A name to reference your membership.</p>
                            </Col>
                            <Col xs={24}>
                                <h3>Description</h3>
                                <Input.TextArea placeholder="A description on what the membership will include." value={this.state.Description} autoSize={{ minRows: 3 }} onChange={(e) => this.handleChange("Description", e.target.value)} />
                            </Col>
                            <Col xs={24}>
                                <Row gutter={[10,10]}>
                                    <Col xs={12} sm={8}>
                                        <h3>Currency</h3>
                                        <Select
                                        placeholder="Select Currency"
                                        onSelect={(value) => this.handleChange('Currency', value)}
                                        value={this.state.Currency}
                                        style={{ width: '100%' }}
                                        >
                                            {
                                                Object.keys(currencies).map((curr) => {
                                                    return (
                                                        <Select.Option key={curr} name={currencies[curr]}>
                                                        <strong>{curr}: </strong>{currencies[curr]}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col xs={12} sm={8}>
                                        <h3>Price</h3>
                                        <InputNumber value={this.state.Price} onChange={(value) => this.handleChange("Price", value)} style={{ width: "100%" }} min={1} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="Coupon-Row" gutter={[10,10]}>
                            <Col xs={24} sm={8}>
                                <h3>Recurring Interval</h3>
                                <Select
                                placeholder="Select membership/subscription inerval"
                                onSelect={(value) => this.handleChange('RecurringInterval', value)}
                                value={this.state.RecurringInterval}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(SUBSCRIPTION_INTERVAL).map((key) => {
                                            return (
                                                <Select.Option key={SUBSCRIPTION_INTERVAL[key].value} name={SUBSCRIPTION_INTERVAL[key].value}>
                                                {SUBSCRIPTION_INTERVAL[key].name}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col xs={24}>
                                <p>The recurring interval specifies when your customer will be charged either on a weekly, monthly, or yearly basis.</p>
                            </Col>
                        </Row>
                        <Row className="Coupon-Row" gutter={[10,20]}>
                            <Col xs={24}>
                                <h3>What's included in the membership?</h3>
                            </Col>
                            {
                                data.profile.PLAN?.FEATURES.LIVECLASSES?
                                <Col xs={24}>
                                    <Row gutter={[10,10]}>
                                        <Col xs={24}>
                                        <h3>Live Classes</h3>
                                        <Radio.Group value={this.state.SelectedResources.LiveClasses.Capacity} onChange={(e) => {
                                            const resources = { ...this.state.SelectedResources };
                                            resources.LiveClasses.Capacity = e.target.value;
                                            this.handleChange("SelectedResources", resources);
                                        }}>
                                            <Radio value={RESOURCE_CAPACITY.UNLIMITED.value}>{RESOURCE_CAPACITY.UNLIMITED.name}</Radio>
                                            <Radio value={RESOURCE_CAPACITY.CAPPED.value}>{RESOURCE_CAPACITY.CAPPED.name}</Radio>
                                        </Radio.Group>
                                        </Col>
                                        {
                                            this.state.SelectedResources.LiveClasses.Capacity === RESOURCE_CAPACITY.CAPPED.value ?
                                            <Col xs={24} sm={12}>
                                                <h4>Number of Live Classes</h4>
                                                <InputNumber value={this.state.SelectedResources.LiveClasses.Limit} style={{ width: '100%' }}
                                                onChange={(value) => {
                                                    const resources = { ...this.state.SelectedResources };
                                                    resources.LiveClasses.Limit = value;
                                                    this.handleChange("SelectedResources", resources);
                                                }}
                                                />
                                                <p>This is the number of live classes a member will be able to select per {this.state.RecurringInterval}.</p>
                                            </Col>
                                            :
                                            null
                                        }
                                    </Row>
                                </Col>
                                :
                                null
                            }
                            {
                                data.profile.PLAN?.FEATURES.ONDEMAND?
                                <Col xs={24}>
                                    <Row gutter={[10,10]}>
                                        <Col xs={24}>
                                        <h3>On Demand Videos</h3>
                                        <Radio.Group value={this.state.SelectedResources.OnDemandClasses.Capacity} onChange={(e) => {
                                            const resources = { ...this.state.SelectedResources };
                                            resources.OnDemandClasses.Capacity = e.target.value;
                                            this.handleChange("SelectedResources", resources);
                                        }}>
                                            <Radio value={RESOURCE_CAPACITY.UNLIMITED.value}>{RESOURCE_CAPACITY.UNLIMITED.name}</Radio>
                                            <Radio value={RESOURCE_CAPACITY.CAPPED.value}>{RESOURCE_CAPACITY.CAPPED.name}</Radio>
                                        </Radio.Group>
                                        </Col>
                                        {
                                            this.state.SelectedResources.OnDemandClasses.Capacity === RESOURCE_CAPACITY.CAPPED.value ?
                                            <Col xs={24} sm={12}>
                                                <h4>Number of On Demand Classes</h4>
                                                <InputNumber
                                                onChange={(value) => {
                                                    const resources = { ...this.state.SelectedResources };
                                                    resources.OnDemandClasses.Limit = value;
                                                    this.handleChange("SelectedResources", resources);
                                                }}
                                                value={this.state.SelectedResources.OnDemandClasses.Limit} style={{ width: '100%' }} />
                                                <p>This is the number of on-demand videos a member will be able to select per {this.state.RecurringInterval}.</p>
                                            </Col>
                                            :
                                            null
                                        }
                                    </Row>
                                </Col>
                                :
                                null
                            }
                            {
                                data.profile.PLAN?.FEATURES.TRAININGPROGRAM?
                                <Col xs={24}>
                                    <Row gutter={[10,10]}>
                                        <Col xs={24}>
                                        <h3>Workout Programs</h3>
                                        <Radio.Group value={this.state.SelectedResources.TrainingPrograms.Capacity} onChange={(e) => {
                                            const resources = { ...this.state.SelectedResources };
                                            resources.TrainingPrograms.Capacity = e.target.value;
                                            this.handleChange("SelectedResources", resources);
                                        }}>
                                            <Radio value={RESOURCE_CAPACITY.UNLIMITED.value}>{RESOURCE_CAPACITY.UNLIMITED.name}</Radio>
                                            <Radio value={RESOURCE_CAPACITY.CAPPED.value}>{RESOURCE_CAPACITY.CAPPED.name}</Radio>
                                        </Radio.Group>
                                        </Col>
                                        {
                                            this.state.SelectedResources.TrainingPrograms.Capacity === RESOURCE_CAPACITY.CAPPED.value ?
                                            <Col xs={24} sm={12}>
                                                <h4>Number of Workout Programs</h4>
                                                <InputNumber
                                                onChange={(value) => {
                                                    const resources = { ...this.state.SelectedResources };
                                                    resources.TrainingPrograms.Limit = value;
                                                    this.handleChange("SelectedResources", resources);
                                                }}
                                                value={this.state.SelectedResources.TrainingPrograms.Limit} style={{ width: '100%' }} />
                                                <p>This is the number of workout programs a member will be able to select per {this.state.RecurringInterval}.</p>
                                            </Col>
                                            :
                                            null
                                        }
                                    </Row>
                                </Col>
                                :
                                null
                            }
                        </Row>
                        <Row className="Coupon-Row" style={{ marginTop: "40px" }} gutter={[10,10]}>
                            <Col xs={24}>
                                <h3>Does this membership have a trial period?</h3>
                            </Col>
                            <Col xs={24}>
                                <Radio.Group value={this.state.TrialPeriod} onChange={(e) => this.handleChange("TrialPeriod", e.target.value)}>
                                    <Radio value={TRIAL_PERIOD.TRIAL.value}>{TRIAL_PERIOD.TRIAL.name}</Radio>
                                    <Radio value={TRIAL_PERIOD.NOTRIAL.value}>{TRIAL_PERIOD.NOTRIAL.name}</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                this.state.TrialPeriod === TRIAL_PERIOD.TRIAL.value ?
                                <Col xs={24} sm={12}>
                                    <h4>Number of Days in the Trial Period</h4>
                                    <InputNumber style={{ width: "100%" }} value={this.state.TrialPeriodDays} min={1} onChange={(value) => this.handleChange("TrialPeriodDays", value)} />
                                </Col>
                                :
                                null
                            }
                        </Row>
                        <Row className="Coupon-Row" gutter={[10,10]}>
                        <Col xs={24}>
                            <h3>Membership Image</h3>
                            <p>Upload an image for your membership (this get's displayed on your website!).</p>
                            {
                                this.state.ImageURL !== undefined && this.state.ImageURL !== null  && String(this.state.ImageURL).length > 0?
                                <Card
                                style={{ width: "400px" }}
                                cover={<img src={this.state.ImageURL} alt="" />}
                                >
                                <Upload
                                fileList={[]}
                                accept=".png,.jpeg,.jpg"
                                customRequest={async (info) => {
                                    const url = await this.handleImageUpload('images','UploadDisabled',info);
                                    this.handleChange("ImageURL", url);
                                }}>
                                    <Button icon={<UploadOutlined />}>Replace Image</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    fileList={[]}
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={async (info) => {
                                        const url = await this.handleImageUpload('images','UploadDisabled',info);
                                        this.handleChange("ImageURL", url);
                                    }}>
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                            }
                        </Col>
                        </Row>
                        <Row className="Coupon-Row" gutter={[10,10]}>
                            <Col xs={24} sm={12}>
                            <h3>Upload Waiver/Liability Form</h3>
                            {
                                this.state.WaiverURL !== undefined && this.state.WaiverURL !== null && String(this.state.WaiverURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                >
                                <Col xs={24} style={{ paddingBottom: '10px' }}>
                                <a target="_blank" href={this.state.WaiverURL} rel="noreferrer"><FileOutlined style={{ fontSize: '25px' }}/>View Uploaded PDF</a>
                                </Col>
                                <Upload
                                    fileList={[]}
                                    listType='picture'
                                    accept=".pdf"
                                    customRequest={async (info) => {
                                        await this.deleteFile(this.state.WaiverURL);
                                        const url = await this.handleImageUpload('files', 'UploadDisabled', info);
                                        this.handleChange("WaiverURL", url);
                                    }}>
                                    <Button icon={<UploadOutlined />} >Upload Waiver</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    fileList={[]}
                                    listType='picture'
                                    accept=".pdf"
                                    customRequest={async (info) => {
                                        await this.deleteFile(this.state.WaiverURL);
                                        const url = await this.handleImageUpload('files', 'UploadDisabled', info);
                                        this.handleChange("WaiverURL", url);
                                    }}
                                    >
                                    <Button icon={<UploadOutlined />}>Upload Waiver</Button>
                                </Upload>
                            }
                            </Col>
                        </Row>
                        <Row justify="end" className="Coupon-Row">
                            <Col>
                            <Button className="Button" onClick={() => this.createMembership()}>Create Membership</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </Layout.Content>
            </Layout>
                )
            }
        </DataContext.Consumer>
        )
    }
}

export default Membership;