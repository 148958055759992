import React from 'react';
import { Row, Col, Input } from 'antd';
import PropTypes from 'prop-types';
import './Banner4.css';

/*
Required Props
BannerURL
ProfessionalTitle
FirstName
LastName
BusinessStatement
*/
class Banner4 extends React.Component {
    render() {
        // const bannerImageURl = "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fphros%2Fpexels-rfstudio-3820364.jpg?alt=media&token=aa812eaf-b52f-450f-8d84-116d3f0e4ae9";
        const bannerImageURl = this.props.BannerImageURL;
        return (
            <Row justify="center">
            <Col xs={24} className="Banner4-Main-Col"
            style={{ backgroundColor: this.props.ColourPalette?.LIGHT }}
            >
                <Row justify="center" align="middle">
                    <Col xs={24} sm={12}>
                        <img className="Banner4-Img" src={bannerImageURl} alt={this.props.ProfessionalTitle} />
                    </Col>
                    <Col xs={24} sm={12} className="Banner4-Text-Col">
                    <h2 className="Banner4-Name">{this.props.FirstName} {this.props.LastName}</h2>
                    <h3 className="Banner4-BusinessStatement">{this.props.BusinessStatement}</h3>
                    <Row gutter={[10,10]}>
                        <Col xs={24} sm={14}>
                        <Input placeholder="Enter E-mail" className="Banner4-Input" />
                        </Col>
                        <Col xs={24} sm={10}>
                            <button
                            className="Banner4-Contact-Button"
                            >
                                Contact Me
                            </button>
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </Col>
            </Row>
        )
    }
}

Banner4.propTypes = {
    BannerImageURL: PropTypes.string.isRequired,
    ProfessionalTitle: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
    BusinessStatement: PropTypes.string.isRequired,
    ColourPalette: PropTypes.object.isRequired,
}

export default Banner4;