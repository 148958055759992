import React from 'react';
import './Headings.css';

class H3 extends React.Component {
    render() {
        return (
            <h3 className='H3'>
                <span className='H3-Highlight'>
                {this.props.text}
                </span>
            </h3>
        )
    }
}

export default H3;