import { useState, useEffect } from "react";
import { Card1 } from "../../phros-web-components/Cards/Card1/Card1";
import { Input, Select } from "antd";
import { PhrosUpload } from "../../Common/Uploads/Upload";
import { ImageTemplate } from "../../phros-web-components/TemplateData";

/*
this.Image = image;
this.Title = title;
this.Description = description;
this.CTA = cta;
this.Link = ctaLink;
this.LinkText = ctaText;
*/

const Card1Edit = ({ data = Card1.Default(), editComponent = () => {} }) => {
    const [imageMap, setImageMap] = useState({
        "image_id": {
            url: data.Image,
            description: data.ImageAlt,
            fileId: "image_id",
        }
    });
    const [title, setTitle] = useState(data.Title);
    const [description, setDescription] = useState(data.Description);
    const [cta, setCta] = useState(data.CTA);
    const [link, setLink] = useState(data.Link);
    const [linkText, setLinkText] = useState(data.LinkText);

    const deleteWebComponentImage = () => {
        return;
    }

    useEffect(() => {
        const keys = Object.keys(imageMap);
        let imageUrl = ImageTemplate;
        let imageAlt = "This is some image alt text.";
        if (keys.length > 0) {
            const image = imageMap[keys[0]];
            imageUrl = image.url;
            imageAlt = `${image.title} ${image.description}`;
        }
        const updateCard = new Card1(imageUrl, title, description, cta, link, linkText);
        editComponent(updateCard);
    }, [imageMap, title, description, cta, link, linkText]);

    return (
        <div>
            <h3>Image</h3>
            <PhrosUpload
                accept = ".jpg,.jpeg,.png"
                text="Choose an image or drag it here."
                type="static"
                fileMap={imageMap}
                setFileMap={setImageMap}
                deleteFile={deleteWebComponentImage}
            />

            <h3 style={{ marginTop: "20px" }}>Title:</h3>
            <Input.TextArea
                value = {title}
                onChange = {(e) => setTitle(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Description:</h3>
            <Input.TextArea
                value = {description}
                onChange = {(e) => setDescription(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Use a CTA?</h3>
            <Select value={cta} onChange={(value) => setCta(value)}>
                <Select.Option value={true}>Yes</Select.Option>
                <Select.Option value={false}>No</Select.Option>
            </Select>

            <h3 style={{ marginTop: "20px" }}>Button Text:</h3>
            <Input.TextArea
                value = {linkText}
                onChange = {(e) => setLinkText(e.target.value)}
            />

            <h3 style={{ marginTop: "20px" }}>Button Link:</h3>
            <p>Where does this button link to? Please enter a URL.</p>
            <Input.TextArea
                value = {link}
                onChange = {(e) => setLink(e.target.value)}
            />
        </div>
    )
};

export {
    Card1Edit,
}