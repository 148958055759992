import React, { useState } from "react";
import { QuestionAnswer } from "../../TemplateData";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "./FAQ2.css";


class Question {
    constructor(question, answer) {
        this.Question = question;
        this.Answer = answer;
    }
    static Default() {
        return new Question(QuestionAnswer.Question, QuestionAnswer.Answer);
    }
}

class FAQ2 extends Component {
    ID = IDS.FAQ2;
    FAQS = [];
    constructor(faqs) {
        super();
        this.FAQS = faqs;
    }
    static Default() {
        const one = Question.Default();
        const two = new Question("How do I use this FAQ?", "Hover over this element and click the edit icon to add your FAQ details.")
        return new FAQ2([one, two]);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new FAQ2(obj.FAQS);
    }
}

const FAQ2View = ({ data = FAQ2.Default() }) => {
    const [faqMap, setFaqMap] = useState({});

    const handleOpenFAQ = (key) => {
        if (key in faqMap) {
            setFaqMap((prevState) => {
                return {
                    ...prevState,
                    [key]: !prevState[key],
                }
            })
        } else {
            setFaqMap((prevState) => {
                return {
                    ...prevState,
                    [key]: true,
                }
            })
        }
    }

    return (
        <div className="FAQ2--Wrapper">
        <div className="FAQ2--Container">
            {
                data.FAQS.map((faq, index) => {
                    const question = new Question(faq.Question || "Question?", faq.Answer || "Answer.");
                    const key = `${faq.Question}_${index}`;
                    return (
                        <div className="FAQ2--Row" key={key} onClick={() => handleOpenFAQ(key)}>
                            <div className="FAQ2--FAQ__Box">
                                <div className="FAQ2--FAQ__Question">
                                    <div>
                                    {question.Question}
                                    </div>
                                    <div>
                                        {
                                            faqMap[key] ?
                                            <img className="FAQ--Icon" alt="Minus Icon" src="https://img.icons8.com/material-outlined/24/000000/minus.png"/>
                                            :
                                            <img className="FAQ--Icon" alt="Plus Icon" src="https://img.icons8.com/external-tanah-basah-detailed-outline-tanah-basah/48/000000/external-plus-user-interface-tanah-basah-detailed-outline-tanah-basah-2.png"/>
                                        }
                                    </div>
                                </div>
                                {
                                    faqMap[key] ?
                                    <div>
                                        <div className="FAQ2--FAQ__Answer">
                                            {question.Answer}
                                        </div>
                                        <hr className="FAQ2--HR" />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
        </div>
    )
}


export {
    FAQ2,
    Question,
    FAQ2View,
}