import React from 'react';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import { Row, Col, Layout, Spin, message, Button, Input, Affix, Card, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AuthContext } from '../../Context/AuthContext';
import axios from 'axios';
import { checkArray, checkString, } from '../../Verification';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EditWebsite.css';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';

class EditWebsite extends React.Component {
    static contextType = AuthContext;
    state = {
        paid: false,
        loading: true,
        profile: null,
        FAQ: [],
        AboutMe: "",
        AboutMePictureURL: "",

        AboutPicture: null,
        AboutPicDisabled: false,
        AboutPictureFileList: [],
    }
    componentDidMount() {
        this.getProfile();
    }

    saveProfileInformation = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            FAQ: checkArray(this.state.FAQ),
            AboutMe: checkString(this.state.AboutMe),
            AboutMePictureURL: checkString(this.state.AboutMePictureURL)
        };
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/profile/save-website-edits`, data, config);
        if (save.data.error) {
            message.error('Failed to save profile information.')
        } else {
            message.success('Successfully saved profile information.')
        }
    }

    getProfile = async () => {
        const { currentUser } = this.context;
        if (!currentUser.uid) {
            message.error('Failed to get user details.')
        }
        const data = {
            uid: currentUser.uid,
        }
        const profile = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/profile/get-profile-with-uid`, data);
        if (profile.data.error) return;
        this.setState({
            profile: profile.data,
            loading: false,
            FAQ: checkArray(profile.data.FAQ),
            AboutMe: checkString(profile.data.AboutMe),
            AboutMePictureURL: checkString(profile.data.AboutMePictureURL)
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    addToArray = (arrayName, data) => {
        this.setState({
            [arrayName]: [...this.state[arrayName], data],
        })
    }
    removeFromArray = (arrayName, index) => {
        const temp = this.state[arrayName];
        temp.splice(index, 1);
        this.setState({
            [arrayName]: temp
        });
    }
    modifyArrayObjValue = (arrayName, valueName, index, value) => {
        const val = this.state[arrayName][index];
        val[valueName] = value;
        this.setState({
            [arrayName]: [...this.state[arrayName].slice(0, index), val, ...this.state[arrayName].slice(index+1, this.state[arrayName].length)]
        })
    }

    handleImageUpload = async (fileListName, urlName, buttonName, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/images/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`images/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        message.error('Failed to delete previous image');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='ReviewWebsite-Row'>
                <Col xs={23} sm={22}>
                <H2 text='Edit your Website' />
                {
                    this.state.loading ?
                    <Spin size='large' />
                    :
                    <div>
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <h3>FAQ Section</h3>
                                <p>Have some FAQ (Freqeuently Asked Questions), add them to your site:</p>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.FAQ.length > 0 ?
                                    <Row gutter={[10,10]}>
                                        <Col xs={10}>
                                            Question
                                        </Col>
                                        <Col xs={10}>
                                            Answer
                                        </Col>
                                        <Col xs={4}>
                                            Delete
                                        </Col>
                                    </Row>
                                    :
                                    null
                                }
                                {
                                    this.state.FAQ.map((faq, index) => {
                                        return (
                                            <Row gutter={[10,10]} style={{ marginTop: '3px', marginBottom: '3px' }}>
                                                <Col xs={24} sm={10}>
                                                    <Input placeholder="FAQ Question" onChange={(e) => this.modifyArrayObjValue('FAQ', 'Question', index, e.target.value)} value={faq.Question} />
                                                </Col>
                                                <Col xs={24} sm={10}>
                                                    <Input placeholder="FAQ Answer" onChange={(e) => this.modifyArrayObjValue('FAQ', 'Answer', index, e.target.value)} value={faq.Answer} />
                                                </Col>
                                                <Col xs={24} sm={4}>
                                                    <Button block type="primary" danger
                                                    onClick={() => this.removeFromArray('FAQ', index)}
                                                    >Delete</Button>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                            <Col xs={24}>
                                <Button type="primary"
                                onClick={() => {
                                    this.addToArray("FAQ", { Question: '', Answer: '' })
                                }}
                                >Add FAQ Question</Button>
                            </Col>
                        </Row>
                    </div>
                }
                <Row className="Profile-Row">
                    <Col xs={24}>
                        <h3>"About Me" Section</h3>
                        <p>Use the text editor below to add information about yourself so customers can learn a little more about you.</p>
                    </Col>
                    <Col xs={24} style={{ backgroundColor: 'white' }}>
                        <ReactQuill
                            value={this.state.AboutMe}
                            onChange={(content) => this.handleChange("AboutMe", content)}
                        />
                    </Col>
                </Row>
                <Row className="Profile-Row">
                    <Col xs={24}>
                    <h3>"About Me" Picture</h3>
                    <p>This will be the picture displayed alongside the about me text.</p>
                    {
                        this.state.AboutMePictureURL !== undefined && this.state.AboutMePictureURL !== null  && String(this.state.AboutMePictureURL).length > 0?
                        <Card
                        hoverable
                        style={{ minWidth: 240, maxWidth: 300 }}
                        cover={<img src={this.state.AboutMePictureURL} alt='Profile' className='Profile-ProfilePicture' />}
                        >
                        <Upload
                            disabled={this.state.AboutPicDisabled}
                            listType='picture'
                            accept=".png,.jpeg,.jpg"
                            customRequest={(info) => this.handleImageUpload('AboutPictureFileList','AboutMePictureURL','AboutPicDisabled',info)}
                            fileList={this.state.AboutPictureFileList}>
                            <Button icon={<UploadOutlined />}>Replace About Me Picture</Button>
                        </Upload>
                        </Card>
                        :
                        <Upload
                            disabled={this.state.AboutPicDisabled}
                            listType='picture'
                            accept=".png,.jpeg,.jpg"
                            customRequest={(info) => this.handleImageUpload('AboutPictureFileList','AboutMePictureURL','AboutPicDisabled',info)}
                            fileList={this.state.AboutPictureFileList}>
                            <Button icon={<UploadOutlined />}>Upload About Me Picture</Button>
                        </Upload>
                    }
                    </Col>
                </Row>
                <Row className='Profile-Row' justify='end'>
                    <Col xs={24} sm={8}>
                        <Affix offsetBottom={20}>
                        <Button type='primary' size='large' className='Button' onClick={this.saveProfileInformation} block >Save Website</Button>
                        </Affix>
                    </Col>
                </Row>
                </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default EditWebsite;