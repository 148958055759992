import React from 'react';
import { Row, Modal, Col, Layout, Input, Button, Upload, Card, Select, Affix, message, InputNumber, notification, Divider } from 'antd';
import { SideNav } from '../../Components';
import { H2, H3 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { UploadOutlined, FileOutlined, DeleteFilled } from '@ant-design/icons';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import currencies from '../../Assets/currencies';
import { TRAINING_EXPERIENCE_LEVEL, TRAINING_INTENSITY_LEVEL, FREE_EVENTS, FITNESS_CATEGORIES } from '../../constants';
import axios from 'axios';
import './AddWorkout.css';

class AddWorkout extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Title: '',
            Description: '',
            Price: 0,
            Currency: '',
            Categories: [],
            FreeClass: FREE_EVENTS.NO.Key,
            IntensityRating: 1,
            Level: undefined,
            Published: false,
            Weeks: [{ Week: [] }],
            ExerciseKeys: {},

            WaiverFileList: [],
            WaiverURL: '',
            WaiverDisabled: false,

            ThumbnailFileList: [],
            ThumbnailURL: '',
            ThumbnailDisabled: false,

            Exercises: [],
            exerciseLoading: true,

            ViewExerciseID: '',
            ExerciseModalOpen: false,

            InstructionDetails: {
                Name: '',
                WeekIndex: 0,
                DayIndex: 0,
                ExerciseIndex: 0,
            },
            InstructionModalOpen: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.ID !== this.props.match.params.ID) {
            this.setState({
                Title: '',
                Description: '',
                Price: 0,
                Currency: '',
                Categories: [],
                FreeClass: FREE_EVENTS.NO.Key,
                IntensityRating: 1,
                Level: undefined,
                Published: false,
                Weeks: [{ Week: [] }],
                WaiverFileList: [],
                WaiverURL: '',
                WaiverDisabled: false,
                ExerciseKeys: {},
                ThumbnailFileList: [],
                ThumbnailURL: '',
                ThumbnailDisabled: false,
                ViewExerciseID: '',
                ExerciseModalOpen: false,
            });
            this.getData();
            this.getExerciseData();
        }
    }

    getData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
        }
        await this.getExerciseData();
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/workouts/get-workout-with-id`, payload, config);
        if(response.data.error) return;
        let free = FREE_EVENTS.NO.Key;
        if (response.data.FreeClass === true) {
            free = FREE_EVENTS.YES.Key;
        }
        if (response.data) {
            this.setState({
                FreeClass: free,
                Title: response.data.Title,
                Description: response.data.Description,
                Price: response.data.Price,
                Currency: response.data.Currency,
                Categories: response.data.Categories,
                IntensityRating: response.data.IntensityRating,
                Level: response.data.Level,
                Weeks: response.data.Weeks,
                WaiverURL: response.data.WaiverURL,
                ThumbnailURL: response.data.ThumbnailURL,
                ViewExerciseID: '',
            })
        }
    }

    changeExerciseModalVisibility = () => {
        this.setState({
            ExerciseModalOpen: !this.state.ExerciseModalOpen
        });
    }

    changeInstructionModalVisibility = () => {
        this.setState({
            InstructionModalOpen: !this.state.InstructionModalOpen
        });
    }

    getExerciseData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/exercise/get-all-exercises-with-uid`, data, config);
        const exerciseKeys = response.data.reduce((obj, exer) => {
            return {
                ...obj,
                [exer.ID]: exer,
            }
        }, {});
        this.setState({
            Exercises: response.data,
            ExerciseKeys: exerciseKeys,
            exerciseLoading: false,
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleArrayAdd = (name, value) => {
        let tempArray = [...this.state[name], value];
        this.setState({
            [name]: tempArray
        })
    }

    handleArrayDelete = (name, value) => {
        let tempArray = [...this.state[name]];
        let index = tempArray.indexOf(value);
        tempArray.splice(index, 1);
        this.setState({
            [name]: tempArray
        })
    }

    handleFileUpload = async (fileListName, urlName, buttonName, fileType, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/${fileType}/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                message.error('Something went wrong, please contact our team.');
                console.log(err);
            }, () => {
                storage.ref(`${fileType}/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            // Send URL to backend to delete
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        console.log(e);
                        message.error('Failed to delete the file.');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    saveEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
            ID: this.props.match.params.ID,
            Title: this.state.Title,
            Description: this.state.Description,
            Price: this.state.Price,
            Currency: this.state.Currency,
            Categories: this.state.Categories,
            FreeClass: this.state.FreeClass,
            IntensityRating: this.state.IntensityRating,
            Level: this.state.Level,
            Weeks: this.state.Weeks,
            WaiverURL: this.state.WaiverURL,
            ThumbnailURL: this.state.ThumbnailURL,
        }
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/workouts/save-workout`, data, config);
        if (save.data.error) {
            notification.error({
                duration: null,
                message: 'Failed to Save',
                description: 'Failed to save the workout, please try again or contact our team at support@phros.ca'
            })
        } else {
            notification.success({
                message: 'Workout Saved',
                description: 'Successfully saved the workout.'
            })
        }
    }

    publishEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            uid: currentUser.uid,
            Published: !this.state.Published,
        }
        const publish = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/on-demand-class/publish-on-demand-class`, data, config);
        if (publish) {
            this.setState({
                Published: !this.state.Published,
            })
            this.saveEvent();
            return message.success('Published the event.');
        }
    }

    handleAddWeek = () => {
        this.setState({
            Weeks: [...this.state.Weeks, { Week: [] }],
        })
    }

    removeWeek = (weekIndex) => {
        let weeks = this.state.Weeks;
        weeks.splice(weekIndex, 1);
        this.setState({
            Weeks: weeks,
        })
    }

    handleAddDay = async (index) => {
        let week = this.state.Weeks[index].Week;
        const day = {
            Notes: '',
            Exercises: [],
            SelectDay: '',
        }
        week = [...week, day]
        const temp  = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, index), temp, ...this.state.Weeks.slice(index+1, this.state.Weeks.length)]
        });
    }

    handleRemoveDay = async (weekIndex, dayIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        week.splice(dayIndex, 1);
        let tempWeek = {
            Week: week
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), tempWeek, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)]
        });
    }

    handleChangeDayNotes = async(value, weekIndex, dayIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        day.Notes = value;
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)],
        })
    }

    handleDaySelectExercise = async (value, weekIndex, dayIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        day.SelectDay = value;
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)],
        })
    }

    addExerciseToDay = async (weekIndex, dayIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        let exercises = day.Exercises;
        if (String(day.SelectDay).length === 0) {
            return notification.error({
                message: 'Select an Exercise',
                description: 'Please select an exercise before adding.'
            })
        }
        const exer = {
            ID: day.SelectDay,
            Instructions: [],
        };
        day.Exercises = [...exercises, exer]
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)]
        })
    }

    removeExerciseFromDay = async (weekIndex, dayIndex, exerciseIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        let exercises = day.Exercises;
        exercises.splice(exerciseIndex, 1);
        day.Exercises = exercises;
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)]
        })
    }

    handleInstructionDetailChange = (weekIndex, dayIndex, exerIndex, instIndex, name, value) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        let exercise = day.Exercises[exerIndex];
        let instructions = exercise.Instructions[instIndex];
        instructions[name] = value;
        exercise.Instructions = [...exercise.Instructions.slice(0, instIndex), instructions, ...exercise.Instructions.slice(instIndex+1, instructions.length)];
        day.Exercises = [...day.Exercises.slice(0, exerIndex), exercise, ...day.Exercises.slice(exerIndex+1, day.Exercises.length)];
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)]
        })
    }

    addInstructionToExercise = async (weekIndex, dayIndex, exerciseIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        let exercise = day.Exercises[exerciseIndex];
        let instructions = exercise.Instructions;
        const instr = {
            Target: '',
            Weight: '',
            ExtraNotes: '',
        }
        exercise.Instructions = [...instructions, instr];
        day.Exercises = [...day.Exercises.slice(0, exerciseIndex), exercise, ...day.Exercises.slice(exerciseIndex+1, day.length)];
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)]
        })
    }

    removeInstructionFromExercise = async (weekIndex, dayIndex, exerciseIndex, instIndex) => {
        let week = this.state.Weeks[weekIndex].Week;
        let day = week[dayIndex];
        let exercise = day.Exercises[exerciseIndex];
        let instructions = exercise.Instructions;
        instructions.splice(instIndex, 1);
        exercise.Instructions = instructions;
        day.Exercises = [...day.Exercises.slice(0, exerciseIndex), exercise, ...day.Exercises.slice(exerciseIndex+1, day.length)];
        week = [...week.slice(0, dayIndex), day, ...week.slice(dayIndex+1, week.length)];
        const temp = {
            Week: week,
        }
        this.setState({
            Weeks: [...this.state.Weeks.slice(0, weekIndex), temp, ...this.state.Weeks.slice(weekIndex+1, this.state.Weeks.length)]
        })
    }

    handleInstructionChange = async (weekIndex, dayIndex, exerIndex, exerID) => {
        let temp = this.state.InstructionDetails;
        temp = {
            Name: this.state.ExerciseKeys[exerID].Title,
            WeekIndex: weekIndex,
            DayIndex: dayIndex,
            ExerciseIndex: exerIndex,
        }
        this.setState({
            InstructionDetails: temp,
            InstructionModalOpen: true,
        })
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                    <Col xs={23} sm={22}>
                        <Row>
                            <Col xs={24}>
                            <H2 text='Workout Program' />
                            </Col>
                        </Row>
                        <Row gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                            <h3>Title</h3>
                            <Input value={this.state.Title} onChange={(e) => this.handleChange('Title', e.target.value)} placeholder="I.e. HIIT Training"/>
                        </Col>
                        <Col xs={24} sm={6}>
                            <h3>Free Workout Program?</h3>
                            <Select onChange={(value) => this.handleChange('FreeClass', value)} value={this.state.FreeClass} style={{ width: '100%' }}>
                                <Select.Option key={FREE_EVENTS.YES.Key}>{FREE_EVENTS.YES.Value}</Select.Option>
                                <Select.Option key={FREE_EVENTS.NO.Key}>{FREE_EVENTS.NO.Value}</Select.Option>
                            </Select>
                        </Col>
                        {
                            this.state.FreeClass === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={4}>
                            <h3>Price</h3>
                            <InputNumber className='AddLiveClass-Input' min={1} value={this.state.Price} onChange={(value) => this.handleChange('Price', value)} />
                            <p style={{ marginBottom: '0px' }}>Minimum price is $1, unless free </p>
                            </Col>
                            :
                            null
                        }
                        {
                            this.state.FreeClass === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Currency</h3>
                            <Select
                                placeholder="Search for a currency"
                                filterOption={(input, option) =>
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value) => this.handleChange('Currency', value)}
                                showSearch={true}
                                value={this.state.Currency}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(currencies).map((curr) => {
                                            return (
                                                <Select.Option key={curr} name={currencies[curr]}>
                                                <strong>{curr}: </strong>{currencies[curr]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            :
                            null
                        }
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <h3>Description</h3>
                                <Input.TextArea value={this.state.Description} onChange={(e) => this.handleChange("Description", e.target.value)} autoSize={{ minRows: 3}} />
                            </Col>
                        </Row>
                        <Row className='AddLiveClass-Row' gutter={[10,10]}>
                            <Col xs={24} sm={8}>
                                <h3>Intensity Rating</h3>
                                <Select value={this.state.IntensityRating} className='AddLiveClass-Input' onChange={(value) => this.handleChange('IntensityRating', parseInt(value))}>
                                    {
                                        TRAINING_INTENSITY_LEVEL.map(val => {
                                            return <Select.Option key={val}>{val}</Select.Option>
                                        })
                                    }
                                </Select>
                                <p>1: Low Intensity, 5: High Intensity</p>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Level of Experience</h3>
                                <Select value={this.state.Level} className='AddLiveClass-Input' onSelect={(value) => this.handleChange('Level', value)}>
                                    {
                                        Object.keys(TRAINING_EXPERIENCE_LEVEL).map(lvl => {
                                            return <Select.Option key={lvl}>{TRAINING_EXPERIENCE_LEVEL[lvl]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Categories</h3>
                                <Select
                                mode='multiple'
                                style={{ width: '100%' }}
                                value={this.state.Categories}
                                onSelect={(value) => this.handleArrayAdd('Categories', value)}
                                onDeselect={(value) => this.handleArrayDelete('Categories', value)}
                                >
                                    {
                                        FITNESS_CATEGORIES.map((cat) => {
                                            return (
                                                <Select.Option key={cat}>{cat}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                <p>Select Multiple</p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <h2>Workout Program Details</h2>
                            <Col xs={24}>
                            {
                                this.state.Weeks.map((weekObj, index) => {
                                    return (
                                        <Row gutter={[10,10]} style={{ marginTop: '10px', marginBottom: '10px' }} className="AddWorkout-Week">
                                            <Col xs={24}>
                                            <Row align='bottom' justify='space-between'>
                                                <Col>
                                                <H3 highlight text={`Week ${index+1}`} />
                                                </Col>
                                                <Col>
                                                <Button type="primary" danger onClick={() => this.removeWeek(index)}>Remove Week {index+1}</Button>
                                                </Col>
                                            </Row>
                                            </Col>
                                            {
                                                weekObj.Week?.map((day, dayIndex) => {
                                                    return (
                                                        <Col xs={24} className="AddWorkout-Day">
                                                        <Row align='bottom' justify='space-between'>
                                                            <Col>
                                                            <h3>Day {dayIndex+1}</h3>
                                                            </Col>
                                                            <Col>
                                                            <Button type="primary"
                                                            style={{ marginTop: '10px' }}
                                                            danger
                                                            onClick={() => this.handleRemoveDay(index, dayIndex)}
                                                            >
                                                                Remove Day {dayIndex+1} from Week {index+1}
                                                            </Button>
                                                            </Col>
                                                        </Row>
                                                        <p>Notes for the Day:</p>
                                                        <Input
                                                        onChange={(e) => this.handleChangeDayNotes(e.target.value, index, dayIndex)}
                                                        value={day.Notes} placeholder={`Notes for day ${dayIndex+1}`} style={{ marginBottom: '5px' }} />
                                                        <Row>
                                                            <Col xs={24}>
                                                                {
                                                                    day.Exercises.map((exerObj, exerIndex) => {
                                                                        const exerID = exerObj.ID;
                                                                        return (
                                                                            <Row gutter={[10,10]}>
                                                                                <Col xs={24}>
                                                                                    <p>Exercise {exerIndex+1}</p>
                                                                                </Col>
                                                                                <Col xs={24} sm={9}>
                                                                                    {this.state.ExerciseKeys[exerID].Title}, {this.state.ExerciseKeys[exerID].ReferenceName}
                                                                                </Col>
                                                                                <Col xs={24} sm={5}>
                                                                                    <Button block onClick={() => this.handleInstructionChange(index, dayIndex, exerIndex, exerID)}>Add Instructions</Button>
                                                                                </Col>
                                                                                <Col xs={24} sm={5}>
                                                                                    <Button block onClick={() => {
                                                                                        this.handleChange('ViewExerciseID', exerID);
                                                                                        this.changeExerciseModalVisibility();
                                                                                    }}>View Details</Button>
                                                                                </Col>
                                                                                <Col xs={24} sm={5}>
                                                                                    <Button type="primary" danger block
                                                                                    onClick={() => this.removeExerciseFromDay(index, dayIndex, exerIndex)}
                                                                                    >Remove Exercise</Button>
                                                                                </Col>
                                                                                <Divider />
                                                                            </Row>
                                                                        )
                                                                    })
                                                                }
                                                            </Col>
                                                            <Col xs={24}>
                                                            <p>Search & Select an Exercise</p>
                                                            <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.props.name !== null && String(option.props.name).toLowerCase().includes((String(input).toLowerCase()))
                                                            }
                                                            style={{ width: '100%' }} value={day.SelectDay} onChange={(value) => this.handleDaySelectExercise(value, index, dayIndex)}>
                                                                {
                                                                    this.state.Exercises.map((exer) => {
                                                                        return (
                                                                            <Select.Option key={exer.ID} name={`${exer.Title} ${exer.ReferenceName}`}>
                                                                            {exer.Title} - {exer.ReferenceName}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                            <Button
                                                            onClick={() => this.addExerciseToDay(index, dayIndex)}
                                                            style={{ marginTop: '4px', backgroundColor: 'black', color: 'white' }}>
                                                                Add Exercise to Day {dayIndex+1}
                                                            </Button>
                                                            </Col>
                                                        </Row>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            <Col xs={24} sm={6}>
                                            <Button block type="primary" onClick={() => this.handleAddDay(index)}>Add Day to Week {index+1}</Button>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                            </Col>
                            <Col xs={24}>
                                <Button type="primary" onClick={this.handleAddWeek}>Add Week to Workout Program</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }} gutter={[10,10]}>
                            <Col xs={24} sm={12}>
                            <h3>Upload Workout Thumbnail</h3>
                            {
                                this.state.ThumbnailURL !== undefined && this.state.ThumbnailURL !== null && String(this.state.ThumbnailURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                cover={<img src={this.state.ThumbnailURL} alt='Profile' className='Profile-ProfilePicture' />}
                                >
                                <Upload
                                    listType='picture'
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={(info) => this.handleFileUpload('ThumbnailFileList','ThumbnailURL','ThumbnailDisabled','images',info)}
                                    fileList={this.state.ThumbnailFileList}>
                                    <Button icon={<UploadOutlined />}>Replace Thumbnail</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={(info) => this.handleFileUpload('ThumbnailFileList','ThumbnailURL','ThumbnailDisabled','images',info)}
                                    fileList={this.state.ThumbnailFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
                                </Upload>
                            }
                            </Col>
                            <Col xs={24} sm={12}>
                            <h3>Upload Waiver/Liability Form</h3>
                            {
                                this.state.WaiverURL !== undefined && this.state.WaiverURL !== null && String(this.state.WaiverURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                >
                                <Col xs={24} style={{ paddingBottom: '10px' }}>
                                <a target="_blank" href={this.state.WaiverURL} rel="noreferrer"><FileOutlined style={{ fontSize: '25px' }}/>View Uploaded PDF</a>
                                </Col>
                                <Upload
                                    listType='picture'
                                    accept=".pdf"
                                    customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled','forms',info)}
                                    fileList={this.state.WaiverFileList}>
                                    <Button icon={<UploadOutlined />} >Upload Waiver</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".pdf"
                                    customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled','forms',info)}
                                    fileList={this.state.WaiverFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Waiver</Button>
                                </Upload>
                            }
                            </Col>
                        </Row>
                        <Row justify='end' className='AddLiveClass-Save-Buttons' gutter={[10,10]}>
                        <Col xs={0}>
                            {
                                this.state.Published ?
                                <Button block type='primary' danger onClick={this.publishEvent}>Un-Publish Workout</Button>
                                :
                                <Button block className='Button-Yellow' onClick={this.publishEvent}>Publish Workout</Button>
                            }
                        </Col>
                        <Col xs={6}>
                            <Affix offsetBottom={20}>
                            <Button block type='primary' className='Button' onClick={this.saveEvent}>Save Workout</Button>
                            </Affix>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                <Modal footer={null} onCancel={this.changeExerciseModalVisibility} visible={this.state.ExerciseModalOpen}>
                    <h2>{this.state.ExerciseKeys[this.state.ViewExerciseID]?.Title}</h2>
                    <h3>{this.state.ExerciseKeys[this.state.ViewExerciseID]?.ReferenceName}</h3>
                    <h3>Video: </h3>
                    <div>
                        {
                            String(this.state.ExerciseKeys[this.state.ViewExerciseID]?.VideoURL).length > 0 ?
                            <video src={this.state.ExerciseKeys[this.state.ViewExerciseID]?.VideoURL} className="AddWorkout-Video" />
                            :
                            String(this.state.ExerciseKeys[this.state.ViewExerciseID]?.VideoLink).length > 0 ?
                            <iframe width="100%" height="315" src={this.state.ExerciseKeys[this.state.ViewExerciseID].VideoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            :
                            "No explanation video."
                        }
                    </div>
                </Modal>
                <Modal width={650} footer={null} visible={this.state.InstructionModalOpen} onCancel={this.changeInstructionModalVisibility}>
                    <h2>{this.state.InstructionDetails.Name}</h2>
                    {
                        <Row gutter={[10,10]}>
                            <Col xs={0} sm={3}>
                                Set
                            </Col>
                            <Col xs={0} sm={6}>
                                Target
                            </Col>
                            <Col xs={0} sm={6}>
                                Weight
                            </Col>
                            <Col xs={0} sm={6}>
                                Extra Notes
                            </Col>
                            <Col xs={0} sm={3}>
                                Delete
                            </Col>
                        </Row>
                    }
                    {
                        this.state.Weeks[this.state.InstructionDetails.WeekIndex]?.Week[this.state.InstructionDetails.DayIndex]?.Exercises?.[this.state.InstructionDetails.ExerciseIndex]?.Instructions?.map((instruc, instIndex) => {
                            return (
                                <Row gutter={[10,10]} style={{ marginTop: '5px', marginBottom: '5px'}}>
                                    <Col xs={0} sm={3}>
                                        Set {instIndex+1}
                                    </Col>
                                    <Col xs={0} sm={6}>
                                        <Input
                                        value={instruc.Target}
                                        onChange={(e) => this.handleInstructionDetailChange(
                                            this.state.InstructionDetails.WeekIndex,
                                            this.state.InstructionDetails.DayIndex,
                                            this.state.InstructionDetails.ExerciseIndex,
                                            instIndex,
                                            "Target",
                                            e.target.value
                                        )}
                                        placeholder="I.e., 6 reps, 30 seconds, etc."/>
                                    </Col>
                                    <Col xs={0} sm={6}>
                                        <Input
                                        value={instruc.Weight}
                                        onChange={(e) => this.handleInstructionDetailChange(
                                            this.state.InstructionDetails.WeekIndex,
                                            this.state.InstructionDetails.DayIndex,
                                            this.state.InstructionDetails.ExerciseIndex,
                                            instIndex,
                                            "Weight",
                                            e.target.value
                                        )}
                                        placeholder="I.e., 65%, 120 lbs, 20 kg, etc.," />
                                    </Col>
                                    <Col xs={0} sm={6}>
                                        <Input
                                        value={instruc.ExtraNotes}
                                        onChange={(e) => this.handleInstructionDetailChange(
                                            this.state.InstructionDetails.WeekIndex,
                                            this.state.InstructionDetails.DayIndex,
                                            this.state.InstructionDetails.ExerciseIndex,
                                            instIndex,
                                            "ExtraNotes",
                                            e.target.value
                                        )}
                                        placeholder="" />
                                    </Col>
                                    <Col xs={0} sm={3}>
                                        <Button
                                        onClick={() => this.removeInstructionFromExercise(
                                            this.state.InstructionDetails.WeekIndex,
                                            this.state.InstructionDetails.DayIndex,
                                            this.state.InstructionDetails.ExerciseIndex,
                                            instIndex,
                                        )}
                                        danger type='primary' block icon={<DeleteFilled />}></Button>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={8}>
                        <Button block
                        type='primary'
                        onClick={() => this.addInstructionToExercise(this.state.InstructionDetails.WeekIndex, this.state.InstructionDetails.DayIndex, this.state.InstructionDetails.ExerciseIndex)}
                        >Add Exercise Instruction</Button>
                        </Col>
                    </Row>
                </Modal>
                </Layout.Content>
            </Layout>
        )
    }
}

export default AddWorkout;