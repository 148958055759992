import React from 'react';
import './SectionOrder.css';
import { Row, Col } from 'antd';
import { WEBSITE_SECTIONS } from '../../../constants';

class SectionOrder extends React.Component {
    render() {
        return (
            <Row>
                <Col xs={24}>
                    <Row gutter={[30,30]} align="stretch">
                        <Col xs={24}>
                            <h2>Select the Order for your Sections</h2>
                            <p>The order is how these sections will appear on your website. Click the tiles in the order that you want them on your website. Check the preview below for more details.</p>
                        </Col>
                    {
                        Object.keys(this.props.SelectedSections).map((key) => {
                            if (!this.props.SelectedSections[key]) return null;
                            let index = this.props.Order.findIndex((val) => val === WEBSITE_SECTIONS[key].VALUE);
                            if (index === -1) {
                                return (
                                    <Col xs={24} sm={6}>
                                        <div onClick={() => {
                                            let order = [ ...this.props.Order ];
                                            order.push(key);
                                            this.props.handleChange("Order", order);
                                        }} className="SectionOrder-Selection">
                                            {WEBSITE_SECTIONS[key]?.NAME}
                                        </div>
                                    </Col>
                                )
                            } else {
                                return (
                                    <Col xs={24} sm={6}>
                                        <div
                                        onClick={() => {
                                            let order = [ ...this.props.Order ];
                                            let index = order.findIndex((val) => val === key);
                                            if (index !== -1) {
                                                order.splice(index, 1);
                                            }
                                            this.props.handleChange("Order", order);
                                        }}
                                        className="SectionOrder-Selection-Selected">
                                            {index+1}: {WEBSITE_SECTIONS[key]?.NAME}
                                        </div>
                                    </Col>
                                )
                            }
                        })
                    }
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default SectionOrder;