import React from 'react';
import { Row, Col, Layout, Input, Button, Upload, Card, Affix, message, notification } from 'antd';
import { SideNav } from '../../Components';
import { H2 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { UploadOutlined } from '@ant-design/icons';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import axios from 'axios';
import Checkbox from 'antd/lib/checkbox/Checkbox';

class AddExercise extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Title: '',
            ReferenceName: '',
            Description: '',
            Instruction: [{
                Target: '',
                Weight: '',
                ExtraNotes: '',
            }],
            Equipment: '',
            VideoLink: '',

            VideoFileList: [],
            VideoURL: '',
            VideoDisabled: false,

            WaiverFileList: [],
            WaiverURL: '',
            WaiverDisabled: false,

            ThumbnailFileList: [],
            ThumbnailURL: '',
            ThumbnailDisabled: false,

            PublishToLibrary: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.ID !== this.props.match.params.ID) {
            this.setState({
                Title: '',
                ReferenceName: '',
                Description: '',
                Instruction: [{
                    Target: '',
                    Weight: '',
                    ExtraNotes: '',
                }],
                Equipment: '',
                VideoLink: '',

                VideoFileList: [],
                VideoURL: '',
                VideoDisabled: false,

                ThumbnailFileList: [],
                ThumbnailURL: '',
                ThumbnailDisabled: false,
                PublishToLibrary: false,
            });
            this.getData();
        }
    }

    getData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            ID: this.props.match.params.ID,
        }
        const exerciseData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/exercise/get-exercise-with-id`, payload, config);
        if (exerciseData.data.error) return;
        this.setState({
            Title: exerciseData.data.Title,
            ReferenceName: exerciseData.data.ReferenceName,
            Description: exerciseData.data.Description,
            Instruction: exerciseData.data.Instruction,
            Equipment: exerciseData.data.Equipment,
            VideoLink: exerciseData.data.VideoLink,
            VideoURL: exerciseData.data.VideoURL,
            ThumbnailURL: exerciseData.data.ThumbnailURL,
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleInstructionAdd = () => {
        const instruction = {
            Target: '',
            Weight: '',
            ExtraNotes: '',
        }
        this.setState({
            Instruction: [...this.state.Instruction, instruction]
        })
    }

    handleInstructionChange = (index, name, value) => {
        let temp = this.state.Instruction[index];
        temp[name] = value;
        this.setState({
            Instruction: [...this.state.Instruction.slice(0, index), temp, ...this.state.Instruction.slice(index+1, this.state.Instruction.length)]
        })
    }

    handleInstructionDelete = (index) => {
        let temp = [...this.state.Instruction];
        temp.splice(index, 1);
        this.setState({
            Instruction: temp,
        });
    }

    handleFileUpload = async (fileListName, urlName, buttonName, fileType, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/${fileType}/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                message.error('Something went wrong, please contact our team.');
                console.log(err);
            }, () => {
                storage.ref(`${fileType}/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            // Send URL to backend to delete
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        console.log(e);
                        message.error('Failed to delete the file.');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    setDuration = (duration) => {
        this.setState({
            Duration: duration,
        })
    }

    saveEvent = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            Title: this.state.Title,
            ReferenceName: this.state.ReferenceName,
            Description: this.state.Description,
            Instruction: this.state.Instruction,
            Equipment: this.state.Equipment,
            VideoLink: this.state.VideoLink,
            VideoURL: this.state.VideoURL,
            ThumbnailURL: this.state.ThumbnailURL,
            PublishToLibrary: this.state.PublishToLibrary,
        }
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/exercise/save-exercise`, data, config);
        if (save.data.error) {
            notification.error({
                message: 'Failed to save the Exercise',
                description: 'Something went wrong when uploading the exercise, please contact a member of our at support@phros.ca',
                duration: null
            })
        } else {
            notification.success({
                message: 'Success',
                description: `Successfully added the exercise: ${this.state.Title}`
            })
        }
    }

    render() {
        const { currentUser } = this.context;
        const uid = currentUser.uid;
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='AddLiveClass-Row'>
                    <Col xs={23} sm={22}>
                        <Row>
                            <Col xs={24}>
                            <H2 text='Exercise' />
                            </Col>
                        </Row>
                        <Row gutter={[10,10]} style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col xs={24}>
                            <h3>Title</h3>
                            <Input value={this.state.Title} onChange={(e) => this.handleChange('Title', e.target.value)} placeholder="I.e. Bench Press"/>
                        </Col>
                        <Col xs={24}>
                            <h3>Reference Name</h3>
                            <p>(Optional) A "Reference Name" make it easier to build workouts</p>
                            <Input value={this.state.ReferenceName} onChange={(e) => this.handleChange('ReferenceName', e.target.value)} placeholder="(I.e. Bench Press Exercise for Bodybuilding, Bench Press for Client Josh, etc.)"/>
                        </Col>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <h3>Description</h3>
                                <p>(Optional) Give your clients a detailed description of the exercise.</p>
                                <Input.TextArea value={this.state.Description} onChange={(e) => this.handleChange("Description", e.target.value)} autoSize={{ minRows: 2}} placeholder="Give your chest a killer burn! Lay flat on a bench and place your grip on the bar a bit wider than shoulder width apart." />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col xs={24}>
                                <h3>Video Link (optional)</h3>
                                <p>Please use the embed URL for the video.</p>
                            </Col>
                            <Col xs={24}>
                                <Input value={this.state.VideoLink} placeholder="I.e. YouTube Link" onChange={(e) => this.handleChange("VideoLink", e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col xs={24}>
                            <h3>Upload Exercise Video</h3>
                            {
                                this.state.VideoURL !== undefined && this.state.VideoURL !== null && String(this.state.VideoURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                >
                                <Col xs={24} style={{ paddingBottom: '10px' }}>
                                <video width="100%" height="400" controls>
                                    <source src={this.state.VideoURL} />
                                </video>
                                </Col>
                                <Upload
                                    listType='picture'
                                    accept=".mp4"
                                    customRequest={(info) => this.handleFileUpload('VideoFileList','VideoURL','VideoDisabled','videos',info)}
                                    fileList={this.state.VideoFileList}>
                                    <Button icon={<UploadOutlined />}>Replace Video</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".mp4"
                                    customRequest={(info) => this.handleFileUpload('VideoFileList','VideoURL','VideoDisabled','videos',info)}
                                    fileList={this.state.VideoFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Exercise Video</Button>
                                </Upload>
                            }
                            <p>When uploading, please wait for your video to finish before saving or exiting the page.</p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }} gutter={[10,10]}>
                            <Col xs={24} sm={12}>
                            <h3>Upload Exercise Image</h3>
                            {
                                this.state.ThumbnailURL !== undefined && this.state.ThumbnailURL !== null && String(this.state.ThumbnailURL).length > 0?
                                <Card
                                hoverable
                                style={{ width: '100%' }}
                                cover={<img src={this.state.ThumbnailURL} alt='Profile' className='Profile-ProfilePicture' />}
                                >
                                <Upload
                                    listType='picture'
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={(info) => this.handleFileUpload('ThumbnailFileList','ThumbnailURL','ThumbnailDisabled','images',info)}
                                    fileList={this.state.ThumbnailFileList}>
                                    <Button icon={<UploadOutlined />}>Replace Exercise Video Thumbnail</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    listType='picture'
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={(info) => this.handleFileUpload('ThumbnailFileList','ThumbnailURL','ThumbnailDisabled','images',info)}
                                    fileList={this.state.ThumbnailFileList}>
                                    <Button icon={<UploadOutlined />}>Upload Video Thumbnail</Button>
                                </Upload>
                            }
                            </Col>
                        </Row>
                        {
                            uid === "V5H7QQPrEcbo6KpFuqALbFMJSaH3" ?
                            <Checkbox checked={this.state.PublishToLibrary} onChange={(e) => this.handleChange("PublishToLibrary", e.target.checked)}>Publish to Library</Checkbox>
                            :
                            null
                        }
                        <Row justify='end' className='AddLiveClass-Save-Buttons' gutter={[10,10]}>
                        <Col xs={6}>
                            <Affix offsetBottom={20}>
                            <Button block type='primary' className='Button' onClick={this.saveEvent}>Save Exercise</Button>
                            </Affix>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default AddExercise;