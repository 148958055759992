import React from 'react';
import { H2, H3 } from '../../Common';
import { SideNav } from '../../Components';
import { Row, Col, Layout, message, Button, Input, Affix, Card, Upload, notification, Progress } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { AuthContext } from '../../Context/AuthContext';
import axios from 'axios';
import { checkArray, checkString, } from '../../Verification';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import './Aumthenticus.css';

class Aumthenticus extends React.Component {
    static contextType = AuthContext;
    state = {
        loading: true,
        pages: [],
        featureCards: [],
        reviews: [],
        landingVideo: "",
        contactVideo: "",
        AboutPicture: null,
        UploadDisabled: false,
        UploadFileList: [],
    }
    componentDidMount() {
        this.getProfile();
    }

    saveProfileInformation = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            Pages: this.state.pages,
            FeatureCards: this.state.featureCards,
            Reviews: this.state.reviews,
            LandingVideo: this.state.landingVideo,
            ContactVideo: this.state.contactVideo,
        };
        const save = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/custom-website/save-custom-website`, data, config);
        if (save.data.error) {
            notification.error({
                message: "Error",
                description: "Failed to save the page information, please contact us at support@phros.ca"
            })
        } else {
            notification.success({
                message: "Success",
                description: "Successfully saved the custom website changes."
            })
        }
    }

    getProfile = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        if (!currentUser.uid) {
            message.error('Failed to get user details.')
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/custom-website/get-custom-website-data-token`, {}, config);
        this.setState({
            loading: false,
            pages: checkArray(response.data.Pages),
            featureCards: checkArray(response.data.FeatureCards),
            reviews: checkArray(response.data.Reviews),
            landingVideo: checkString(response.data.LandingVideo),
            contactVideo: checkString(response.data.ContactVideo),
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    addToArray = (arrayName, data) => {
        this.setState({
            [arrayName]: [...this.state[arrayName], data],
        })
    }
    removeFromArray = (arrayName, index) => {
        const temp = this.state[arrayName];
        temp.splice(index, 1);
        this.setState({
            [arrayName]: temp
        });
    }
    modifyArrayObjValue = (arrayName, valueName, index, value) => {
        console.log({ arrayName, valueName, index, value });
        const val = this.state[arrayName][index];
        val[valueName] = value;
        this.setState({
            [arrayName]: [...this.state[arrayName].slice(0, index), val, ...this.state[arrayName].slice(index+1, this.state[arrayName].length)]
        })
    }

    handleImageUpload = async (fileListName, buttonName, info) => {
        notification.open({
            key: "Progress-Upload",
            message: "Uploading File",
            description: (
                <Row>
                    <Col xs={24}>
                        Status:
                    </Col>
                    <Col xs={24}>
                        <Progress percent={0} status="active" />
                    </Col>
                </Row>
            )
        })
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/images/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
                notification.open({
                    key: "Progress-Upload",
                    message: "Uploading File",
                    description: (
                        <Row>
                            <Col xs={24}>
                                Status:
                            </Col>
                            <Col xs={24}>
                                <Progress percent={Math.ceil(snapshot.bytesTransferred/snapshot.totalBytes*100)} />
                            </Col>
                        </Row>
                    )
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`images/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    resolve(firebaseUrl);
                })
            })
        });
        return url;
    }

    deleteFile = async (url) => {
        try {
            const previousLogo = storage.refFromURL(url);
            const value = await previousLogo.delete();
            if (value) return true;
            return false;
        } catch (e) {
            message.error('Failed to delete file.');
            return false;
        }
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='ReviewWebsite-Row'>
                <Col xs={23} sm={22}>
                <H2 text='Edit your Custom Website' />
                <Row className="Profile-Row" gutter={[20,20]}>
                    <Col xs={24}>
                    <h3>Landing Page Video</h3>
                    </Col>
                    <Col xs={24}>
                    {
                        this.state.landingVideo !== undefined && this.state.landingVideo !== null  && String(this.state.landingVideo).length > 0?
                        <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<video className="Aumthenticus-VideoBanner" src={this.state.landingVideo} />}
                        >
                        <Upload
                        fileList={[]}
                        accept=".mp4"
                        customRequest={async (info) => {
                            const currentVideo = this.state.landingVideo;
                            const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                            this.handleChange("landingVideo", url);
                            await this.deleteFile(currentVideo)
                        }}>
                            <Button icon={<UploadOutlined />}>Replace Video</Button>
                        </Upload>
                        </Card>
                        :
                        <Upload
                            fileList={[]}
                            accept=".mp4"
                            customRequest={async (info) => {
                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                this.handleChange("landingVideo", url);
                            }}>
                            <Button icon={<UploadOutlined />}>Upload Video</Button>
                        </Upload>
                    }
                    </Col>
                </Row>
                <Row className="Profile-Row" gutter={[20,20]}>
                    <Col xs={24}>
                    <h3>Contact Page Video</h3>
                    </Col>
                    <Col xs={24}>
                    {
                        this.state.contactVideo !== undefined && this.state.contactVideo !== null  && String(this.state.contactVideo).length > 0?
                        <Card
                        hoverable
                        style={{ width: "100%" }}
                        cover={<video className="Aumthenticus-VideoBanner" src={this.state.contactVideo} />}
                        >
                        <Upload
                        fileList={[]}
                        accept=".mp4"
                        customRequest={async (info) => {
                            const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                            await this.deleteFile(this.state.landingVideo)
                            this.handleChange("contactVideo", url);
                        }}>
                            <Button icon={<UploadOutlined />}>Replace Video</Button>
                        </Upload>
                        </Card>
                        :
                        <Upload
                            fileList={[]}
                            accept=".mp4"
                            customRequest={async (info) => {
                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                this.handleChange("contactVideo", url);
                            }}>
                            <Button icon={<UploadOutlined />}>Upload Video</Button>
                        </Upload>
                    }
                    </Col>
                </Row>
                <Row className="Profile-Row" gutter={[20,20]}>
                    <Col xs={24}>
                        <h3>Feature Cards (Specials)</h3>
                        {
                            checkArray(this.state.featureCards).map((feature, index) => (
                                <Row gutter={[20,20]}>
                                    <Col xs={24}>
                                        <h4>Feature Card Image</h4>
                                        {
                                            feature.Image !== undefined && feature.Image !== null  && String(feature.Image).length > 0?
                                            <Card
                                            style={{ width: "400px" }}
                                            cover={<img className="Aumthenticus-Image" src={feature.Image} alt="" />}
                                            >
                                            <Upload
                                            fileList={[]}
                                            accept=".png,.jpeg,.jpg"
                                            customRequest={async (info) => {
                                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                await this.deleteFile(feature.Image)
                                                this.modifyArrayObjValue("featureCards", "Image", index, url);
                                            }}>
                                                <Button icon={<UploadOutlined />}>Replace Image</Button>
                                            </Upload>
                                            </Card>
                                            :
                                            <Upload
                                                fileList={[]}
                                                accept=".png,.jpeg,.jpg"
                                                customRequest={async (info) => {
                                                    const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                    this.modifyArrayObjValue("featureCards", "Image", index, url);
                                                }}>
                                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                                            </Upload>
                                        }
                                    </Col>
                                    <Col xs={8}>
                                        Title
                                        <Input value={feature.Title} placeholder="Title"
                                            onChange={(e) => {
                                                this.modifyArrayObjValue("featureCards", "Title", index, e.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={8}>
                                        Description
                                        <Input value={feature.Description} placeholder="Description"
                                            onChange={(e) => {
                                                this.modifyArrayObjValue("featureCards", "Description", index, e.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={8}>
                                        Subtitle
                                        <Input value={feature.Subtitle} placeholder="Subtitle"
                                            onChange={(e) => {
                                                this.modifyArrayObjValue("featureCards", "Subtitle", index, e.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <Button
                                        type="primary"
                                        danger
                                        icon={<DeleteOutlined />} onClick={() => {
                                            this.removeFromArray("featureCards", index)
                                        }}>
                                            Delete Feature Card
                                        </Button>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Col>
                    <Col xs={24}>
                        <Button onClick={() => this.addToArray("featureCards", {
                            Image: "",
                            Title: "",
                            Description: "",
                            Subtitle: "",
                        })}>
                            Add Feature Card (Special)
                        </Button>
                    </Col>
                </Row>
                <Row className="Profile-Row" gutter={[20,20]}>
                    <Col xs={24}>
                        <h3>Reviews</h3>
                    </Col>
                    {
                        checkArray(this.state.reviews).map((review, index) => (
                            <Row gutter={[20,20]}>
                                <Col xs={24}>
                                    <h4>Review Image</h4>
                                    {
                                        review.Image !== undefined && review.Image !== null  && String(review.Image).length > 0?
                                        <Card
                                        style={{ width: '400px' }}
                                        hoverable
                                        cover={<img className="Aumthenticus-Image" src={review.Image} alt="" />}
                                        >
                                        <Upload
                                        fileList={[]}
                                        accept=".png,.jpeg,.jpg"
                                        customRequest={async (info) => {
                                            const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                            await this.deleteFile(review.Image)
                                            this.modifyArrayObjValue("reviews", "Image", index, url);
                                        }}>
                                            <Button icon={<UploadOutlined />}>Replace Image</Button>
                                        </Upload>
                                        </Card>
                                        :
                                        <Upload
                                            fileList={[]}
                                            accept=".png,.jpeg,.jpg"
                                            customRequest={async (info) => {
                                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                this.modifyArrayObjValue("reviews", "Image", index, url);
                                            }}>
                                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                                        </Upload>
                                    }
                                </Col>
                                <Col xs={24} sm={24}>
                                    Image Alt Text
                                    <Input placeholder="Name" value={review.Alt} onChange={(e) => {
                                        this.modifyArrayObjValue("reviews", "Alt", index, e.target.value);
                                    }} />
                                </Col>
                                <Col xs={24} sm={8}>
                                    Customer Name (Reviewer Name)
                                    <Input placeholder="Name" value={review.Reviewer} onChange={(e) => {
                                        this.modifyArrayObjValue("reviews", "Reviewer", index, e.target.value);
                                    }} />
                                </Col>
                                <Col xs={24} sm={16}>
                                    Review Description
                                    <Input placeholder="Name" value={review.Description} onChange={(e) => {
                                        this.modifyArrayObjValue("reviews", "Description", index, e.target.value);
                                    }} />
                                </Col>
                                <Col xs={24}>
                                    <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />} onClick={() => {
                                        this.removeFromArray("reviews", index)
                                    }}>
                                        Delete Review
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }

                    <Col xs={24}>
                        <Button onClick={() => {
                            const value = {
                                Image: "",
                                Alt: "",
                                Reviewer: "",
                                Description: "",
                            }
                            this.addToArray("reviews", value);
                        }}>Add Customer Review</Button>
                    </Col>
                </Row>
                <Row className="Profile-Row" gutter={[20,20]}>
                    <Col xs={24}>
                        <h3>Pages</h3>
                    </Col>
                    {
                        checkArray(this.state.pages).map((page, index) => (
                            <Col xs={24}>
                                <H3 text={`Page ${index+1}`} />
                                <Row gutter={[20,20]}>
                                    <Col xs={24}>
                                        Video Banner
                                        <p>This video is displayed at the top of the page (MP4 files only!).</p>
                                        {
                                            page.VideoBanner !== undefined && page.VideoBanner !== null  && String(page.VideoBanner).length > 0?
                                            <Card
                                            hoverable
                                            style={{ width: "100%" }}
                                            cover={<video className="Aumthenticus-VideoBanner" src={page.VideoBanner} />}
                                            >
                                            <Upload
                                            fileList={[]}
                                            accept=".mp4"
                                            customRequest={async (info) => {
                                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                await this.deleteFile(page.VideoBanner)
                                                this.modifyArrayObjValue("pages", "VideoBanner", index, url);
                                            }}>
                                                <Button icon={<UploadOutlined />}>Replace Video</Button>
                                            </Upload>
                                            </Card>
                                            :
                                            <Upload
                                                fileList={[]}
                                                accept=".mp4"
                                                customRequest={async (info) => {
                                                    const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                    this.modifyArrayObjValue("pages", "VideoBanner", index, url);
                                                }}>
                                                <Button icon={<UploadOutlined />}>Upload Video</Button>
                                            </Upload>
                                        }
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        URL Link
                                        <Input placeholder="about-me" value={page.URL} onChange={(e) => this.modifyArrayObjValue("pages", "URL", index, e.target.value)}/>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        Navigation Text
                                        <Input placeholder="About Me" value={page.NavText} onChange={(e) => this.modifyArrayObjValue("pages", "NavText", index, e.target.value)}/>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        Page Title
                                        <Input placeholder="About Me" value={page.Title} onChange={(e) => this.modifyArrayObjValue("pages", "Title", index, e.target.value)}/>
                                    </Col>
                                    <Col xs={24} style={{ backgroundColor: 'white' }}>
                                        Page Text
                                        <ReactQuill
                                            value={page.Text}
                                            onChange={(content) => this.modifyArrayObjValue("pages", "Text", index, content)}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <p>Page Gallery</p>
                                        <Row gutter={[20,20]} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        {
                                            checkArray(page.Gallery).map((pic, galleryIndex) => (
                                                    <Col xs={24} sm={8}>
                                                        <img className="Aumthenticus-Page-Gallery-Image" src={pic.SRC} alt={pic.ALT} />
                                                        <Input placeholder="Image ALT Text" value={pic.ALT} onChange={(e) => {
                                                            const temp = [...page.Gallery];
                                                            const value = {
                                                                SRC: pic.SRC,
                                                                ALT: e.target.value,
                                                            }
                                                            const pics = [...temp.slice(0, galleryIndex), value, ...temp.slice(galleryIndex+1, temp.length)];
                                                            this.modifyArrayObjValue("pages", "Gallery", index, pics);
                                                        }} />
                                                        <Button
                                                        block
                                                        type="primary"
                                                        danger
                                                        onClick={async () => {
                                                            const temp = [...page.Gallery];
                                                            const url = temp[index];
                                                            temp.splice(galleryIndex, 1);
                                                            this.modifyArrayObjValue("pages", "Gallery", index, temp);
                                                            this.deleteFile(url.SRC);
                                                        }} icon={<DeleteOutlined />} />
                                                    </Col>
                                            ))
                                        }
                                        </Row>
                                        <Upload
                                            fileList={[]}
                                            accept=".png,.jpeg,.jpg"
                                            customRequest={async (info) => {
                                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                const value = {
                                                    SRC: url,
                                                    ALT: "",
                                                }
                                                const pictures = [...page.Gallery, value];
                                                this.modifyArrayObjValue("pages", "Gallery", index, pictures);
                                            }}>
                                            <Button icon={<UploadOutlined />}>Upload Image for Gallery</Button>
                                        </Upload>
                                    </Col>
                                    <Col xs={24}>
                                        End of Page Video
                                        <p>This video is displayed at the end of the page (MP4 files only!).</p>
                                        {
                                            page.VideoBanner2 !== undefined && page.VideoBanner2 !== null  && String(page.VideoBanner2).length > 0?
                                            <Card
                                            hoverable
                                            style={{ width: "100%" }}
                                            cover={<video className="Aumthenticus-VideoBanner" src={page.VideoBanner2} />}
                                            >
                                            <Upload
                                            fileList={[]}
                                            accept=".mp4"
                                            customRequest={async (info) => {
                                                const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                await this.deleteFile(page.VideoBanner2)
                                                this.modifyArrayObjValue("pages", "VideoBanner2", index, url);
                                            }}>
                                                <Button icon={<UploadOutlined />}>Replace Video</Button>
                                            </Upload>
                                            <Button type="primary" danger onClick={async () => {
                                                await this.deleteFile(page.VideoBanner2)
                                                this.modifyArrayObjValue("pages", "VideoBanner2", index, "");
                                            }}>Delete Video</Button>
                                            </Card>
                                            :
                                            <Upload
                                                fileList={[]}
                                                accept=".mp4"
                                                customRequest={async (info) => {
                                                    const url = await this.handleImageUpload('UploadFileList','UploadDisabled',info);
                                                    this.modifyArrayObjValue("pages", "VideoBanner2", index, url);
                                                }}>
                                                <Button icon={<UploadOutlined />}>Upload Video</Button>
                                            </Upload>
                                        }
                                    </Col>
                                    <Col xs={24}>
                                        <Button type="primary" danger onClick={() => this.removeFromArray("pages", index)}>Delete Page</Button>
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }

                    <Col xs={24}>
                        <Button onClick={() => {
                            const pageData = {
                                VideoBanner: "",
                                URL: "",
                                NavText: "",
                                Title: "",
                                Text: "",
                                Gallery: [],
                            };
                            const pages = [...this.state.pages, pageData];
                            this.handleChange("pages", pages);
                        }}>Add Page</Button>
                    </Col>
                </Row>

                <Row className='Profile-Row' justify='end'>
                    <Col xs={24} sm={8}>
                        <Affix offsetBottom={20}>
                        <Button type='primary' size='large' className='Button' onClick={this.saveProfileInformation} block >Save Website</Button>
                        </Affix>
                    </Col>
                </Row>
                </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default Aumthenticus;