import React, { useContext, useState, useEffect } from 'react';
import { ENVIRONMENT } from '../constants';
import axios from 'axios';
import { message, Spin, Row, Col } from 'antd';
import { AuthContext } from './AuthContext';

export const DataContext = React.createContext();

export function useAuth() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {

    const [profile, setProfileData] = useState(false);
    const [awsInfo, setAWSData] = useState(false);
    const [loading, setLoading] = useState(true);
    const auth = useContext(AuthContext);

    useEffect(() => {
        // Get the Profile Data
        const getData = async () => {
            try {
                const { currentUser } = auth;
                if (currentUser !== null) {
                    const payload = {
                        uid: currentUser.uid,
                    };
                    const profile = await axios.post(`${ENVIRONMENT.URL}/profile/get-profile-with-uid`, payload);


                    /*
                    const token = await currentUser.getIdToken(true);
                    const config = {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    };
                    */

                    let awsDetails = { data: {} };
                    /*
                    try {
                        awsDetails = await axios.post(`${ENVIRONMENT.WEB_API}/aws-details/get-aws-details`, {}, config);
                    } catch (e) {
                        console.log(e);
                    }
                    */

                    if (profile.data.error) {
                        return message.error('Something went wrong :( please reload the page.');
                    }
                    setProfileData(profile.data);
                    setAWSData(awsDetails.data);
                }
            } catch(e) {
                console.log(e);
            }
        }
        getData().then(() => {
            setLoading(false);
        });
    }, [auth]);

    const updateValues = async (currentUser = null) => {
        if (currentUser === null) {
            const user = auth.currentUser;
            currentUser = user;
        }
        if (currentUser !== null && currentUser !== undefined) {
            const payload = {
                uid: currentUser.uid,
            };
            const profile = await axios.post(`${ENVIRONMENT.URL}/profile/get-profile-with-uid`, payload);
            let awsDetails = { data: {} };
            /*
            try {
                awsDetails = await axios.post(`${ENVIRONMENT.WEB_API}/aws-details/get-aws-details`, {}, config);
            } catch (e) {
                console.log(e);
            }
            */
            setProfileData(profile.data);
            setAWSData(awsDetails.data);
        }
    }

    const value = {
        profile,
        awsInfo,
        updateValues,
        loading,
    };

    return (
        <DataContext.Provider value={value}>
            {
                loading ? // TODO CHANGE BACK TO loading not !loading
                <Row justify='center' align='middle' style={{ height: '100vh' }}>
                    <Col>
                        <Spin size='large' />
                    </Col>
                </Row>
                :
                children
            }
        </DataContext.Provider>
    )
}

