import React from 'react';
import { AutoComplete } from 'antd';
import Script from 'react-load-script';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

class MapSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            latitude: 0,
            longitude: 0,
        }
    }

    handleScriptLoad = () => {
          // Initialize Google Autocomplete
          // eslint-disable-next-line no-unused-vars
          /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new this.props.google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
        );
        this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }

    handlePlaceSelect = () => {
        const addressObject = this.autocomplete.getPlace();
        let latitude = 0;
        let longitude = 0;
        try {
            latitude = addressObject.geometry.location.lat();
            longitude = addressObject.geometry.location.lng();
        } catch (e) {
            console.log("Failed to get the longitude and latitude.")
        }
        const address = addressObject.address_components;
        if (address) {
            this.props.handleChange(addressObject.formatted_address, latitude, longitude, this.props.index);
          this.setState(
            {
              query: addressObject.formatted_address,
              latitude: latitude,
              longitude: longitude,
            }
          );
        }
      }

    handleChange= (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    render() {
        return (
            <div>
              <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBirukPQbIxRTV1Ip1-S1GrMzq8DcEzKNk"}&libraries=places`}
                onLoad={this.handleScriptLoad}
              />
              <AutoComplete style={{ width: "100%", height: "50px" }} id="autocomplete" value={this.state.query} onChange={(value) => this.handleChange("query", value)} />
              <Map
                initialCenter={{
                    lat: this.props.latitude,
                    lng: this.props.longitude,
                }}
                center={{
                    lat: this.props.latitude,
                    lng: this.props.longitude
                }}
                style={{ height: "400px" }} google={this.props.google} zoom={14}>
                <Marker
                name={this.props.query}
                title={this.props.query}
                position={{lat: this.props.latitude, lng: this.props.longitude}}
                />
                <InfoWindow>
                    <div>
                    <h1>{this.props.query}</h1>
                    </div>
                </InfoWindow>
                </Map>
            </div>
          );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyBirukPQbIxRTV1Ip1-S1GrMzq8DcEzKNk"
  })(MapSearch);