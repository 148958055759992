import { Default1 } from "../../phros-web-components/Default/Default";

const DefaultEdit = ({ data = Default1.Default(), editComponent = () => {} }) => {
    return (
        <div>
            <p>Loading ...</p>
        </div>
    )
};

export {
    DefaultEdit,
}