import React from 'react';
import { Row, Col, Input, Button } from 'antd';
import { checkArray } from '../../../Verification';
import { DeleteOutlined } from '@ant-design/icons';

class FAQ extends React.Component {
    render() {
        return (
            <Row gutter={[10,10]}>
                <h3>FAQ Section Information</h3>
                {
                    checkArray(this.props.FAQ).map((faq, index) => (
                        <Col xs={24}>
                            <Row gutter={[10,10]} align="bottom">
                                <Col xs={24} sm={10}>
                                <p>FAQ Question</p>
                                <Input
                                value={faq.Question} onChange={(e) => {
                                    let faq = [ ...this.props.FAQ ];
                                    faq[index].Question = e.target.value;
                                    this.props.handleChange("FAQ", faq);
                                }} />
                                </Col>
                                <Col xs={24} sm={12}>
                                <p>FAQ Answer</p>
                                <Input
                                value={faq.Answer} onChange={(e) => {
                                    let faq = [ ...this.props.FAQ ];
                                    faq[index].Answer = e.target.value;
                                    this.props.handleChange("FAQ", faq);
                                }} />
                                </Col>
                                <Col xs={24} sm={2}>
                                    <Button
                                    block
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        let faq = [ ...this.props.FAQ ];
                                        faq.splice(index, 1);
                                        this.props.handleChange("FAQ", faq);
                                    }}
                                    icon={<DeleteOutlined />} />
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
                <Col xs={24} sm={6}>
                    <Button
                    type="primary"
                    onClick={() => {
                        let faq = [ ...this.props.FAQ ];
                        let tempFaq = {
                            Question: "",
                            Answer: "",
                        }
                        faq.push(tempFaq);
                        this.props.handleChange("FAQ", faq);
                    }}>Add FAQ</Button>
                </Col>
            </Row>
        )
    }
}

export default FAQ;