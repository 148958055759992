import React, { useState } from "react";
import { QuestionAnswer } from "../../TemplateData";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "./FAQ1.css";


class Question {
    constructor(question, answer) {
        this.Question = question;
        this.Answer = answer;
    }
    static Default() {
        return new Question(QuestionAnswer.Question, QuestionAnswer.Answer);
    }
}

class FAQ1 extends Component {
    ID = IDS.FAQ1;
    FAQS = [];
    constructor(faqs) {
        super();
        this.FAQS = faqs;
    }
    static Default() {
        const one = Question.Default();
        const two = new Question("How do I use this FAQ?", "Hover over this element and click the edit icon to add your FAQ details.")
        return new FAQ1([one, two]);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new FAQ1(obj.FAQS);
    }
}

const FAQ1View = ({ data = FAQ1.Default() }) => {
    const [faqMap, setFaqMap] = useState({});

    const handleOpenFAQ = (key) => {
        if (key in faqMap) {
            setFaqMap((prevState) => {
                return {
                    ...prevState,
                    [key]: !prevState[key],
                }
            })
        } else {
            setFaqMap((prevState) => {
                return {
                    ...prevState,
                    [key]: true,
                }
            })
        }
    }

    return (
        <div className="FAQ1--Wrapper">
        <div className="FAQ1--Container">
            {
                data.FAQS.map((faq, index) => {
                    const question = new Question(faq.Question || "Question?", faq.Answer || "Answer.");
                    const key = `${faq.Question}_${index}`;
                    return (
                        <div className="FAQ1--Row" key={key} onClick={() => handleOpenFAQ(key)}>
                            <div className="FAQ1--FAQ__Box">
                                <div className="FAQ1--FAQ__Question">
                                    {question.Question}
                                </div>
                                {
                                    faqMap[key] ?
                                    <div className="FAQ1--FAQ__Answer">
                                        {question.Answer}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
        </div>
    )
}


export {
    FAQ1,
    Question,
    FAQ1View
}