import React from 'react';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import { Row, Col, Layout, Spin, message } from 'antd';
import { AuthContext } from '../../Context/AuthContext';
import './ReviewWebsite.css';
import axios from 'axios';

class ReviewWebsite extends React.Component {
    static contextType = AuthContext;
    state = {
        paid: false,
        loading: true,
        profile: null,
    }
    componentDidMount() {
        this.getProfile();
    }

    getProfile = async () => {
        const { currentUser } = this.context;
        if (!currentUser.uid) {
            message.error('Failed to get user details.')
        }
        const data = {
            uid: currentUser.uid,
        }
        const profile = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/profile/get-profile-with-uid`, data);
        if (profile.data.error) return;
        this.setState({
            profile: profile.data,
            loading: false,
        })
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around' className='ReviewWebsite-Row'>
                        <Col xs={23} sm={22}>
                            <H2 text='Review your Website' />
                            {
                                this.state.loading ?
                                <Spin size='large' />
                                :
                                this.state.profile?.Website ?
                                <h3>Check out your website at <a href={this.state.profile.Website} target="_blank" rel="noreferrer">{this.state.profile.Website}</a></h3>
                                :
                                <h3>We're setting up your website! Please contact us at <a href='mailto:support@phros.ca'>support@phros.ca</a> if you have any questions.</h3>
                            }
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ReviewWebsite;