import React from 'react';
import './Headings.css';

class H2 extends React.Component {
    render() {
        return (
            <h1 className='H2'>
                <span className='H2-Highlight'>
                {this.props.text}
                </span>
            </h1>
        )
    }
}

export default H2;