import React from "react";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";

class Program extends Component {
    ID = IDS.PROGRAM1;
    constructor(uid) {
        super();
        this.UID = uid;
    }
    static Default() {
        let uid = localStorage.getItem("phros-app-uid");
        if (!uid) {
            uid = "blank";
        }
        return new Program(uid);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Program(obj.UID);
    }
}

const ProgramView = ({ data = Program.Default() }) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="container">
            <iframe
            src={`https://phros.ca/embed/${data.UID}/programs`}
            title="Live Classes"
            frameborder="0"
            style={{
                height: "1000px",
                width: "100%"
            }}
            id="live-class-embed">
            </iframe>
            </div>
        </div>
    )
}

export {
    Program,
    ProgramView,
}