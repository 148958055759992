import React from "react";
import IDS from "../IDS";
import { Component } from "../Component";
import "../common.css";

class Default1 extends Component {
    ID = IDS.DEFAULT;
    static Default() {
        return new Default1();
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Default1();
    }
}

const Default1View = ({ data = Default1.Default() }) => {
    return (
        <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
            <p>Nothing to Edit Here</p>
        </div>
    )
}


export {
    Default1, Default1View,
}