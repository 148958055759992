import React from 'react';
import { AuthContext } from '../../Context/AuthContext';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';

class StripeReauth extends React.Component {
    static contextType = AuthContext;

    state = {
        redirect: false,
    }

    componentDidMount() {
        this.stripeReAuth();
    }

    stripeReAuth = async () => {
        // Get Token
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        // Send Token and Check if User has an Account Already
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stripe/reauth`, {}, config);
        if (res.data.error) {
            this.setState({
                redirect: true,
            })
        } else {
            window.location.href=res.data.url;
        }
    }

    render() {
        return (
            <Row justify='center' align='middle' style={{ height: '100vh' }}>
                <Col>
                    <Spin size='large' />
                </Col>
                {
                    this.state.redirect ?
                    <Redirect to="/" />
                    :
                    null
                }
            </Row>
        )
    }
}

export default StripeReauth;