import React from 'react';
import { Row, Col, Input, Radio, Select, Card, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { checkArray } from '../../../Verification';
import ReactQuill from 'react-quill';
import { WEBSITE_LINKS } from '../../../constants';

class Services extends React.Component {
    render() {
        return (
            <Row gutter={[10,10]}>
                <Col xs={24}>
                    <h3>Services Section Information</h3>
                </Col>
                {
                    checkArray(this.props.Services).map((service, index) => (
                    <Col xs={24}>
                        <Row gutter={[10,10]} align="top">
                            <Col xs={24}>
                                <p>Service {index+1}</p>
                            </Col>
                            <Col xs={24} sm={12}>
                                <p>Service Title</p>
                                <Input value={service.Title} onChange={(e) => {
                                    let services = [...this.props.Services];
                                    services[index].Title = e.target.value;
                                    this.props.handleChange("Services", services);
                                }} />
                            </Col>
                            <Col xs={24}>
                                <p>Link this service section to an external site?</p>
                                <Radio.Group value={service.ExternalLink}
                                onChange={(e) => {
                                    let services = [...this.props.Services];
                                    services[index].ExternalLink = e.target.value;
                                    this.props.handleChange("Services", services);
                                }}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                service.ExternalLink ?
                                <Col xs={24} sm={12}>
                                    <p>Enter External Link URL</p>
                                    <Input
                                    placeholder="URL"
                                    value={service.Link}
                                    onChange={(e) => {
                                        let services = [...this.props.Services];
                                        services[index].Link = e.target.value;
                                        this.props.handleChange("Services", services);
                                    }}
                                    />
                                </Col>
                                :
                                <Col xs={24} sm={12}>
                                    <Select
                                    value={service.Link}
                                    style={{ width: "100%" }}
                                    onSelect={(value) => {
                                        let services = [...this.props.Services];
                                        services[index].Link = value;
                                        this.props.handleChange("Services", services);
                                    }}
                                    >
                                    {
                                        Object.keys(WEBSITE_LINKS).map((key) => (
                                            <Select.Option value={WEBSITE_LINKS[key]}>{WEBSITE_LINKS[key]}</Select.Option>
                                        ))
                                    }
                                    </Select>
                                </Col>
                            }
                            <Col xs={24}>
                                <p>Service Description</p>
                                <ReactQuill
                                    style={{ backgroundColor: 'white' }}
                                    value={service.Description}
                                    onChange={(content) => {
                                        let services = [...this.props.Services];
                                        services[index].Description = content;
                                        this.props.handleChange("Services", services);
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={12}>
                                <p>Button/Link Text</p>
                                <Input value={service.ButtonText} onChange={(e) => {
                                    let services = [...this.props.Services];
                                    services[index].ButtonText = e.target.value;
                                    this.props.handleChange("Services", services);
                                }} />
                            </Col>
                            <Col xs={24}>
                            <p>Service Image</p>
                            <p>Upload an image for your Service section.</p>
                            {
                                service.ImageURL !== undefined && service.ImageURL !== null  && String(service.ImageURL).length > 0?
                                <Card
                                style={{ width: "400px" }}
                                cover={<img src={service.ImageURL} alt="" />}
                                >
                                <Upload
                                fileList={[]}
                                accept=".png,.jpeg,.jpg"
                                customRequest={async (info) => {
                                    const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                    let services = [...this.props.Services];
                                    services[index].ImageURL = url;
                                    this.props.handleChange("Services", services);
                                }}>
                                    <Button icon={<UploadOutlined />}>Replace Image</Button>
                                </Upload>
                                </Card>
                                :
                                <Upload
                                    fileList={[]}
                                    accept=".png,.jpeg,.jpg"
                                    customRequest={async (info) => {
                                        const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                        let services = [...this.props.Services];
                                        services[index].ImageURL = url;
                                        this.props.handleChange("Services", services);
                                    }}>
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                            }
                        </Col>
                        <Col xs={24} sm={8}>
                            <Button
                                type="primary"
                                danger
                                block
                                onClick={() => {
                                    let services = [...this.props.Services];
                                    services.splice(index, 1);
                                    this.props.handleChange("Services", services);
                                }}
                            >
                                Delete Service {index+1}
                            </Button>
                        </Col>
                        </Row>
                    </Col>
                    ))
                }
                <Col xs={24} sm={8}>
                    <Button
                    type="primary"
                    block
                    onClick={() => {
                        let services = [...this.props.Services];
                        let tempService = {
                            Title: "",
                            Description: "",
                            ImageURL: "",
                            ExternalLink: false,
                            ButtonText: "Contact Me",
                            Link: "",
                        };
                        services.push(tempService);
                        this.props.handleChange("Services", services);
                    }}
                    >
                        Add Service
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default Services;