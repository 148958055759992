import React from 'react';
import './Login.css';
import { Row, Col, Input, Button, message, Modal, notification } from 'antd';
import { Logo } from '../../Common';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import { DataContext } from '../../Context/ProfileData';
import axios from 'axios';
import { ENVIRONMENT } from '../../constants';

class Login extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            modalState: false,
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    handleSignIn = async () => {
        try {
            const { login } = this.context;
            const { user } = await login(this.state.email, this.state.password);

            // Track the login
            try {
                const token = await user.getIdToken(true);
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                axios.post(`${ENVIRONMENT.URL}/segment/user-logged-in`, {}, config);
            } catch(e) {
                console.log(e);
            }

            this.props.history.push('/');
        } catch (e) {
            message.error(e.message);
        }
    }

    handleResetPassword = async () => {
        try {
            if (!String(this.state.email).includes("@") || !String(this.state.email).includes(".") || String(this.state.email).length <= 4) {
                return notification.error({
                    message: "Error",
                    description: "Please enter a valid email."
                })
            }
            const { resetpassword } = this.context;
            await resetpassword(this.state.email);
            this.handleModal();
            message.success('Password reset email sent.');
        } catch (e) {
            message.error('Something went wrong, please contact our team.')
        }
    }

    handleModal = () => {
        this.setState({
            modalState: !this.state.modalState,
        })
    }


    handleGoogleAuth = async () => {
        const { signInWithGoogle } = this.context;
        const user = await signInWithGoogle();
        if (user) this.props.history.push('/');
    }

    render() {
        return (
            <DataContext.Consumer>
                {
                    data => {
                        return (
                            <div>
                            <Row justify='space-around'>
                                <Col xs={24} sm={14} className='SignUp-Col'>
                                    <Row justify='space-around'>
                                        <Col xs={23} sm={16}>
                                            <Row justify='space-around'>
                                                <Col xs={24} className='SignUp-Info-Col'>
                                                <Logo />
                                                </Col>
                                                <Col xs={24} className='SignUp-Col-Spacing'>
                                                    <h3>Email</h3>
                                                    <Input size='large' onChange={(e) => this.handleChange('email', e.target.value)}/>
                                                </Col>
                                                <Col xs={24} className='SignUp-Col-Spacing'>
                                                    <h3>Password</h3>
                                                    <Input size='large' type='password' onChange={(e) => this.handleChange('password', e.target.value)}/>
                                                    <p>Password has to be at least 6 characters.</p>
                                                </Col>
                                                <Col xs={24} className='SignUp-Button-Col'>
                                                    <Button size='large' block type='primary' className='Button' onClick={async () => {
                                                        await this.handleSignIn();
                                                        await data.updateValues();
                                                    }}>
                                                        login
                                                    </Button>
                                                </Col>
                                                <Col xs={24} className="SignUp-Col-Spacing">
                                                <Button onClick={this.handleGoogleAuth} block size='large'>
                                                <img className='Auth-Google-Image' style={{ maxHeight: "20px" }} src="https://img.icons8.com/color/48/000000/google-logo.png" alt="google icon"/>
                                                <span> Login with Google</span>
                                                </Button>
                                                </Col>
                                                <Col xs={24}>
                                                    <p>Need an account? <Link to='/sign-up'>Sign Up</Link></p>
                                                    <p>Forgot your password? Forgot your password? <Button type='link' onClick={this.handleModal} style={{ margin: '1px', padding: '1px' }}>Reset Password</Button></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Modal
                             visible={this.state.modalState} onOk={this.handleResetPassword} onCancel={this.handleModal}>
                                <h3>Enter Email Below</h3>
                                <Input value={this.state.email} size='large' onChange={(e) => this.handleChange('email', e.target.value)}/>
                            </Modal>
                            </div>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

export default Login;