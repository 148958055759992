import React, { useContext, useState, useEffect } from 'react';
import { v4 } from "uuid";
import IDS from "../phros-web-components/IDS";
import { COMPONENTS } from "../phros-web-components/index";
import axios from "axios";
import { ENVIRONMENT } from "../constants";
import { AuthContext } from "./AuthContext";
import { message } from 'antd';

const WebsiteContext = React.createContext();

function useWebContext() {
    return useContext(WebsiteContext);
}


class Page {
    id = v4();
    constructor(name, link, title, description) {
        this.name = name;
        this.link = link;
        this.title = title;
        this.description = description;
    }
    setLink = (link) => {
        this.link = link;
    }
    setName = (name) => {
        this.name = name;
    }
    setId = (id) => {
        this.id = id;
    }
    static Default() {
        const page = new Page("New Page", `/new-page`, "Page Title", "Page Description");
        page.setLink(`${page.id}`);
        page.setName(`New Page (${page.id})`)
        return page;
    }
}

class WebComponent {
    ID = v4();
    constructor(componentId, data) {
        this.ComponentID = componentId;
        this.Data = data;
    }
    getObject() {
        return {
            ID: this.ID,
            ComponentID: this.ComponentID,
            Data: JSON.stringify(this.Data),
        }
    }
}

function WebContextProvider({ children }) {
    const [websiteComponents, setWebsiteComponents] = useState({});
    const [pageData, setPageData] = useState({});
    const [navbar, setNavbar] = useState({});
    const [footer, setFooter] = useState({});
    const [siteInfo, setSiteInfo] = useState({});
    const auth = useContext(AuthContext);

    useEffect(() => {
        getWebsiteData();
    }, []);


    const deletePage = async (PageID) => {
        const { currentUser } = auth;
        if (!currentUser) return;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            await axios.post(`${ENVIRONMENT.URL}/website/delete-page`, { PageID }, config);
            message.success("Successfully deleted the page.");
        } catch (e) {
            message.error("Failed to delete the page.")
        }
    }

    const getWebsiteData = async () => {
        const { currentUser } = auth;
        if (!currentUser) return;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const websiteData = await axios.post(`${ENVIRONMENT.URL}/website/get-website-data`, {}, config);

        if (!websiteData) {
            console.log("Failed to get website data.");
            return;
        }

        const { url, default_components, phros_domain, website_information } = websiteData?.data?.website_data;

        setSiteInfo({
            URL: String(url).replace(".phros.ca", ""),
            PhrosDomain: phros_domain,
            WebsiteInformation: website_information,
        });

        // Set the Page Data
        const page_data = websiteData?.data?.page_data;
        for (let i = 0; i<page_data.length; i++) {
            const page = page_data[i];
            const newPage = new Page(page.name, page.page_link, page.title, page.description);
            newPage.id = page.page_id;
            setPageData((prev) => {
                return {
                    ...prev,
                    [page.page_id]: newPage
                }
            })
            const pageComponents = JSON.parse(page.website_components);
            setWebsiteComponents((prev) => {
                return {
                    ...prev,
                    [page.page_id]: pageComponents[page.page_id],
                }
            })
        };

        // Set a default for the Home page if nothing is there
        if (page_data.length === 0) {
            const page = new Page("Home", "/", "Home", "Home page for your site.")
            setPageData({ [page.id]: page });

            const homeBase = new WebComponent(IDS.BANNER1, COMPONENTS[IDS.BANNER1].Class.Default());
            setWebsiteComponents({
                [page.id]: [homeBase.getObject()]
            })
        }

        // Set the Default data
        if (default_components?.footer) {
            setFooter(JSON.parse(default_components.footer));
        }

        if (default_components?.navbar) {
            setNavbar(JSON.parse(default_components.navbar));
        }
    }

    const upsertDomain = async () => {
        const { currentUser } = auth;
        if (!currentUser) return;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        let url = siteInfo.URL;
        if (siteInfo.PhrosDomain) {
            url = `${siteInfo.URL}.phros.ca`;
        }
        const webDefaults = {
            WebURL: url,
        }

        console.log({ webDefaults, config });
        try {
            const saveWebsite = await axios.post(`${ENVIRONMENT.URL}/website/upsert-website`, webDefaults, config);
            if (saveWebsite.status === 200) {
                message.success("Successfully updated your domain.");
            } else {
                message.error("Failed to update your domain.")
            }
        } catch (e) {
            console.log(e);
            message.error("Failed to update your website domain.");
        }

    }

    const saveWebsite = async () => {
        const { currentUser } = auth;
        if (!currentUser) return;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const pageInformation = Object.keys(pageData).reduce((obj, pageId) => {
            const page = pageData[pageId];
            const WebComponents = JSON.stringify({ [pageId]: websiteComponents[pageId ]});
            let tempLink = page.link;
            if (String(page.link).charAt(0) !== "/") {
                tempLink = `/${tempLink}`;
            }
            return {
                ...obj,
                [pageId]: {
                    PageID: pageId,
                    PageLink: tempLink,
                    Name: page.name,
                    Title: page.title,
                    Description: page.description,
                    Protected: false,
                    Password: "",
                    WebComponents,
                }
            }
        }, {});
        const webDefaults = {
            WebURL: "something.phros.ca",
            WebsiteInformation: {},
            DefaultComponents: {
                navbar: JSON.stringify(navbar),
                footer: JSON.stringify(footer),
            },
        }

        const saveWebsite = await axios.post(`${ENVIRONMENT.URL}/website/save-website-data`, { pageInformation, webDefaults }, config);

        if (saveWebsite.status === 200) {
            message.success("Successfully saved your website information.");
        } else {
            message.error("Failed to save the website information.")
        }
    };

    const value = {
        websiteComponents,
        setWebsiteComponents,
        pageData,
        setPageData,
        navbar,
        setNavbar,
        footer,
        setFooter,
        saveWebsite,
        siteInfo,
        setSiteInfo,
        upsertDomain,
        deletePage,
    };

    return (
        <WebsiteContext.Provider value={value}>
            {children}
        </WebsiteContext.Provider>
    )
}

export {
    WebsiteContext,
    useWebContext,
    Page,
    WebContextProvider,
    WebComponent
}