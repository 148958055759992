import React from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import momentTZ from 'moment-timezone'

class TimeRange extends React.Component {
  componentDidMount() {
    if (this.props.TimeZone) {
      moment.tz.setDefault(this.props.TimeZone);
    } else {
      moment.tz.setDefault(momentTZ.tz.guess());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.TimeZone !== this.props.TimeZone) {
      moment.tz.setDefault(this.props.TimeZone);
    }
  }

  render() {
    return (
      <TimePicker.RangePicker
      style={{ width: '100%' }}
      format={'HH:mm'}
      value={this.props.values}
      onChange={(values) => {
          this.props.onChange(this.props.StartTime, this.props.index, values[0]);
          this.props.onChange(this.props.EndTime, this.props.index, values[1]);
      }}
      />
    )
  }
}

export default TimeRange;