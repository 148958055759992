import React from "react";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";

class HTML1 extends Component {
    ID = IDS.HTML1;
    constructor(html) {
        super();
        this.HTML = html;
    }
    static Default() {
        const defaultHTML = `
        <h1>This is an HTML tag, edit this.</h1>
        `
        return new HTML1(defaultHTML);
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new HTML1(obj.HTML);
    }
}

const HTML1View = ({ data = HTML1.Default() }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: data.HTML }}>
        </div>
    )
}


export {
    HTML1,
    HTML1View,
}