import React from 'react';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import { Row, Col, Layout, Spin, message, Modal, Progress, Button, Input, Affix, Checkbox, Radio, notification } from 'antd';
import { AuthContext } from '../../Context/AuthContext';
import axios from 'axios';
import { checkArray, checkString, checkObj, checkBool } from '../../Verification';
import 'react-quill/dist/quill.snow.css';
import './BuildWebsite.css';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import { WEBSITE_SECTIONS, COLOUR_PALETTE, PHROS_WEB_DESIGN_ORDER, WEBSITE_COMPONENTS } from '../../constants';
import { AboutMe, BasicInformation, FAQ, Services, Gallery, Testimonials } from './BuildWebsiteComponents';
import { Banner1, Banner2, Banner3, Banner4,
    Navbar1, Navbar2, Navbar3,
    AboutMe1, AboutMe2, AboutMe3,
    Gallery1, Gallery2,
    Service1, Service2, Service3,
    Testimonial1, Testimonial2, Testimonial3,
    FAQ1, FAQ2,
    Contact1, Contact2, } from './WebsiteComponents';
import SectionOrder from './SectionOrder/SectionOrder';
import PreviewWebsite from './PreviewWebsite/PreviewWebsite';
import { ENVIRONMENT } from '../../constants';

class BuildWebsite extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            paid: false,
            loading: true,
            profile: null,

            AboutPicture: null,
            AboutPicDisabled: false,
            AboutPictureFileList: [],

            Step: 0,
            StepValue: PHROS_WEB_DESIGN_ORDER.SELECT_SECTIONS,
            SelectedSections: {},
            SelectedDesigns: {
                BANNER: WEBSITE_COMPONENTS.BANNERS.BANNER1,
                NAVBAR: WEBSITE_COMPONENTS.NAVBARS.NAVBAR1,
                ABOUTME: WEBSITE_COMPONENTS.ABOUTME.ABOUTME1,
                GALLERY: WEBSITE_COMPONENTS.GALLERY.GALLERY1,
                SERVICE: WEBSITE_COMPONENTS.SERVICE.SERVICE1,
                FAQ: WEBSITE_COMPONENTS.FAQ.FAQ1,
                TESTIMONIAL: WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL1,
                CONTACT: WEBSITE_COMPONENTS.CONTACTME.CONTACT1,
            },

            /* Basic Information */
            CustomizedColours: false,
            ColourPalette: {
                DARK: COLOUR_PALETTE.PALETTE_1.DARK,
                MEDIUM: COLOUR_PALETTE.PALETTE_1.MEDIUM,
                LIGHT: COLOUR_PALETTE.PALETTE_1.LIGHT,
            },
            ExistingWebsite: false,
            ExistingWebsiteURL: "",
            FirstName: "",
            LastName: "",
            ProfessionalTitle: "",
            BusinessStatement: "",
            Email: "",
            BannerImageURL: "",
            LogoURL: "",
            BusinessName: "",
            SocialMedia: [],

            /* About Me */
            AboutMe: "",
            AboutMePictureURL: "",

            /* FAQ */
            FAQ: [],

            /* Services */
            Services: [],

            /* Gallery */
            Gallery: [],

            /* Testimonials */
            Testimonials: [],

            /* Design Order */
            PhrosDesignOrder: [
                PHROS_WEB_DESIGN_ORDER.SELECT_SECTIONS,
                PHROS_WEB_DESIGN_ORDER.ADD_BASIC_INFORMATION,
            ],

            OrderModal: false,
            Order: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleStaticUpload = this.handleStaticUpload.bind(this);
    }
    componentDidMount() {
        this.getProfile();
    }

    saveProfileInformation = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const payload = {
            SelectedDesigns: this.state.SelectedDesigns,
            LogoURL: this.state.LogoURL,
            BusinessName: this.state.BusinessName,
            ExistingWebsite: this.state.ExistingWebsite,
            ExistingWebsiteURL: this.state.ExistingWebsiteURL,
            SocialMedia: this.state.SocialMedia,
            Profile: this.state.profile,
            BannerImageURL: this.state.BannerImageURL,
            ProfessionalTitle: this.state.ProfessionalTitle,
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            BusinessStatement: this.state.BusinessStatement,
            ColourPalette: this.state.ColourPalette,
            Testimonials: this.state.Testimonials,
            Gallery: this.state.Gallery,
            Services: this.state.Services,
            FAQ: this.state.FAQ,
            AboutMe: this.state.AboutMe,
            AboutMePictureURL: this.state.AboutMePictureURL,
            Order: this.state.Order,
            CustomizedColours: this.state.CustomizedColours,
            Email: this.state.Email,
            UID: currentUser.uid,
            SelectedSections: this.state.SelectedSections,
        };
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website-templates/save-website`, payload, config);
        try {
            await axios.post(`${ENVIRONMENT.WEB_API}/website/update-phros-website`, {}, config);
        } catch(e) {
            console.log(e);
        }
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: "Failed to save website data, please try again or contact us at support@phros.ca if this persists.",
                duration: null,
            })
        } else {
            return notification.success({
                message: "Success",
                description: "Successfully updated website."
            })
        }
    }

    getProfile = async () => {
        const { currentUser } = this.context;
        if (!currentUser.uid) {
            message.error('Failed to get user details.')
        }
        const data = {
            UID: currentUser.uid,
        }
        const profile = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/website-templates/get-website-data-with-uid`, data);
        if (profile.data.error) return;
        this.setState({
            profile: profile.data.profile,
            loading: false,
            SelectedDesigns: checkObj(profile.data.website?.SelectedDesigns),
            LogoURL: checkString(profile.data.website?.LogoURL),
            BusinessName: checkString(profile.data.website?.BusinessName),
            ExistingWebsite: checkBool(profile.data.website?.ExistingWebsite),
            ExistingWebsiteURL: checkString(profile.data.website?.ExistingWebsiteURL),
            SocialMedia: checkArray(profile.data.website?.SocialMedia),
            BannerImageURL: checkString(profile.data.website?.BannerImageURL),
            ProfessionalTitle: checkString(profile.data.website?.ProfessionalTitle),
            FirstName: checkString(profile.data.website?.FirstName),
            LastName: checkString(profile.data.website?.LastName),
            BusinessStatement: checkString(profile.data.website?.BusinessStatement),
            ColourPalette: checkObj(profile.data.website?.ColourPalette),
            Testimonials: checkArray(profile.data.website?.Testimonials),
            Gallery: checkArray(profile.data.website?.Gallery),
            Services: checkArray(profile.data.website?.Services),
            FAQ: checkArray(profile.data.website?.FAQ),
            AboutMe: checkString(profile.data.website?.AboutMe),
            AboutMePictureURL: checkString(profile.data.website?.AboutMePictureURL),
            Order: checkArray(profile.data.website?.Order),
            CustomizedColours: checkBool(profile.data.website?.CustomizedColours),
            Email: checkString(profile.data.website?.Email),
            SelectedSections: checkObj(profile.data.website?.SelectedSections),
            PhrosDesignOrder: [...this.state.PhrosDesignOrder, ...checkArray(profile.data.website?.Order)]
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleStaticUpload = async (folderName, buttonName, info) => {
        notification.open({
            key: "Progress-Upload",
            message: "Uploading File",
            description: (
                <Row>
                    <Col xs={24}>
                        Status:
                    </Col>
                    <Col xs={24}>
                        <Progress percent={0} status="active" />
                    </Col>
                </Row>
            )
        })
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/static/${uid}/${folderName}/${filename}`).put(file);
            uploadTask.on('state_changed',
            (snapshot) => {
                notification.open({
                    key: "Progress-Upload",
                    message: "Uploading File",
                    description: (
                        <Row>
                            <Col xs={24}>
                                Status:
                            </Col>
                            <Col xs={24}>
                                <Progress percent={Math.ceil(snapshot.bytesTransferred/snapshot.totalBytes*100)} />
                            </Col>
                        </Row>
                    )
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`/static/${uid}/${folderName}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    resolve(firebaseUrl);
                })
            })
        });
        return url;
    }

    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify='space-around' className='ReviewWebsite-Row'>
                <Col xs={23} sm={22}>
                <H2 text='Build/Edit your Website' />
                {
                    this.state.loading ?
                    <Spin size='large' />
                    :
                    <div>
                    <Row gutter={[10,10]}>
                    {
                        this.state.StepValue === PHROS_WEB_DESIGN_ORDER.SELECT_SECTIONS ?
                        <Col xs={24}>
                            <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <h2>Do you already have an existing website?</h2>
                                <Radio.Group value={this.state.ExistingWebsite} onChange={(e) => this.handleChange("ExistingWebsite", e.target.value)}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                this.state.ExistingWebsite ?
                                <Col xs={24}>
                                    What's your Website URL? We'll have a link on your Phros website so customers can return to your main website.
                                    <Input value={this.state.ExistingWebsiteURL} onChange={(e) => this.handleChange("ExistingWebsiteURL", e.target.value)} />
                                </Col>
                                :
                                null
                            }
                            <Col xs={24}>
                                <h2>What sections do you need on your Phros template website?</h2>
                                <p>Select the sections that you'd like to display on your website landing page.</p>
                            </Col>
                            {
                                Object.keys(WEBSITE_SECTIONS).map((key) => {
                                    return (
                                        <Col xs={24}>
                                            <Checkbox
                                            checked={this.state.SelectedSections[key]}
                                            onChange={(e) => {
                                                let tempSelects = { ...this.state.SelectedSections };
                                                tempSelects[key] = e.target.checked;
                                                this.handleChange("SelectedSections", tempSelects);
                                                let sectionOrder = [...this.state.PhrosDesignOrder];
                                                let order = [...this.state.Order];
                                                if (!e.target.checked) {
                                                    // Establish the design order (from clicking next)
                                                    let index = sectionOrder.findIndex((val) => val === WEBSITE_SECTIONS[key].VALUE);
                                                    if (index !== -1) {
                                                        sectionOrder.splice(index, 1);
                                                        this.handleChange("PhrosDesignOrder", sectionOrder);
                                                    }
                                                    // Attempt to remove from Order
                                                    let orderIndex = order.findIndex((val) => val === WEBSITE_SECTIONS[key].VALUE);
                                                    if (orderIndex !== -1) {
                                                        order.splice(orderIndex, 1);
                                                        this.handleChange("Order", order);
                                                    }
                                                } else {
                                                    sectionOrder.push(WEBSITE_SECTIONS[key].VALUE);
                                                    this.handleChange("PhrosDesignOrder", sectionOrder);
                                                }
                                            }}
                                            >
                                                {WEBSITE_SECTIONS[key]?.NAME}
                                            </Checkbox>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </Col>
                        :
                        null
                    }
                    <Col xs={24}>
                    {
                        this.state.StepValue === PHROS_WEB_DESIGN_ORDER.ADD_BASIC_INFORMATION ?
                        <Row gutter={[10,20]}>
                            <BasicInformation
                            handleChange={this.handleChange}
                            CustomizedColours={this.state.CustomizedColours}
                            ColourPalette={this.state.ColourPalette}
                            FirstName={this.state.FirstName}
                            LastName={this.state.LastName}
                            ProfessionalTitle={this.state.ProfessionalTitle}
                            BusinessStatement={this.state.BusinessStatement}
                            Email={this.state.Email}
                            BannerImageURL={this.state.BannerImageURL}
                            LogoURL={this.state.LogoURL}
                            handleStaticUpload = {this.handleStaticUpload}
                            SocialMedia = {this.state.SocialMedia}
                            BusinessName = {this.state.BusinessName}
                            />
                            <Col xs={24}>
                                <h2>Select your Navigation Bar Design</h2>
                                <p>The Navigation Bar appears on all parts of the website</p>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.NAVBAR} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.NAVBAR = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.NAVBARS).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.NAVBARS[key]}>{WEBSITE_COMPONENTS.NAVBARS[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR1 ?
                                    <Navbar1
                                        LogoURL = {this.state.LogoURL}
                                        BusinessName = {this.state.BusinessName}
                                        ExistingWebsite = {this.state.ExistingWebsite}
                                        ExistingWebsiteURL = {this.state.ExistingWebsiteURL}
                                        SocialMedia = {this.state.SocialMedia}
                                        Profile = {this.state.profile}
                                    />
                                    :
                                    this.state.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR2 ?
                                    <Navbar2
                                        LogoURL = {this.state.LogoURL}
                                        BusinessName = {this.state.BusinessName}
                                        ExistingWebsite = {this.state.ExistingWebsite}
                                        ExistingWebsiteURL = {this.state.ExistingWebsiteURL}
                                        SocialMedia = {this.state.SocialMedia}
                                        Profile = {this.state.profile}
                                    />
                                    :
                                    this.state.SelectedDesigns?.NAVBAR === WEBSITE_COMPONENTS.NAVBARS.NAVBAR3 ?
                                    <Navbar3
                                        LogoURL = {this.state.LogoURL}
                                        BusinessName = {this.state.BusinessName}
                                        ExistingWebsite = {this.state.ExistingWebsite}
                                        ExistingWebsiteURL = {this.state.ExistingWebsiteURL}
                                        SocialMedia = {this.state.SocialMedia}
                                        Profile = {this.state.profile}
                                    />
                                    :
                                    null
                                }
                            </Col>
                            <Col xs={24}>
                                <h2>Select your Banner Design</h2>
                                <p>The banner is the first thing a customer will see on your site, and is typically the focal point of your page.</p>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.BANNER} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.BANNER = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.BANNERS).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.BANNERS[key]}>{WEBSITE_COMPONENTS.BANNERS[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                            {
                                this.state.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER1 ?
                                <Banner1
                                    BannerImageURL = {this.state.BannerImageURL}
                                    ProfessionalTitle = {this.state.ProfessionalTitle}
                                    FirstName = {this.state.FirstName}
                                    LastName = {this.state.LastName}
                                    BusinessStatement = {this.state.BusinessStatement}
                                    ColourPalette = {this.state.ColourPalette}
                                />
                                :
                                this.state.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER2 ?
                                <Banner2
                                    BannerImageURL = {this.state.BannerImageURL}
                                    ProfessionalTitle = {this.state.ProfessionalTitle}
                                    FirstName = {this.state.FirstName}
                                    LastName = {this.state.LastName}
                                    BusinessStatement = {this.state.BusinessStatement}
                                    ColourPalette = {this.state.ColourPalette}
                                />
                                :
                                this.state.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER3 ?
                                <Banner3
                                    BannerImageURL = {this.state.BannerImageURL}
                                    ProfessionalTitle = {this.state.ProfessionalTitle}
                                    FirstName = {this.state.FirstName}
                                    LastName = {this.state.LastName}
                                    BusinessStatement = {this.state.BusinessStatement}
                                    ColourPalette = {this.state.ColourPalette}
                                />
                                :
                                this.state.SelectedDesigns?.BANNER === WEBSITE_COMPONENTS.BANNERS.BANNER4 ?
                                <Banner4
                                    BannerImageURL = {this.state.BannerImageURL}
                                    ProfessionalTitle = {this.state.ProfessionalTitle}
                                    FirstName = {this.state.FirstName}
                                    LastName = {this.state.LastName}
                                    BusinessStatement = {this.state.BusinessStatement}
                                    ColourPalette = {this.state.ColourPalette}
                                />
                                :
                                null
                            }
                            </Col>
                        </Row>
                        :
                        null
                    }
                    {
                        this.state.SelectedSections[WEBSITE_SECTIONS.ABOUTME.VALUE] && this.state.StepValue === WEBSITE_SECTIONS.ABOUTME.VALUE ?
                        <Row guter={[20,20]}>
                            <Col xs={24}>
                                <AboutMe
                                AboutMe = {this.state.AboutMe}
                                AboutMePictureURL = {this.state.AboutMePictureURL}
                                handleChange = {this.handleChange}
                                handleStaticUpload = {this.handleStaticUpload}
                                />
                            </Col>
                            <Col xs={24}>
                                <h2>Select your About Me design</h2>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.ABOUTME} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.ABOUTME = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.ABOUTME).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.ABOUTME[key]}>{WEBSITE_COMPONENTS.ABOUTME[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME1 ?
                                    <AboutMe1
                                        FirstName = {this.state.FirstName}
                                        LastName = {this.state.LastName}
                                        ProfessionalTitle = {this.state.ProfessionalTitle}
                                        AboutMe = {this.state.AboutMe}
                                        AboutMePictureURL = {this.state.AboutMePictureURL}
                                    />
                                    :
                                    this.state.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME2 ?
                                    <AboutMe2
                                        FirstName = {this.state.FirstName}
                                        LastName = {this.state.LastName}
                                        ProfessionalTitle = {this.state.ProfessionalTitle}
                                        AboutMe = {this.state.AboutMe}
                                        AboutMePictureURL = {this.state.AboutMePictureURL}
                                    />
                                    :
                                    this.state.SelectedDesigns?.ABOUTME === WEBSITE_COMPONENTS.ABOUTME.ABOUTME3 ?
                                    <AboutMe3
                                        FirstName = {this.state.FirstName}
                                        LastName = {this.state.LastName}
                                        ProfessionalTitle = {this.state.ProfessionalTitle}
                                        AboutMe = {this.state.AboutMe}
                                        AboutMePictureURL = {this.state.AboutMePictureURL}
                                    />
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        :
                        null
                    }
                    {
                        this.state.SelectedSections[WEBSITE_SECTIONS.FAQ.VALUE] && this.state.StepValue === WEBSITE_SECTIONS.FAQ.VALUE ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <FAQ
                                FAQ = {this.state.FAQ}
                                handleChange = {this.handleChange}
                                />
                            </Col>
                            <Col xs={24}>
                                <h2>Select your FAQ design</h2>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.FAQ} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.FAQ = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.FAQ).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.FAQ[key]}>{WEBSITE_COMPONENTS.FAQ[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.SelectedDesigns?.FAQ === WEBSITE_COMPONENTS.FAQ.FAQ1 ?
                                    <FAQ1
                                        FAQ = {this.state.FAQ}
                                    />
                                    :
                                    this.state.SelectedDesigns?.FAQ === WEBSITE_COMPONENTS.FAQ.FAQ2 ?
                                    <FAQ2
                                        FAQ = {this.state.FAQ}
                                    />
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        :
                        null
                    }
                                        {
                        this.state.SelectedSections[WEBSITE_SECTIONS.CONTACT.VALUE] && this.state.StepValue === WEBSITE_SECTIONS.CONTACT.VALUE ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <h2>Select your "Contact Me" Design</h2>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.CONTACT} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.CONTACT = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.CONTACTME).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.CONTACTME[key]}>{WEBSITE_COMPONENTS.CONTACTME[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.SelectedDesigns?.CONTACT === WEBSITE_COMPONENTS.CONTACTME.CONTACT1 ?
                                    <Contact1 />
                                    :
                                    this.state.SelectedDesigns?.CONTACT === WEBSITE_COMPONENTS.CONTACTME.CONTACT2 ?
                                    <Contact2 />
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        :
                        null
                    }
                    {
                        this.state.SelectedSections[WEBSITE_SECTIONS.SERVICES.VALUE] && this.state.StepValue === WEBSITE_SECTIONS.SERVICES.VALUE ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <Services
                                Services = {this.state.Services}
                                handleChange={this.handleChange}
                                handleStaticUpload={this.handleStaticUpload}
                                />
                            </Col>
                            <Col xs={24}>
                                <h2>Select your Service Card design</h2>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.SERVICE} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.SERVICE = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.SERVICE).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.SERVICE[key]}>{WEBSITE_COMPONENTS.SERVICE[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE1 ?
                                    <Service1
                                        Services = {this.state.Services}
                                    />
                                    :
                                    this.state.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE2 ?
                                    <Service2
                                        Services = {this.state.Services}
                                    />
                                    :
                                    this.state.SelectedDesigns?.SERVICE === WEBSITE_COMPONENTS.SERVICE.SERVICE3 ?
                                    <Service3
                                        Services = {this.state.Services}
                                    />
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        :
                        null
                    }
                    {
                        this.state.SelectedSections[WEBSITE_SECTIONS.GALLERY.VALUE] && this.state.StepValue === WEBSITE_SECTIONS.GALLERY.VALUE ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}>
                                <Gallery
                                    Gallery={this.state.Gallery}
                                    handleChange={this.handleChange}
                                    handleStaticUpload={this.handleStaticUpload}
                                />
                            </Col>
                            <Col xs={24}>
                                <h2>Select your Gallery design</h2>
                            </Col>
                            <Col xs={24}>
                            <Radio.Group value={this.state.SelectedDesigns?.GALLERY} onChange={(e) => {
                                let designs = { ...this.state.SelectedDesigns };
                                designs.GALLERY = e.target.value;
                                this.handleChange("SelectedDesigns", designs)
                            }}>
                                {
                                    Object.keys(WEBSITE_COMPONENTS.GALLERY).map((key) => (
                                        <Radio value={WEBSITE_COMPONENTS.GALLERY[key]}>{WEBSITE_COMPONENTS.GALLERY[key]}</Radio>
                                    ))
                                }
                            </Radio.Group>
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.SelectedDesigns?.GALLERY === WEBSITE_COMPONENTS.GALLERY.GALLERY1 ?
                                    <Gallery1
                                        Gallery = {this.state.Gallery}
                                    />
                                    :
                                    this.state.SelectedDesigns?.GALLERY === WEBSITE_COMPONENTS.GALLERY.GALLERY2 ?
                                    <Gallery2
                                        Gallery = {this.state.Gallery}
                                    />
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        :
                        null
                    }
                    {
                        this.state.SelectedSections[WEBSITE_SECTIONS.TESTIMONIALS.VALUE] && this.state.StepValue === WEBSITE_SECTIONS.TESTIMONIALS.VALUE ?
                        <Row gutter={[10,10]}>
                        <Col xs={24}>
                            <Testimonials
                                Testimonials={this.state.Testimonials}
                                handleChange={this.handleChange}
                                handleStaticUpload={this.handleStaticUpload}
                            />
                        </Col>
                        <Col xs={24}>
                            <h2>Select your Testimonial design</h2>
                        </Col>
                        <Col xs={24}>
                        <Radio.Group value={this.state.SelectedDesigns?.TESTIMONIAL} onChange={(e) => {
                            let designs = { ...this.state.SelectedDesigns };
                            designs.TESTIMONIAL = e.target.value;
                            this.handleChange("SelectedDesigns", designs)
                        }}>
                            {
                                Object.keys(WEBSITE_COMPONENTS.TESTIMONIALS).map((key) => (
                                    <Radio value={WEBSITE_COMPONENTS.TESTIMONIALS[key]}>{WEBSITE_COMPONENTS.TESTIMONIALS[key]}</Radio>
                                ))
                            }
                        </Radio.Group>
                        </Col>
                        <Col xs={24}>
                            {
                                this.state.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL1 ?
                                <Testimonial1
                                    Testimonials = {this.state.Testimonials}
                                />
                                :
                                this.state.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL2 ?
                                <Testimonial2
                                    Testimonials = {this.state.Testimonials}
                                />
                                :
                                this.state.SelectedDesigns?.TESTIMONIAL === WEBSITE_COMPONENTS.TESTIMONIALS.TESTIMONIAL3 ?
                                <Testimonial3
                                    Testimonials = {this.state.Testimonials}
                                />
                                :
                                null
                            }
                        </Col>
                        </Row>
                        :
                        null
                    }
                    </Col>
                    <Col xs={24}>
                        <Row className='Profile-Row' justify="space-between">
                            <Col xs={24} sm={6}>
                                {
                                    this.state.Step > 0 ?
                                    <Affix offsetBottom={20}>
                                    <Button type='primary' className='Button'
                                    onClick={() => {
                                        let step = this.state.Step - 1;
                                        this.handleChange("Step", step);
                                        this.handleChange("StepValue", this.state.PhrosDesignOrder[step])
                                    }} block >
                                        Previous Step
                                    </Button>
                                    </Affix>
                                    :
                                    null
                                }
                            </Col>
                            <Col xs={24} sm={6}>
                                <Affix offsetBottom={20}>
                                {
                                    this.state.Step === this.state.PhrosDesignOrder.length-1 ?
                                    <Button type='primary' className='Button'
                                    onClick={() => {
                                        this.setState({
                                            OrderModal: true,
                                        })
                                    }} block >
                                        Select Section Order
                                    </Button>
                                    :
                                    <Button type='primary' className='Button'
                                    onClick={() => {
                                        let step = this.state.Step + 1;
                                        this.handleChange("Step", step);
                                        this.handleChange("StepValue", this.state.PhrosDesignOrder[step])
                                        window.scrollTo(0,0);
                                    }} block >
                                        Next Step
                                    </Button>
                                }
                                </Affix>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                    </div>
                }
                </Col>
                <Col xs={24}>
                    <Modal
                    width = {1200}
                    closable
                    visible={this.state.OrderModal}
                    onCancel={() => this.setState({
                        OrderModal: false,
                    })}
                    okText="Save and Update Website"
                    onOk={this.saveProfileInformation}>
                    <SectionOrder
                        SelectedSections = {this.state.SelectedSections}
                        SelectedDesigns = {this.state.SelectedDesigns}
                        Order = {this.state.Order}
                        handleChange = {this.handleChange}
                    />
                    <PreviewWebsite
                        SelectedDesigns = {this.state.SelectedDesigns}
                        LogoURL = {this.state.LogoURL}
                        BusinessName = {this.state.BusinessName}
                        ExistingWebsite = {this.state.ExistingWebsite}
                        ExistingWebsiteURL = {this.state.ExistingWebsiteURL}
                        SocialMedia = {this.state.SocialMedia}
                        Profile = {this.state.profile}
                        BannerImageURL = {this.state.BannerImageURL}
                        ProfessionalTitle = {this.state.ProfessionalTitle}
                        FirstName = {this.state.FirstName}
                        LastName = {this.state.LastName}
                        BusinessStatement = {this.state.BusinessStatement}
                        ColourPalette = {this.state.ColourPalette}
                        Testimonials = {this.state.Testimonials}
                        Gallery = {this.state.Gallery}
                        Services = {this.state.Services}
                        FAQ = {this.state.FAQ}
                        AboutMe = {this.state.AboutMe}
                        AboutMePictureURL = {this.state.AboutMePictureURL}
                        Order = {this.state.Order}
                    />
                    </Modal>
                </Col>
                </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default BuildWebsite;