import { useState, useEffect } from "react";
import { FAQ2, Question } from "../../phros-web-components/FAQ/FAQ2/FAQ2";
import { Input, Button } from "antd";

const Faq2Edit = ({ data = FAQ2.Default(), editComponent = () => {} }) => {
    const [questions, setQuestions] = useState(data.FAQS);

    useEffect(() => {
        const updateFaq = new FAQ2(questions);
        editComponent(updateFaq);
    }, [questions]);

    return (
        <div>
            {
                questions.map((faq, index) => {
                    return (
                        <div key={index} style={{ marginBottom: "20px" }}>
                            <h4>FAQ {index+1}</h4>
                            <h5>Question:</h5>
                            <Input.TextArea
                                value={faq.Question}
                                onChange={(e) => {
                                    setQuestions((prevState) => {
                                        const newQuestion = new Question(e.target.value, faq.Answer);
                                        return [
                                            ...prevState.slice(0, index),
                                            newQuestion,
                                            ...prevState.slice(index+1)
                                        ];
                                    });
                                }}
                            />
                            <h5>Answer:</h5>
                            <Input.TextArea
                                value={faq.Answer}
                                onChange={(e) => {
                                    setQuestions((prevState) => {
                                        const newQuestion = new Question(faq.Question, e.target.value);
                                        return [
                                            ...prevState.slice(0, index),
                                            newQuestion,
                                            ...prevState.slice(index+1)
                                        ]
                                    })
                                }}
                            />
                            <Button danger type="primary" ghost style={{ marginTop: "10px" }} onClick={() => setQuestions((prevState) => {
                                let temp = prevState;
                                temp.splice(index, 1);
                                return [...temp];
                            })}>Delete FAQ</Button>
                        </div>
                    )
                })
            }
            <div style={{ padding: "20px" }}>
            <Button
                onClick={() => setQuestions((prevState) => {
                    return [
                        ...prevState,
                        new Question("Question", "Answer")
                    ]
                })}
                type="primary"
                block
            >Add FAQ</Button>
            </div>
        </div>
    )
};

export {
    Faq2Edit,
}