import React from 'react';
import './AboutMe1.css';
import { Row, Col } from 'antd';
import DOMPurify from 'dompurify';

/*
PROPS
FirstName
LastName
ProfessionalTitle
AboutMe
AboutMePictureURL
*/

class AboutMe1 extends React.Component {
    render() {
        return (
            <Row className="AboutMe-Row">
                <Col xs={24}>
                    <Row justify="center" gutter={[20,20]} align="middle">
                        <Col className="AboutMe1-Image-Col">
                            <img className="AboutMe1-Image" src={this.props.AboutMePictureURL} alt="" />
                        </Col>
                        <Col xs={24} sm={12}>
                            <h2 className="AboutMe1-Name">{this.props.FirstName} {this.props.LastName}</h2>
                            <h3 className="AboutMe1-Title">{this.props.ProfessionalTitle}</h3>
                            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.AboutMe)}} />
                            <a href="/#contact-me" className="AboutMe-Contact-Me">Contact Me</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default AboutMe1;