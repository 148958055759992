import { RootSider, SiderViews } from "./Sider";
import { useParams } from "react-router-dom";
import { WebsiteContext } from "../../Context/WebsiteContext";
import { useContext, createElement, useState, useEffect } from "react";
import { COMPONENTS, Navbar, Footer } from "../../phros-web-components/index";
import { Button, Drawer } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EDITABLE_COMPONENTS, EDITABLE_NAVBARS, EDITABLE_FOOTERS } from "../../phros-editable-web-components/index";
import IDS from "../../phros-web-components/IDS";
import "./Website.css";

/*
FETCH at the beginning to get the page data
*/

const PageEditor = () => {
    const { ID } = useParams();
    const { websiteComponents, setWebsiteComponents, navbar, footer, setNavbar, setFooter } = useContext(WebsiteContext);
    const [editorOpen, setEditorOpen] = useState(false);
    const [componentToEdit, setComponentToEdit] = useState(null);
    const [componentIndex, setComponentIndex] = useState(0);
    const [navbarEdit, setNavbarEdit] = useState(null);
    const [footerEdit, setFooterEdit] = useState(null);

    const deleteWebComponent = (index) => {
        setWebsiteComponents((prev) => {
            const tempComponents = [...prev[ID]];
            tempComponents.splice(index, 1);
            return {
                ...prev,
                [ID]: tempComponents,
            }
        })
    }

    const editComponent = (classObject) => {
        setWebsiteComponents((prev) => {
            const tempComponents = [...prev[ID]];
            const newComponent = {
                ...componentToEdit,
                Data: JSON.stringify(classObject),
            };
            tempComponents.splice(componentIndex, 1, newComponent);
            return {
                ...prev,
                [ID]: tempComponents,
            }
        })
    };

    const editNavbar = (classObject) => {
        setNavbar((prev) => ({
            ...prev,
            Data: JSON.stringify(classObject),
        }))
    }

    const editFooter = (classObject) => {
        setFooter((prev) => ({
            ...prev,
            Data: JSON.stringify(classObject),
        }))
    }

    const handleDragEnd = (result) => {
        setWebsiteComponents((prev) => {
            const tempComponents = [...prev[ID]];
            const [reorderedComp] = tempComponents.splice(result.source.index, 1);
            tempComponents.splice(result.destination.index, 0, reorderedComp);
            return {
                ...prev,
                [ID]: tempComponents,
            }
        })
    };

    return (
        <RootSider view={SiderViews.PAGE}>
            {
                Object.keys(navbar).length > 0 ?
                <div className="WebElement--Div">
                    <div className="WebElement--Buttons">
                        <Button onClick={() => {
                            setNavbarEdit(navbar);
                            setFooterEdit(null);
                            setComponentToEdit(null);
                            setEditorOpen(true);
                        }} size="small" className="WebElement--Edit"><EditFilled /></Button>
                    </div>
                    {createElement(Navbar[navbar.ComponentID].View, { data: Navbar[navbar.ComponentID].Class.fromJSON(navbar.Data) } )}
                </div>
                :
                null
            }

            <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="web-components">
            {(provided) => (
            <div className="web-components" {...provided.droppableProps} ref={provided.innerRef} >
            {
                websiteComponents[ID]?.map((comp, index) => {
                    const props = { data: COMPONENTS[comp.ComponentID].Class.fromJSON(comp.Data) };
                    return (
                        <Draggable key={comp.ID} draggableId={comp.ID} index={index}>
                            {(provided2) => (
                            <div className="WebElement--Div" ref={provided2.innerRef} {...provided2.draggableProps} {...provided2.dragHandleProps}>
                                <div className="WebElement--Buttons">
                                    <Button onClick={() => {
                                        setComponentToEdit({
                                            ID: "temp",
                                            ComponentID: IDS.DEFAULT,
                                            Data: JSON.stringify(COMPONENTS[IDS.DEFAULT]?.Class.Default())
                                        }) // This is to attempt to reset the component. If you click on the same component twice someties it pulls up the old data ?
                                        setEditorOpen(true);
                                        setNavbarEdit(null);
                                        setFooterEdit(null);
                                        setComponentIndex(index);
                                        setTimeout(() => setComponentToEdit(comp), 50); // For some reason we need a timeout, otherwise the component does not update
                                    }} size="small" className="WebElement--Edit"><EditFilled />Edit Component Details</Button>
                                    <Button onClick={() => deleteWebComponent(index)} size="small" className="WebElement--Delete" danger type="primary"><DeleteFilled /></Button>
                                </div>
                                {createElement(COMPONENTS[comp.ComponentID].View, props)}
                            </div>
                            )}
                        </Draggable>
                    )
                })
            }
            {provided.placeholder}
            </div>
            )}
            </Droppable>
            </DragDropContext>

            {
                Object.keys(footer).length > 0 ?
                <div className="WebElement--Div">
                    <div className="WebElement--Buttons">
                        <Button onClick={() => {
                            setNavbarEdit(null);
                            setFooterEdit(footer);
                            setComponentToEdit(null);
                            setEditorOpen(true);
                        }} size="small" className="WebElement--Edit"><EditFilled /></Button>
                    </div>
                    {createElement(Footer[footer.ComponentID].View, { data: Footer[footer.ComponentID].Class.fromJSON(footer.Data) } )}
                </div>
                :
                null
            }

            <Drawer
                placement="right"
                onClose={() => setEditorOpen(false)}
                visible={editorOpen}
                closable={true}
                width={500}
            >
                {
                    !EDITABLE_NAVBARS[navbarEdit?.ComponentID] && !EDITABLE_COMPONENTS[componentToEdit?.ComponentID] && !EDITABLE_FOOTERS[footerEdit?.ComponentID] ?
                    <div style={{ textAlign: "center" }}>
                        <h5>Nothing to edit here!</h5>
                    </div>
                    :
                    <>
                    {
                        navbarEdit ?
                        createElement(EDITABLE_NAVBARS[navbarEdit.ComponentID],
                        {
                            data: Navbar[navbarEdit.ComponentID]?.Class.fromJSON(navbarEdit.Data),
                            editComponent: editNavbar,
                        })
                        :
                        null
                    }
                    {
                        componentToEdit ?
                        createElement(EDITABLE_COMPONENTS[componentToEdit.ComponentID],
                            {
                                data: COMPONENTS[componentToEdit.ComponentID]?.Class.fromJSON(componentToEdit.Data),
                                editComponent: editComponent,
                            })
                        :
                        null
                    }
                    {
                        footerEdit ?
                        createElement(EDITABLE_FOOTERS[footerEdit.ComponentID],
                        {
                            data: Footer[footerEdit.ComponentID]?.Class.fromJSON(footerEdit.Data),
                            editComponent: editFooter,
                        })
                        :
                        null
                    }
                    </>
                }
            </Drawer>
        </RootSider>
    )
}

export {
    PageEditor,
}