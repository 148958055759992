import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import './Banner2.css';

/*
Required Props
BannerURL
ProfessionalTitle
FirstName
LastName
BusinessStatement
*/
class Banner2 extends React.Component {
    render() {
        // const bannerImageURl = "https://firebasestorage.googleapis.com/v0/b/phros-dev-ddfdb.appspot.com/o/pt-assets%2Fphros%2Fpexels-rfstudio-3820364.jpg?alt=media&token=aa812eaf-b52f-450f-8d84-116d3f0e4ae9";
        const bannerImageURl = this.props.BannerImageURL;
        return (
            <Row>
            <Col xs={24} className="Banner2-Main-Col">
                <div className="Banner2-Main-Div">
                    <img className="Banner2-Img" src={bannerImageURl} alt={this.props.ProfessionalTitle} />
                    <div className="Banner2-Text-Div">
                        <h2 className="Banner2-Name">{this.props.FirstName} {this.props.LastName}</h2>
                        <h3 className="Banner2-BusinessStatement">{this.props.BusinessStatement}</h3>
                    </div>
                </div>
            </Col>
            </Row>
        )
    }
}

Banner2.propTypes = {
    BannerImageURL: PropTypes.string.isRequired,
    ProfessionalTitle: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
    BusinessStatement: PropTypes.string.isRequired,
}

export default Banner2;