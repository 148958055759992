const SOCIAL_MEDIA = {
    INSTAGRAM: 'Instagram',
    FACEBOOK: 'Facebook',
    TWITTER: 'Twitter',
    PINTEREST: 'Pinterest',
    LINKEDIN: 'LinkedIn',
    YOUTUBE: 'YouTube',
}

const COUNTRIES = [
    {
        Name: 'USA',
        Code: 'US',
    },
    {
        Name: 'Canada',
        Code: 'CA'
    }
]

const UNITS = {
    WEIGHT: ['lbs', 'kgs'],
    TIME: ['seconds', 'minutes', 'hours'],
    TARGET_INPUT: { REPS: 'reps', TIMED: 'timed', OTHER: 'other' },
    WEIGHT_INPUT: { PERCENTAGE: 'percentage', LB: "lb", KG: "kg", BODYWEIGHT: 'bodyweight', OTHER: 'other' },
};

const COLOUR_PALETTE = {
    PALETTE_1: {
        NAME: 'Palette 1',
        DARK: '#242582',
        MEDIUM: '#C38D9E',
        LIGHT: '#EDF5E1',
    },
    PALETTE_2: {
        NAME: 'Palette 2',
        DARK: '#1A2930',
        MEDIUM: '#659EBC',
        LIGHT: '#F7CE3E',
    },
    PALETTE_3: {
        NAME: 'Palette 3',
        DARK: '#41B3A3',
        MEDIUM: '#85CDCA',
        LIGHT: '#E8A87C',
    },
    PALETTE_4: {
        NAME: 'Palette 4',
        DARK: '#659EBC',
        MEDIUM: '#DAAD86',
        LIGHT: '#FBEEC1',
    },
    PALETTE_5: {
        NAME: 'Palette 5',
        DARK: '#05396B',
        MEDIUM: '#5CDB94',
        LIGHT: '#EDF5E0',
    },
    PALETTE_6: {
        NAME: 'Palette 6',
        DARK: '#55BDCA',
        MEDIUM: '#96CAEF',
        LIGHT: '#CAFAFE',
    },
    PALETTE_7: {
        NAME: 'Palette 7',
        DARK: '#5D5C61',
        MEDIUM: '#7395AE',
        LIGHT: '#B0A295',
    },
    PALETTE_8: {
        NAME: 'Palette 8',
        DARK: '#AFD274',
        MEDIUM: '#E7717D',
        LIGHT: '#C2C9CF',
    },
    PALETTE_9: {
        NAME: 'Palette 9',
        DARK: '#4B5320',
        MEDIUM: '#AFD274',
        LIGHT: '#FC4444',
    }
}

const TRAINING_EXPERIENCE_LEVEL = {
    'BEGINNER': 'Beginner',
    'INTERMEDIATE': 'Intermediate',
    'ADVANCED': 'Advanced',
    'EXPERT': 'Expert',
    "ALLLEVELS": "All Levels"
}

const FREE_EVENTS = {
    YES: {
        Key: 'Yes',
        Value: 'Yes'
    },
    NO: {
        Key: 'No',
        Value: 'No'
    }
}

const environments = {
    DEV: {
        URL: "http://localhost:8080",
        WEB_API: "http://localhost:8081",
    },
    PROD: {
        URL: "https://api2.phros.ca",
        WEB_API: "https://api2.phros.ca",
    }
}

const PLANS = {
    BEGINNER: "BEGINNER",
    INTERMEDIATE: "INTERMEDIATE",
    ADVANCED: "ADVANCED",
}

const FEATURES = {
    LIVECLASSES: "Live Classes",
    ONDEMAND: "On-demand Classes",
    TRAININGPROGRAM: "Build Training Programs",
}

const TRAINING_INTENSITY_LEVEL = [1,2,3,4,5,6];

const FITNESS_CATEGORIES = ['Cardiovascular', 'Stamina', 'Strength', 'Flexibility', 'Power', 'Speed', 'Coordination', 'Agility', 'Balance', 'Control', 'Weight Loss', 'Bodybuilding', 'Yoga', 'Powerlifting', 'Weightlifting']

const COUPON_TYPES = {
    FIXED: {
        value: "fixed",
        name: "Fixed amount discount"
    },
    PERCENTAGE: {
        value: "percentage",
        name: "Percentage discount"
    }
}

const COUPON_DURATION = {
    FOREVER: {
        value: "forever",
        name: "Forever"
    },
    ONCE: {
        value: "once",
        name: "Once",
    },
    MULTIPLE_MONTHS: {
        value: "repeating",
        name: "Multiple months"
    }
}

const SUBSCRIPTION_INTERVAL = {
    WEEK: {
        value: "week",
        name: "Week",
    },
    MONTH: {
        value: "month",
        name: "Month"
    },
    YEAR: {
        value: "year",
        name: "Year",
    }
}

const RESOURCE_CAPACITY = {
    CAPPED: {
        value: "restricted",
        name: "Restricted",
    },
    UNLIMITED: {
        value: "unlimited",
        name: "Unlimited"
    }
}

const TRIAL_PERIOD = {
    NOTRIAL: {
        value: "no-trial",
        name: "No"
    },
    TRIAL: {
        value: "trial",
        name: "Yes"
    }
}

const ONLINE_IN_PERSON = {
    ONLINE: {
        name: "Online",
        value: "ONLINE",
    },
    IN_PERSON: {
        name: "In Person",
        value: "INPERSON"
    }
}

const WEBSITE_SECTIONS = {
    ABOUTME: {
        NAME: "About Me",
        VALUE: "ABOUTME",
    },
    FAQ: {
        NAME: "FAQ",
        VALUE: "FAQ",
    },
    SERVICES: {
        NAME: "Services",
        VALUE: "SERVICES",
    },
    GALLERY: {
        NAME: "Gallery",
        VALUE: "GALLERY",
    },
    TESTIMONIALS: {
        NAME: "Testimonials",
        VALUE: "TESTIMONIALS"
    },
    CONTACT: {
        NAME: "Contact Form",
        VALUE: "CONTACT",
    },
}

const WEBSITE_LINKS = {
    MEMBERSHIPS: "/memberships",
    LIVECLASSES: "/live-classes",
    WORKOUTPROGRAMS: "/programs",
    ONDEMANDCLASSES: "/on-demand-classes",
    CHECKOUT: "/checkout",
    MYFITNESS: "/my-fitness",
    CONTACT: "/#contact-me"
}

const PHROS_WEB_DESIGN_ORDER = {
    SELECT_SECTIONS: "SELECT_SECTIONS",
    ADD_BASIC_INFORMATION: "ADD_BASIC_INFORMATION",
    SELECT_ORDER: "SELECT_SECTION_ORDER",
}

const WEBSITE_COMPONENTS = {
    BANNERS: {
        BANNER1: "Banner 1",
        BANNER2: "Banner 2",
        BANNER3: "Banner 3",
        BANNER4: "Banner 4",
    },
    NAVBARS: {
        NAVBAR1: "Navigation Bar 1",
        NAVBAR2: "Navigation Bar 2",
        NAVBAR3: "Navigation Bar 3",
    },
    ABOUTME: {
        ABOUTME1: "About Me 1",
        ABOUTME2: "About Me 2",
        ABOUTME3: "About Me 3",
    },
    GALLERY: {
        GALLERY1: "Gallery 1",
        GALLERY2: "Gallery 2",
    },
    SERVICE: {
        SERVICE1: "Service 1",
        SERVICE2: "Service 2",
        SERVICE3: "Service 3",
    },
    FAQ: {
        FAQ1: "FAQ 1",
        FAQ2: "FAQ 2",
    },
    TESTIMONIALS: {
        TESTIMONIAL1: "Testimonial 1",
        TESTIMONIAL2: "Testimonial 2",
        TESTIMONIAL3: "Testimonial 3",
    },
    CONTACTME: {
        CONTACT1: "Contact Me 1",
        CONTACT2: "Contact Me 2",
    }
}


/*
NEW (PLANNED) CONSTANTS
*/
const INTENSITY_LEVELS = [1, 2, 3, 4, 5, "All Levels"];
const EXPERIENCE_LEVEL = ["Beginner", "Intermediate", "Advanced", "Expert", "All Levels"];

module.exports = {
    SOCIAL_MEDIA,
    COLOUR_PALETTE,
    TRAINING_EXPERIENCE_LEVEL,
    TRAINING_INTENSITY_LEVEL,
    FREE_EVENTS,
    COUNTRIES,
    FITNESS_CATEGORIES,
    ENVIRONMENT: environments.PROD,
    PLANS,
    FEATURES,
    UNITS,
    COUPON_TYPES,
    COUPON_DURATION,
    SUBSCRIPTION_INTERVAL,
    RESOURCE_CAPACITY,
    TRIAL_PERIOD,
    ONLINE_IN_PERSON,
    WEBSITE_SECTIONS,
    WEBSITE_LINKS,
    PHROS_WEB_DESIGN_ORDER,
    WEBSITE_COMPONENTS,


    INTENSITY_LEVELS,
    EXPERIENCE_LEVEL,
}