import React from 'react';
import { Row, Col, Layout, Button, Popconfirm, message, Table, Tag } from 'antd';
import { H2, } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { storage } from '../../Firebase';
import { DeleteOutlined } from '@ant-design/icons';

class ViewOnDemandClasses extends React.Component {
    static contextType = AuthContext;
    state = {
        OnDemandClasses: [],
        loading: true,
    }

    componentDidMount() {
        this.getEventData();
    }

    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/on-demand-class/get-all-on-demand-classes`, data, config);
        this.setState({
            OnDemandClasses: response.data,
            loading: false,
        })
    }

    deleteEvent = async (index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const event = this.state.OnDemandClasses[index];
        const id = event.ID;

        if (currentUser.uid !== event.uid) return message.error("Not authorized to delete this class.");
        const data = {
            id: id,
        };
        const deleteEvent = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/on-demand-class/delete-on-demand-class`, data, config);
        if (deleteEvent.data.error) return message.error('Failed to delete the on-demand video.');

        const classes = [...this.state.OnDemandClasses];
        classes.splice(index, 1);
        this.setState({
            OnDemandClasses: classes,
        })
        try {
            const file = storage.refFromURL(event.VideoURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        try {
            const file = storage.refFromURL(event.ThumbnailURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        try {
            const file = storage.refFromURL(event.WaiverURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }
        return message.success('Successfully deleted the on-demand-video.');
    }

    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Title}</h4>
                )
            },
            {
                title: "Price",
                dataIndex: "Price",
                key: "Code",
                render: (text, obj) => (
                    <h4>{obj.Price} {obj.Currency ? obj.Currency : null}</h4>
                )
            },
            {
                title: "Published Status",
                dataIndex: "PublishedStatus",
                key: "PublishedStatus",
                render: (text, obj) => {
                    return (
                        obj.Published ?
                        <Tag color="green">PUBLISHED</Tag>
                        :
                        <Tag color="red">NOT PUBLISHED</Tag>
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <Row justify="center" gutter={[10,10]}>
                            <Col>
                                <Link to={`/on-demand-class/${obj.ID}`}>
                                <Button type='primary'>Edit</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Popconfirm
                                title='Are you sure you want to delete this class? This action cannot be reversed, and any customers who purchased this class will not be able to access this class.'
                                onConfirm={() => this.deleteEvent(index)}
                                okText="Delete On-Demand Class"
                                >
                                <Button type='primary' danger icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    )
                }
            },
        ]
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View On-Demand Classes'/>
                            <Table
                            scroll={{ x: true }}
                            loading={this.state.loading}
                            columns={columns} dataSource={this.state.OnDemandClasses} />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewOnDemandClasses;