import React from 'react';
import './ViewLiveClasses.css';
import { Row, Col, Layout, Button, Popconfirm, message, Modal, Table, Tag } from 'antd';
import { H2, H3 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from "@ant-design/icons";

class ViewLiveClasses extends React.Component {
    static contextType = AuthContext;
    state = {
        LiveClasses: [],
        Clients: {},
        loading: true,
        ClientVisible: false,
        LiveClassId: '',
        LiveClassIndex: 0,
        AvailabilityKeys: {},
        ClassConfirmedClients: {},
    }

    componentDidMount() {
        this.getEventData();
    }

    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const events = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/events/get-all-events`, data);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/get-client-purchases`, {}, config);

        let availabilityKeys = {};
        try {
            availabilityKeys = events.data.reduce((obj, liveClass) => {
                const avail = liveClass.Availability.reduce((availObj, avail) => {
                    return {
                        ...availObj,
                        [avail.ID]: avail,
                    }
                }, {});
                return {
                    ...obj,
                    ...avail,
                }
            }, {});
        } catch (e) {
            console.log(e);
        }

        this.setState({
            LiveClasses: events.data,
            AvailabilityKeys: availabilityKeys,
            Clients: response.data.LiveClasses,
            loading: false,
        })
    }

    deleteEvent = async (index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const event = this.state.LiveClasses[index];
        const id = event.ID;
        const data = {
            id: id,
        };
        const deleteEvent = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/events/delete-event`, data, config);
        if (deleteEvent.data.error) return message.error('Failed to delete event.');

        const events = [...this.state.LiveClasses];
        events.splice(index, 1);
        this.setState({
            LiveClasses: events
        })
        return message.success('Successfully deleted the event.');
    }

    handleClientVisible = async (LiveClassID) => {
        // Find the Class Index
        const lcIndex = this.state.LiveClasses.findIndex((lc) => lc.ID === LiveClassID)

        if (lcIndex === -1) {
            message.error("Failed to display the clients for this live class.");
            return;
        }

        // Get the clients
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const eventClients = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/events/get-confirmed-clients`, { ClassId: LiveClassID }, config);
        if (eventClients.status !== 200) {
            return message.error("Could not get the clients for this class.");
        } else {
            // Parse through the data
            if (Array.isArray(eventClients.data)) {
                const parsedData = eventClients.data.reduce((obj, info) => {
                    const avail = info.AvailabilityId;
                    const email = info.Email;
                    const quantity = info.Quantity;
                    
                    if (avail in obj) {
                        const temp = obj[avail];
                        return {
                            ...obj,
                            [avail]: [
                                ...temp,
                                { Email: email, Quantity: quantity }
                            ]
                        }
                    } else {
                        return {
                            ...obj,
                            [avail]: [
                                { Email: email, Quantity: quantity }
                            ]
                        }
                    }
                }, {});
                this.setState({
                    ClassConfirmedClients: parsedData,
                })
            }
        }

        this.setState({
            LiveClassIndex: lcIndex,
            LiveClassId: LiveClassID,
            ClientVisible: true
        })
    }

    handleClientModal = () => {
        this.setState({
            ClientVisible: !this.state.ClientVisible,
        })
    }

    getDisplayTime = (date, start, end, timezone) => {
        try {
            const eventDate = new Date(date).toLocaleDateString();
            const startTime = new Date(start).toLocaleTimeString("en-US", { timeZone: timezone, hour: "2-digit", minute: "2-digit" });
            const endTime = new Date(end).toLocaleTimeString("en-US", { timeZone: timezone, hour: "2-digit", minute: "2-digit" });
            return `${eventDate}, ${startTime} to ${endTime} (${timezone})`;
        } catch (e) {
            console.log(e);
            return `${date}, ${start} to ${end} (${timezone})`;
        }
    }

    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Name}</h4>
                )
            },
            {
                title: "Price",
                dataIndex: "Price",
                key: "Code",
                render: (text, obj) => (
                    <h4>{obj.Price} {obj.Currency ? obj.Currency : null}</h4>
                )
            },
            {
                title: "Published Status",
                dataIndex: "PublishedStatus",
                key: "PublishedStatus",
                render: (text, obj) => {
                    return (
                        obj.Published ?
                        <Tag color="green">PUBLISHED</Tag>
                        :
                        <Tag color="red">NOT PUBLISHED</Tag>
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <Row justify="center" gutter={[10,10]}>
                            <Col>
                                <Button
                                onClick={() => this.handleClientVisible(obj.ID)}>
                                    View Clients
                                </Button>
                            </Col>
                            <Col>
                                <Link to={`/add-live-class/${obj.ID}`}>
                                <Button type='primary'>Edit</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Popconfirm
                                title='Are you sure you want to delete this class? This action cannot be reversed, and any customers who purchased this class will not be able to access this class.'
                                onConfirm={() => this.deleteEvent(index)}
                                okText="Delete Live Class"
                                >
                                <Button type='primary' danger icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    )
                }
            },
        ]

        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View Live Classes'/>
                            <Table
                            scroll={{ x: true }}
                            loading={this.state.loading}
                            pagination={{ defaultPageSize: 5 }}
                            columns={columns} dataSource={this.state.LiveClasses} />
                        </Col>
                    </Row>
                    <Modal
                        visible={this.state.ClientVisible}
                        onCancel={this.handleClientModal}
                        width={600}
                        footer={null}
                    >
                        <Row>
                            <H3 text={`${this.state.LiveClasses[this.state.LiveClassIndex]?.Name}`} />
                        </Row>
                        <Row>
                            <Col xs={12}>
                                Email
                            </Col>
                            <Col xs={6}>
                                Quantity
                            </Col>
                        </Row>
                        {
                            Object.keys(this.state.ClassConfirmedClients).map((availId) => {
                                return (
                                    <div>
                                        <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <Col xs={24}><strong><small>{availId}</small></strong></Col>
                                        </Row>
                                        {
                                            this.state.ClassConfirmedClients[availId].map((client, availIndex) => {
                                                return (
                                                    <Row gutter={[10,10]} style={{ marginTop: '3px', marginBottom: '3px' }}>
                                                        <Col xs={12}>
                                                            {client.Email}
                                                        </Col>
                                                        <Col xs={6}>
                                                            {client.Quantity}
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                )   
                            })
                        }
                        {
                            this.state.Clients?.[this.state.LiveClassId] ?
                            Object.keys(this.state.Clients?.[this.state.LiveClassId]).map((availId, index) => {
                                const users = this.state.Clients?.[this.state.LiveClassId]?.[availId];
                                const availability = this.state.AvailabilityKeys[availId];
                                const availString = this.getDisplayTime(availability.Date, availability.StartTime, availability.EndTime, availability.TimeZone);

                                const clientEmails = users.reduce((arr, client) => {
                                    return [
                                        ...arr,
                                        client.Email,
                                    ]
                                }, []);

                                return (
                                    <div>
                                        <Row>
                                            <Col xs={24}><strong>{availString}</strong></Col>
                                        </Row>
                                        {
                                            users.map((client, availIndex) => {
                                                return (
                                                    <Row gutter={[10,10]} style={{ marginTop: '3px', marginBottom: '3px' }}>
                                                        <Col xs={12}>
                                                            {client.Email}
                                                        </Col>
                                                        <Col xs={6}>
                                                            1
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                        <Row style={{ marginTop: "10px" }}>
                                            <Col xs={24}>
                                                <Button
                                                href={`mailto:${clientEmails.join(";")}`}
                                                block type="primary">Send Email to the Clients Above</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )

                            })
                            :
                            null
                        }
                    </Modal>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewLiveClasses;