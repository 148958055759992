import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>

class GoogleMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: 0,
            longitude: 0,
            zoom: 11,
        }
    }

    render() {
        return (
            <div style={{ height: "40vh", width: "100%" }}>
                <GoogleMapReact
                    defaultCenter = {{
                        center: {
                            lat: this.state.latitude,
                            lng: this.state.longitude
                        }
                    }}
                    defaultZoom={this.state.zoom}
                >
                    <AnyReactComponent
                        lat={this.state.latitude}
                        lng={this.state.longitude}
                        text="Location"
                    />
                </GoogleMapReact>
            </div>
        )
    }
}

export default GoogleMap;