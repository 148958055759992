import React from 'react';
import './CardButton.css';
import { Link } from 'react-router-dom';
import { H1 } from '../index';

class CardButton extends React.Component {
    render() {
        return (
            <Link to={this.props.LinkTo}>
                <div className='CardButton-Div'>
                    <H1 text={this.props.text} />
                </div>
            </Link>
        )
    }
}

export default CardButton;