import React from 'react';
import { Row, Modal, Col, Layout, Input, Button, Upload, Card, Select, Affix, message, InputNumber, notification, Divider, Tag, Popconfirm } from 'antd';
import { SideNav } from '../../Components';
import { H2 } from '../../Common';
import { AuthContext } from '../../Context/AuthContext';
import { UploadOutlined, DeleteOutlined, PlusCircleOutlined, EditOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { storage } from '../../Firebase';
import { v4 } from 'uuid';
import currencies from '../../Assets/currencies';
import { TRAINING_EXPERIENCE_LEVEL, TRAINING_INTENSITY_LEVEL, FREE_EVENTS, FITNESS_CATEGORIES, UNITS } from '../../constants';
import axios from 'axios';
import './AddWorkout.css';
import './Program.css';
import { checkArray, checkString, checkBool, checkNum } from '../../Verification';

class Program extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            Title: '',
            Description: '',
            Price: 0,
            Currency: '',
            Categories: [],
            FreeClass: FREE_EVENTS.NO.Key,
            IntensityRating: 1,
            Level: undefined,
            Published: false,
            Program: [],
            WorkoutModalVisible: false,
            WorkoutID: null,
            WeekIndex: 0,
            DayIndex: 0,
            Exercises: [],
            ExerciseKeys: {},
            exerciseLoading: false,

            WorkoutIndex: null,
            WorkoutName: "",
            WorkoutExercise: null,
            WorkoutDescription: "",
            WorkoutExercises: [],

            ExerciseIndex: null,
            ExerciseDeepIndex: null,

            SupersetModal: false,
            SupersetExercises: [],
            SupersetSelectedExercise: null,

            InstructionModal: false,
            Instructions: [],

            WorkoutTemplates: [],
            WorkoutTemplatesObj: {},

            ImportTemplate: false,
            ImportTemplateID: null,

            PictureFileList: [],
            PictureURL: '',
            PictureDisabled: false,

            WaiverFileList: [],
            WaiverURL: '',
            WaiverDisabled: false,

            TargetType_All: null,
            Target_All: null,
            WeightType_All: null,
            ExtraNotes_All: null,
            Weight_All: null,
            Hours_All: 0,
            Minutes_All: 0,
            Seconds_All: 0,
        }
    }

    componentDidMount() {
        this.getExerciseData();
        this.getDataToBuildPrograms();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.ID !== this.props.match.params.ID) {
            this.setState({
                Title: '',
                Description: '',
                Price: 0,
                Currency: '',
                Categories: [],
                FreeClass: FREE_EVENTS.NO.Key,
                IntensityRating: 1,
                Level: undefined,
                Published: false,
                WorkoutModalVisible: false,
                exerciseLoading: false,

                WorkoutID: null,
                WeekIndex: 0,
                DayIndex: 0,
                WorkoutIndex: null,
                WorkoutName: "",
                WorkoutExercise: null,
                WorkoutDescription: "",
                WorkoutExercises: [],

                SupersetModal: false,
                SupersetExercises: [],
                SupersetSelectedExercise: null,

                InstructionModal: false,
                Instructions: [],

                ExerciseIndex: null,
                ExerciseDeepIndex: null,

                Programs: [],

                PictureFileList: [],
                PictureURL: '',
                PictureDisabled: false,

                WaiverFileList: [],
                WaiverURL: '',
                WaiverDisabled: false,

                TargetType_All: null,
                Target_All: null,
                WeightType_All: null,
                ExtraNotes_All: null,
                Weight_All: null,
                Hours_All: 0,
                Minutes_All: 0,
                Seconds_All: 0,
            });
            this.getDataToBuildPrograms();
        }
    }

    saveProgram = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            Title: this.state.Title,
            Description: this.state.Description,
            Currency: this.state.Currency,
            Categories: this.state.Categories,
            IntensityRating: this.state.IntensityRating,
            Level: this.state.Level,
            Program: this.state.Program,
            FreeEvent: this.state.FreeClass,
            Price: this.state.Price,
            ID: this.props.match.params.ID,
            PictureURL: this.state.PictureURL,
            WaiverURL: this.state.WaiverURL,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/programs/save-workout-program`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error",
                description: "Failed to save program. Please try again, or contact our team if you're having trouble."
            });
        } else {
            notification.success({
                message: "Success",
                description: "Successfully saved the program."
            })
        }
        return response;
    }

    handleFileUpload = async (fileListName, urlName, buttonName, info) => {
        this.setState({
            [buttonName]: true
        })
        const url = await new Promise((resolve, reject) => {
            const file = info.file;
            const { currentUser } = this.context;
            const uid = currentUser.uid;
            const filename = `${v4()}-${file.name}`;
            const uploadTask = storage.ref(`/static/${uid}/${filename}`).put(file);
            this.setState({
                [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: 0 }]
            })
            uploadTask.on('state_changed',
            (snapshot) => {
                this.setState({
                    [fileListName]: [{ uid: filename, name: file.name, status: 'uploading', url: '', percent: snapshot.bytesTransferred/snapshot.totalBytes*100 }]
                })
            },
            (err) => {
                console.log(err);
            }, () => {
                storage.ref(`static/${uid}`).child(filename).getDownloadURL()
                .then(firebaseUrl => {
                    this.setState({
                        [fileListName]: [{ uid: filename, name: file.name, status: 'done', url: firebaseUrl, percent: 100 }]
                    })
                    try {
                        if (this.state[urlName] !== null && this.state[urlName] !== undefined && String(this.state[urlName]).length > 0) {
                            // Send URL to backend to delete
                            const previousLogo = storage.refFromURL(this.state[urlName]);
                            previousLogo.delete();
                        }
                    } catch (e) {
                        message.error('Failed to delete the file.');
                    }
                    resolve(firebaseUrl);
                })
            })
        });
        this.setState({
            [urlName]: url,
            [fileListName]: false,
        })
    }

    publishProgram = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ID: this.props.match.params.ID,
            Published: !this.state.Published,
        }
        await this.saveProgram();
        const publish = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/programs/publish-workout-program`, data, config);
        this.setState({
            Published: !this.state.Published,
        })
        if (!publish.data.error) {
            notification.success({
                message: "Success",
                description: "Successfully saved and changed published status of the training program."
            })
        } else {
            notification.error({
                message: "Error",
                description: "Failed to publish the training program."
            })
        }
    }

    saveWorkoutTemplate = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            WorkoutID: this.state.WorkoutID,
            WorkoutName: this.state.WorkoutName,
            WorkoutDescription: this.state.WorkoutDescription,
            WorkoutExercises: this.state.WorkoutExercises,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/workout-template/save-workout-template`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error",
                description: "Failed to save workout template. Please try again, or contact our team if you're having trouble."
            });
        } else {
            notification.success({
                message: "Success",
                description: "Successfully saved the workout template."
            })
        }
        let tempObj = this.state.WorkoutTemplatesObj;
        tempObj[this.state.WorkoutID] = data;
        this.setState({
            WorkoutTemplates: [...this.state.WorkoutTemplates, data],
            WorkoutTemplatesObj: tempObj
        })
    }

    getDataToBuildPrograms = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            ProgramID: this.props.match.params.ID
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/programs/get-data-to-build-programs`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error",
                notification: "Failed to get the data to build the program, please refresh the page."
            })
            return;
        }
        const workoutTempObj = checkArray(response.data.WorkoutTemplates).reduce((obj, wkt) => {
            return {
                ...obj,
                [wkt.WorkoutID]: wkt,
            }
        }, {});
        const program = response.data.Program
        let free = FREE_EVENTS.NO.Key;
        if (program.FreeClass === true) {
            free = FREE_EVENTS.YES.Key;
        }
        this.setState({
            WorkoutTemplates: response.data.WorkoutTemplates,
            WorkoutTemplatesObj: workoutTempObj,
            Title: checkString(program.Title),
            Description: checkString(program.Description),
            Price: checkNum(program.Price),
            Currency: checkString(program.Currency),
            Categories: checkArray(program.Categories),
            FreeClass: free,
            IntensityRating: checkNum(program.IntensityRating),
            Level: checkString(program.Level),
            Program: checkArray(program.Program),
            Published: checkBool(program.Published),
            PictureURL: checkString(program.PictureURL),
            WaiverURL: checkString(program.WaiverURL),
        })
    }

    getExerciseData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/exercise/get-all-exercises`, data, config);
        const exerciseKeys = response.data.reduce((obj, exer) => {
            return {
                ...obj,
                [exer.ID]: exer,
            }
        }, {});
        this.setState({
            Exercises: response.data,
            ExerciseKeys: exerciseKeys,
            exerciseLoading: false,
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    handleArrayAdd = (name, value) => {
        let tempArray = [...this.state[name], value];
        this.setState({
            [name]: tempArray
        })
    }

    handleArrayChange = (arrName, name, index, value) => {
        let temp = this.state[arrName][index];
        temp[name] = value;
        this.setState({
            [arrName]: [...this.state[arrName].slice(0, index), temp, ...this.state[arrName].slice(index+1, this.state[arrName].length)]
        })
    }

    handleArrayDelete = (name, value) => {
        let tempArray = [...this.state[name]];
        let index = tempArray.indexOf(value);
        tempArray.splice(index, 1);
        this.setState({
            [name]: tempArray
        })
    }

    deleteFromArray = (name, index) => {
        let tempArray = [...this.state[name]];
        tempArray.splice(index, 1);
        this.setState({
            [name]: tempArray,
        })
    }

    addWeekToProgram = () => {
        const day = {
            day: [],
        }
        const week = {
            week: [day, day, day, day, day, day, day]
        };
        this.setState({
            Program: [...this.state.Program, week]
        });
    }
    deleteWeekFromProgram = (weekIndex) => {
        let program = [...this.state.Program];
        program.splice(weekIndex, 1);
        this.setState({
            Program: program,
        })
    }

    deleteWorkoutFromDay = (weekIndex, dayIndex, workoutIndex) => {
        let program = [...this.state.Program];
        let week = program[weekIndex].week;
        let day = week[dayIndex].day;
        day.splice(workoutIndex, 1);
        week = [...week.slice(0, dayIndex), { day }, ...week.slice(dayIndex+1, week.length)];
        program = [...program.slice(0, weekIndex), { week }, ...program.slice(weekIndex+1, program.length)];
        this.setState({
            Program: program,
        })
    }

    saveWorkoutForDay = (weekIndex, dayIndex, workoutIndex, value) => {
        let program = [...this.state.Program];
        let week = program[weekIndex].week;
        let day = week[dayIndex].day;
        let newDay = [];
        if (workoutIndex === null) {
            newDay = [...day, value];
        } else {
            newDay = [...day.slice(0, workoutIndex), value, ...day.slice(workoutIndex+1, day.length)];
        }
        week = [...week.slice(0, dayIndex), { day: newDay }, ...week.slice(dayIndex+1, week.length)];
        program = [...program.slice(0, weekIndex), { week }, ...program.slice(weekIndex+1, program.length)];
        this.setState({
            Program: program,
        })
    }

    saveInstruction = (exerciseIndex, exerciseDeepIndex, value) => {
        let exercisePlacement = this.state.WorkoutExercises[exerciseIndex].exercise;
        let exercise = exercisePlacement[exerciseDeepIndex];
        exercise.Instructions = value;
        let newExerciseArr = [...exercisePlacement.slice(0, exerciseDeepIndex), exercise, ...exercisePlacement.slice(exerciseDeepIndex+1, exercisePlacement.length)];
        this.setState({
            WorkoutExercises: [...this.state.WorkoutExercises.slice(0, exerciseIndex), { exercise: newExerciseArr }, ...this.state.WorkoutExercises.slice(exerciseIndex+1, this.state.WorkoutExercises.length)]
        })
    }

    resetWorkoutModal = () => {
        this.setState({
            WorkoutID: null,
            WeekIndex: 0,
            DayIndex: 0,
            WorkoutIndex: null,
            WorkoutName: "",
            WorkoutExercise: null,
            WorkoutDescription: "",
            WorkoutExercises: [],
        })
    }

    handleApplyToAll = () => {
        for (let i = 0; i<this.state.Instructions.length; i++) {
            this.handleArrayChange('Instructions', 'TargetType', i, this.state.TargetType_All);
            this.handleArrayChange('Instructions', 'Target', i, this.state.Target_All);
            this.handleArrayChange('Instructions', 'WeightType', i, this.state.WeightType_All);
            this.handleArrayChange('Instructions', 'ExtraNotes', i, this.state.ExtraNotes_All);
            this.handleArrayChange('Instructions', 'Weight', i, this.state.Weight_All);
            this.handleArrayChange('Instructions', 'Hours', i, this.state.Hours_All);
            this.handleArrayChange('Instructions', 'Minutes', i, this.state.Minutes_All);
            this.handleArrayChange('Instructions', 'Seconds', i, this.state.Seconds_All);
        }
    }

    deleteWorkoutTemplate = async (WorkoutID) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            WorkoutID
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/workout-template/delete-workout-template`, data, config);
        if (response.data.error) {
            notification.error({
                message: "Error",
                description: "Failed to delete workout template. Please try again, or contact our team if you're having trouble."
            });
        } else {
            notification.success({
                message: "Success",
                description: "Successfully deleted the workout template."
            })
        }
    }


    render() {
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                <Row justify="space-around" className="AddLiveClass-Row">
                    <Col xs={23} sm={22}>
                        <Row>
                            <Col xs={24}>
                            <H2 text='Program Planner' />
                            </Col>
                        </Row>
                        <Row gutter={[10,10]}>
                        <Col xs={24} sm={8}>
                            <h3>Title</h3>
                            <Input value={this.state.Title} onChange={(e) => this.handleChange('Title', e.target.value)} placeholder="I.e. HIIT Training"/>
                        </Col>
                        <Col xs={24} sm={6}>
                            <h3>Free Workout Program?</h3>
                            <Select onChange={(value) => this.handleChange('FreeClass', value)} value={this.state.FreeClass} style={{ width: '100%' }}>
                                <Select.Option key={FREE_EVENTS.YES.Key}>{FREE_EVENTS.YES.Value}</Select.Option>
                                <Select.Option key={FREE_EVENTS.NO.Key}>{FREE_EVENTS.NO.Value}</Select.Option>
                            </Select>
                        </Col>
                        {
                            this.state.FreeClass === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={4}>
                            <h3>Price</h3>
                            <InputNumber className='AddLiveClass-Input' min={1} value={this.state.Price} onChange={(value) => this.handleChange('Price', value)} />
                            <p style={{ marginBottom: '0px' }}>Minimum price is $1, unless free </p>
                            </Col>
                            :
                            null
                        }
                        {
                            this.state.FreeClass === FREE_EVENTS.NO.Key ?
                            <Col xs={24} sm={6}>
                            <h3>Currency</h3>
                            <Select
                                placeholder="Search for a currency"
                                filterOption={(input, option) =>
                                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(value) => this.handleChange('Currency', value)}
                                showSearch={true}
                                value={this.state.Currency}
                                style={{ width: '100%' }}
                                >
                                    {
                                        Object.keys(currencies).map((curr) => {
                                            return (
                                                <Select.Option key={curr} name={currencies[curr]}>
                                                <strong>{curr}: </strong>{currencies[curr]}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </Col>
                            :
                            null
                        }
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <h3>Description</h3>
                                <Input.TextArea value={this.state.Description} onChange={(e) => this.handleChange("Description", e.target.value)} autoSize={{ minRows: 3}} />
                            </Col>
                        </Row>
                        <Row className='AddLiveClass-Row' gutter={[10,10]}>
                            <Col xs={24} sm={8}>
                                <h3>Intensity Rating</h3>
                                <Select value={this.state.IntensityRating} className='AddLiveClass-Input' onChange={(value) => this.handleChange('IntensityRating', parseInt(value))}>
                                    {
                                        TRAINING_INTENSITY_LEVEL.map(val => {
                                            return <Select.Option key={val}>{val}</Select.Option>
                                        })
                                    }
                                </Select>
                                <p>1: Low Intensity, 5: High Intensity</p>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Level of Experience</h3>
                                <Select value={this.state.Level} className='AddLiveClass-Input' onSelect={(value) => this.handleChange('Level', value)}>
                                    {
                                        Object.keys(TRAINING_EXPERIENCE_LEVEL).map(lvl => {
                                            return <Select.Option key={lvl}>{TRAINING_EXPERIENCE_LEVEL[lvl]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} sm={8}>
                                <h3>Categories</h3>
                                <Select
                                mode='multiple'
                                style={{ width: '100%' }}
                                value={this.state.Categories}
                                onSelect={(value) => this.handleArrayAdd('Categories', value)}
                                onDeselect={(value) => this.handleArrayDelete('Categories', value)}
                                >
                                    {
                                        FITNESS_CATEGORIES.map((cat) => {
                                            return (
                                                <Select.Option key={cat}>{cat}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                <p>Select Multiple</p>
                            </Col>
                        </Row>

                        <Row className='AddLiveClass-Row' gutter={[10,10]}>
                        <Col xs={24} sm={12}>
                        <h3>Upload a Workout Program Picture</h3>
                        <p>Upload a picture for your workout program (makes it more enticing to buy!)</p>
                        {
                            this.state.PictureURL !== undefined && this.state.PictureURL !== null && String(this.state.PictureURL).length > 0?
                            <Card
                            hoverable
                            style={{ width: '100%' }}
                            cover={<img src={this.state.PictureURL} alt='Profile' className='Profile-ProfilePicture' />}
                            >
                            <Upload
                                disabled={this.state.PictureDisabled}
                                listType='picture'
                                accept=".png,.jpeg,.jpg"
                                customRequest={(info) => this.handleFileUpload('PictureFileList','PictureURL','PictureDisabled',info)}
                                fileList={this.state.PictureFileList}>
                                <Button icon={<UploadOutlined />}>Replace Program Picture</Button>
                            </Upload>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.PictureDisabled}
                                listType='picture'
                                accept=".png,.jpeg,.jpg"
                                customRequest={(info) => this.handleFileUpload('PictureFileList','PictureURL','PictureDisabled',info)}
                                fileList={this.state.PictureFileList}>
                                <Button icon={<UploadOutlined />}>Upload Program Picture</Button>
                            </Upload>
                        }
                        </Col>
                        <Col xs={24} sm={12}>
                        <h3>Upload a Workout Program Waiver</h3>
                        <p>Have a waiver your clients need to confirm on? Upload it here.</p>
                        {
                            this.state.WaiverURL !== undefined && this.state.WaiverURL !== null && String(this.state.WaiverURL).length > 0 ?
                            <Card
                            hoverable
                            style={{ minWidth: '100%' }}
                            >
                            <Row style={{ textAlign: 'center' }} gutter={[10,10]}>
                                <Col xs={24}>
                                <a target="_blank" href={this.state.WaiverURL} rel="noreferrer">View Uploaded PDF</a>
                                </Col>
                                <Col xs={24}>
                                <Upload
                                disabled={this.state.WaiverDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled',info)}
                                fileList={this.state.WaiverFileList}>
                                <Button icon={<UploadOutlined />}>Replace Program Waiver</Button>
                                </Upload>
                                </Col>
                            </Row>
                            </Card>
                            :
                            <Upload
                                disabled={this.state.WaiverDisabled}
                                listType='text'
                                accept=".pdf"
                                customRequest={(info) => this.handleFileUpload('WaiverFileList','WaiverURL','WaiverDisabled',info)}
                                fileList={this.state.WaiverFileList}>
                                <Button icon={<UploadOutlined />}>Upload Program Document</Button>
                            </Upload>
                        }
                        </Col>
                    </Row>

                        {
                            this.state.Program.map((weekObj, weekIndex) => {
                                const week = weekObj.week;
                                return (
                                    <Row align="stretch" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Col xs={24}><h3>Week {weekIndex+1}</h3></Col>
                                        <Col xs={24}>
                                            <Row align="stretch">

                                            {
                                                week.map((dayObj, dayIndex) => {
                                                    const day = dayObj.day;
                                                    return (
                                                        <Col xs={24} sm={3}>
                                                            <p>Day {dayIndex+1}</p>
                                                            <div className="Program-Week-Day">
                                                            {
                                                                day.map((workout, workoutIndex) => {
                                                                    return <Tag onClick={() => {
                                                                        this.handleChange('WorkoutID', workout.WorkoutID);
                                                                        this.handleChange("WorkoutName", workout.WorkoutName);
                                                                        this.handleChange("WorkoutDescription", workout.WorkoutDescription);
                                                                        this.handleChange("WorkoutExercises", workout.WorkoutExercises);
                                                                        this.handleChange("WeekIndex", weekIndex);
                                                                        this.handleChange("DayIndex", dayIndex);
                                                                        this.handleChange("WorkoutIndex", workoutIndex);
                                                                        this.handleChange('WorkoutModalVisible', true);
                                                                    }} className="Workout-Tag" color="blue">{String(workout.WorkoutName).length > 0 ? workout.WorkoutName : "Unnamed Workout"}</Tag>
                                                                })
                                                            }
                                                            <Button onClick={() => {
                                                                this.resetWorkoutModal();
                                                                this.handleChange("WeekIndex", weekIndex);
                                                                this.handleChange("DayIndex", dayIndex);
                                                                this.handleChange("WorkoutModalVisible", true);
                                                            }} type="ghost" icon={<PlusCircleOutlined />} block className="Program-Week-Day-Add" />
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            <Col xs={24} sm={3}>
                                                <p>Delete Week</p>
                                                <Button onClick={() => this.deleteWeekFromProgram(weekIndex)} type="primary" danger className="Program-Week-Day-Delete" icon={<DeleteOutlined />} />
                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                        <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <Col xs={24}>
                                <Button type="primary" onClick={this.addWeekToProgram}>Add Training Week to Program</Button>
                            </Col>
                        </Row>

                        <Row justify='end' className='AddLiveClass-Save-Buttons' gutter={[10,10]}>
                            <Col xs={24} sm={6}>
                                {
                                    this.state.Published ?
                                    <Button block type='primary' danger onClick={() => this.publishProgram()}>Un-Publish Program</Button>
                                    :
                                    <Button block className='Button-Yellow' onClick={() => this.publishProgram()}>Publish Program</Button>
                                }
                            </Col>
                            <Col xs={24} sm={6}>
                                <Affix offsetBottom={20}>
                                <Button block type='primary' className='Button' onClick={() => this.saveProgram()}>Save Program</Button>
                                </Affix>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal
                footer={null}
                onCancel={() => {
                    // this.saveWorkoutForDay(this.state.WeekIndex, this.state.DayIndex, this.state.WorkoutIndex, value);
                    this.resetWorkoutModal();
                    this.handleChange('WorkoutModalVisible', false)
                }}
                visible={this.state.WorkoutModalVisible}
                width={1000}>
                    {
                        this.state.WorkoutID === null && !this.state.ImportTemplate ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}><h2>Workout Planner</h2></Col>
                            <Col xs={24} sm={12}>
                                <Button className="Program-Workout-Planner-Large-Action" onClick={() => this.handleChange('WorkoutID', v4())} >create new workout</Button>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Button className="Program-Workout-Planner-Large-Action" onClick={() => this.handleChange("ImportTemplate", true)}>import from template</Button>
                            </Col>
                        </Row>
                        :
                        this.state.WorkoutID === null && this.state.ImportTemplate ?
                        <Row gutter={[10,10]} align="bottom">
                            <Col xs={12}>
                                <Button icon={<ArrowLeftOutlined />} onClick={() => this.handleChange("ImportTemplate", false)} />
                            </Col>
                            <Col xs={24}><h2>Workout Planner</h2></Col>
                            <Col xs={24}>
                                <h3>Import Workout Template</h3>
                                {
                                    this.state.WorkoutTemplates.map((template, index) => {
                                        return (
                                            <div>
                                            <Row gutter={[10,10]}>
                                                <Col xs={12}>
                                                    <strong>
                                                    {template.WorkoutName}
                                                    </strong>
                                                    <div>
                                                        {template.WorkoutDescription}
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <Button type="primary" block
                                                    onClick={() => {
                                                        this.handleChange("ImportTemplateID", template.WorkoutID);
                                                        this.setState({
                                                            WorkoutID: v4(),
                                                            WorkoutName: template.WorkoutName,
                                                            WorkoutDescription: template.WorkoutDescription,
                                                            WorkoutExercises: template.WorkoutExercises,
                                                            ImportTemplateID: null,
                                                            ImportTemplate: false,
                                                        })
                                                    }}>
                                                        Import Template
                                                    </Button>
                                                </Col>
                                                <Col xs={6}>
                                                    <Popconfirm
                                                    title="If you delete this workout template, it cannot be undone."
                                                    onConfirm={() => {
                                                        this.deleteWorkoutTemplate(template.WorkoutID);
                                                        const workTemplates = [...this.state.WorkoutTemplates];
                                                        workTemplates.splice(index, 1);
                                                        this.handleChange("WorkoutTemplates", workTemplates);
                                                    }}>
                                                        <Button type="primary" danger block>Delete Template</Button>
                                                    </Popconfirm>
                                                </Col>
                                                <Col xs={24}>
                                                    <Divider />
                                                </Col>
                                            </Row>
                                            </div>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        :
                        this.state.WorkoutID !== null ?
                        <Row gutter={[10,10]}>
                            <Col xs={24}><h2>Workout Planner</h2></Col>
                            <Col xs={24}>
                                <Row><h3>Workout Name</h3></Row>
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col xs={24}>
                                        <Input value={this.state.WorkoutName} onChange={(e) => this.handleChange("WorkoutName", e.target.value)} />
                                    </Col>
                                </Row>
                                <Row><h3>Workout Description (optional)</h3></Row>
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col xs={24}>
                                        <Input value={this.state.WorkoutDescription} onChange={(e) => this.handleChange("WorkoutDescription", e.target.value)} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24}>
                                <Row gutter={[10,10]}>
                                    <Col xs={24} sm={16}>
                                    <h3>Search & Add Exercise</h3>
                                    <Select
                                    placeholder="search"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.name !== null && String(option.props.name).toLowerCase().includes((String(input).toLowerCase()))
                                    }
                                    value={this.state.WorkoutExercise}
                                    onChange={(value) => {
                                        this.handleChange("WorkoutExercise", value);
                                        const workout = {
                                            exercise: [{
                                                ExerciseID: value,
                                                Instructions: [],
                                            }]
                                        };
                                        this.handleArrayAdd("WorkoutExercises", workout);
                                    }}
                                    style={{ width: '100%' }}>
                                        {
                                            this.state.Exercises.map((exer) => {
                                                return (
                                                    <Select.Option key={exer.ID} name={`${exer.Title} ${exer.ReferenceName}`}>
                                                    {exer.Title} - {exer.ReferenceName}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <h3>Create Superset/Circuit</h3>
                                        <Button className="Program-Create-Superset" block onClick={() => this.handleChange("SupersetModal", true)}>Create Superset/Circuit</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24}>
                                <Divider />
                            </Col>
                            <Col xs={24}>
                                {
                                    this.state.WorkoutExercises.map((obj, exerIndex) => {
                                        const exer = obj.exercise;
                                        let superset = false;
                                        if (exer.length > 1) {
                                            superset = true;
                                        }
                                        return (
                                            <div className="Program-Exercise-Row">
                                            <h3>Exercise {exerIndex+1} {superset ? "(Superset)" : null}</h3>
                                            {
                                                exer.map((exercise, exerciseDeepIndex) => {
                                                    return (
                                                        <Row justify='space-between' className="Program-Workout-Exercise">
                                                            <Col xs={24} sm={16}>
                                                                <p className="Program-Workout-Name">{this.state.ExerciseKeys[exercise.ExerciseID]?.Title}</p>
                                                            </Col>
                                                            <Col xs={24} sm={8}>
                                                            <Row gutter={[10,10]} justify="end">
                                                                <Col xs={24} sm={16}>
                                                                    <Button
                                                                    icon={<EditOutlined />}
                                                                    block onClick={() => {
                                                                        this.handleChange("ExerciseIndex", exerIndex);
                                                                        this.handleChange("ExerciseDeepIndex", exerciseDeepIndex);
                                                                        this.handleChange("InstructionModal", true);
                                                                        this.handleChange("Instructions", exercise.Instructions)
                                                                    }}>Add/Edit Instructions</Button>
                                                                </Col>
                                                                {
                                                                    exerciseDeepIndex === 0 ?
                                                                    <Col xs={24} sm={8}>
                                                                        <Button icon={<DeleteOutlined />} block type="primary" danger onClick={() => this.deleteFromArray("WorkoutExercises", exerIndex)} />
                                                                    </Col>
                                                                    :
                                                                    <Col xs={24} sm={8}>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            </div>
                                        )
                                    })
                                }
                            </Col>
                            <Col xs={24}>
                                <Row justify="end" gutter={[10,10]} style={{ marginTop: '30px' }}>
                                    {
                                        this.state.WorkoutIndex !== null ?
                                        <Col>
                                            <Button
                                            className="Program-Workout-Delete-Workout"
                                            type="primary" danger onClick={() => {
                                                this.deleteWorkoutFromDay(this.state.WeekIndex, this.state.DayIndex, this.state.WorkoutIndex);
                                                this.handleChange("WorkoutModalVisible", false);
                                            }}>Delete Workout from Day {this.state.DayIndex+1}, Week {this.state.WeekIndex+1}</Button>
                                        </Col>
                                        :
                                        null
                                    }
                                    <Col><Button className="Button-Yellow" onClick={() => this.saveWorkoutTemplate()}>Save as Workout Template</Button></Col>
                                    <Col>
                                    <Button
                                    className="Program-Workout-Save-Workout"
                                    type="primary"
                                    onClick={() => {
                                        const value = {
                                            WorkoutID: this.state.WorkoutID,
                                            WorkoutName: this.state.WorkoutName,
                                            WorkoutDescription: this.state.WorkoutDescription,
                                            WorkoutExercises: this.state.WorkoutExercises,
                                        };
                                        this.saveWorkoutForDay(this.state.WeekIndex, this.state.DayIndex, this.state.WorkoutIndex, value);
                                        this.resetWorkoutModal();
                                        this.handleChange("WorkoutModalVisible", false);
                                    }}>Save Workout for Week {this.state.WeekIndex+1}, Day {this.state.DayIndex+1}</Button></Col>
                                </Row>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </Modal>
                <Modal
                width={800}
                onOk={() => {
                    const val = {
                        exercise: this.state.SupersetExercises,
                    }
                    this.handleArrayAdd("WorkoutExercises", val);
                    this.handleChange("SupersetModal", false);
                }}
                okText="Add Superset/Circuit"
                visible={this.state.SupersetModal}
                onCancel={() => this.handleChange("SupersetModal", false)}>
                    <Row>
                        <Col xs={24}>
                            <h3>Create a Superset/Circuit</h3>
                        </Col>
                        <Col xs={24}>
                        <Col xs={24}>
                            <Row gutter={[10,10]}>
                                <Col xs={24}>
                                <h3>Search for an Exercise</h3>
                                <Row>
                                    <Col xs={24}>
                                    <Select
                                    placeholder="search"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.name !== null && String(option.props.name).toLowerCase().includes((String(input).toLowerCase()))
                                    }
                                    value={this.state.SupersetSelectedExercise}
                                    onChange={(value) => {
                                        this.handleChange("SupersetSelectedExercise", value);
                                        const workout = {
                                            ExerciseID: value,
                                            Instructions: [],
                                        };
                                        this.handleArrayAdd("SupersetExercises", workout);
                                    }}
                                    style={{ width: '100%' }}>
                                        {
                                            this.state.Exercises.map((exer) => {
                                                return (
                                                    <Select.Option key={exer.ID} name={`${exer.Title} ${exer.ReferenceName}`}>
                                                    {exer.Title} - {exer.ReferenceName}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        {
                            this.state.SupersetExercises.map((exer, exerIndex) => {
                                return (
                                    <div className="Program-Exercise-Row">
                                    <p>Superset Exercise {exerIndex+1}</p>
                                    <Row justify='space-between' className="Program-Workout-Exercise">
                                        <Col xs={24} sm={16}>
                                            <h3 className="Program-Workout-Name">{this.state.ExerciseKeys[exer.ExerciseID].Title}</h3>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                        <Row justify="end">
                                            <Col xs={24} sm={8}>
                                                <Button icon={<DeleteOutlined />} block type="primary" danger onClick={() => this.deleteFromArray("SupersetExercises", exerIndex)} />
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                    </div>
                                )
                            })
                        }
                        </Col>
                    </Row>
                </Modal>
                <Modal
                width={1000}
                visible={this.state.InstructionModal}
                okText="Complete Instructions"
                onCancel={() => this.handleChange("InstructionModal", false)}
                onOk={() => {
                    this.saveInstruction(this.state.ExerciseIndex, this.state.ExerciseDeepIndex, this.state.Instructions);
                    this.handleChange("InstructionModal", false);
                }}
                >
                    <Row>
                        <Col xs={24}><h3>Add Exercise Instructions</h3></Col>
                    </Row>
                    <Row align="bottom" gutter={[10,10]} className="Program-Exercise-Row">
                        <Col xs={24} sm={2}>
                        <p>All Sets</p>
                        </Col>

                        <Col xs={24} sm={3}>
                        <p>Target Type</p>
                        <Select
                        value={this.state.TargetType_All}
                        onChange={(value) => this.handleChange("TargetType_All", value)}
                        className="Program-Input-Width">
                            {
                                Object.keys(UNITS.TARGET_INPUT).map((key) => {
                                    return <Select.Option key={UNITS.TARGET_INPUT[key]} value={UNITS.TARGET_INPUT[key]}>{UNITS.TARGET_INPUT[key]}</Select.Option>
                                })
                            }
                        </Select>
                        </Col>

                        <Col xs={24} sm={6}>
                            {
                                this.state.TargetType_All === UNITS.TARGET_INPUT.REPS ?
                                <p>Reps</p>
                                :
                                this.state.TargetType_All === UNITS.TARGET_INPUT.TIMED ?
                                null
                                :
                                this.state.TargetType_All === UNITS.TARGET_INPUT.OTHER ?
                                <p>Target Details</p>
                                :
                                null
                            }
                            {
                                this.state.TargetType_All === UNITS.TARGET_INPUT.REPS ?
                                <InputNumber className="Program-Input-Width" placeholder="enter number" value={this.state.Target_All} onChange={(value) => this.handleChange("Target_All", value)} />
                                :
                                this.state.TargetType_All === UNITS.TARGET_INPUT.TIMED ?
                                <Row>
                                    <Col xs={24} sm={8}><p>Hours</p><InputNumber className="Program-Input-Width" min={0} value={this.state.Hours_All} onChange={(value) => this.handleChange("Hours_All", value)} /></Col>
                                    <Col xs={24} sm={8}><p>Minutes</p><InputNumber className="Program-Input-Width" min={0} value={this.state.Minutes_All} onChange={(value) => this.handleChange("Minutes_All", value)} /></Col>
                                    <Col xs={24} sm={8}><p>Seconds</p><InputNumber className="Program-Input-Width" min={0} value={this.state.Seconds_All} onChange={(value) => this.handleChange("Seconds_All", value)} /></Col>
                                </Row>
                                :
                                this.state.TargetType_All === UNITS.TARGET_INPUT.OTHER ?
                                <Input placeholder="enter details" value={this.state.Target_All} onChange={(e) => this.handleChange("Target_All", e.target.value)} />
                                :
                                null
                            }
                        </Col>

                        <Col xs={24} sm={3}>
                            <p>Weight Type</p>
                            <Select
                            className="Program-Input-Width"
                            value={this.state.WeightType_All}
                            onChange={(value) => this.handleChange("WeightType_All", value)}
                            >
                                {
                                    Object.keys(UNITS.WEIGHT_INPUT).map((key) => {
                                        return <Select.Option key={UNITS.WEIGHT_INPUT[key]} value={UNITS.WEIGHT_INPUT[key]}>{UNITS.WEIGHT_INPUT[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Col>

                        <Col xs={24} sm={4}>
                            {
                                this.state.WeightType_All === UNITS.WEIGHT_INPUT.PERCENTAGE || this.state.WeightType_All === UNITS.WEIGHT_INPUT.LB || this.state.WeightType_All === UNITS.WEIGHT_INPUT.KG ?
                                <p>Weight</p>
                                :
                                this.state.WeightType_All === UNITS.WEIGHT_INPUT.OTHER ?
                                <p>Other</p>
                                :
                                this.state.WeightType_All === UNITS.WEIGHT_INPUT.BODYWEIGHT ?
                                <p>Weight</p>
                                :
                                null
                            }
                            {
                                this.state.WeightType_All === UNITS.WEIGHT_INPUT.PERCENTAGE || this.state.WeightType_All === UNITS.WEIGHT_INPUT.LB || this.state.WeightType_All === UNITS.WEIGHT_INPUT.KG ?
                                <InputNumber className="Program-Input-Width" min={0} placeholder="enter number" value={this.state.Weight_All} onChange={(value) => this.handleChange("Weight_All", value)} />
                                :
                                this.state.WeightType_All === UNITS.WEIGHT_INPUT.OTHER ?
                                <Input placeholder="enter details" value={this.state.Weight_All} onChange={(e) => this.handleChange("Weight_All", e.target.value)} />
                                :
                                this.state.WeightType_All === UNITS.WEIGHT_INPUT.BODYWEIGHT ?
                                <p>(bodyweight)</p>
                                :
                                null
                            }
                        </Col>

                        <Col xs={24} sm={3}>
                        <p>Extra Notes</p>
                        <Input value={this.state.ExtraNotes_All} onChange={(e) => this.handleChange("ExtraNotes_All", e.target.value)} />
                        </Col>

                        <Col xs={24} sm={3}>
                            <Button type="primary" block onClick={() => this.handleApplyToAll()} >Apply to All</Button>
                        </Col>

                        <Col xs={24}>
                            <Divider />
                        </Col>
                    </Row>
                    {
                        this.state.Instructions.map((inst, instIndex) => {
                            return (
                                <Row align="bottom" gutter={[10,10]} className="Program-Exercise-Row">
                                    <Col xs={24} sm={2}>
                                    <p>Set {instIndex+1}</p>
                                    </Col>

                                    <Col xs={24} sm={3}>
                                    <p>Target Type</p>
                                    <Select
                                    className="Program-Input-Width"
                                    value={inst.TargetType}
                                    onSelect={(value) => this.handleArrayChange("Instructions", "TargetType", instIndex, value)}>
                                        {
                                            Object.keys(UNITS.TARGET_INPUT).map((key) => {
                                                return <Select.Option key={UNITS.TARGET_INPUT[key]} value={UNITS.TARGET_INPUT[key]}>{UNITS.TARGET_INPUT[key]}</Select.Option>
                                            })
                                        }
                                    </Select>
                                    </Col>

                                    <Col xs={24} sm={6}>
                                        {
                                            inst.TargetType === UNITS.TARGET_INPUT.REPS ?
                                            <p>Reps</p>
                                            :
                                            inst.TargetType === UNITS.TARGET_INPUT.TIMED ?
                                            null
                                            :
                                            inst.TargetType === UNITS.TARGET_INPUT.OTHER ?
                                            <p>Target Details</p>
                                            :
                                            null
                                        }
                                        {
                                            inst.TargetType === UNITS.TARGET_INPUT.REPS ?
                                            <InputNumber className="Program-Input-Width" placeholder="enter number" value={inst.Target} onChange={(value) => this.handleArrayChange('Instructions', 'Target', instIndex, value)} />
                                            :
                                            inst.TargetType === UNITS.TARGET_INPUT.TIMED ?
                                            <Row>
                                                <Col xs={24} sm={8}><p>Hours</p><InputNumber className="Program-Input-Width" min={0} value={inst.Hours} onChange={(value) => this.handleArrayChange('Instructions', 'Hours', instIndex, value)} /></Col>
                                                <Col xs={24} sm={8}><p>Minutes</p><InputNumber className="Program-Input-Width" min={0} value={inst.Minutes} onChange={(value) => this.handleArrayChange('Instructions', 'Minutes', instIndex, value)}/></Col>
                                                <Col xs={24} sm={8}><p>Seconds</p><InputNumber className="Program-Input-Width" min={0} value={inst.Seconds} onChange={(value) => this.handleArrayChange('Instructions', 'Seconds', instIndex, value)}/></Col>
                                            </Row>
                                            :
                                            inst.TargetType === UNITS.TARGET_INPUT.OTHER ?
                                            <Input placeholder="enter details" value={inst.Target} onChange={(e) => this.handleArrayChange('Instructions', 'Target', instIndex, e.target.value)} />
                                            :
                                            null
                                        }
                                    </Col>

                                    <Col xs={24} sm={3}>
                                        <p>Weight Type</p>
                                        <Select
                                        className="Program-Input-Width"
                                        value={inst.WeightType}
                                        onSelect={(value) => this.handleArrayChange("Instructions", "WeightType", instIndex, value)}
                                        >
                                            {
                                                Object.keys(UNITS.WEIGHT_INPUT).map((key) => {
                                                    return <Select.Option key={UNITS.WEIGHT_INPUT[key]} value={UNITS.WEIGHT_INPUT[key]}>{UNITS.WEIGHT_INPUT[key]}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Col>

                                    <Col xs={24} sm={4}>
                                        {
                                            inst.WeightType === UNITS.WEIGHT_INPUT.PERCENTAGE || inst.WeightType === UNITS.WEIGHT_INPUT.LB || inst.WeightType === UNITS.WEIGHT_INPUT.KG ?
                                            <p>Weight</p>
                                            :
                                            inst.WeightType === UNITS.WEIGHT_INPUT.OTHER ?
                                            <p>Other</p>
                                            :
                                            inst.WeightType === UNITS.WEIGHT_INPUT.BODYWEIGHT ?
                                            <p>Weight</p>
                                            :
                                            null
                                        }
                                        {
                                            inst.WeightType === UNITS.WEIGHT_INPUT.PERCENTAGE || inst.WeightType === UNITS.WEIGHT_INPUT.LB || inst.WeightType === UNITS.WEIGHT_INPUT.KG ?
                                            <InputNumber className="Program-Input-Width" min={0} placeholder="enter number" value={inst.Weight} onChange={(value) => this.handleArrayChange('Instructions', 'Weight', instIndex, value)} />
                                            :
                                            inst.WeightType === UNITS.WEIGHT_INPUT.OTHER ?
                                            <Input placeholder="enter details" value={inst.Weight} onChange={(e) => this.handleArrayChange('Instructions', 'Weight', instIndex, e.target.value)} />
                                            :
                                            inst.WeightType === UNITS.WEIGHT_INPUT.BODYWEIGHT ?
                                            <p>(bodyweight)</p>
                                            :
                                            null
                                        }
                                    </Col>

                                    <Col xs={24} sm={3}>
                                    <p>Extra Notes</p>
                                    <Input value={inst.ExtraNotes} onChange={(e) => this.handleArrayChange('Instructions', 'ExtraNotes', instIndex, e.target.value)} />
                                    </Col>

                                    <Col xs={24} sm={3}>
                                        <Button type="primary" onClick={() => this.deleteFromArray("Instructions", instIndex)} block danger icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Row>
                        <Col xs={24} sm={12}>
                            <Button onClick={() => {
                                const value = {
                                    TargetType: "",
                                    WeightType: "",
                                    Target: "",
                                    Weight: "",
                                    ExtraNotes: "",
                                    Hours: 0,
                                    Minutes: 0,
                                    Seconds: 0,
                                };
                                this.handleArrayAdd("Instructions", value);
                            }}>Add Exercise Instruction</Button>
                        </Col>
                    </Row>
                </Modal>
                </Layout.Content>
            </Layout>
        )
    }
}

export default Program;