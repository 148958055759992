import React from "react";
import IDS from "../../IDS";
import { Component } from "../../Component";
import "../../common.css";
import "./Card1.css";
import { ImageTemplate } from "../../TemplateData";

class Card1 extends Component {
    ID = IDS.CARD1;
    constructor(image, title, description, cta, ctaLink, ctaText) {
        super();
        this.Image = image;
        this.Title = title;
        this.Description = description;
        this.CTA = cta;
        this.Link = ctaLink;
        this.LinkText = ctaText;
    }
    static Default() {
        return new Card1( ImageTemplate, "Card Title", "Card description", true, "/", "Contact Me");
    }
    static fromJSON(data) {
        const obj = JSON.parse(data);
        return new Card1(obj.Image, obj.Title, obj.Description, obj.CTA, obj.Link, obj.LinkText);
    }
}

const Card1View = ({ data = Card1.Default() }) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
        <div className="container flex-wrap">
            <div className="Card1--Container">
                <img src={data.Image} alt={data.Title} className="Card1--Image" />
            </div>
            <div className="Card1--Container">
                <div>
                    <h2 className="Card1--Title">{data.Title}</h2>
                </div>
                <div>
                    <p className="Card1--Description">{data.Description}</p>
                </div>
                {
                    data.CTA ?
                    <a href={data.Link} className="Button__Outlined Card1--Button">
                        {data.LinkText}
                    </a>
                    :
                    null
                }
            </div>
        </div>
        </div>
    )
}


export {
    Card1,
    Card1View,
}