import React from 'react';
import { Row, Col, Layout, Button, Popconfirm, Table, notification } from 'antd';
import { H2 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { DataContext } from '../../Context/ProfileData';

class ViewMemberships extends React.Component {
    static contextType = AuthContext;
    state = {
        Memberships: [],
        loading: true,
        Profile: {},
        ProfileLoading: true,
    }

    componentDidMount() {
        this.getMembershipData();
    }

    getMembershipData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/memberships/get-all-memberships`, {}, config);
        if (response.data.error) {
            return notification.error({
                message: "Error",
                description: "Failed to get your created memberships. Please try again, if this problem persists please contact us at support@phros.ca",
                duration: null,
            })
        }
        this.setState({
            Memberships: response.data,
            loading: false,
        })
    }

    deleteMembership = async (MembershipID, PriceID, ProductID, index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            MembershipID: MembershipID,
            PriceID: PriceID,
            ProductID: ProductID
        };
        const deleteMembership = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/memberships/delete-membership`, data, config);
        if (deleteMembership.data.error) {
            return notification.error({
                message: "Error",
                description: "Failed to delete this membership, please refresh the page and try again. If this persists, please contact us at support@cloudsafari.io.",
                duration: 0,
            })
        } else {
            let memberships = [...this.state.Memberships];
            memberships.splice(index, 1);
            this.setState({
                Memberships: memberships,
            })
            return notification.success({
                message: "Success",
                description: "Successfully deleted the membership."
            })
        }
    }


    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Name}</h4>
                )
            },
            {
                title: "Description",
                dataIndex: "Description",
                key: "Description",
                render: (text, obj) => (
                    <h4>{obj.Description}</h4>
                )
            },
            {
                title: "Price",
                dataIndex: "Price",
                key: "Price",
                render: (text, obj) => {
                    return (
                        <h4>${obj.Price} ({obj.Currency}) - charged every {obj.RecurringInterval}</h4>
                    )
                }
            },
            {
                title: "Selected Resources",
                dataIndex: "Resources",
                key: "Resources",
                render: (text, obj) => {
                    let liveClasses = "Unlimited";
                    let onDemandClasses = "Unlimited";
                    let trainingPrograms = "Unlimited";
                    if (obj.SelectedResources?.LiveClasses?.Capacity === "restricted") {
                        liveClasses = `${obj.SelectedResources?.LiveClasses?.Limit} per ${obj.RecurringInterval}.`
                    }
                    if (obj.SelectedResources?.OnDemandClasses?.Capacity === "restricted") {
                        onDemandClasses = `${obj.SelectedResources?.OnDemandClasses?.Limit} per ${obj.RecurringInterval}.`
                    }
                    if (obj.SelectedResources?.TrainingPrograms?.Capacity === "restricted") {
                        trainingPrograms = `${obj.SelectedResources?.TrainingPrograms?.Limit} per ${obj.RecurringInterval}.`
                    }
                    return (
                        <div>
                            { this.state.Profile?.PLAN?.FEATURES?.LIVECLASSES ? <h4> <strong>Live Classes:</strong> {liveClasses} </h4> : null }
                            { this.state.Profile?.PLAN?.FEATURES?.ONDEMAND ? <h4> <strong>On Demand Classes:</strong> {onDemandClasses} </h4> : null }
                            { this.state.Profile?.PLAN?.FEATURES?.TRAININGPROGRAM ? <h4> <strong>Workout Programs:</strong> {trainingPrograms} </h4> : null }
                        </div>
                    )
                }
            },
            {
                title: "Trial",
                dataIndex: "Trial",
                key: "Trial",
                render: (text, obj) => {
                    let trial = `Trial period of ${obj.TrialPeriodDays} days.`;
                    if (obj.TrialPeriod === "no-trial") {
                        trial = "No trial period.";
                    }
                    return (
                        <h4>{trial}</h4>
                    )
                }
            },
            {
                title: "Image",
                dataIndex: "Image",
                key: "Image",
                render: (text, obj) => {
                    return (
                        String(obj.ImageURL).length > 0 ?
                        <a href={obj.ImageURL} target="_blank" rel="noreferrer">View Image <LinkOutlined /></a>
                        :
                        null
                    )
                }
            },
            {
                title: "Waiver",
                dataIndex: "Waiver",
                key: "Waiver",
                render: (text, obj) => {
                    return (
                        String(obj.WaiverURL).length > 0 ?
                        <a href={obj.WaiverURL} target="_blank" rel="noreferrer">View Waiver <LinkOutlined /></a>
                        :
                        null
                    )
                }
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <Popconfirm
                            title="Are you sure you want to delete this membership plan?"
                            onConfirm={() => this.deleteMembership(obj.ID, obj.PriceID, obj.ProductID, index)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" danger icon={<DeleteOutlined />}>Delete</Button>
                        </Popconfirm>
                    )
                }
            },
        ]
        return (
            <DataContext.Consumer>
                {
                    data => {
                        if (data.profile?.PLAN && this.state.ProfileLoading) {
                            this.setState({
                                Profile: data.profile,
                                ProfileLoading: false,
                            })
                        }
                        return (
                            <Layout>
                            <SideNav />
                            <Layout.Content>
                                <Row justify='space-around'>
                                    <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                                        <H2 text='View Memberships'/>
                                        <Table
                                            loading={this.state.loading}
                                            scroll={{ x: true }}
                                            columns={columns} dataSource={this.state.Memberships} />
                                    </Col>
                                </Row>
                            </Layout.Content>
                        </Layout>
                        )
                    }
                }
            </DataContext.Consumer>
        )
    }
}

export default ViewMemberships;