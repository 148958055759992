import React, { useContext, useEffect } from "react";
import { Upload, message, Input, Progress, Button } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { AuthContext } from "../../Context/AuthContext";
import { v4 } from "uuid";
import { storage } from "../../Firebase";
import "./Uploads.scss";
import axios from "axios";
import { ENVIRONMENT } from "../../constants";
const { Dragger } = Upload;

class FileStruct {
   constructor(name, description, url, fileId, fileName, originalName, fileType, fileOrder) {
       this.name = name;
       this.description = description;
       this.url = url;
       this.fileId = fileId;
       this.fileName = fileName;
       this.originalName = originalName;
       this.fileType = fileType;
       this.fileOrder = fileOrder;
       this.percent = 100;
   }
   static Default() {
       return new FileStruct(
           "Name or Title",
           "Description",
           "URL",
           "ID",
           "File Name",
           "Original File Name",
           "image",
           0,
       )
   }
}

const PhrosUpload = ({ deleteFile = async () => {}, accept = "*", multiple=true, type="static", fileMap = {}, setFileMap, text = "Choose your files or drag them here.", protectedFiles = false }) => {
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        Object.values(fileMap).reduce((arr, file) => {
            const detailToggle = file.detailToggle || false;
            const status = file.status || "done";
            const percent = file.percent || 100;
            return [
                ...arr,
                {
                    ...file,
                    detailToggle: detailToggle,
                    status,
                    percent,
                }
            ]
        }, []);
    }, [fileMap]);

    const handleChangeFileDetails = (file, name, value) => {
        setFileMap(prevState => {
            const temp = { ...prevState };
            temp[file.fileId][name] = value;
            return {
                ...temp,
            }
        })
    }

    // Handle Upload to Firebase
    const handleFileUpload = async (info, fileType) => {
        const file = info.file;
        const fileId = v4();
        const fileName = `${fileId}-${file.name}`;
        const uid = currentUser.uid;
        const originalName = file.name;


        setFileMap(prevState => {
            return {
                ...prevState,
                [fileId]: {
                    name: file.name,
                    status: "uploading",
                    percent: 0,
                    url: "",
                    fileId: fileId,
                    fileName: fileName,
                    originalName: originalName,
                    bucketName: fileType,
                    protectedFile: protectedFiles,
                    fileType: file.type,
                    file_order: 0,
                }
            }
        })

        // If a protected file, we send to the backend
        if (protectedFiles) {
            const token = await currentUser.getIdToken(true);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const signedURLPayload = {
                FileName: fileName,
                FileType: file.type,
            };
            const res = await axios.post(`${ENVIRONMENT.URL}/aws/get-signed-s3-put-url`, signedURLPayload, config);
            try {
                if (!res.data) return message.error("Failed to upload your file, please contact us at support@phros.ca");

                const response = await axios({
                    url: res.data,
                    method: "put",
                    data: file,
                    headers: { "Content-Type": file.type },
                    maxContentLength: ( 100 * 1024 * 1024 * 1024 ),
                    timeout: (60 * 60 * 1000), // 1 hour
                    onUploadProgress: (progressEvent) => {
                        setFileMap(prevState => {
                            return {
                                ...prevState,
                                [fileId]: {
                                    name: file.name,
                                    status: "done",
                                    percent: Math.floor((progressEvent.loaded/progressEvent.total)*100),
                                    url: "",
                                    fileId: fileId,
                                    fileName: fileName,
                                    originalName: originalName,
                                    bucketName: fileType,
                                    protectedFile: protectedFiles,
                                    fileType: file.type,
                                    file_order: 0,
                                }
                            }
                        })
                    },
                })
                if (response.status === 200) {
                    setFileMap(prevState => {
                        return {
                            ...prevState,
                            [fileId]: {
                                ...prevState[fileId],
                                percent: 100
                            }
                        }
                    })
                    message.success(`${file.name} upload complete.`)
                }
            } catch (e) {
                console.log(e);
            }
            return;
        }

        const uploadTask = storage.ref(`/${fileType}/${uid}/${fileName}`).put(file);
        uploadTask.on('state_changed',
        (snapshot) => {
            setFileMap(prevState => {
                return {
                    ...prevState,
                    [fileId]: {
                        name: file.name,
                        status: "uploading",
                        percent: snapshot.bytesTransferred/snapshot.totalBytes*100,
                        url: "",
                        fileId: fileId,
                        fileName: fileName,
                        originalName: originalName,
                        bucketName: fileType,
                        protectedFile: protectedFiles,
                        fileType: file.type,
                        file_order: 0,
                    }
                }
            })
        },
        (err) => {
            message.error("Something went wrong, please contact our team.");
            console.log(err);
        },
        () => {
            storage.ref(`${fileType}/${uid}`).child(fileName).getDownloadURL()
            .then(firebaseUrl => {
                setFileMap(prevState => {
                    return {
                        ...prevState,
                        [fileId]: {
                            name: file.name,
                            status: "done",
                            percent: 100,
                            url: firebaseUrl,
                            fileId: fileId,
                            fileName: fileName,
                            originalName: originalName,
                            bucketName: fileType,
                            protectedFile: protectedFiles,
                            file_order: 0,
                        }
                    }
                })
            })
        })

    }

    const handleDelete = async (file) => {
        const url = fileMap[file.fileId]?.url;
        setFileMap(prevState => {
            const temp = { ...prevState };
            delete temp[file.fileId];
            return {
                ...temp,
            }
        })
        try {
            if (file.fileId) {
                try {
                    await deleteFile(file);
                } catch (e) {
                    return message.error("Failed to delete file, please contact us at support@phros.ca");
                }
            }
            if (file?.protectedFile) return;
            if (url.length > 0) {
                // Delete from Firebase
                const previousFile = storage.refFromURL(url);
                previousFile.delete();
            }
        } catch (e) {
            message.error("Something went wrong when deleting your file, please try again or contact us.")
            console.log(e);
        }
    }

    return (
        <>
        <Dragger
            multiple={multiple}
            name="DragDropUpload"
            customRequest={(info) => handleFileUpload(info, type)}
            onRemove={(file) => handleDelete(file)}
            fileList={[]}
            accept={accept}
        >
            <p>
                <InboxOutlined />
            </p>
            <p>{text}</p>
        </Dragger>
        {
            Object.values(fileMap).map((file) => {
                return (
                    <div className="Upload--File" key={file.fileId}>
                        <div className="flex-row">
                            <a href={file.url}>{file.name}</a>
                            <div>
                                <Button size="small" className="Upload--AddDetails" type="primary" ghost onClick={() => handleChangeFileDetails(file, "detailToggle", !file.detailToggle)}>Add Details</Button>
                                <Button type="primary" size="small" danger ghost onClick={() => handleDelete(file)}><DeleteOutlined /></Button>
                            </div>
                        </div>
                        {
                            file.detailToggle ?
                            <div>
                                <small>File Name:</small>
                                <Input value={file.title} size="small" onChange={(e) => handleChangeFileDetails(file, "title", e.target.value)} />
                                <small>File Description:</small>
                                <Input.TextArea value={file.description} className="Upload--AddDetails--TextArea" multiple rows={2} onChange={(e) => handleChangeFileDetails(file, "description", e.target.value)} />
                                <small>File Order</small>
                                <Input value={file.file_order} size="small" type="number" min={0} onChange={(e) => handleChangeFileDetails(file, "file_order", e.target.value)} />
                            </div>
                            :
                            null
                        }
                        {
                            file.percent < 100 ?
                            <>
                            <Progress size="small" percent={Math.round(file.percent)} />
                            </>
                            :
                            null
                        }
                    </div>
                )
            })
        }
        </>
    )
}

export {
    PhrosUpload,
    FileStruct,
}