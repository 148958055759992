import React from 'react';
import { Row, Col, Layout, Button, Popconfirm, Table, notification } from 'antd';
import { H2, H3 } from '../../Common';
import { SideNav } from '../../Components';
import axios from 'axios';
import {AuthContext} from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { storage } from '../../Firebase';
import Modal from 'antd/lib/modal/Modal';
import { DeleteOutlined } from '@ant-design/icons';

class ViewBootcamps extends React.Component {
    static contextType = AuthContext;
    state = {
        Bootcamps: [],
        Clients: {},
        loading: true,
        ClientVisible: false,
        BootcampId: '',
        BootcampIndex: 0,
    }

    componentDidMount() {
        this.getEventData();
    }

    getEventData = async () => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const data = {
            uid: currentUser.uid,
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bootcamps/get-all-bootcamps-with-uid`, data, config);
        const bootcamps = response.data?.bootcamps;
        const clients = response.data?.clients;
        this.setState({
            Bootcamps: bootcamps,
            Clients: clients,
            loading: false,
        })
    }

    deleteBootcamp = async (index) => {
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const boot = this.state.Bootcamps[index];
        const id = boot.ID;

        if (currentUser.uid !== boot.uid) {
            return notification.error({
                message: 'Not Authorized',
                description: "You're not authorized to delete this bootcamp.",
                duration: null,
            })
        }
        const data = {
            id: id,
        };
        const deleteEvent = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bootcamps/delete-bootcamp`, data, config);
        if (deleteEvent.data.error) {
            return notification.error({
                message: "Failed to Delete",
                description: "Failed to delete this bootcamp. Please try again, or contact our team."
            })
        }

        const classes = [...this.state.Bootcamps];
        classes.splice(index, 1);
        this.setState({
            Bootcamps: classes,
        })
        try {
            const file = storage.refFromURL(boot.PictureURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        try {
            const file = storage.refFromURL(boot.WaiverURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        try {
            const file = storage.refFromURL(boot.DocumentURL);
            file.delete();
        } catch (e) {
            console.log(e);
        }

        return (
            notification.success({
                message: 'Success',
                description: 'Successfully deleted the bootcamp.'
            })
        )
    }

    handleBootcampClientVisible = (BootcampIndex, BootcampID) => {
        this.setState({
            BootcampIndex: BootcampIndex,
            BootcampId: BootcampID,
            ClientVisible: true
        })
    }

    handleBootcampClientModal = () => {
        this.setState({
            ClientVisible: !this.state.ClientVisible,
        })
    }

    render() {
        const columns = [
            {
                title: "Name",
                dataIndex: "Name",
                key: "Name",
                render: (text, obj) => (
                    <h4>{obj.Title}</h4>
                )
            },
            {
                title: "Price",
                dataIndex: "Price",
                key: "Code",
                render: (text, obj) => (
                    <h4>{obj.Price} {obj.Currency ? obj.Currency : null}</h4>
                )
            },
            {
                title: "Start Date (to Sell)",
                dataIndex: "SellingStartDate",
                key: "SellingStartDate",
                render: (text, obj) => (
                    <h4>{new Date(obj.SellingStartDate).toLocaleDateString()}</h4>
                )
            },
            {
                title: "End Date (to Sell)",
                dataIndex: "SellingEndDate",
                key: "SellingEndDate",
                render: (text, obj) => (
                    <h4>{new Date(obj.SellingEndDate).toLocaleDateString()}</h4>
                )
            },
            {
                title: "Actions",
                dataIndex: "Actions",
                key: "Actions",
                render: (text, obj, index) => {
                    return (
                        <Row justify="center" gutter={[10,10]}>
                            <Col>
                                <Button
                                onClick={() => this.handleBootcampClientVisible(index, obj.ID)}>
                                    View Clients
                                </Button>
                            </Col>
                            <Col>
                                <Link to={`/bootcamp/${obj.ID}`}>
                                <Button type='primary'>Edit</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Popconfirm
                                title='Are you sure you want to delete this bootcamp class? This action cannot be reversed, and any customers who purchased this class will not be able to access this class.'
                                onConfirm={() => this.deleteBootcamp(index)}
                                okText="Delete Bootcamp"
                                >
                                <Button type='primary' danger icon={<DeleteOutlined />}>
                                    Delete
                                </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    )
                }
            },
        ]
        return (
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22} className='ViewLiveClasses-Row'>
                            <H2 text='View Bootcamps'/>
                            <Table
                            scroll={{ x: true }}
                            columns={columns} dataSource={this.state.Bootcamps} />
                        </Col>
                    </Row>
                    <Modal
                        visible={this.state.ClientVisible}
                        onCancel={this.handleBootcampClientModal}
                        width={600}
                        footer={null}
                    >
                        <Row>
                            <H3 text={`${this.state.Bootcamps[this.state.BootcampIndex]?.Title}`} />
                        </Row>
                        <Row>
                            <Col xs={6}>
                                Client Number
                            </Col>
                            <Col xs={18}>
                                Email
                            </Col>
                        </Row>
                        {
                            this.state.Clients.Bootcamps?.[this.state.BootcampId]?.map((client, index) => {
                                return (
                                    <Row style={{ marginTop: '3px', marginBottom: '3px' }}>
                                        <Col xs={6}>
                                            {index+1}
                                        </Col>
                                        <Col xs={18}>
                                            {client.Email}
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Modal>
                </Layout.Content>
            </Layout>
        )
    }
}

export default ViewBootcamps;