import React from 'react';
import { Layout, Row, Col } from 'antd';
import { SideNav } from '../../Components';
import { H1, CardButton } from '../../Common';
import './Dashboard.css';
import { v4 } from "uuid";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { Link } from "react-router-dom";

class Dashboard extends React.Component {
    static contextType = AuthContext;
    state = {
        accountExistsLoading: true,
        accountExists: true,
    }

    componentDidMount() {
        this.checkUserAccount();
    }

    checkUserAccount = async () => {
        // Get Token
        const { currentUser } = this.context;
        const token = await currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        // Send Token and Check if User has an Account Already
        const accountCheck = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stripe/check-account`, {}, config);

        if (accountCheck.data.error) {
            this.setState({
                accountExistsLoading: false,
                accountExists: false,
            })
        } else {
            this.setState({
                accountExistsLoading: false,
                accountExists: accountCheck.data,
            })
        }
    }

    render() {
        return (
            <>
            {
                !this.state.accountExistsLoading && !this.state.accountExists ?
                <Link to="/profile">
                    <div style={{ width: "100%", padding: "20px", textAlign: "center", backgroundColor: "#F7B733", color: "black", fontWeight: 600 }}>
                        Click here to connect your Stripe account to start receiving payments!
                    </div>
                </Link>
                :
                null
            }
            <Layout>
                <SideNav />
                <Layout.Content>
                    <Row justify='space-around'>
                        <Col xs={23} sm={22}>
                            <Row gutter={[20,20]} className='Dashboard-Row'>
                                <Col xs={24}>
                                    <H1 text='Welcome to Phros!'/>
                                </Col>
                                <Col xs={24} sm={12}>
                                <CardButton LinkTo='/profile' text="Profile"/>
                                </Col>
                                <Col xs={24} sm={12}>
                                <CardButton LinkTo={`/add-live-class/${v4()}`} text="Create Live Class"/>
                                </Col>
                                <Col xs={24} sm={12}>
                                <CardButton LinkTo={`/programs/${v4()}`} text="Create Program"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
            </>
        )
    }
}

export default Dashboard;