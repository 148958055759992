import React from 'react';
import './Gallery2.css';
import { Row, Col } from 'antd';
import { checkArray } from '../../../../../Verification';

class Gallery2 extends React.Component {
    render() {
        let currentIndex = 0;
        let indexAdd = 3;
        return (
        <Row justify="center">
        <Col xs={23}>
            <Row gutter={[20,20]} justify="center">
            {
                checkArray(this.props.Gallery).map((img, index) => {
                    if (index === currentIndex) {
                        currentIndex = currentIndex+indexAdd;
                        if (indexAdd === 3) {
                            indexAdd = 1;
                        } else {
                            indexAdd = 3;
                        }
                        return (
                            <Col xs={24} sm={16}>
                                <img src={img.ImageURL} alt={img.ALT} className="Gallery1-Image" />
                            </Col>
                        )
                    }
                    return (
                        <Col xs={24} sm={8}>
                            <img src={img.ImageURL} alt={img.ALT} className="Gallery1-Image" />
                        </Col>
                    )
                })
            }
            </Row>
        </Col>
        </Row>
        )
    }
}

export default Gallery2;