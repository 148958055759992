import { useState, useEffect, useContext } from "react";
import { SideNav } from "../../Components";
import { Layout, Input, Switch, Select, Button, message, Tag, Popconfirm } from "antd";
import "./Programs.scss";
import { PhrosUpload } from "../../Common";
import { INTENSITY_LEVELS, EXPERIENCE_LEVEL, ENVIRONMENT } from "../../constants";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { getAuthHeader } from "../../util";
import { DeleteFilled } from "@ant-design/icons";


const { Option } = Select;

const Program = (props) => {
    const [fileMap, setFileMap] = useState({});
    const [videoMap, setVideoMap] = useState({});
    const [imageMap, setImageMap] = useState({});
    const [waiverMap, setWaiverMap] = useState({});
    const [allLiveClasses, setAllLiveClasses] = useState({});
    const [selectedClass, setSelectedClass] = useState({});
    const [selectedClasses, setSelectedClasses] = useState([]);
    const [programDetails, setProgramDetails] = useState({
        Name: "",
        Price: 0,
        Currency: "CAD",
        Description: "",
        IntensityRating: "N/A",
        ExperienceLevel: "N/A",
        ExtraDetails: "",
        Published: false,
        ProgramID: props.match.params.ID,
        Capacity: null,
    })
    const { currentUser } = useContext(AuthContext);

    const handleProgramDetailChange = (name, value) => {
        setProgramDetails(prevState => {
            return {
                ...prevState,
                [name]: value,
            }
        })
    }

    // On Component Mount, Attempt to get Details
    useEffect(() => {
        getProgramData();
    }, []);

    const getProgramData = async () => {
        const payload = {
            ProgramID: programDetails.ProgramID
        };
        const config = await getAuthHeader(currentUser);
        try {
            const response = await axios.post(`${ENVIRONMENT.URL}/quick-program/get-program-data`, payload, config);
            if (response.status !== 200) {
                return message.error("Failed to get the program data, please contact us at support@phros.ca");
            }

            if (response.data?.program?.length === 0) return;

            // Format the Data
           const files = response.data?.files?.reduce((obj, file) => {
            const detailToggle = file.detailToggle || false;
            const status = file.status || "done";
            const percent = file.percent || 100;
            const fileId = file.file_id;
                const fileFormat = {
                    name: file.file_name,
                    url: file.storage_url,
                    fileId: fileId,
                    fileName: file.file_name,
                    title: file.name,
                    description: file.description,
                    fileType: file?.file_type,
                    protectedFile: file?.protected_file,
                    detailToggle,
                    status,
                    percent,
                };
                if (file.file_type === "file") {
                    return {
                        ...obj,
                        Files: {...obj.Files, [fileId]: fileFormat }
                    }
                } else if (file.file_type === "video") {
                    return {
                        ...obj,
                        Videos: {...obj.Videos, [fileId]: fileFormat }
                    }
                } else if (file.file_type === "image") {
                    return {
                        ...obj,
                        Image: {...obj.Image, [fileId]: fileFormat }
                    }
                }
                return obj;
           }, { Files: {}, Videos: {}, Image: {} });

           // Format Waivers
           const waivers = response.data?.waivers?.reduce((obj, file) => {
            const detailToggle = file.detailToggle || false;
            const status = file.status || "done";
            const percent = file.percent || 100;
            const fileId = file.file_id;
                const fileFormat = {
                    name: file.file_name,
                    url: file.storage_url,
                    fileId: fileId,
                    fileName: file.file_name,
                    title: file.name,
                    description: file.description,
                    fileType: file?.file_type,
                    protectedFile: file?.protected_file,
                    detailToggle,
                    status,
                    percent,
                };
                return {
                    ...obj,
                    [fileId]: fileFormat
                };
           }, {});

           const programData = response.data.program[0];
            const programFormat = {
                Name: programData.name,
                Price: programData.price / 100,
                Currency: programData.currency,
                Description: programData.description,
                IntensityRating: programData.intensity_rating,
                ExperienceLevel: programData.experience_level,
                ExtraDetails: programData.extra_details,
                Published: programData.published,
                Capacity: programData.capacity,
            }

            setFileMap(files.Files);
            setVideoMap(files.Videos);
            setImageMap(files.Image);
            setProgramDetails((prevState) => {
                return {
                    ...prevState,
                    ...programFormat,
                }
            })

            setWaiverMap(waivers);

            const liveClassMap = response.data.liveClasses.reduce((obj, ev) => {
                return {
                    ...obj,
                    [ev.ID]: ev
                }
            }, {});
            setAllLiveClasses(liveClassMap)

            if (response?.data?.programClasses?.length > 0) {
                setSelectedClasses(response.data.programClasses);
            }

        } catch (e) {
            return message.error("Failed to get the program data, please contact us at support@phros.ca");
        }
    }

    // Save Details
    const saveProgram = async () => {
        const payload = {
            Files: Object.values(fileMap),
            Videos: Object.values(videoMap),
            Image: Object.values(imageMap),
            ...programDetails,
            SelectedClasses: selectedClasses,
            Waivers: Object.values(waiverMap),
        }
        const config = await getAuthHeader(currentUser);

        try {
            await axios.post(`${ENVIRONMENT.URL}/quick-program/save-quick-program`, payload, config);
        } catch (e) {
            return message.error("Failed to save the program, please contact us at support@phros.ca");
        }

        return message.success("Successfully saved your program.");
    }

    const deleteProgramFile = async (file, isWaiver = false) => {
        // Delete file from database
        const payload = {
            FileID: file.fileId,
            FileType: file.fileType, // If a user is uploading for the first time, this is the actual file type as decoded from the file NOT (file, image, video) enum
            FileName: file.fileName,
            ProtectedFile: file.protectedFile,
        }
        const config = await getAuthHeader(currentUser);
        try {

            let del = {};
            if (isWaiver) {
                del = await axios.post(`${ENVIRONMENT.URL}/files/delete-program-waiver`, payload, config);
            } else {
                del = await axios.post(`${ENVIRONMENT.URL}/files/delete-program-file`, payload, config);
            }

            if (del?.status === 200) {
                return message.success("Successfully deleted.")
            } else {
                return message.error("Failed to delete the file, please contact us.")
            }
        } catch (e) {
            return message.error("Failed to delete the file, please contact us at support@phros.ca");
        }
    }

    return (
    <Layout>
    <SideNav />
    <Layout.Content>
        <div className="AddProgram">
            {/* Titles */}
            <div>
                <h1 className="AddProgram--Title">Create a Health & Fitness Program</h1>
                <h5 className="AddProgram--Subtitle">Create a complete program for your clients with PDF's, spreadsheets, videos, and live-classes.</h5>
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Program Published</p>
                <small>Make your program available for clients to purchase.</small>
                <br />
                <Switch checked={programDetails.Published} onChange={(checked) => handleProgramDetailChange("Published", checked)} />
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Program Name</p>
                <Input
                value={programDetails.Name}
                onChange={e => handleProgramDetailChange("Name", e.target.value)}
                className="AddProgram--Input" />
            </div>

            <div className="AddProgram--Input--Div flex-row justify-start">
                <div className="flex-box">
                    <h5 className="AddProgram--Input--Label">Price</h5>
                    <Input
                    type="number"
                    value={programDetails.Price}
                    onChange={e => handleProgramDetailChange("Price", e.target.value)}
                    className="AddProgram--Input__Small" />
                </div>
                <div className="flex-box Flex-Margin">
                    <h5 className="AddProgram--Input--Label">Currency</h5>
                    <Select
                    className="AddProgram--Input__Small"
                    value={programDetails.Currency}
                    onChange={(value) => handleProgramDetailChange("Currency", value)}
                    >
                        <Select.Option value="CAD">CAD</Select.Option>
                        <Select.Option value="USD">USD</Select.Option>
                    </Select>
                </div>
            </div>

            <div className="AddProgram--Input--Div flex-row justify-start">
                <div className="flex-box">
                    <h5 className="AddProgram--Input--Label">Remaining Capacity</h5>
                    <small>Limit for how many people can purchase this program. This will decrease automatically when people start to purchase your program.</small>
                    <Input
                    style={{ maxWidth: "50%" }}
                    type="number"
                    value={programDetails.Capacity}
                    onChange={e => handleProgramDetailChange("Capacity", e.target.value)}
                    className="AddProgram--Input__Small" />
                    <br />
                    <small>Leave blank for no limit.</small>
                </div>
            </div>


            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Description</p>
                <Input.TextArea
                value={programDetails.Description}
                onChange={e => handleProgramDetailChange("Description", e.target.value)}
                className="AddProgram--Input" rows={4} />
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Program Image</p>
                <small>
                    Upload an image your clients will see when purchasing the program.
                </small>
                <PhrosUpload
                    accept = ".jpg,.jpeg,.png"
                    text="Choose an image or drag it here."
                    type="static"
                    fileMap={imageMap}
                    setFileMap={setImageMap}
                    deleteFile={deleteProgramFile}
                />
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Intensity Rating (optional)</p>
                <small>Rating on a scale from 1 (easy) to 5 (intense).</small>
                <Select
                    bordered
                    className="AddProgram--Input__Small Select--FullWidth"
                    value={programDetails.IntensityRating}
                    onChange={(value) => handleProgramDetailChange("IntensityRating", value)}
                >
                    {
                        INTENSITY_LEVELS.map((value, index) => {
                            return (
                                <Select.Option value={value} key={`${value}_${index}`}>
                                    {value}
                                </Select.Option>
                            )
                        })
                    }
                    <Select.Option value="N/A">N/A</Select.Option>
                </Select>
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Level of Experience (optional)</p>
                <Select
                    className="AddProgram--Input__Small Select--FullWidth"
                    value={programDetails.ExperienceLevel}
                    onChange={(value) => handleProgramDetailChange("ExperienceLevel", value)}
                >
                    {
                        EXPERIENCE_LEVEL.map((value, index) => {
                            return (
                                <Select.Option value={value} key={`${value}_${index}`}>
                                    {value}
                                </Select.Option>
                            )
                        })
                    }
                    <Select.Option value="N/A">N/A</Select.Option>
                </Select>

            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Extra Details</p>
                <Input.TextArea
                value={programDetails.ExtraDetails}
                onChange={e => handleProgramDetailChange("ExtraDetails", e.target.value)}
                className="AddProgram--Input" rows={4} />
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Upload File(s)</p>
                <small>
                    Upload one or more file(s) that describes the health/fitness program that your clients can follow.
                </small>
                <PhrosUpload
                    accept = ".pdf,.xlsx"
                    type="testaa"
                    fileMap={fileMap}
                    setFileMap={setFileMap}
                    deleteFile={deleteProgramFile}
                    protectedFiles={true}
                />
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Upload Video(s)</p>
                <small>
                    Upload the video(s) your clients can use for reference.
                </small>
                <PhrosUpload
                    accept = ".mp4,mkv,.mov,.avi"
                    type="testaa"
                    fileMap={videoMap}
                    setFileMap={setVideoMap}
                    text="Choose your videos or drag them here."
                    protectedFiles={true}
                    deleteFile={deleteProgramFile}
                />
            </div>


            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Add Live Classes</p>
                <small>
                    Add live classes so your clients can get access to all live class event information at any time, and reminder emails before an event.
                </small>
                <Select
                    className="AddProgram--Input__Small Select--FullWidth"
                    value={selectedClass.Name}
                    onChange={(value) => setSelectedClass(value)}
                >
                    {
                        Object.keys(allLiveClasses).map((key) => {
                            return (
                                <Select.Option value={key} key={key}>
                                    {allLiveClasses[key].Name}
                                </Select.Option>
                            )
                        })
                    }
                    <Select.Option value="N/A">N/A</Select.Option>
                </Select>
                <small>Select your availabilities</small>
                <Select
                    className="AddProgram--Input__Small Select--FullWidth"
                    onChange={(key) => {
                        setSelectedClasses((prevState) => {
                            let index = prevState.findIndex(el => {
                                if (el.ID === selectedClass && el.AvailabilityID === key) {
                                    return true;
                                }
                                return false;
                            })
                            if (index >= 0) {
                                message.info("This class was already added.")
                                return prevState;
                            }
                            return [
                                ...prevState,
                                {
                                    ID: selectedClass,
                                    AvailabilityID: key,
                                }
                            ]
                        })
                    }}
                >
                    {
                        allLiveClasses[selectedClass]?.Availability?.map((avail) => {
                            let startTime = new Date(avail.StartTime).toLocaleTimeString("en-US", { timeZone: avail.TimeZone, hour: "2-digit", minute: "2-digit" });
                            let endTime = new Date(avail.EndTime).toLocaleTimeString("en-US", { timeZone: avail.TimeZone, hour: "2-digit", minute: "2-digit" })
                            let dateString = new Date(avail.Date).toDateString();
                            let timezone = avail.TimeZone;
                            return (
                                <Select.Option key={avail.ID} value={avail.ID}>
                                    {dateString}: {startTime} to {endTime} ({timezone} timezone)
                                </Select.Option>
                            )
                        })
                    }
                </Select>
                <Popconfirm
                    title="Are you sure you want to add all the availabilities for this event?"
                    onConfirm={() => {
                        allLiveClasses[selectedClass]?.Availability?.forEach((avail) => {
                            const key = avail.ID;
                            setSelectedClasses((prevState) => {
                                let index = prevState.findIndex(el => {
                                    if (el.ID === selectedClass && el.AvailabilityID === key) {
                                        return true;
                                    }
                                    return false;
                                })
                                if (index >= 0) {
                                    message.info("This class was already added.")
                                    return prevState;
                                }
                                return [
                                    ...prevState,
                                    {
                                        ID: selectedClass,
                                        AvailabilityID: key,
                                    }
                                ]
                            })
                        })
                    }}
                >
                <Button style={{ marginTop: "10px" }} type="primary">
                    Add All Availabilites for this Class
                </Button>
                </Popconfirm>
                <div style={{ marginTop: "10px" }}>
                <small>
                    Your Upcoming Live Classes:
                </small>
                </div>
                {
                    selectedClasses.map((obj) => {
                        if (!allLiveClasses[obj.ID]) return null;
                        let name = allLiveClasses[obj.ID]?.Name;
                        let avail = allLiveClasses[obj.ID]?.Availability?.find((el) => el.ID === obj.AvailabilityID);
                        if (!avail) return null;
                        let classDate = new Date(avail?.Date).toDateString();
                        let startTime = new Date(avail?.StartTime).toLocaleTimeString("en-US", { timeZone: avail?.TimeZone, hour: "2-digit", minute: "2-digit" });
                            let endTime = new Date(avail?.EndTime).toLocaleTimeString("en-US", { timeZone: avail?.TimeZone, hour: "2-digit", minute: "2-digit" })
                        return (
                            <Tag style={{ marginBottom: "10px" }}>
                                <b>{name}</b> - {classDate}: {startTime} to {endTime} <small>({avail?.TimeZone} timezone)</small>
                                <Button
                                    style={{ margin: "5px" }}
                                    danger
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        setSelectedClasses((prevState) => {
                                            let index = prevState.findIndex(el => {
                                                if (el.ID === obj.ID && el.AvailabilityID === obj.AvailabilityID) {
                                                    return true;
                                                }
                                                return false;
                                            })
                                            let temp = [...prevState];
                                            temp.splice(index, 1);
                                            return temp;
                                        })
                                    }}
                                >
                                    <DeleteFilled />
                                </Button>
                            </Tag>
                        )
                    })
                }
            </div>

            <div className="AddProgram--Input--Div">
                <p className="AddProgram--Input--Label">Program Waiver(s):</p>
                <small>
                    Upload the waiver(s) your clients need to review before purchasing the program.
                </small>
                <PhrosUpload
                    accept = ".pdf"
                    type="testaa"
                    fileMap={waiverMap}
                    setFileMap={setWaiverMap}
                    text="Choose your PDF's or drag them here."
                    protectedFiles={false}
                    deleteFile={(file) => deleteProgramFile(file, true)}
                />
            </div>

            <div className="AddProgram--Input--Div">
                <Button
                onClick={saveProgram}
                className="Button">Save Program</Button>
            </div>
        </div>
    </Layout.Content>
    </Layout>
    )
}

export {
    Program,
}