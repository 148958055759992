import { useState, useEffect } from "react";
import { HTML1 } from "../../phros-web-components/HTML/HTML1/HTML1";
import { Input } from "antd";


/*
        this.HTML = html;
*/

const HTML1Edit = ({ data = HTML1.Default(), editComponent = () => {} }) => {
    const [html, setHTML] = useState(data.HTML);

    useEffect(() => {
        const updateFaq = new HTML1(html)
        editComponent(updateFaq);
    }, [html])

    return (
        <div>
            <h3>Text:</h3>
            <Input.TextArea
                value = {html}
                onChange = {(e) => setHTML(e.target.value)}
                autoSize={{ minRows: 6 }}
            />
        </div>
    )
};

export {
    HTML1Edit
}