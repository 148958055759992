const IDS = {
    BANNER1: "BANNER1",
    BANNER2: "BANNER2",
    BANNER3: "BANNER3",
    FAQ1: "FAQ1",
    FAQ2: "FAQ2",
    PROFILE1: "PROFILE1",
    SPACER1: "SPACER1",
    TEXT1: "TEXT1",
    HTML1: "HTML1",
    LIVECLASS1: "LIVECLASS1",
    PROGRAM1: "PROGRAM1",
    PURCHASES1: "PURCHASES1",
    CARD1: "CARD1",
    DEFAULT: "DEFAULT",
};

const NAVBARS = {
    NAVBAR1: "NAVBAR1",
    NAVBAR2: "NAVBAR2",
};

const FOOTERS = {
    FOOTER1: "FOOTER1",
    FOOTER2: "FOOTER2",
};

export {
    NAVBARS,
    FOOTERS,
};

export default IDS;