import React from 'react';
import { Row, Col, Input, Radio, Select, Card, Upload, Button } from 'antd';
import { COLOUR_PALETTE } from '../../../constants';
import { SketchPicker } from 'react-color';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { checkArray } from '../../../Verification';


class BasicInformation extends React.Component {
    render() {
        return (
            <Col xs={24}>
            <Row gutter={[30,30]}>
                <Col xs={24}>
                <h3>Basic Website Information</h3>
                </Col>
                <Col xs={24}>
                <p>What first name do you want to display to customers?</p>
                <Input value={this.props.FirstName} onChange={(e) => this.props.handleChange("FirstName", e.target.value)} placeholder="First Name" />
                </Col>
                <Col xs={24}>
                <p>What last name do you want to display to customers?</p>
                <Input value={this.props.LastName} onChange={(e) => this.props.handleChange("LastName", e.target.value)} placeholder="Last Name" />
                </Col>
                <Col xs={24}>
                <p>What professional title do you want to display to customers?</p>
                <Input value={this.props.ProfessionalTitle} onChange={(e) => this.props.handleChange("ProfessionalTitle", e.target.value)} placeholder="Professional Title" />
                <p>(I.e., Yoga Instructor, Fitness Enthusiast, Personal Trainer & Nutrition Coach, etc.)</p>
                </Col>
                <Col xs={24}>
                <p>What business statement do you want to show to your customers? This will display on the banner of your page.</p>
                <Input value={this.props.BusinessStatement} onChange={(e) => this.props.handleChange("BusinessStatement", e.target.value)} placeholder="Business Statement" />
                <p>(I.e., "yoga instructor & fitness enthusiast", "leading you to a stronger version of yourself", etc.)</p>
                </Col>
                <Col xs={24}>
                <p>What's your Business Name? This will display on your Navigation Bar.</p>
                <Input value={this.props.BusinessName} onChange={(e) => this.props.handleChange("BusinessName", e.target.value)} placeholder="Business Name" />
                </Col>
                <Col xs={24}>
                    <p>Social Media Profiles: </p>
                    <Row gutter={[10,10]}>
                        {
                            checkArray(this.props.SocialMedia).map((sm, index) => (
                                <Col xs={24}>
                                    <Row gutter={[10,10]} align="middle">
                                        <Col xs={24} sm={18}>
                                        <p>Social Media URL {index+1}</p>
                                        <Input placeholder="Enter URL" value={sm} onChange={(e) => {
                                            let socialMedia = [...this.props.SocialMedia];
                                            socialMedia[index] = e.target.value;
                                            this.props.handleChange("SocialMedia", socialMedia);
                                        }} />
                                        <p>(I.e., "https://www.instagram.com/phros.ca/", etc.)</p>
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Button
                                                block
                                                icon={<DeleteOutlined />}
                                                type="primary"
                                                danger
                                                onClick={() => {
                                                    let socialMedia = [...this.props.SocialMedia];
                                                    socialMedia.splice(index, 1);
                                                    this.props.handleChange("SocialMedia", socialMedia);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            ))
                        }
                        <Col xs={24} sm={6}>
                            <Button
                            type="primary"
                            block
                            onClick={() => {
                                let socialMedia = [...this.props.SocialMedia];
                                socialMedia.push("");
                                this.props.handleChange("SocialMedia", socialMedia);
                            }}
                            >
                                Add Social Media
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                <p>What email do you want your customers contacting you at?</p>
                <Input value={this.props.Email} onChange={(e) => this.props.handleChange("Email", e.target.value)} placeholder="Main Email" />
                </Col>
                <Col xs={24}>
                    <p>Would you like to create your own Colour Palette?</p>
                    <Radio.Group value={this.props.CustomizedColours} onChange={(e) => this.props.handleChange("CustomizedColours", e.target.value)}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Col>

                {
                    !this.props.CustomizedColours ?
                    <Col xs={24}>
                    <Select
                    placeholder="Select Pre-defined Colour Palette"
                    className='Profile-Select'
                    onSelect={(value) => {
                        let colours = { ...this.props.ColourPalette };
                        colours.DARK = COLOUR_PALETTE[value].DARK;
                        colours.MEDIUM = COLOUR_PALETTE[value].MEDIUM;
                        colours.LIGHT = COLOUR_PALETTE[value].LIGHT;
                        this.props.handleChange("ColourPalette", colours);
                    }}>
                        {
                            Object.keys(COLOUR_PALETTE).map(key => {
                                return (
                                    <Select.Option key={key}>
                                        {COLOUR_PALETTE[key].NAME}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                    </Col>
                    :
                    null
                }

                {
                    this.props.CustomizedColours ?
                    <Col xs={24} sm={8}>
                        <p>Select a DARK Colour</p>
                        <SketchPicker
                            color={this.props.ColourPalette.DARK}
                            onChangeComplete={(color) => {
                                let colours = { ...this.props.ColourPalette };
                                colours.DARK = color.hex;
                                this.props.handleChange("ColourPalette", colours);
                            }}
                        />
                    </Col>
                    :
                    null
                }
                {
                    this.props.CustomizedColours ?
                    <Col xs={24} sm={8}>
                        <p>Select a MEDIUM Colour</p>
                        <SketchPicker
                            color={this.props.ColourPalette.MEDIUM}
                            onChangeComplete={(color) => {
                                let colours = { ...this.props.ColourPalette };
                                colours.MEDIUM = color.hex;
                                this.props.handleChange("ColourPalette", colours);
                            }}
                        />
                    </Col>
                    :
                    null
                }
                {
                    this.props.CustomizedColours ?
                    <Col xs={24} sm={8}>
                        <p>Select a LIGHT Colour</p>
                        <SketchPicker
                            color={this.props.ColourPalette.LIGHT}
                            onChangeComplete={(color) => {
                                let colours = { ...this.props.ColourPalette };
                                colours.LIGHT = color.hex;
                                this.props.handleChange("ColourPalette", colours);
                            }}
                        />
                    </Col>
                    :
                    null
                }

                <Col xs={24}>
                    <p>Website Colour Palette:</p>
                    <div className='Profile-Colour-Outer-Div'>
                        <Row>
                            <Col xs={8} style={{ backgroundColor: this.props.ColourPalette.DARK }} className='Profile-Colour-Div'>
                            </Col>
                            <Col xs={8} style={{ backgroundColor: this.props.ColourPalette.MEDIUM }} className='Profile-Colour-Div'>
                            </Col>
                            <Col xs={8} style={{ backgroundColor: this.props.ColourPalette.LIGHT }} className='Profile-Colour-Div'>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col xs={24}>
                    <p>Banner Image</p>
                    <p>This image will be what users first see when loading your website. We recommended uploading a picture where the object of focus is directly in the center.</p>
                    {
                        this.props.BannerImageURL !== undefined && this.props.BannerImageURL !== null  && String(this.props.BannerImageURL).length > 0?
                        <Card
                        style={{ width: "400px" }}
                        cover={<img src={this.props.BannerImageURL} alt="" />}
                        >
                        <Upload
                        fileList={[]}
                        accept=".png,.jpeg,.jpg"
                        customRequest={async (info) => {
                            const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                            this.props.handleChange("BannerImageURL", url);
                        }}>
                            <Button icon={<UploadOutlined />}>Replace Image</Button>
                        </Upload>
                        </Card>
                        :
                        <Upload
                            fileList={[]}
                            accept=".png,.jpeg,.jpg"
                            customRequest={async (info) => {
                                const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                this.props.handleChange("BannerImageURL", url);
                            }}>
                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                        </Upload>
                    }
                </Col>

                <Col xs={24}>
                    <p>Logo Image</p>
                    <p>Upload the logo for your website.</p>
                    {
                        this.props.LogoURL !== undefined && this.props.LogoURL !== null  && String(this.props.LogoURL).length > 0?
                        <Card
                        style={{ width: "300px" }}
                        cover={<img src={this.props.LogoURL} alt="" />}
                        >
                        <Upload
                        fileList={[]}
                        accept=".png,.jpeg,.jpg"
                        customRequest={async (info) => {
                            const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                            this.props.handleChange("LogoURL", url);
                        }}>
                            <Button icon={<UploadOutlined />}>Replace Image</Button>
                        </Upload>
                        </Card>
                        :
                        <Upload
                            fileList={[]}
                            accept=".png,.jpeg,.jpg"
                            customRequest={async (info) => {
                                const url = await this.props.handleStaticUpload('images','UploadDisabled',info);
                                this.props.handleChange("LogoURL", url);
                            }}>
                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                        </Upload>
                    }
                </Col>
            </Row>
        </Col>
        )
    }
}

export default BasicInformation;